import React from 'react';
import { Helmet } from 'react-helmet';
import MinimalLayout from '../Shared/Layout/MinimalLayout';
import './NPSUnsubscribed.scss';

const NPSUnsubscribed: React.FC = () => (
  <MinimalLayout>
    <div className="unsubscribed-page-container">
      <Helmet>
        <title>Unsubscribed Successfully | alooba.com</title>
      </Helmet>
      <h2>Unsubscribed</h2>
      <br />
      <p>You have successfully unsubscribed from future NPS-related emails.</p>
    </div>
  </MinimalLayout>
);

export default NPSUnsubscribed;
