import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { RootState } from 'store/rootReducer';
import { isObjectEmpty, getTestTabUrl } from 'helpers';
import {
  getCandidateDetails,
  loadResources,
  setCandidateCloaked,
} from 'store/actions/candidate.actions';
import PageHeader from 'components/Shared/PageHeader';
import Tooltip from 'components/Shared/Tooltip/LightTooltip';
import CandidateTabs from './CandidateTabs';
import CandidateActionList from './CandidateActionList';
import MCQTest from './Tests/MCQTest';
import PersonalityTest from './Tests/PersonalityTest';
import ResponseTest from './Tests/ResponseTest';
import '../Assessment/assessment.scss';
import '../../styles/heading.scss';
import OverviewTab from './OverviewTab/index';
import SelfAwarenessTab from './SelfAwarenessTab';
import FeedbackTab from './FeedbackTab';
import EvaluationIcon from '../../images/icons/requires-evaluation-blue.svg';
import WarningIcon from '../../images/warning.svg';
import AreasForReviewTab from '../CandidateResults/AreasForReviewTab';

const FULL_DETAILS = 8;
const BASIC_DETAILS = 6;

const notStartedStatuses = ['Invited', 'Opened Assessment', 'Expired'];
const SelfAwarenessTitle: React.FC = () => {
  return (
    <div className="tab-header">
      <h3>Self Awareness</h3>
    </div>
  );
};
const SelfAwarenessDescription: React.FC = () => {
  return (
    <div className="tab-header">
      <p className="description">
        Alooba’s self awareness is based on how you rated yourself across each
        of the skills compared with how well you actually performed in that
        skill against how other Alooba users compared themselves and performed
        across the same skills.
      </p>
    </div>
  );
};
const CandidateDetails = ({
  isPublic = false,
}: {
  isPublic?: boolean;
}): any => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { userDetails } = useSelector((state: RootState) => state.profile);

  const { currentAssessment } = useSelector(
    (state: RootState) => state.assessment
  );
  const { organizationDetails: organization } = useSelector(
    (state: RootState) => state.organization
  );
  const {
    candidate,
    candidateResources,
    selectedTestId,
    error,
    toastError,
    cloaked: candidateDetailsHidden,
  } = useSelector((state: RootState) => state.candidate);
  const subscription = useSelector((state: RootState) => state.subscription);

  const setCandidateDetailsHidden = useCallback(
    (value: boolean): void => {
      dispatch(setCandidateCloaked(value));
    },
    [dispatch]
  );

  const { reference }: { reference: string; partIndex?: string } = useParams();

  const [showTestRetakeModal, setShowTestRetakeModal] = useState<boolean>(
    false
  );
  const [trialBannerShow, setTrialBannerShow] = useState<boolean>(false);
  const [showAddTimeModal, setShowAddTimeModal] = useState<boolean>(false);

  const toggleShowTestRetakeModal = useCallback((): void => {
    setShowTestRetakeModal(!showTestRetakeModal);
  }, [showTestRetakeModal]);

  const toggleShowAddTimeModal = useCallback((): void => {
    setShowAddTimeModal(!showAddTimeModal);
  }, [showAddTimeModal]);

  const [currentToastError, setCurrentToastError] = useState<string>(null);

  const showAreasForReview = useMemo(() => {
    const isOnlyPersonalityTest =
      candidate?.tests?.length === 1 &&
      candidate?.tests[0].test_type === 'Personality Profiling';
    return (
      isPublic &&
      currentAssessment?.show_candidate_results >= BASIC_DETAILS &&
      !isOnlyPersonalityTest
    );
  }, [candidate?.tests, isPublic, currentAssessment?.show_candidate_results]);

  // Eagerly fetch the candidate Areas for Review
  useEffect(() => {
    if (showAreasForReview && candidateResources.subjects.length === 0) {
      dispatch(loadResources(reference));
    }
  }, [
    reference,
    dispatch,
    candidateResources?.subjects?.length,
    showAreasForReview,
  ]);

  useEffect(() => {
    if (toastError && toastError !== currentToastError) {
      addToast({
        type: 'error',
        msg: toastError,
      });
      setCurrentToastError(toastError);
    }
  }, [toastError, addToast, currentToastError]);

  /**
   * If the candidate is not set in the store, dispatch the action to fetch
   * it from the backend
   */
  useEffect(() => {
    if (
      !candidate ||
      !candidate.reference ||
      (candidate.reference !== reference && !isPublic)
    ) {
      dispatch(getCandidateDetails(reference, false, isPublic));
    }
  }, [isPublic, candidate, dispatch, reference]);

  useEffect(() => {
    setCandidateDetailsHidden(
      currentAssessment?.cloak_candidates ||
        organization?.cloak_candidates ||
        userDetails?.recruiter_detail?.cloak_candidates
    );
  }, [
    organization?.cloak_candidates,
    currentAssessment?.cloak_candidates,
    userDetails?.recruiter_detail,
    setCandidateDetailsHidden,
  ]);

  useEffect(() => {
    setTrialBannerShow(subscription?.subscription?.status === 'trialing');
  }, [subscription?.subscription?.status]);

  const getTestComponent = useCallback(
    (test): any => {
      if (!currentAssessment?.id) {
        return null;
      }
      const testTabProps = {
        testId: test.id,
        testType: test.test_type,
        candidateTestId: candidate.id,
        candidateReference: candidate.reference,
        recruiterTestId: currentAssessment.id,
        toggleShowTestRetakeModal,
        toggleShowAddTimeModal,
        hideSensitiveInfo: isPublic,
      };

      if (test.test_type === 'MCQ' || test.test_type === 'Data Analysis') {
        return <MCQTest {...testTabProps} />;
      } else if (test.test_type === 'Personality Profiling') {
        return <PersonalityTest {...testTabProps} />;
      } else {
        return <ResponseTest {...testTabProps} />;
      }
    },
    [
      currentAssessment,
      candidate,
      toggleShowTestRetakeModal,
      toggleShowAddTimeModal,
      isPublic,
    ]
  );
  const url = isPublic ? 'test-completed' : 'candidate-details';

  const tabsData = useMemo(() => {
    const tabs = [
      {
        tabTitle: 'Overview',
        contentLink: `/${url}/${reference}/overview`,
        component: (
          <OverviewTab
            candidateDetailsHidden={candidateDetailsHidden}
            setCandidateDetailsHidden={setCandidateDetailsHidden}
            hideSensitiveInfo={isPublic}
          />
        ),
        icon: undefined,
        disabled: false,
        is_test: false,
      },
    ];
    if (showAreasForReview) {
      tabs.push({
        tabTitle: 'Areas For Review',
        contentLink: `/${url}/${reference}/areas-for-review`,
        component: <AreasForReviewTab />,
        icon: undefined,
        disabled: false,
        is_test: false,
      });
    }
    if (
      candidate &&
      candidate.score !== null &&
      !notStartedStatuses.includes(candidate?.status) &&
      currentAssessment?.show_candidate_results >= BASIC_DETAILS
    ) {
      tabs.push({
        tabTitle: 'Self Awareness',
        contentLink: `/${url}/${reference}/self-awareness`,
        component: (
          <SelfAwarenessTab
            candidate={candidate}
            isVerificationCode={isPublic}
            title={isPublic ? <SelfAwarenessTitle /> : null}
            description={isPublic ? <SelfAwarenessDescription /> : null}
          />
        ),
        icon: undefined,
        disabled: false,
        is_test: false,
      });
    }
    if (
      !candidate?.tests?.length &&
      !notStartedStatuses.includes(candidate?.status)
    ) {
      tabs.push({
        tabTitle: '',
        contentLink: '#',
        component: <></>,
        icon: <Skeleton width={124} height={24} />,
        disabled: true,
        is_test: true,
      });
    }
    for (let i = 1; i <= candidate?.tests?.length; i += 1) {
      const tabTitle =
        candidate?.tests[i - 1].name ??
        (candidate?.tests[i - 1].test_type === 'MCQ'
          ? 'Concepts & Knowledge'
          : candidate?.tests[i - 1].test_type);
      const requiresEvaluation =
        candidate?.tests[i - 1].evaluation_status === 'Requires Evaluation';
      if (
        !isPublic ||
        currentAssessment?.show_candidate_results >= FULL_DETAILS
      ) {
        tabs.push({
          tabTitle,
          contentLink: `/${url}/${reference}/${getTestTabUrl(tabTitle)}`,
          component: getTestComponent(candidate?.tests[i - 1]),
          icon: requiresEvaluation ? (
            <Tooltip title="Requires Evaluation" placement="top" arrow>
              <img src={EvaluationIcon} alt="icon" />
            </Tooltip>
          ) : undefined,
          disabled: false,
          is_test: true,
        });
      }
    }
    if (
      candidate &&
      !notStartedStatuses.includes(candidate?.status) &&
      !isPublic
    ) {
      tabs.push({
        tabTitle: 'Feedback',
        contentLink: `/${url}/${reference}/feedback`,
        component: (
          <FeedbackTab
            candidate={candidate}
            shouldCloakPII={candidateDetailsHidden}
            purpose={currentAssessment?.purpose}
          />
        ),
        icon: undefined,
        disabled: false,
        is_test: true,
      });
    }
    return tabs;
  }, [
    url,
    reference,
    candidateDetailsHidden,
    setCandidateDetailsHidden,
    isPublic,
    showAreasForReview,
    candidate,
    currentAssessment?.show_candidate_results,
    currentAssessment?.purpose,
    getTestComponent,
  ]);

  const loading = useMemo(() => {
    return isObjectEmpty(currentAssessment) || !candidate;
  }, [currentAssessment, candidate]);

  const nameOrReference = useMemo((): any => {
    if (
      userDetails?.recruiter_detail?.cloak_candidates ||
      organization.cloak_candidates ||
      currentAssessment.cloak_candidates
    ) {
      return candidate?.reference;
    }
    return candidate?.full_name;
  }, [
    candidate,
    userDetails.recruiter_detail,
    organization?.cloak_candidates,
    currentAssessment?.cloak_candidates,
  ]);

  const ActionListProps = useMemo(
    () => ({
      candidate,
      userDetails,
      showTestRetakeModal,
      setShowTestRetakeModal,
      showAddTimeModal,
      setShowAddTimeModal,
      testId: selectedTestId,
      assessment: currentAssessment,
      candidateDetailsHidden,
    }),
    [
      candidate,
      userDetails,
      showTestRetakeModal,
      showAddTimeModal,
      selectedTestId,
      currentAssessment,
      candidateDetailsHidden,
    ]
  );
  const PageHeaderProps = {
    assessment: currentAssessment,
    trialBannerShow,
    candidateName: nameOrReference,
    isCandidateErased: candidate?.is_erased,
    ActionListComponent: isPublic ? null : (
      <CandidateActionList {...ActionListProps} />
    ),
    loading,
    disableBreadcrumb: isPublic,
  };
  const CandidateTabsProps = {
    tabsData,
    containerWidth: 0,
    trialBannerShow,
  };

  if (error) {
    return (
      <div className="error-page-container">
        <h2>
          <img src={WarningIcon} alt="Error" /> This page isn&apos;t available
        </h2>
        <br />
        <p>{error}</p>
        <p>Check to see if the link you&apos;re trying to open is correct.</p>
      </div>
    );
  }

  return (
    <div
      className={`assessment-container ${trialBannerShow ? 'has-banner' : ''}`}
    >
      <PageHeader {...PageHeaderProps} />
      <CandidateTabs {...CandidateTabsProps} />
    </div>
  );
};

export default CandidateDetails;
