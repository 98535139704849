import React from 'react';
import ImportCandidateIcon from 'images/icons/import-candidates-blue.svg';
import VerticalDivider from '../../Shared/VerticalDivider';

const ImportCandidatesButton = (props: {
  assessment: any;
  divider: boolean;
}): JSX.Element => {
  const { assessment, divider } = props;
  const legacyUrl = process.env.REACT_APP_ALOOBA_LEGACY_URL;
  const linkTo = `${legacyUrl}/add-candidate-import/?test_id=${assessment.id}&purpose=${assessment.purpose}`;
  const label =
    assessment.purpose === 'ld' ? 'Import Team Members' : 'Import Candidates';
  return (
    <>
      <a href={linkTo} className="button-bar-item">
        <img src={ImportCandidateIcon} alt={label || 'tooltip'} />
        <span className="button-bar-label">{label}</span>
      </a>
      {divider ? <VerticalDivider className="button-bar-divider" /> : ''}
    </>
  );
};

export default ImportCandidatesButton;
