import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import CardContainer from '../MainContainer/CardContainer';
import ProgressBar from './progressBar';
import './stackedProgresssBars.scss';
import GraphicPlaceholder from '../GraphicPlaceholder';
import info from '../../../images/icons/question-mark.svg';
import { PROGRESSBAR_COLORS } from './colors';

const StackedProgressBars: React.FC<{
  title: string;
  questions: Array<any>;
  options: Array<any>;
  summary: any;
  tooltipText?: string;
}> = ({ title, questions, options, summary, tooltipText }): JSX.Element => {
  const neutralOrBetterTitle = (className: string, value: any): string => {
    if (className === 'error-text') {
      return `Only ${value}% of responses were neutral or positive. This looks concerning. Check in with your Alooba account manager to understand how to improve this.`;
    }
    return `${value}% of responses were neutral or positive.`;
  };
  return (
    <CardContainer>
      {summary.num_feedback ? (
        <>
          <Grid container xs={12} spacing={0} justifyContent="space-between">
            <Grid item>
              <h3 className="spacing-bottom">
                {title}{' '}
                <span>
                  <Tooltip arrow title={tooltipText} placement="top">
                    <img width={15} src={info} alt="Info" />
                  </Tooltip>
                </span>
              </h3>
            </Grid>
            <Grid item>
              <Grid container item spacing={4}>
                {options.map((option) => (
                  <Grid item className="legend-container">
                    <Grid container alignItems="center">
                      <span
                        className="legend-color"
                        style={{
                          backgroundColor: PROGRESSBAR_COLORS[option.key],
                        }}
                      />
                      <h5 className="text-light">{option.title}</h5>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {questions.map((question) => (
            <ProgressBar
              key={question.no}
              question={question}
              options={options}
              summary={summary}
              getTitle={neutralOrBetterTitle}
            />
          ))}
        </>
      ) : (
        <div className="overview-carousel-placeholder-container">
          <GraphicPlaceholder variant="pie" />
        </div>
      )}
    </CardContainer>
  );
};
export default StackedProgressBars;
