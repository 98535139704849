import React, { useState, useRef, useMemo, useEffect } from 'react';
import DownloadResultsIcon from 'images/icons/download-results-blue.svg';
import RequestRetakeIcon from 'images/icons/reload-icon-blue.svg';
import DeleteIcon from 'images/icons/delete-icon-blue.svg';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import StatIcon from 'components/Shared/StatIcon';
import { getFormattedAssessmentName } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getOrgDetails } from 'store/actions/organization.actions';
import ExtendExpiryModal from 'components/Participants/Modals/ExtendExpiryModal';
import {
  changeCandidateTestStatus,
  setExpiresOn,
} from 'store/reducers/candidate';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import authorize, { hasOrgFeature } from '../../Authorization/Check';
import DeleteCandidateModal from '../Shared/Modals/DeleteCandidateModal';
import RetakeAssessmentModal from './Modals/RetakeAssessmentModal';
import '../AssessmentActionList/index.scss';
import AddTimeModal from './Modals/AddTimeModal';

interface Props {
  userDetails: any;
  assessment: any;
  candidate: any;
  testId?: number | null;
  showTestRetakeModal?: any;
  setShowTestRetakeModal?: any;
  showAddTimeModal?: any;
  setShowAddTimeModal?: any;
  candidateDetailsHidden?: boolean;
}

const STATUSES_WITH_DOWNLOADABLE_RESULTS = ['Completed'];

const CandidateActionList = ({
  assessment,
  candidate,
  userDetails,
  testId,
  showTestRetakeModal,
  setShowTestRetakeModal,
  showAddTimeModal,
  setShowAddTimeModal,
  candidateDetailsHidden,
}: Props): JSX.Element => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRetakeModal, setShowRetakeModal] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showExtendExpiryModal, setShowExtendExpiryModal] = useState<boolean>(
    false
  );
  const legacyUrl = process.env.REACT_APP_ALOOBA_LEGACY_URL;
  const candidateWord = assessment.purpose === 'ld' ? 'employee' : 'candidate';

  const organizationCloakedCandidates = useSelector(
    (state: RootState) =>
      state.organization.organizationDetails?.cloak_candidates
  );
  const recruiterCloakedCandidates = useSelector(
    (state: RootState) =>
      state.profile.userDetails.recruiter_detail?.cloak_candidates
  );
  const assessmentCloakedCandidates = useSelector(
    (state: RootState) => state.assessment.currentAssessment?.cloak_candidates
  );

  const canExportResults = hasOrgFeature('export_results');

  const dispatch = useDispatch();

  const shouldCloakPII = useMemo(
    () =>
      (organizationCloakedCandidates ||
        recruiterCloakedCandidates ||
        assessmentCloakedCandidates) &&
      candidateDetailsHidden,
    [
      organizationCloakedCandidates,
      recruiterCloakedCandidates,
      assessmentCloakedCandidates,
      candidateDetailsHidden,
    ]
  );

  const deleteModalProps = useRef(null);
  deleteModalProps.current = {
    setModalVisibility: setShowDeleteModal,
    isShown: showDeleteModal,
    is_cloaked: shouldCloakPII,
    redirectTo: `/assessment/${assessment.id}-${getFormattedAssessmentName(
      assessment.recruiter_test_name
    )}/overview`,
    candidate,
  };

  const retakeModalProps = useRef(null);
  retakeModalProps.current = {
    setModalVisibility: setShowRetakeModal,
    isShown: showRetakeModal,
    userDetails,
    assessment,
    candidate,
    test_ids: candidate.tests
      ?.filter(
        (part) => part.test_type !== null && part.test_type !== 'Interview'
      )
      .map((test) => test.id),
  };

  const testRetakeModalProps = useRef(null);
  testRetakeModalProps.current = {
    setModalVisibility: setShowTestRetakeModal,
    isShown: showTestRetakeModal,
    assessment,
    test_ids: testId
      ? [testId]
      : candidate.tests
          ?.filter(
            (part) => part.test_type !== null && part.test_type !== 'Interview'
          )
          .map((test) => test.id),
    userDetails,
    candidate,
  };

  const addTimeModalProps = {
    setModalVisibility: setShowAddTimeModal,
    shouldCloakPII,
    isShown: showAddTimeModal,
    assessment,
    test_id: testId,
    userDetails,
    candidate,
  };

  const extendExpiryModalProps = useRef(null);
  const row = {
    candidate_test_id: candidate.id,
    Reference: candidate.reference,
    Name: candidate.full_name,
    Email: candidate.email_address,
  };
  row['Expires On'] = candidate.expires_on;
  extendExpiryModalProps.current = {
    setModalVisibility: setShowExtendExpiryModal,
    isShown: showExtendExpiryModal,
    row,
    assessment,
    userDetails,
    shouldCloakPII,
    onSuccess: (expiryDate: string, candidateStatus: string) => {
      dispatch(setExpiresOn({ expires_on: expiryDate }));
      dispatch(changeCandidateTestStatus({ status: candidateStatus }));
    },
  };

  useEffect(() => {
    const orgId = userDetails?.recruiter_detail?.organisation_id;
    if (orgId && organizationCloakedCandidates === null) {
      dispatch(getOrgDetails(orgId));
    }
  }, [dispatch, userDetails, organizationCloakedCandidates]);

  const showRequestRetake = useMemo(() => {
    return (
      !assessment.is_archived &&
      candidate.tests &&
      candidate.tests.length &&
      candidate.tests.some((test) => test.is_submitted) &&
      userDetails &&
      !candidate?.is_erased
    );
  }, [
    candidate.tests,
    userDetails,
    candidate?.is_erased,
    assessment.is_archived,
  ]);

  const showDownloadResults = useMemo(() => {
    return (
      candidate.tests &&
      candidate.tests.length &&
      STATUSES_WITH_DOWNLOADABLE_RESULTS.includes(candidate.status) &&
      userDetails
    );
  }, [candidate, userDetails]);

  const downloadReportTooltipMsg = useMemo((): string => {
    if (candidate.is_erased) {
      return 'This candidate requested their data to be erased, the summary is not available.';
    }
    if (candidate.status === 'Completed') {
      return 'Download Summary Report';
    }
    if (candidate.status === 'Requires Evaluation') {
      return 'The download summary will be available once the assessment is evaluated.';
    }
    return 'The download summary will be available once the assessment is completed.';
  }, [candidate.status, candidate.is_erased]);

  const showExtendExpiry = useMemo(() => {
    return Boolean(
      !assessment.is_archived &&
        candidate?.can_extend_expiry_date !== undefined &&
        userDetails
    );
  }, [candidate?.can_extend_expiry_date, userDetails, assessment.is_archived]);

  const isExtendExpiryEnabled = !!candidate.can_extend_expiry_date;

  const extendExpiryDateTooltipMsg = useMemo((): string => {
    if (candidate.can_extend_expiry_date) {
      return 'Extend Expiry Date';
    }
    if (candidate.is_erased) {
      return 'This candidate requested their data to be erased, the expiry date cannot be extended.';
    }
    return 'The expiry date cannot be extended once the assessment is completed.';
  }, [candidate.can_extend_expiry_date, candidate.is_erased]);

  const handleDownloadResultsClick = (candidate: any): void => {
    if (!canExportResults) {
      setShowUpgradeModal(true);
      return;
    }
    const link =
      assessment.purpose === 'hiring'
        ? `/candidate-report/${candidate?.reference}`
        : `${legacyUrl}/download-candidate-result-${candidate?.id}`;
    window.open(link, '_blank');
  };

  return (
    <>
      <div
        className="assessment-page-actions"
        onClick={(e: React.SyntheticEvent<EventTarget>) => e.stopPropagation()}
        onKeyDown={(e: React.SyntheticEvent<EventTarget>) =>
          e.stopPropagation()
        }
        role="button"
        tabIndex={0}
      >
        {authorize(
          'assessment-candidate',
          'view',
          candidate?.recruiter_user_id
        ) &&
          !candidateDetailsHidden &&
          !!showDownloadResults && (
            <StatIcon
              id="download-results-action"
              tooltip={downloadReportTooltipMsg}
              icon={DownloadResultsIcon}
              alt={downloadReportTooltipMsg}
              linkTarget="_blank"
              handleImageClick={() => handleDownloadResultsClick(candidate)}
              disabled={candidate.status !== 'Completed' || candidate.is_erased}
            />
          )}
        {authorize(
          'assessment-candidate',
          'edit',
          candidate?.recruiter_user_id
        ) && (
          <>
            {showRequestRetake ? (
              <StatIcon
                id="request-retake-action"
                tooltip="Request Retake"
                icon={RequestRetakeIcon}
                alt="Request Retake"
                isLink
                handleImageClick={() => setShowRetakeModal(true)}
              />
            ) : (
              ''
            )}
            {showExtendExpiry && (
              <StatIcon
                id="extend-expiry-date-action"
                tooltip={extendExpiryDateTooltipMsg}
                faIcon={faCalendar}
                alt="Extend Expiry Date"
                disabled={!isExtendExpiryEnabled}
                isLink
                handleImageClick={() => setShowExtendExpiryModal(true)}
              />
            )}
          </>
        )}
        {authorize(
          'assessment-candidate',
          'delete',
          candidate?.recruiter_user_id
        ) && (
          <StatIcon
            id="delete-candidate-action"
            tooltip="Delete Candidate"
            icon={DeleteIcon}
            alt="Delete Candidate"
            isLink
            handleImageClick={() => setShowDeleteModal(true)}
          />
        )}
      </div>

      {candidate ? <DeleteCandidateModal {...deleteModalProps.current} /> : ''}
      {showRequestRetake ? (
        <RetakeAssessmentModal {...retakeModalProps.current} />
      ) : (
        ''
      )}
      <PlanUpgradeModal
        isShown={showUpgradeModal}
        setModalVisibility={setShowUpgradeModal}
        title={`Upgrade Your Account to Download Sharable ${
          candidateWord[0].toUpperCase() + candidateWord.slice(1)
        } Reports`}
      >
        <p>
          Sharable {candidateWord} summary reports are not supported on your
          current subscription plan. Upgrade your subscription to gain access to{' '}
          {candidateWord} reports as well as many other amazing features Alooba
          has to offer.
        </p>
      </PlanUpgradeModal>

      {testId ? (
        <RetakeAssessmentModal {...testRetakeModalProps.current} />
      ) : (
        ''
      )}
      {testId ? <AddTimeModal {...addTimeModalProps} /> : ''}

      {!!candidate && <ExtendExpiryModal {...extendExpiryModalProps.current} />}
    </>
  );
};

export default CandidateActionList;
