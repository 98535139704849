import axios from 'axios';
import { handleError } from 'handleError';
import { AppThunk } from '../store';
import {
  getCandidateStart,
  getCandidateSuccess,
  getCandidateFailure,
  getTestResultsSuccess,
  getTestResultsFailure,
  saveCommentFailure,
  pushCandidateComment,
  deleteCommentSuccess,
  editCommentSuccess,
  saveNewCommentStart,
  clearCommentFailure,
  editHiringStatusSuccess,
  editHiringStatusFailure,
  Comment,
  getSelfAwarenessStart,
  getSelfAwarenessSuccess,
  getSelfAwarenessFailure,
  resetHiringStatus,
  setInsightsLoadingState,
  setQuestionScore,
  setSubjectScore,
  setTotalScore,
  getResourcesStart,
  getResourcesSuccess,
  getResourcesFailure,
  getSummaryStart,
  getSummarySuccess,
  getSummaryFailure,
  calculateTestSubjectScore,
  getTestEventsSuccess,
  getTestEventsFailure,
  setCloaked,
} from '../reducers/candidate';
import { selectAssessment } from '../reducers/assessment';
import {
  getCandidateByReference,
  updateHiringStatus,
  getTestByReferenceAndId,
  getSelfAwarenessScore,
  getCandidateByVerificationCode,
  getSelfAwarenessScoreByVerificationCode,
  getTestByVerificationCodeAndId,
  getResourcesByVerificationCode,
  getTestEventsByReferenceAndId,
  getSummaryByVerificationCode,
} from '../../api/candidate.api';
import {
  saveNewComment,
  deleteComment,
  updateComment,
} from '../../api/comment.api';

export const NO_TEST_SCORE_AVAILABLE = 'no_test_score_available';

export const addAssessmentComment = (
  comment: string,
  reference: string
): AppThunk => async (dispatch, useData) => {
  dispatch(saveNewCommentStart());
  const { profile } = useData();
  const name = `${profile.userDetails.first_name} ${profile.userDetails.last_name}`;

  dispatch(clearCommentFailure());
  // add the placeholder text to make it feel super fast
  dispatch(
    pushCandidateComment({
      comment,
      created_at: new Date().toISOString(),
      name,
      id: 0,
      user_id: 0,
      writable: false,
    })
  );
  saveNewComment(comment, reference)
    .then((response) => {
      dispatch(
        pushCandidateComment({
          comment: response.data.data.comment,
          created_at: response.data.data.created_at,
          name: response.data.data.name,
          id: response.data.data.id,
          user_id: response.data.data.user_id,
          writable: response.data.data.writable,
        })
      );
    })
    .catch((e) => {
      handleError(Object.assign(e, { stack: new Error().stack }));
      dispatch(
        saveCommentFailure({
          message: 'Comment could not be added. Please try again.',
        })
      );
    })
    .finally(() => {
      dispatch(deleteCommentSuccess(0)); // remove the placeholder comment
    });
};
export const destroyComment = (
  reference: string,
  commentId: number
): AppThunk => async (dispatch) => {
  dispatch(clearCommentFailure());
  deleteComment(reference, commentId)
    .then(() => {
      dispatch(deleteCommentSuccess(commentId));
    })
    .catch((err) => {
      handleError(Object.assign(err, { stack: new Error().stack }));
      dispatch(
        saveCommentFailure({
          message: 'Comment could not be deleted. Please try again.',
          id: commentId,
        })
      );
    });
};
export const editComment = (
  reference: string,
  comment: Comment
): AppThunk => async (dispatch, useState) => {
  const state = useState();
  const i = state.candidate.candidate.comments.findIndex(
    (c) => c.id === comment.id
  );
  const originalComment = state.candidate.candidate.comments[i];

  dispatch(editCommentSuccess(comment));
  updateComment(reference, comment.id, comment.comment).catch((e) => {
    handleError(e);
    dispatch(clearCommentFailure());
    dispatch(editCommentSuccess(originalComment));
    dispatch(
      saveCommentFailure({
        message: 'Comment could not be updated. Please try again.',
        id: comment.id,
      })
    );
  });
};
export const setHiringStatus = (
  reference: string,
  hiringStatus: string,
  rejectionDetails?: {
    reason: string;
    note?: string;
    notifyCandidate: boolean;
  }
): AppThunk => async (dispatch, useData) => {
  const { candidate } = useData();
  const candidateSnapshot = candidate.candidate;
  dispatch(
    getCandidateSuccess({
      ...candidateSnapshot,
      hiring_status: hiringStatus,
    })
  );
  return updateHiringStatus(reference, hiringStatus, rejectionDetails)
    .then(() => {
      dispatch(editHiringStatusSuccess());
    })
    .catch((e) => {
      handleError(Object.assign(e, { stack: new Error().stack }));
      dispatch(getCandidateSuccess(candidateSnapshot));
      dispatch(
        editHiringStatusFailure(
          'Hiring status could not be changed. Try again.'
        )
      );
    });
};

export const acknowledgeHiringStatusError = (): AppThunk => async (
  dispatch
) => {
  dispatch(editHiringStatusFailure(null));
};
export const acknowledgeHiringStatusUpdated = (): AppThunk => async (
  dispatch
) => {
  dispatch(resetHiringStatus());
};
const getCandidate = (
  reference: string,
  uncloak = false,
  isVerificationCode = false
): Promise<any> => {
  if (isVerificationCode) {
    return getCandidateByVerificationCode(reference, uncloak);
  }
  return getCandidateByReference(reference, uncloak);
};
export const getCandidateDetails = (
  reference: string,
  uncloak = false,
  isVerificationCode = false
): AppThunk => async (dispatch) => {
  dispatch(getCandidateStart());
  getCandidate(reference, uncloak, isVerificationCode)
    .then((response) => {
      // Set the candidate data in the store
      dispatch(
        getCandidateSuccess({
          id: response.data.data.id,
          email_address: response.data.data.email_address,
          first_name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          full_name: `${response.data.data.first_name} ${response.data.data.last_name}`,
          reference: response.data.data.reference,
          phone: response.data.data.phone,
          invited_at: response.data.data.invited_at,
          expires_on: response.data.data.expires_on,
          score: response.data.data.score,
          score_percent: response.data.data.score_percent,
          submitted_at: response.data.data.submitted_at,
          information_fields: response.data.data.information_fields,
          related_assessments: response.data.data.related_assessments,
          suspicious_activities: response.data.data.suspicious_activities,
          hiring_status: response.data.data.hiring_status,
          comments: response.data.data.comments,
          feedback: response.data.data.feedback,
          tests: response.data.data.tests.sort((a, b) =>
            a.part_index > b.part_index ? 1 : -1
          ),
          status: response.data.data.status ?? 'Invited',
          enable_snapshot: response.data.data.enable_snapshot,
          enable_screen_recording:
            response.data.data.recruiter_test.enable_screen_recording,
          playback_url: response.data.data.playback_url,
          video_records: response.data.data.video_records,
          snapshots: response.data.data.snapshots,
          recruiter_user_id: response.data.data.recruiter_user_id,
          invite_source: response.data.data.invite_source,
          recruiter: response.data.data.recruiter,
          resume_url: response.data.data.resume_url,
          organisation_name: response.data.data.organisation_name,
          percentile: response.data.data.percentile,
          subject_scores: response.data.data.subject_scores,
          assessment_modified_after_submission:
            response.data.data.assessment_modified_after_submission,
          candidates_scores_buckets:
            response.data.data.candidates_scores_buckets,
          candidates_skills_scores_buckets:
            response.data.data.candidates_skills_scores_buckets,
          is_erased: response.data.data.is_erased,
          can_compare: response.data.data.can_compare,
          can_extend_expiry_date: response.data.data.can_extend_expiry_date,
        })
      );

      // Update the current assessment data on the store
      dispatch(
        selectAssessment({
          assessment: response.data.data.recruiter_test,
        })
      );
    })
    .catch((error: Error) => {
      handleError(Object.assign(error, { stack: new Error().stack }));
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(
            getCandidateFailure(
              'The link may be broken, or the candidate may have been deleted.'
            )
          );
        } else if (
          error.response?.data.errors &&
          error.response?.data.errors.length > 0
        ) {
          dispatch(getCandidateFailure(error.response?.data.errors[0].detail));
        } else {
          dispatch(
            getCandidateFailure('Could not fetch candidate from server')
          );
        }
      }
    });
};
const getTest = (
  reference: string,
  testId: number,
  isVerificationCode = false
): Promise<any> => {
  if (isVerificationCode) {
    return getTestByVerificationCodeAndId(reference, testId);
  }
  return getTestByReferenceAndId(reference, testId);
};
export const getTestDetails = (
  reference: string,
  testId: number,
  isVerificationCode = false
): AppThunk => async (dispatch, useState) => {
  getTest(reference, testId, isVerificationCode)
    .then((response) => {
      dispatch(
        getTestResultsSuccess({
          testId,
          test: {
            ...response.data.data,
            questions: response.data.data.questions.sort((a, b) =>
              parseInt(a.position, 10) > parseInt(b.position, 10) ? 1 : -1
            ),
            subjects_with_questions: formatSubjectsAndQuestions(
              response.data.data.subject_scores,
              response.data.data.questions
            ),
          },
        })
      );

      const state = useState();
      const { subjects_with_questions } = state.candidate.results.tests[testId];

      for (let i = 0; i < subjects_with_questions.length; i += 1) {
        const fullMarked = subjects_with_questions[i].questions.reduce(
          (isPreviousMarked, question) => {
            return isPreviousMarked && question.score !== null;
          },
          true
        );
        if (fullMarked) {
          dispatch(
            calculateTestSubjectScore({
              testId,
              subjectIndex: i,
            })
          );
        }
      }
    })
    .catch((e) => {
      handleError(Object.assign(e, { stack: new Error().stack }));
      dispatch(getTestResultsFailure(testId));
    });
};

export const getTestEvents = (
  reference: string,
  testId: number
): AppThunk => async (dispatch) => {
  getTestEventsByReferenceAndId(reference, testId)
    .then((response) => {
      dispatch(
        getTestEventsSuccess({
          testId,
          events: response.data.data,
        })
      );
    })
    .catch((e) => {
      handleError(Object.assign(e, { stack: new Error().stack }));
      dispatch(getTestEventsFailure({ testId, details: 'test' }));
    });
};
export const getSelfAwareness = (
  reference: string,
  isVerificationCode = false
): Promise<any> => {
  if (isVerificationCode) {
    return getSelfAwarenessScoreByVerificationCode(reference);
  }
  return getSelfAwarenessScore(reference);
};
export const loadSelfAwarenessData = (
  reference: string,
  isVerificationCode = false
): AppThunk => async (dispatch) => {
  dispatch(getSelfAwarenessStart());
  getSelfAwareness(reference, isVerificationCode)
    .then((response) => {
      dispatch(getSelfAwarenessSuccess(response.data.data));
    })
    .catch((e) => {
      handleError(Object.assign(e, { stack: new Error().stack }));
      dispatch(
        getSelfAwarenessFailure(
          'Failed to load candidate self-awareness details'
        )
      );
    });
};

export const getResources = (reference: string): Promise<any> => {
  return getResourcesByVerificationCode(reference);
};
export const loadResources = (reference: string): AppThunk => async (
  dispatch
) => {
  dispatch(getResourcesStart());
  getResources(reference)
    .then((response) => {
      dispatch(getResourcesSuccess(response.data.data));
      if (response.data.data.is_summary_enabled) {
        dispatch(loadSummary(reference));
      }
    })
    .catch(() => {
      dispatch(getResourcesFailure('Failed to load candidate resources'));
    });
};

export const getSummary = (reference: string): Promise<any> => {
  return getSummaryByVerificationCode(reference);
};
export const loadSummary = (reference: string): AppThunk => async (
  dispatch
) => {
  dispatch(getSummaryStart());
  getSummary(reference)
    .then((response) => {
      dispatch(getSummarySuccess(response.data.data));
    })
    .catch(() => {
      dispatch(getSummaryFailure('Failed to load candidate summary'));
    });
};

export const recalculateTotalScore = (
  testId: number,
  assessment_score: any,
  test_scores: any[]
): AppThunk => async (dispatch, useState) => {
  const state = useState();

  const subjects =
    state.candidate.results.tests[testId].subjects_with_questions;
  let unevaluatedSubjects = subjects.length;

  for (let i = 0; i < subjects.length; i += 1) {
    if (!subjects.max_score || subjects[i].score !== NO_TEST_SCORE_AVAILABLE) {
      unevaluatedSubjects -= 1;
    }
  }

  let testIndex = 0;
  for (let i = 0; i < state.candidate.candidate.tests.length; i += 1) {
    if (state.candidate.candidate.tests[i].id === testId) {
      testIndex = i;
      break;
    }
  }

  if (unevaluatedSubjects <= 0) {
    // Update overall score if all subjects have a score
    let score;
    let maxScore;
    for (let i = 0; i < test_scores.length; i += 1) {
      if (test_scores[i].test_id === testId) {
        score = test_scores[i].score;
        maxScore = test_scores[i].max_score;
        break;
      }
    }
    const overallScore = assessment_score;

    dispatch(
      setTotalScore({
        testId,
        testIndex,
        overallScore,
        score,
        maxScore,
      })
    );
  }
};

export const updateScores = (
  testId: number,
  subjectIndex: number,
  assessment_score: any,
  test_scores: any[],
  subject_scores: any[]
): AppThunk => async (dispatch, useState) => {
  const state = useState();

  const subject =
    state.candidate.results.tests[testId].subjects_with_questions[subjectIndex];
  let unanswered = 0;

  state.candidate.results.tests[testId].questions.forEach((question) => {
    if (question.max_score > 0 && question.score === null) {
      unanswered += 1;
    }
  });

  let scoredPercent;

  for (let i = 0; i < subject_scores.length; i += 1) {
    if (subject_scores[i].subject_id === subject.subject_id) {
      scoredPercent = Math.round(subject_scores[i].scored_percent);
      break;
    }
  }

  const isSubjectFullyMarked = subject.questions.reduce(
    (isPreviousMarked, question) => {
      return isPreviousMarked && question.score !== null;
    },
    true
  );

  if (isSubjectFullyMarked) {
    dispatch(
      calculateTestSubjectScore({
        testId,
        subjectIndex,
      })
    );
    dispatch(
      setSubjectScore({
        testId,
        subjectIndex,
        scoredPercent,
      })
    );
  }
  if (unanswered <= 0) {
    dispatch(recalculateTotalScore(testId, assessment_score, test_scores));
  }
};

export const updateQuestionScore = (
  question: any,
  testId: number,
  selectedQuestionIndex: number,
  marks: number,
  assessment_score: any,
  test_scores: any[],
  subject_scores: any[]
): AppThunk => async (dispatch, useState) => {
  const state = useState();
  const subjectsWithQuestions =
    state.candidate.results.tests[testId].subjects_with_questions;
  let found = false;
  let subjectIndex = -1;
  let questionIndex = -1;

  for (let i = 0; i < subjectsWithQuestions.length; i += 1) {
    for (let j = 0; j < subjectsWithQuestions[i].questions.length; j += 1) {
      if (
        subjectsWithQuestions[i].questions[j].question_id ===
        question.question_id
      ) {
        subjectIndex = i;
        questionIndex = j;
        found = true;
        break;
      }
    }
    if (found) break;
  }

  dispatch(
    setQuestionScore({
      testId,
      selectedQuestionIndex,
      subjectIndex,
      questionIndex,
      marks,
    })
  );

  dispatch(
    updateScores(
      testId,
      subjectIndex,
      assessment_score,
      test_scores,
      subject_scores
    )
  );
};

// Helper functions
export const formatSubjectsAndQuestions = (subjectScores, questions): any[] => {
  // Setup initial subjects map and question list
  const result = [];
  const subjectsMap = {};
  subjectScores.forEach((subject) => {
    subjectsMap[subject.subject_id] = subject;
  });
  const sortedQuestions = questions.sort((a, b) =>
    parseInt(a.position, 10) > parseInt(b.position, 10) ? 1 : -1
  );
  const subjectsOrdered = [];
  sortedQuestions.forEach((question) => {
    if (question.primary_subject) {
      /* For Interview questions, it is divided into 2 section: Interview Content and Additional Criteria
       * Both sections can have questions with same skills. So to differentiate, if question_type is additional
       * criteria, we add a separate subject group for it.
       */
      let subjectQuasiId = question.primary_subject.id;
      if (question.question_type?.question_type === 'Additional Criteria') {
        subjectQuasiId += 'ac';
      }
      if (!subjectsOrdered.includes(subjectQuasiId)) {
        subjectsOrdered.push(subjectQuasiId);
      }
    } else if (question.subjects?.length) {
      if (!subjectsOrdered.includes(question.subjects[0].id)) {
        subjectsOrdered.push(question.subjects[0].id);
      }
    }
  });

  subjectsOrdered.forEach((subjectId) => {
    let realSubjectId = subjectId;
    if (typeof realSubjectId === 'string' && realSubjectId.endsWith('ac')) {
      realSubjectId = parseInt(realSubjectId.slice(0, -2), 10);
    }
    const extraData = subjectsMap[realSubjectId]
      ? subjectsMap[realSubjectId]
      : {
          score: NO_TEST_SCORE_AVAILABLE,
        };
    const subjectQuestions = sortedQuestions.filter((question) => {
      if (question.primary_subject) {
        let subjectQuasiId = question.primary_subject.id;
        if (question.question_type?.question_type === 'Additional Criteria') {
          subjectQuasiId += 'ac';
        }
        return subjectQuasiId === subjectId;
      }
      if (question.subjects?.length) {
        return question.subjects[0].id === subjectId;
      }
      return false;
    });

    const currentSubject =
      subjectQuestions[0].primary_subject ||
      subjectQuestions[0].subjects.find(
        (subject) => subject.id === subjectId
      ) ||
      subjectQuestions[0].subjects[0];

    result.push({
      ...extraData,
      description: currentSubject.description,
      subject_id: currentSubject.id,
      subject: currentSubject.subject,
      questions: subjectQuestions,
      collapsed: false,
    });
  });
  return result;
};

export const setInsightsLoading = (isLoading: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(setInsightsLoadingState(isLoading));
};

export const setCandidateCloaked = (cloaked: boolean): AppThunk => async (
  dispatch
) => {
  dispatch(
    setCloaked({
      cloaked,
    })
  );
};
