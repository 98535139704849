type Sticky = 'left' | 'right';
type Scroll = 'start' | 'end';

export interface TableField {
  label: string;
  type?: string;
  sticky?: Sticky;
  scroll?: Scroll;
  nonSortable?: boolean;
  align?: 'left' | 'right' | 'center';
}

export const tableFields: TableField[] = [
  {
    label: 'Date',
    sticky: 'left',
    type: 'datetime',
  },
  {
    label: 'Transaction Details',
    nonSortable: true,
  },
  {
    label: 'Amount',
    nonSortable: true,
    type: 'number',
    align: 'right',
  },
  {
    label: 'Subscription Credit Balance',
    nonSortable: true,
    type: 'number',
    align: 'right',
  },
  {
    label: 'Additional Credit Balance',
    nonSortable: true,
    type: 'number',
    align: 'right',
  },
];
