import PersonalDetails from 'components/PersonalDetails';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { deactivatePageHeader } from '../store/reducers/layout';

const PersonalDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <>
      <Helmet>
        <title>Personal Details | alooba.com</title>
      </Helmet>
      <PersonalDetails />
    </>
  );
};

export default PersonalDetailsPage;
