export const isEscape = (event: KeyboardEvent): boolean => {
  if (!event) {
    return false;
  }
  let escKey = false;
  if ('key' in event) {
    escKey = event.key === 'Escape' || event.key === 'Esc';
  }
  return escKey;
};
export const stopEvent = (e: React.SyntheticEvent<EventTarget>): void => {
  if (e.stopPropagation) {
    e.stopPropagation();
    e.preventDefault();
  }
};

export const debounce = (func, timeout = 300): ((...args) => void) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
