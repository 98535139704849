import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import EditInterview from '../components/Interview/EditInterview';

const EditInterviewPage: React.FC = () => {
  const { interviewId }: UrlParams = useParams();
  return (
    <div className="container">
      <Helmet>
        <title>Create Interview | alooba.com</title>
      </Helmet>
      <EditInterview interviewId={interviewId} />
    </div>
  );
};

export default EditInterviewPage;
