import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { RootState } from 'store/rootReducer';
import { Decoded } from 'Authorization/Check';
import { emptyJwt } from '../helpers';

const useTokenData = (): Decoded => {
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const [tokenData, setTokenData] = useState<Decoded>({
    ufid: null,
    pl: '',
    paid: false,
    org: null,
    grp: {},
    force_sso: false,
    org_features: [],
  });

  useEffect(() => {
    const decoded: Decoded = jwt_decode(accessToken || emptyJwt);
    setTokenData(decoded);
  }, [accessToken]);

  return tokenData;
};

export default useTokenData;
