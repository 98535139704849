import React from 'react';
import ReactDOM from 'react-dom';
import './modal.scss';

export interface MenuProps {
  showAlert: boolean;
  children: JSX.Element;
  hideMenuModal?: any;
}
export const MenuModal = ({
  showAlert,
  hideMenuModal,
  children,
}: MenuProps): JSX.Element => {
  const menu = showAlert ? (
    <>
      <div className="menu">
        <div
          role="button"
          tabIndex={0}
          className="close"
          onClick={hideMenuModal}
        >
          x
        </div>
        <div className="menu-list">{children}</div>
      </div>
    </>
  ) : null;
  return showAlert ? ReactDOM.createPortal(menu, document.body) : null;
};
