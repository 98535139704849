import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';

const useStyles = makeStyles({
  '@media (min-width: 769px)': {
    head: {
      left: 0,
      position: 'sticky',
    },
    body: {
      backgroundColor: 'inherit',
      left: 0,
      position: 'sticky',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StickyTableCellLeft = (props: any): JSX.Element => {
  const classes = useStyles();
  return <TableCell {...props} classes={classes} />;
};

export default StickyTableCellLeft;
