import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Button from 'components/Shared/Button';
import {
  reorderQuestionsInPart,
  updateQuestionInPart,
} from 'api/assessment.api';
import LightTooltip from 'components/Shared/Tooltip/LightTooltip';
import { QuestionType } from 'helpers/constants';
import AddIcon from '../../../images/icons/add.svg';
import './InterviewQuestionList.scss';
import {
  QuestionContext,
  setQuestionContext,
} from '../../../store/reducers/interview';
import Skill from './Skill';

enum InterviewSection {
  Content = 0,
  AdditionalCriteria = 1,
}

interface Props {
  interview: any;
  skills: any[];
  skillQuestions: any;
  setInterview: any;
  loading: boolean;
  questions: any[];
  selectedQuestion: any;
  setSelectedQuestion: any;
  selectedQuestionSkill: any;
  setSelectedQuestionSkill: any;
  setShowAddSectionModal: any;
  handleAddQuestion: any;
  handleRemoveQuestion: any;
  toggleSkillCollapse: any;
  removingMap: any;
  assessmentId: number;
  additionalCriteriaSkillLength: number;
}

const InterviewContent: React.FC<Props> = ({
  interview,
  skills,
  skillQuestions,
  setInterview,
  loading,
  selectedQuestion,
  setSelectedQuestion,
  selectedQuestionSkill,
  setSelectedQuestionSkill,
  setShowAddSectionModal,
  handleAddQuestion,
  handleRemoveQuestion,
  toggleSkillCollapse,
  removingMap,
  assessmentId,
  additionalCriteriaSkillLength,
}) => {
  const { addToast } = useToasts();
  const [reordering, setReordering] = useState<boolean>(false);
  const dispatch = useDispatch();

  // Separate content and additional criteria questions.
  // This is important because same skills can be used in both sections.
  const filterSkillQuestions = (
    skillQuestions: any[],
    questionTypes: QuestionType[],
    negated = false
  ): any => {
    const array = Object.entries(skillQuestions).map(([key, value]) => [
      key,
      (value as any[]).filter((question: any) =>
        negated
          ? !questionTypes.includes(question.question_type.question_type)
          : questionTypes.includes(question.question_type.question_type)
      ),
    ]);

    return Object.fromEntries(array);
  };

  const [
    contentSkillQuestions,
    additionalCriteriaSkillQuestions,
  ] = useMemo(() => {
    const additionalCriteriaQuestionTypes = [
      QuestionType.AdditionalCriteria,
      QuestionType.Rating,
    ];

    const contentSkillQuestions = filterSkillQuestions(
      skillQuestions,
      additionalCriteriaQuestionTypes,
      // For now, we're considering all questions other than [additionalCriteriaQuestionTypes] as content questions.
      // Adding more interview sections in the future will require specifiying the question types for each section.
      true
    );

    const additionalCriteriaSkillQuestions = filterSkillQuestions(
      skillQuestions,
      additionalCriteriaQuestionTypes
    );

    return [contentSkillQuestions, additionalCriteriaSkillQuestions];
  }, [skillQuestions]);

  const respondToReorderPromise = (
    promise: Promise<any>,
    tempInterviewQuestions: any[],
    isTopic = false
  ): void => {
    promise
      .then(() => {
        addToast({
          msg: `${isTopic ? 'Questions' : 'Question'} reordered successfully.`,
          type: 'success',
        });
      })
      .catch((error) => {
        setInterview({ ...interview, questions: tempInterviewQuestions });
        if (error.response?.data?.errors) {
          addToast({
            msg: error.response.data.errors[0],
            type: 'error',
          });
        } else {
          addToast({
            msg: `Unable to reorder ${isTopic ? 'questions' : 'question'}.`,
            type: 'error',
          });
        }
      })
      .finally(() => setReordering(false));
  };

  const sectionSkillQuestions = {
    [InterviewSection.Content]: contentSkillQuestions,
    [InterviewSection.AdditionalCriteria]: additionalCriteriaSkillQuestions,
  };

  const onQuestionDragEnd = (result: any, section: InterviewSection): void => {
    const { source, destination } = result;
    // dropped outside the list or dropped in the same place
    if (!destination || destination.index === source.index) {
      return;
    }

    // don't allow reordering while a reorder operation is in progress
    if (reordering) {
      addToast({
        msg: 'Please wait while the new questions order is saved.',
        type: 'warning',
      });
      return;
    }

    const tempInterviewQuestions = [...interview.questions];
    const tempSkillQuestions = { ...sectionSkillQuestions[section] };
    const sourceQuestion = tempSkillQuestions[source.droppableId][source.index];
    const destinationPosition =
      tempSkillQuestions[source.droppableId][destination.index].position;
    const questions = reorderQuestions(
      [...interview.questions],
      sourceQuestion.position,
      destinationPosition
    );
    setInterview((prev) => ({
      ...prev,
      questions,
    }));
    setReordering(true);
    const promise = updateQuestionInPart(
      assessmentId,
      interview.id,
      sourceQuestion.id,
      {
        position: destinationPosition,
      }
    );
    respondToReorderPromise(promise, tempInterviewQuestions);
  };

  const reorderQuestions = (
    questions,
    sourcePosition,
    destinationPosition
  ): [] => {
    const positionIncreased = sourcePosition < destinationPosition;
    const orderedQuestionIndex = questions.findIndex(
      (q) => q.position === sourcePosition
    );
    questions = questions.map((q) => {
      if (positionIncreased) {
        if (q.position > sourcePosition && q.position <= destinationPosition) {
          q.position -= 1;
        }
      } else if (
        q.position < sourcePosition &&
        q.position >= destinationPosition
      ) {
        q.position += 1;
      }
      return q;
    });
    // update the dragged question position
    questions[orderedQuestionIndex].position = destinationPosition;
    return questions.sort((a, b) => a.position - b.position);
  };

  const onTopicDragEnd = (result: any): void => {
    const { source, destination } = result;
    // dropped outside the list or dropped in the same place
    if (!destination || destination.index === source.index) {
      return;
    }

    // don't allow reordering while a reorder operation is in progress
    if (reordering) {
      addToast({
        msg: 'Please wait while the new questions order is saved.',
        type: 'warning',
      });
      return;
    }

    // cannot be before intro or after wrap up.
    if (
      destination.index === 0 ||
      destination.index === skills.length - additionalCriteriaSkillCount - 1
    ) {
      addToast({
        msg:
          destination.index === 0
            ? 'Interview Intro must be first'
            : 'Interview Wrap-up must be last',
        type: 'warning',
      });
      return;
    }

    const positionIncreased = source.index < destination.index;
    const tempInterviewQuestions = [...interview.questions];
    const tempSkillQuestions = { ...contentSkillQuestions };
    const sourceSkill = skills[source.index];
    const destinationSkill = skills[destination.index];
    const sourceQuestions = contentSkillQuestions[sourceSkill.id];
    const destinationSkillQuestions = tempSkillQuestions[destinationSkill.id];
    const destinationPosition = positionIncreased
      ? destinationSkillQuestions[destinationSkillQuestions.length - 1].position
      : destinationSkillQuestions[0].position;
    const questions = reorderQuestionsByTopic(
      [...interview.questions],
      sourceSkill,
      destinationPosition
    );
    setInterview((prev) => ({
      ...prev,
      questions,
    }));
    setReordering(true);
    const promise = reorderQuestionsInPart(
      assessmentId,
      interview.id,
      sourceQuestions.map((e) => e.id),
      destinationPosition
    );
    respondToReorderPromise(promise, tempInterviewQuestions, true);
  };

  const reorderQuestionsByTopic = (
    questions,
    sourceSkill,
    destinationPosition
  ): [] => {
    const sourceQuestionsCount = contentSkillQuestions[sourceSkill.id].length;
    const firstSourceQuestionIndex = questions.findIndex(
      (q) => q.id === contentSkillQuestions[sourceSkill.id][0].id
    );
    const sourcePosition = questions[firstSourceQuestionIndex].position;
    const positionIncreased = sourcePosition < destinationPosition;
    questions = questions.map((q) => {
      if (positionIncreased) {
        if (q.position > sourcePosition && q.position <= destinationPosition) {
          q.position -= sourceQuestionsCount;
        }
      } else if (
        q.position < sourcePosition &&
        q.position >= destinationPosition
      ) {
        q.position += sourceQuestionsCount;
      }
      return q;
    });
    let firstDestinationPosition = destinationPosition;
    if (positionIncreased) {
      firstDestinationPosition = destinationPosition - sourceQuestionsCount + 1;
    }
    for (let i = 0; i < sourceQuestionsCount; i += 1) {
      questions[i + firstSourceQuestionIndex].position =
        firstDestinationPosition + i;
    }
    return questions.sort((a, b) => a.position - b.position);
  };

  const interviewContentSkillCount = useMemo(() => {
    return Object.keys(contentSkillQuestions).reduce((acc, skillId) => {
      return acc + (contentSkillQuestions[skillId].length ? 1 : 0);
    }, 0);
  }, [contentSkillQuestions]);

  const additionalCriteriaSkillCount = useMemo(() => {
    return Object.keys(additionalCriteriaSkillQuestions).reduce(
      (acc, skillId) => {
        return acc + (additionalCriteriaSkillQuestions[skillId].length ? 1 : 0);
      },
      0
    );
  }, [additionalCriteriaSkillQuestions]);

  const showAddSectionModal = (context: QuestionContext): void => {
    dispatch(setQuestionContext(context));
    setShowAddSectionModal(true);
  };

  const handleSelectQuestion = (question, skill): void => {
    setSelectedQuestion(question);
    setSelectedQuestionSkill(skill);
  };

  const getQuestionContext = (questions): QuestionContext => {
    if (
      questions.length &&
      questions[0].question_type?.question_type === 'Additional Criteria'
    ) {
      return 'additional-criteria';
    }
    return 'interview-content';
  };

  const canAddQuestion = (i: number): boolean => {
    // Empty informational sections
    const skill = skills[i];
    if (
      skill.questions_type === QuestionType.Informational &&
      skillQuestions[skill.id].length === 0
    )
      return true;
    // Overall section
    if (i === skills.length - 1) return false;
    // Based on selection
    else
      return selectedQuestionSkill && skills[i].id === selectedQuestionSkill.id;
  };

  // This means that the InterviewContent only has the Interview Intro and Wrap-up.
  const noExtraContentSkills = useMemo(() => interviewContentSkillCount === 2, [
    interviewContentSkillCount,
  ]);

  return (
    <div>
      <h3>Interview Content</h3>
      {!loading && interviewContentSkillCount > 0 && (
        <>
          <DragDropContext onDragEnd={onTopicDragEnd}>
            <Droppable
              droppableId="interview-content"
              direction="vertical"
              type="TOPIC"
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  <div className="question-groups">
                    {skills
                      .slice(0, interviewContentSkillCount)
                      .map((skill, i) => (
                        <div key={skill.id}>
                          <Draggable draggableId={`${skill.id}`} index={i}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                {i > 0 && snapshot.isDragging === false && (
                                  <hr />
                                )}
                                <Skill
                                  skill={skill}
                                  questions={contentSkillQuestions[skill.id]}
                                  toggleSkillCollapse={toggleSkillCollapse}
                                  selectedQuestion={selectedQuestion}
                                  handleSelectQuestion={handleSelectQuestion}
                                  handleAddQuestion={() =>
                                    handleAddQuestion(
                                      i,
                                      contentSkillQuestions[skill.id],
                                      getQuestionContext(
                                        contentSkillQuestions[skill.id]
                                      )
                                    )
                                  }
                                  handleRemoveQuestion={handleRemoveQuestion}
                                  canAddQuestion={canAddQuestion(i)}
                                  removingMap={removingMap}
                                  onQuestionDragEnd={(result) =>
                                    onQuestionDragEnd(
                                      result,
                                      InterviewSection.Content
                                    )
                                  }
                                  dndEnabled={
                                    i > 0 && i < interviewContentSkillCount - 1
                                  }
                                  dndDragHandleProps={provided.dragHandleProps}
                                />
                              </div>
                            )}
                          </Draggable>

                          {i === 0 && noExtraContentSkills && (
                            <div className="add-section mt5">
                              <div
                                className="skill-row"
                                role="button"
                                tabIndex={0}
                              >
                                <div className="skill">
                                  <b>Experience &amp; Skills</b>
                                </div>
                                <p className="description">
                                  Add a new section to include experience or
                                  skill related questions.
                                </p>
                                <Button
                                  variant="sub-primary md"
                                  text="Add Interview Topic"
                                  onClick={() =>
                                    showAddSectionModal('interview-content')
                                  }
                                  addButton
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {additionalCriteriaSkillCount > 0 && (
            <>
              <hr />

              <h3 className="additional-criteria-header">
                Additional Criteria
                {additionalCriteriaSkillLength !== 0 && (
                  <LightTooltip
                    title="Add additional criteria to be evaluated after the interview"
                    arrow
                  >
                    <div
                      className="add-section"
                      role="button"
                      tabIndex={0}
                      onClick={() => showAddSectionModal('additional-criteria')}
                    >
                      <img src={AddIcon} alt="text" />
                    </div>
                  </LightTooltip>
                )}
              </h3>

              <div className="question-groups">
                {skills.slice(interviewContentSkillCount).map((skill, i) => (
                  <div key={skill.id}>
                    {i > 0 && <hr />}
                    <Skill
                      skill={skill}
                      questions={additionalCriteriaSkillQuestions[skill.id]}
                      toggleSkillCollapse={toggleSkillCollapse}
                      selectedQuestion={selectedQuestion}
                      handleSelectQuestion={handleSelectQuestion}
                      handleAddQuestion={() =>
                        handleAddQuestion(
                          interviewContentSkillCount + i,
                          additionalCriteriaSkillQuestions[skill.id],
                          getQuestionContext(
                            additionalCriteriaSkillQuestions[skill.id]
                          )
                        )
                      }
                      handleRemoveQuestion={handleRemoveQuestion}
                      canAddQuestion={canAddQuestion(
                        i + interviewContentSkillCount
                      )}
                      removingMap={removingMap}
                      onQuestionDragEnd={(result) =>
                        onQuestionDragEnd(
                          result,
                          InterviewSection.AdditionalCriteria
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InterviewContent;
