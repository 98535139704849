import React, { useRef } from 'react';

const CircleProgressBar: React.FC<{
  percent: number;
  children: React.ReactNode;
  strokeWidth?: number;
  fillColor?: string;
  size?: number;
}> = ({
  percent,
  children,
  strokeWidth = 10,
  fillColor = 'none',
  size = 150,
}) => {
  const ref = useRef();
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  return (
    <div data-percent={percent} ref={ref} style={{ position: 'relative' }}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        height={size}
      >
        <circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          strokeWidth={strokeWidth}
          fill={fillColor}
          style={{
            stroke: '#ECF1F4',
          }}
        />
        <circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (circumference * percent) / 100}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          fill="none"
          style={{
            stroke: '#004385',
          }}
        />
        <foreignObject
          className="node"
          width={size}
          height={size}
          textAnchor="middle"
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>{children}</div>
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};

export default CircleProgressBar;
