import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DynamicTabs from 'components/Shared/DynamicTabs';
import '../../styles/heading.scss';

interface BillingTabsProps {
  tabsData: any;
  trialBannerShow?: boolean;
}

interface UrlParams {
  tabName?: string;
}

export default function BillingTabs(props: BillingTabsProps): any {
  const { tabsData, trialBannerShow } = props;
  const [value, setValue] = useState<any>(0);
  const [content, setContent] = useState<any>(`this is tab content ${value}`);
  const { tabName }: UrlParams = useParams();

  useEffect(() => {
    tabsData.map((tab, k) => {
      if (tab.contentLink.includes(tabName)) {
        setValue(k);
        setContent(tabsData[k].component);
      } else {
        setValue(0);
        setContent(tabsData[0].component);
      }
      return true;
    });
  }, [tabName, tabsData]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ): any => {
    window.history.replaceState(null, null, tabsData[newValue].contentLink);
    setValue(newValue);
    setContent(tabsData[newValue].component);
  };

  const dynamicTabsProps = {
    tabsData,
    handleChange,
    value,
    content,
    trialBannerShow,
  };
  return <DynamicTabs {...dynamicTabsProps} />;
}
