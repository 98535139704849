import './index.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Input } from '../Shared';
import { RootState } from '../../store/rootReducer';
import { hasOrgFeature } from '../../Authorization/Check';
import {
  acknowledgeSSOError,
  addSamlDomain,
  editSamlDomain,
  enableSSO,
  getSamlDomains,
  getSSO,
  removeSamlDomain,
  updateSAMLConfig,
} from '../../store/actions/organization.actions';
import { refreshCurrentTokens } from '../../store/actions/auth.actions';
import ConfirmModal from '../Shared/ConfirmModal';
import TextArea from '../Shared/Input/textArea';
import { parseMetadata, SamlDomain } from '../../api/organization.api';
import { handleError } from '../../handleError';
import Tooltip from '../Shared/Tooltip';
import CustomSwitch from '../Shared/CustomSwitch';

const SsoConfiguration = (): JSX.Element => {
  const dispatch = useDispatch();
  const toasts = useToasts();
  const fileInput = React.useRef(null);
  const history = useHistory();

  const {
    organization: { sso },
    profile: { userDetails },
  } = useSelector((state: RootState) => state);
  const [fetched, setFetched] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  const [idpMetadata, setIdpMetadata] = useState<string>(null);
  const [idpMetadataLoading, setIdpMetadataLoading] = useState<boolean>(false);
  const [idpIdentifier, setIdpIdentifier] = useState<string>(null);
  const [idpLoginUrl, setIdpLoginUrl] = useState<string>(null);
  const [idpLogoutUrl, setIdpLogoutUrl] = useState<string>(null);
  const [idpCert, setIdpCert] = useState<string>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [domainInput, setDomainInput] = useState<string>('');
  const [domains, setDomains] = useState<SamlDomain[]>([]);

  const orgId = useMemo(() => {
    return userDetails?.recruiter_detail?.organisation_id;
  }, [userDetails]);

  useEffect(() => {
    if (sso.error !== null) {
      toasts.addToast({
        type: 'error',
        msg: sso.error,
      });
      Promise.resolve(dispatch(acknowledgeSSOError())).catch((e) => {
        handleError(e);
      });
    }
  }, [sso.error, toasts, dispatch]);

  // fetch sso details from api if not present in state
  useEffect(() => {
    if (
      sso.samlConfig === null &&
      orgId &&
      !fetched &&
      !sso.samlConfigLoading
    ) {
      setFetched(true);
      new Promise((resolve) => {
        resolve(dispatch(getSSO(orgId)));
      }).catch((e) => {
        // on first time fetch, if it returns forbidden then SSO is not enabled
        if (e.response && e.response.status === 403) {
          setAuthorized(false);
          return;
        }
        handleError(e);
      });
      Promise.resolve(dispatch(getSamlDomains(orgId))).catch((e) => {
        handleError(e);
      });
    }
  }, [sso, orgId, fetched, dispatch]);
  useEffect(() => {
    if (idpIdentifier === null && sso.samlConfig?.idp_entity_id) {
      setIdpIdentifier(sso.samlConfig.idp_entity_id);
    }
    if (idpLoginUrl === null && sso.samlConfig?.idp_login_url) {
      setIdpLoginUrl(sso.samlConfig.idp_login_url);
    }
    if (idpLogoutUrl === null && sso.samlConfig?.idp_logout_url) {
      setIdpLogoutUrl(sso.samlConfig.idp_logout_url);
    }
    if (idpCert === null && sso.samlConfig?.idp_x509_cert) {
      setIdpCert(sso.samlConfig.idp_x509_cert);
    }
    if (idpMetadata === null && sso.samlConfig?.metadata.idp_metadata_url) {
      setIdpMetadata(sso.samlConfig.metadata.idp_metadata_url);
    }
  }, [
    sso.samlConfig,
    idpIdentifier,
    idpLoginUrl,
    idpLogoutUrl,
    idpCert,
    idpMetadata,
  ]);

  useEffect(() => {
    if (!sso.samlConfigLoading) {
      setShowConfirmModal(false);
    }
  }, [sso.samlConfigLoading]);
  useMemo(() => {
    if (sso.domainValidationErrors && sso.domainValidationErrors.domains) {
      toasts.addToast({
        type: 'error',
        msg: sso.domainValidationErrors.domains[0],
      });
    }
  }, [sso.domainValidationErrors, toasts]);

  // function to handle saving the SSO configuration
  const handleSave = (): void => {
    if (
      sso.samlConfig?.idp_entity_id &&
      sso.samlConfig?.idp_login_url &&
      sso.samlConfig?.idp_logout_url
    ) {
      setShowConfirmModal(true);
      return;
    }
    updateIdpConfig();
  };

  const onFileSelected = (e: any): void => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      setIdpCert(e.target.result);
    };
    reader.readAsText(file);
  };

  const updateIdpConfig = (): void => {
    new Promise((resolve) => {
      resolve(
        dispatch(
          updateSAMLConfig(orgId, {
            idp_metadata_url: idpMetadata,
            idp_entity_id: idpIdentifier,
            idp_login_url: idpLoginUrl,
            idp_logout_url: idpLogoutUrl,
            idp_x509_cert: idpCert,
          })
        )
      );
    })
      .then(() => {
        toasts.addToast({
          type: 'success',
          msg: 'Configuration saved',
        });
      })
      .catch((e) => {
        handleError(e);
      })
      .finally(() => {
        setShowConfirmModal(false);
      });
  };

  const handleMetaDataURLChange = (event): void => {
    if (idpMetadata) {
      setIdpMetadataLoading(true);
      parseMetadata(event.target.value)
        .then((response) => {
          setIdpIdentifier(response.data.idp_entity_id);
          setIdpLoginUrl(response.data.idp_login_url);
          setIdpLogoutUrl(response.data.idp_logout_url);
          setIdpCert(response.data.idp_x509_cert);
        })
        .finally(() => {
          setIdpMetadataLoading(false);
        });
    }
  };

  const handleEnableClicked = (): void => {
    dispatch(enableSSO(orgId));
  };

  useEffect(() => {
    setDomains(sso.domains);
  }, [sso.domains]);

  const handleAddDomain = (): void => {
    const newDomain = {
      domain: domainInput,
      force_sso: false,
    };
    new Promise((resolve) => {
      resolve(dispatch(addSamlDomain(orgId, newDomain)));
    }).then(() => {
      setDomainInput('');
      dispatch(refreshCurrentTokens());
    });
  };

  const handleForceSSOChange = (domain: SamlDomain): void => {
    const newDomain = {
      domain: domain.domain,
      force_sso: !domain.force_sso,
    };

    new Promise((resolve) => {
      resolve(dispatch(editSamlDomain(orgId, newDomain)));
    }).then(() => {
      setDomainInput('');
      dispatch(refreshCurrentTokens());
    });
  };

  const handleDeleteDomain = (domain: SamlDomain): void => {
    dispatch(removeSamlDomain(orgId, domain));
    dispatch(refreshCurrentTokens());
  };

  if (!hasOrgFeature('sso')) {
    history.replace('/organization-settings');
  }

  return (
    <div className="sso-configuration">
      {authorized && (
        <div>
          <h2>Configure Azure Active Directory</h2>
          <p>
            Single Sign-On (SSO) allows you to use your organization&apos;s
            existing identity provider to log in to Alooba.
          </p>
          <p>
            Please refer to our documentation for more information on how
            to&nbsp;
            <a
              href="https://support.alooba.com/article/26-active-directory-sso-setup"
              target="_blank"
              rel="noreferrer"
            >
              set up SSO with Azure Active Directory
            </a>
            .
          </p>
          <hr />
          <div className="sso-alooba-settings">
            <div
              id="sso-service-provider-container"
              test-id="sso-service-provider-container"
              className="container main-container"
            >
              {sso.samlConfig === null && !sso.samlConfigLoading && (
                <>
                  <p>To begin, enable Single Sign-on for your organization</p>
                  <Button
                    variant="sub-primary md"
                    text="Enable Single Sign-On"
                    onClick={handleEnableClicked}
                  />
                </>
              )}
              {(sso.samlConfigLoading || sso.samlConfig !== null) && (
                <>
                  <h3>Alooba Metadata</h3>
                  <p>
                    These are the settings you need to add to the Azure Active
                    Directory Application SSO Settings.
                  </p>
                  <Input
                    type="text"
                    value={sso.samlConfig?.metadata_url}
                    label="Identifier (Entity ID)"
                    readOnly
                    loading={sso.samlConfig === null && sso.samlConfigLoading}
                  />
                  <Input
                    type="text"
                    value={sso.samlConfig?.consumer_service_url}
                    label="Reply URL (Assertion Consumer Service URL)"
                    readOnly
                    loading={sso.samlConfig === null && sso.samlConfigLoading}
                  />
                  <Input
                    type="text"
                    value={sso.samlConfig?.login_url}
                    label="Sign on URL"
                    readOnly
                    loading={sso.samlConfig === null && sso.samlConfigLoading}
                  />
                  <Input
                    type="text"
                    value={sso.samlConfig?.logout_url}
                    label="Logout Url"
                    readOnly
                    loading={sso.samlConfig === null && sso.samlConfigLoading}
                  />
                </>
              )}
            </div>
            <div
              id="sso-domain-matching"
              className="container main-container"
              test-id="sso-domain-matching"
            >
              <h3>SSO Domains</h3>
              <p>
                Enter the domains that your organization uses for SSO. All email
                addresses that uses your domain will be taken to the SSO login
                page.
              </p>
              <div id="domain-matching-input">
                <div>
                  <Input
                    type="text"
                    label="Domain"
                    value={domainInput}
                    onChange={(e) => setDomainInput(e.target.value)}
                    disabled={sso.domainsLoading}
                    errorTxt={
                      sso.domainValidationErrors?.domain &&
                      sso.domainValidationErrors?.domain[0]
                    }
                  />
                </div>
                <div>
                  <Button
                    disabled={sso.domainsLoading}
                    variant="sub-primary md"
                    text="Add Domain"
                    onClick={handleAddDomain}
                  />
                </div>
              </div>
              <div className="domain-list">
                {domains.map((domain) => (
                  <div className="domain-list-item" key={domain.domain}>
                    <div className="domain-list-name">{domain.domain}</div>
                    <div className="list-item-actions">
                      <Tooltip
                        tooltip={`Force SSO for users with email addresses under ${domain.domain}`}
                      >
                        <label className="label">
                          <CustomSwitch
                            data-testid="force-sso-switch"
                            checked={domain.force_sso}
                            disabled={sso.domainsLoading}
                            onClick={() => handleForceSSOChange(domain)}
                          />
                          Force SSO
                        </label>
                      </Tooltip>
                      <button
                        type="button"
                        className="domain-delete-button"
                        disabled={sso.domainsLoading}
                        onClick={() => handleDeleteDomain(domain)}
                      >
                        <FontAwesomeIcon className="icon" icon={faTrash} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {(sso.samlConfigLoading || sso.samlConfig !== null) && (
            <div
              id="sso-identity-provider-container"
              test-id="sso-service-provider-container"
              className="container main-container"
            >
              <h3>Active Directory Settings</h3>
              <Input
                type="text"
                value={idpMetadata}
                label="App Federation Metadata Url"
                onChange={(e) => setIdpMetadata(e.target.value)}
                onBlur={handleMetaDataURLChange}
                disabled={idpMetadataLoading}
                suffix={
                  idpMetadataLoading && (
                    <FontAwesomeIcon className="icon" spin icon={faSpinner} />
                  )
                }
                loading={sso.samlConfig === null && sso.samlConfigLoading}
                errorTxt={
                  sso.samlValidationErrors?.idp_metadata_url &&
                  sso.samlValidationErrors.idp_metadata_url[0]
                }
              />
              <Input
                type="text"
                value={idpLoginUrl}
                label="Login URL"
                onChange={(e) => setIdpLoginUrl(e.target.value)}
                loading={!idpLoginUrl && sso.samlConfigLoading}
                errorTxt={
                  sso.samlValidationErrors?.idp_login_url &&
                  sso.samlValidationErrors?.idp_login_url[0]
                }
              />
              <Input
                type="text"
                value={idpIdentifier}
                label="Azure AD Identifier"
                onChange={(e) => setIdpIdentifier(e.target.value)}
                loading={!idpIdentifier && sso.samlConfigLoading}
                errorTxt={
                  sso.samlValidationErrors?.idp_entity_id &&
                  sso.samlValidationErrors?.idp_entity_id[0]
                }
              />
              <Input
                type="text"
                value={idpLogoutUrl}
                label="Logout URL"
                onChange={(e) => setIdpLogoutUrl(e.target.value)}
                loading={!idpLogoutUrl && sso.samlConfigLoading}
                errorTxt={
                  sso.samlValidationErrors?.idp_logout_url &&
                  sso.samlValidationErrors?.idp_logout_url[0]
                }
              />
              <div className="sso-cert-container">
                <div>
                  <TextArea
                    id="idp-cert-text-area"
                    value={idpCert}
                    label="Certificate (Base64)"
                    onChange={(e) => setIdpCert(e.target.value)}
                    loading={!setIdpCert && sso.samlConfigLoading}
                    errorTxt={
                      sso.samlValidationErrors?.idp_x509_cert &&
                      sso.samlValidationErrors?.idp_x509_cert[0]
                    }
                  />
                </div>
                <div className="cert-file-select-container">
                  <Button
                    variant="sub-primary md"
                    text="Choose Certificate File"
                    onClick={() => fileInput.current.click()}
                  />
                  <input
                    ref={fileInput}
                    type="file"
                    id="saml_cert_file_selector"
                    onChange={onFileSelected}
                    accept=".crt, .pem, .cer"
                  />
                </div>
              </div>

              <hr />
              <Button
                variant="primary md"
                text="Save"
                onClick={handleSave}
                loading={sso.samlConfig === null && sso.samlConfigLoading}
              />
              <ConfirmModal
                setModalVisibility={setShowConfirmModal}
                isShown={showConfirmModal}
                title="Confirm configuration changes"
                actionText="save"
                loading={sso.samlConfigLoading}
                handleButtonAction={updateIdpConfig}
              >
                <p>
                  Are you sure you want to replace the current SSO
                  configuration?
                </p>
              </ConfirmModal>
            </div>
          )}
        </div>
      )}
      {!authorized && (
        <div className="no-access-container">
          <div>
            <h2 className="no-access-header">
              You do not currently have access to the Single Sign-On feature.
            </h2>
            <p className="no-access-text">
              Single Sign-on has not been enabled for your organization. Please
              reach out to your account manager if you would like access to
              enable Single Sign-on.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SsoConfiguration;
