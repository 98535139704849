import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './reducers/auth';
import profileReducer from './reducers/profile';
import layoutReducer from './reducers/layout';
import appReducer from './reducers/app';
import organizationReducer from './reducers/organization';
import assessmentReducer from './reducers/assessment';
import candidateReducer from './reducers/candidate';
import assessmentCandidateReducer from './reducers/assessmentCandidate';
import searchCandidateReducer from './reducers/searchCandidate';
import suggestedCandidatesReducer from './reducers/suggestedCandidates';
import organizationUsersReducer from './reducers/organizationUsers';
import interviewReducer from './reducers/interview';
import usersReducer from './reducers/users';
import utilsReducer from './reducers/utils';
import templatesReducer from './reducers/template';
import subscriptionReducer from './reducers/subscription';
import paymentReducer from './reducers/payment';
import billingReducer from './reducers/billing';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  layout: layoutReducer,
  app: appReducer,
  organization: organizationReducer,
  assessment: assessmentReducer,
  candidate: candidateReducer,
  assessmentCandidate: assessmentCandidateReducer,
  searchCandidate: searchCandidateReducer,
  suggestedCandidates: suggestedCandidatesReducer,
  organizationUsers: organizationUsersReducer,
  interview: interviewReducer,
  users: usersReducer,
  utils: utilsReducer,
  template: templatesReducer,
  subscription: subscriptionReducer,
  payment: paymentReducer,
  billing: billingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
