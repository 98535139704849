import React from 'react';

const PieLabel: React.FC<{
  value: number;
  percentage: number;
  text: string;
  showFullLabel: boolean;
  x: number;
  y: number;
  onMouseOver(id: string): void;
  onTouchStart(id: string): void;
  onMouseLeave(): void;
  sliceId: string;
}> = ({
  value,
  percentage,
  text,
  showFullLabel,
  x,
  y,
  onMouseOver,
  onTouchStart,
  onMouseLeave,
  sliceId,
}): JSX.Element => {
  const wrapText = (label: string, maxLength: number): string => {
    if (label.length > maxLength) {
      return `${label.substring(0, maxLength - 3)}...`;
    }
    return label;
  };

  const handleHighlightSlice = (): void => {
    onMouseOver(sliceId);
    onTouchStart(sliceId);
  };

  return (
    <>
      {showFullLabel ? null : (
        <text
          x={x + 50}
          y={y + 15}
          fill="#000000"
          fontWeight="bold"
          onMouseEnter={handleHighlightSlice}
          onTouchStart={handleHighlightSlice}
          onMouseLeave={onMouseLeave}
          fontSize="1rem"
        >
          {`${value} (${percentage}%)`}
        </text>
      )}
      <>
        <text
          x={x + (showFullLabel ? 50 : 165)}
          y={y + 15}
          fontSize="14px"
          fill="#4A4A68"
          onMouseEnter={handleHighlightSlice}
          onTouchStart={handleHighlightSlice}
          onMouseLeave={onMouseLeave}
        >
          {wrapText(text, 30)}
        </text>
      </>
    </>
  );
};

export default PieLabel;
