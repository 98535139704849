import React, { useState } from 'react';

export interface HistogramBarProps {
  height: number;
  width: number;
  y: number;
  index: number;
  balloonLabel?: string;
  balloonText?: string;
}
const Bar: React.FC<HistogramBarProps> = ({
  height,
  y,
  width,
  index,
  balloonLabel,
  balloonText,
}): JSX.Element => {
  const balloonHeight = 50;

  const getTrianglePoints = (
    barWidth: number,
    barY: number
  ): Record<string, Record<string, number>> => {
    const a = {
      x: barWidth * 0.5 - 4,
      y: Math.max(barY - 50, 10) + balloonHeight,
    };
    const b = {
      x: barWidth * 0.5 + 4,
      y: a.y,
    };
    const c = { x: barWidth * 0.5, y: a.y + 4 };
    return { a, b, c };
  };

  const rect_style = { fill: '#004385', strokeWidth: 1, stroke: 'white' };
  const hover_rect_style = {
    fill: '#ECF1F4',
    filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 1px)',
  };

  const trianglePoints = getTrianglePoints(width, y);

  const [showBalloon, setShowBalloon] = useState(false);

  return (
    <g transform={`translate(${38 + width * index} 0)`}>
      <rect
        id={`recBar${index}`}
        y={y}
        width={width}
        height={height}
        style={rect_style}
        onMouseOver={() => setShowBalloon(true)}
        onMouseLeave={() => setShowBalloon(false)}
      />
      {/* hover balloon */}
      {showBalloon === true && (
        <>
          <rect
            id={`recBarBalloon${index}`}
            x={width * 0.05}
            y={Math.max(y - 50, 10)}
            rx={width * 0.02}
            width={width * 0.9}
            height={balloonHeight}
            style={hover_rect_style}
          />
          <polygon
            points={`
                    ${trianglePoints.a.x}, ${trianglePoints.a.y}
                    ${trianglePoints.b.x}, ${trianglePoints.b.y}
                    ${trianglePoints.c.x}, ${trianglePoints.c.y}
                `}
            style={hover_rect_style}
          />
          <text
            x={width * 0.5}
            y={Math.max(y - 50, 10) + 20}
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <tspan fontSize={12}>{balloonLabel}</tspan>
          </text>
          <text
            x={width * 0.5}
            y={Math.max(y - 50, 10) + 35}
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <tspan fontSize={12}>{balloonText}</tspan>
          </text>
        </>
      )}
    </g>
  );
};

export default Bar;
