import React, { useState, useMemo, useRef } from 'react';
import Select from 'react-select';
import pencilIcon from 'images/icons/pencil-blue.svg';
import { useToasts } from 'react-toast-notifications';
import Button from 'components/Shared/Button/index';
import './EditScore.scss';
import { updateAssessmentData } from 'api/assessment.api';
import { setCurrentAssessment } from 'store/actions/assessment.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import authorize from '../../Authorization/Check';
import { DEFAULT_PASSING_SCORE } from '../../helpers/constants';

const EditScore: React.FC<{ title: string }> = ({ title }) => {
  const { currentAssessment: assessment } = useSelector(
    (state: RootState) => state.assessment
  );
  const [editMode, setEditMode] = useState(false);
  const [savingPassingScore, setSavingPassingScore] = useState(false);
  const select = useRef(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const benchmarkScore = Math.round(assessment.benchmark_score * 100);
  const benchmarkCandidateScore = Math.round(
    assessment.benchmark_candidate_test?.total_score?.scored_percent
  );
  const benchmarkCandidateScoreText =
    assessment.purpose === 'ld'
      ? 'Team Member Benchmark'
      : 'Candidate Benchmark';

  const dropdownOptions = useMemo(() => {
    const ps = new Set([40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95]);
    ps.add(benchmarkScore);
    if (
      benchmarkCandidateScore &&
      benchmarkCandidateScore >= 10 &&
      benchmarkCandidateScore <= 95
    ) {
      ps.add(benchmarkCandidateScore);
    }
    const allPercentages = Array.from(ps).sort((a, b) => a - b);
    return allPercentages
      .filter((i) => i !== 0)
      .map((i) => {
        if (i === benchmarkScore) {
          return {
            value: `${i}`,
            label: `${Math.round(i)}% (Alooba Benchmark)`,
          };
        }
        if (
          benchmarkCandidateScore &&
          i === Math.round(benchmarkCandidateScore)
        ) {
          return {
            value: `${i}`,
            label: `${i}% (${benchmarkCandidateScoreText})`,
          };
        }
        return {
          value: `${i}`,
          label: `${Math.round(i)}%`,
        };
      });
  }, [benchmarkCandidateScore, benchmarkCandidateScoreText, benchmarkScore]);

  const benchmarkDefaultValue =
    benchmarkScore !== 0
      ? {
          value: `${benchmarkScore}`,
          label: `${Math.round(benchmarkScore)}% (Alooba Benchmark)`,
        }
      : {
          value: `${DEFAULT_PASSING_SCORE}`,
          label: `${DEFAULT_PASSING_SCORE}%`,
        };
  const defaultValue = assessment.passing_score
    ? {
        value: `${assessment.passing_score}`,
        label: `${Math.round(assessment.passing_score)}%`,
      }
    : benchmarkDefaultValue;

  const toggleEdit = (): void => {
    setEditMode(!editMode);
  };

  const saveScore = (): void => {
    let value = Number(select?.current?.state?.value?.value);
    value = value !== benchmarkScore ? value : null;
    if (value === assessment.passing_score) {
      toggleEdit();
      return;
    }
    setSavingPassingScore(true);
    updateAssessmentData({
      id: assessment.id,
      passing_score: value,
    }).then((response) => {
      if (response.status === 200) {
        dispatch(setCurrentAssessment(response.data.data));
        addToast({ msg: 'Passing Score Updated', type: 'success' });
        toggleEdit();
      }
      setSavingPassingScore(false);
    });
  };

  const editButtonDisplay = authorize(
    'assessment',
    'edit',
    assessment.role.user_id
  )
    ? 'flex'
    : 'none';

  if (editMode) {
    return (
      <div className="edit-score-container">
        <h3>{title}</h3>
        <div className="select-container">
          <Select
            ref={select}
            defaultValue={defaultValue}
            options={dropdownOptions}
            className="react-select"
            classNamePrefix="select"
          />
          <Button
            text="Save"
            variant="primary md"
            onClick={saveScore}
            loading={savingPassingScore}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="edit-score-container">
      <h3>{title}</h3>
      <div className="edit-score-row">
        <h2>
          {assessment.passing_score ??
            (benchmarkScore !== 0 ? benchmarkScore : DEFAULT_PASSING_SCORE)}
          %
        </h2>
        <div style={{ display: editButtonDisplay }}>
          {!assessment.passing_score && benchmarkScore !== 0 && (
            <p>(Alooba Benchmark)</p>
          )}
          {assessment.passing_score === Math.round(benchmarkCandidateScore) && (
            <p>({benchmarkCandidateScoreText})</p>
          )}
          <button type="button" onClick={toggleEdit}>
            <img src={pencilIcon} alt="edit" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditScore;
