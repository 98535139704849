import React from 'react';
import RequiresEvaluationIcon from 'images/icons/requires-evaluation-blue.svg';
import CompletedIcon from './icon-completed.svg';
import InProgressIcon from './icon-in-progress.svg';
import IncompleteIcon from './icon-incomplete.svg';
import InvitedIcon from './icon-invited.svg';

const AssessmentStatusIcon = ({ status }): JSX.Element => {
  let icon = null;
  switch (status) {
    case 'Requires Evaluation':
      icon = RequiresEvaluationIcon;
      break;
    case 'Completed':
      icon = CompletedIcon;
      break;
    case 'In Progress':
      icon = InProgressIcon;
      break;
    case 'Incomplete':
    case 'Expired':
    case 'Incomplete Expired':
      icon = IncompleteIcon;
      break;
    case 'Invited':
    case 'Invite Scheduled':
    case 'Seen Invite':
      icon = InvitedIcon;
      break;
    default:
      return null;
  }
  return <img className="assessment-status-icon" src={icon} alt={status} />;
};

export default AssessmentStatusIcon;
