import React from 'react';
import FlatPill from 'components/Shared/Pill/flatPill';

interface AssessmentFlatPillProps {
  assessment?: any;
}
const AssessmentFlatPill: React.FC<AssessmentFlatPillProps> = ({
  assessment,
}: AssessmentFlatPillProps): JSX.Element => {
  const pills = [];
  if (assessment && assessment.is_archived) {
    pills.push(<FlatPill text="Unpublished" variant="disabled" />);
  }
  if (assessment && assessment.is_published === '0') {
    pills.push(<FlatPill text="Draft" variant="orange" />);
  }
  if (assessment.is_sample) {
    pills.push(<FlatPill text="Sample" variant="primary" />);
  }
  return <>{pills}</>;
};

export default AssessmentFlatPill;
