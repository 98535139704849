import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import './pill.scss';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  variant?: string;
  text?: string;
  hoverText?: string;
  removePill?: any;
  loading?: boolean;
  showDeleteButton?: boolean;
  handleRemovePill?: any;
  showSpinner?: boolean;
}

const Button = ({
  variant,
  text,
  hoverText,
  loading,
  removePill,
  showDeleteButton,
  handleRemovePill,
  showSpinner = false,
}: Props): JSX.Element => (
  <div className="pill-button">
    {loading ? (
      <Skeleton height={30} width={120} />
    ) : (
      <Tooltip arrow title={hoverText} placement="top">
        <button
          data-testid={`pill-${text}`}
          type="button"
          onClick={removePill}
          className={`py1 px2 ${variant}`}
        >
          {text}
          <span
            className="remove"
            role="button"
            tabIndex={0}
            onClick={handleRemovePill}
          >
            {showDeleteButton && (
              <FontAwesomeIcon className="icon" icon={faTimes} />
            )}
          </span>
          {showSpinner && (
            <span className="spinner">
              <FontAwesomeIcon className="icon" spin icon={faSpinner} />
            </span>
          )}
        </button>
      </Tooltip>
    )}
  </div>
);

export default Button;
