import React from 'react';
import './Layout.scss';

const NoLayout: React.FC = ({ children }) => {
  return (
    <>
      <div className="content">
        <main>{children}</main>
      </div>
    </>
  );
};

export default NoLayout;
