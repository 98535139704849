import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '../../../styles/colors';

const CustomSwitch = withStyles(() => ({
  switchBase: {
    '&$checked': {
      color: blue,
    },
    '&$checked + $track': {
      backgroundColor: blue,
    },
  },
  checked: {},
  track: {},
}))(Switch);

export default CustomSwitch;
