import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { downloadExportFile } from 'api/assessment.api';

const AssessmentCandidatesDownloadPage: React.FC = () => {
  const { fileName } = useParams<{
    fileName: string;
  }>();
  const { addToast } = useToasts();
  useEffect(() => {
    downloadExportFile(fileName)
      .then((response) => {
        const url = response.data?.data?.url;
        if (url) window.location.assign(url);
      })
      .catch((errors) => {
        const msg = errors.response.data.errors.map((error) => error.detail);
        addToast({
          type: 'error',
          msg: msg.join(' '),
        });
      });
  });
  return (
    <>
      <Helmet>
        <title>Download Assessment Candidates | alooba.com</title>
      </Helmet>
      <div className="full-width-container center">
        <h2>Download Assessment Candidates</h2>
        <br />
        Your download will start shortly...
      </div>
    </>
  );
};
export default AssessmentCandidatesDownloadPage;
