import CardContainer from 'components/Shared/MainContainer/CardContainer';
import React, {
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  useCallback,
} from 'react';

import { Button } from 'components/Shared';
import { RootState } from 'store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from 'images/icons/email-blue.svg';
import MobileIcon from 'images/icons/mobile-blue.svg';
import CalendarIcon from 'images/icons/calendar-blue.svg';
import StatusIcon from 'images/icons/assessment-status-blue.svg';
import UserIcon from 'images/icons/user-blue.svg';
import UserGroupIcon from 'images/icons/user-group-blue.svg';
import RequiresEvaluationIcon from 'images/icons/requires-evaluation-blue.svg';
import QuestionIcon from 'images/icons/question-mark.svg';
import { useHistory, Link } from 'react-router-dom';

import './OverviewTab.scss';
import Switcher from 'components/Shared/Switcher';
import CustomSelect from 'components/Shared/CustomSelect';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
import { getHiringStatuses } from 'store/actions/assessment.actions';
import {
  acknowledgeHiringStatusError,
  acknowledgeHiringStatusUpdated,
  getCandidateDetails,
  setHiringStatus,
} from 'store/actions/candidate.actions';
import { getOrgGroups } from 'store/actions/profile.actions';
import { useToasts } from 'react-toast-notifications';
import TextSkeleton from 'components/Shared/TextSkeleton';
import Skeleton from 'react-loading-skeleton';
import LightTooltip from 'components/Shared/Tooltip/LightTooltip';
import { Recruiter, Snapshot } from 'store/reducers/candidate';
import Carousel from 'components/Shared/Carousel';
import Lightbox from 'react-image-lightbox';
import { getFormattedAssessmentName } from 'helpers';
import { getPercentileOrdinalIndicator } from 'helpers/numbers';
import SuspiciousActivities, {
  SuspiciousActivity,
} from 'components/Shared/SuspiciousActivityCard';
import { hasOrgFeature } from '../../../Authorization/Check';
import InformationField, { InformationFieldType } from './InformationField';
import OtherAssessments from './OtherAssessments';
import InternalNotes, { Note } from './InternalNotes';
import SubjectScores from './SubjectScores';
import CandidatePosition from './CandidatePosition';
import RejectCandidateModal from '../../Shared/RejectCandidateModal';
import compare from '../../../images/icons/compare-blue.svg';
import SuspiciousSnapshot from './SuspiciousSnapshot';
import LiveVideoPlayer from '../../LiveVideoPlayer';

export interface InformationFieldData {
  id: number;
  label: string;
  is_anonymised: boolean;
  is_predefined: boolean;
  is_required: boolean;
  is_default_for_hiring: boolean;
  is_default_for_ld: boolean;
  type: InformationFieldType;
  value: string;
}
export interface Candidate {
  id: number;
  reference: string;
  email_address: string;
  status: string;
  first_name: string;
  last_name?: string;
  full_name?: string;
  invited_at: string;
  submitted_at: string;
  phone: string;
  score: number;
  score_percent: number;
  hiring_status: string;
  information_fields: InformationFieldData[];
  related_assessments: RelatedAssessment[];
  suspicious_activities: SuspiciousActivity[];
  comments: Note[];
  enable_snapshot?: boolean;
  enable_screen_recording?: boolean;
  playback_url?: string;
  video_records?: string[];
  play?: boolean;
  snapshots?: Snapshot[];
  invite_source?: string;
  resume_url?: string;
  recruiter?: Recruiter;
  is_erased?: boolean;
}

export interface RelatedAssessment {
  reference: string;
  assessment_name: string;
  invited_at: string;
  submitted_at: string;
  status: string;
  total_score?: number;
  recruiter_test: any;
  partial_score?: number;
}
const invitedBySomeoneTypes = ['manual', 'import', 'assign'];
const invitedByPublicTypes = ['link', '', null];

const OverviewTab = ({
  hideSensitiveInfo = false,
  candidateDetailsHidden = false,
  setCandidateDetailsHidden,
}: {
  hideSensitiveInfo?: boolean;
  candidateDetailsHidden?: boolean;
  setCandidateDetailsHidden?: any;
}): any => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const history = useHistory();
  const { organizationDetails: organization } = useSelector(
    (state: RootState) => state.organization
  );
  const { groups } = useSelector((state: RootState) => state.organization);
  const { hiringStatuses, currentAssessment } = useSelector(
    (state: RootState) => state.assessment
  );

  const { hiringStatus, loading, candidate } = useSelector(
    (state: RootState) => state.candidate
  );

  const [rejectModalShown, setRejectModalShown] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState<number>(null);
  const [lightboxImages, setLightboxImages] = useState<string[]>([]);
  const [informationFields, setInformationFields] = useState<
    InformationFieldData[]
  >([]);
  const [relatedAssessments, setRelatedAssessments] = useState<
    RelatedAssessment[]
  >([]);
  const [suspiciousActivities, setSuspicousActivites] = useState<
    SuspiciousActivity[]
  >([]);
  const [internalNotes, setInternalNotes] = useState<Note[]>([]);
  const [hiringStatusChoices, setHiringStatusChoices] = useState([]);
  const [notifyRejection, setNotifyRejection] = useState(false);
  useEffect(() => {
    if (hiringStatuses.length === 1) {
      dispatch(getHiringStatuses());
    }
    setHiringStatusChoices(hiringStatuses);
  }, [dispatch, hiringStatuses]);
  const scoreTitle = useMemo(() => {
    if (candidate?.status === 'Completed') {
      return 'Overall Score';
    }
    if (candidate?.status === 'Incomplete Rejected') {
      return 'Score';
    }
    return 'Partial Score';
  }, [candidate?.status]);
  const suspiciousSnapshot = useMemo(() => {
    if (!candidate?.snapshots || candidate.snapshots.length < 1) {
      return 0;
    }
    return (
      (candidate?.snapshots?.reduce((acc, snapshot) => {
        if (
          snapshot.face_number > 1 ||
          (!snapshot.face_match && snapshot.face_match !== null) ||
          (snapshot.cheating_labels && snapshot.cheating_labels.length > 0)
        ) {
          return acc + 1;
        }
        return acc;
      }, 0) /
        candidate?.snapshots.length) *
      100
    );
  }, [candidate?.snapshots]);
  useEffect(() => {
    if (candidate) {
      setInformationFields(
        candidate.information_fields.filter(
          (field: InformationFieldData) =>
            field.value !== '' && field.value !== null
        )
      );
      setRelatedAssessments(candidate.related_assessments);
      const suspiciousActivities = candidate.suspicious_activities
        ? [...candidate.suspicious_activities]
        : [];
      setSuspicousActivites(suspiciousActivities);
      setInternalNotes(candidate.comments);
      if (candidate.snapshots) {
        setLightboxImages(
          candidate.snapshots.map((snapshot: Snapshot) => snapshot.image_url)
        );
      }
    } else {
      setInformationFields([]);
      setRelatedAssessments([]);
      setSuspicousActivites([]);
      setInternalNotes([]);
      setLightboxImages([]);
    }
  }, [candidate, suspiciousSnapshot]);

  useEffect(() => {
    if (
      userDetails?.recruiter_detail?.cloak_candidates &&
      candidate?.reference &&
      !hideSensitiveInfo
    ) {
      dispatch(
        getCandidateDetails(candidate.reference, true, hideSensitiveInfo)
      );
    }
    if (groups === null && userDetails?.id) {
      dispatch(getOrgGroups(userDetails.id));
    }
  }, [
    hideSensitiveInfo,
    candidate?.reference,
    candidateDetailsHidden,
    dispatch,
    groups,
    userDetails?.id,
    userDetails?.recruiter_detail?.cloak_candidates,
  ]);

  useEffect(() => {
    if (hiringStatus.errorMessage) {
      addToast({
        type: 'error',
        msg: hiringStatus.errorMessage,
      });

      dispatch(acknowledgeHiringStatusError());
    }

    if (hiringStatus.updated && hiringStatus.errorMessage === null) {
      let message = 'Hiring status updated.';
      if (candidate?.hiring_status === 'Rejected' && notifyRejection) {
        message = 'Hiring status updated and the candidate will be notified.';
      }
      addToast({
        type: 'success',
        msg: message,
      });
      dispatch(acknowledgeHiringStatusUpdated());
      setNotifyRejection(false);
    }
  }, [
    hiringStatus,
    addToast,
    dispatch,
    candidate?.hiring_status,
    notifyRejection,
  ]);

  const handleHideCandidateDetailsToggle = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setCandidateDetailsHidden(e.target.checked);
  };
  const onHiringStatusChanged = (
    event: React.ChangeEvent<{ value: string }>
  ): void => {
    if (event.target.value === 'Rejected') {
      setRejectModalShown(true);
    } else {
      dispatch(setHiringStatus(candidate.reference, event.target.value));
    }
  };
  const onCandidateRejectConfirm = ({
    note,
    reason,
    notifyCandidate,
  }): void => {
    setNotifyRejection(notifyCandidate);
    dispatch(
      setHiringStatus(candidate.reference, 'Rejected', {
        note,
        reason,
        notifyCandidate,
      })
    );
    setRejectModalShown(false);
  };

  const onCandidateRejectCancel = (): void => {
    setRejectModalShown(false);
    setNotifyRejection(false);
  };

  const invitedBy = useCallback(() => {
    if (candidate && invitedBySomeoneTypes.includes(candidate.invite_source)) {
      if (candidate.recruiter) {
        return `${candidate.recruiter.first_name} ${candidate.recruiter.last_name}`;
      }
    } else if (
      candidate &&
      invitedByPublicTypes.includes(candidate.invite_source)
    ) {
      return 'Public Link';
    }
    return candidate?.invite_source
      ? candidate?.invite_source
          ?.split(' ')
          .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
          .join(' ')
      : undefined;
  }, [candidate]);

  const getGroupName = useCallback(
    (id: number): string => {
      if (groups && groups[id]) {
        return groups[id];
      }
      return null;
    },
    [groups]
  );

  const restrictedTo = useCallback((): string => {
    if (currentAssessment.group_id === undefined) {
      return '';
    }
    return getGroupName(currentAssessment.group_id);
  }, [currentAssessment, getGroupName]);

  const isLnD = currentAssessment?.purpose === 'ld';
  const participantWord = isLnD ? 'employee' : 'candidate';
  const assessmentWord = isLnD ? 'exercise' : 'assessment';

  const percentileTooltip = `This ${participantWord} scored better than ${Math.round(
    candidate?.percentile * 100
  )}% of ${participantWord}s who have taken this ${assessmentWord}.`;

  return (
    <div
      className={
        candidateDetailsHidden
          ? 'cloaked candidate-details'
          : 'candidate-details'
      }
    >
      <RejectCandidateModal
        candidateName={
          candidateDetailsHidden ? candidate?.reference : candidate?.full_name
        }
        isShown={rejectModalShown}
        onCancel={onCandidateRejectCancel}
        onConfirm={onCandidateRejectConfirm}
      />
      {!currentAssessment?.cloak_candidates &&
      ((userDetails.recruiter_detail?.cloak_candidates &&
        userDetails.recruiter_detail?.user_id) ||
        organization?.cloak_candidates) ? (
        <div className="flex flex-end cloak-toggler">
          <span className="text-dark">Candidate Details Hidden</span>
          <Switcher
            checked={candidateDetailsHidden}
            onChange={handleHideCandidateDetailsToggle}
          />
        </div>
      ) : (
        ''
      )}
      <CardContainer>
        <div className="candidate-details-header">
          <div className="space-between">
            <div>
              <h2 className="cloakable">
                <TextSkeleton loading={loading.candidate} width="300px">
                  {candidate?.full_name}
                </TextSkeleton>
              </h2>
              {hideSensitiveInfo &&
                candidate?.status === 'Incomplete Rejected' && (
                  <div className="candidate-message">
                    <p>
                      Thank you for participating in our assessment process.
                    </p>
                    <p>
                      Unfortunately, your score in the recent test did not meet{' '}
                      {candidate?.organisation_name}&apos;s minimum requirement
                      to proceed to the next stage. We appreciate your effort
                      and encourage you to continue developing your skills for
                      future opportunities.
                    </p>
                    <p>
                      We have provided feedback on your performance and
                      resources for improvement in the{' '}
                      <Link to="areas-for-review">Areas For Review</Link>. Best
                      of luck in your job search and future growth.
                    </p>
                  </div>
                )}
              {!hideSensitiveInfo && (
                <>
                  <h5 className="help my2 cloakable">
                    <TextSkeleton loading={loading.candidate} width="100px">
                      {candidate?.reference}
                    </TextSkeleton>
                  </h5>
                  <h2 className="show-when-cloaked">{candidate?.reference}</h2>
                </>
              )}
            </div>
            {candidate?.can_compare && !hideSensitiveInfo && (
              <button
                style={{ height: '40px' }}
                type="button"
                onClick={() =>
                  history.push(
                    `/compare-candidates/${
                      currentAssessment.id
                    }-${getFormattedAssessmentName(
                      currentAssessment.recruiter_test_name
                    )}/${candidate.reference}`
                  )
                }
                className="sub-primary sm"
              >
                <img
                  width={20}
                  style={{ marginRight: '6px' }}
                  src={compare}
                  alt="Compare candidates"
                />{' '}
                Compare
              </button>
            )}
          </div>
          <div className="cloakable">
            {!loading.candidate &&
            candidate &&
            candidate?.resume_url !== null ? (
              <a href={candidate.resume_url}>
                <Button variant="primary md" text="Download Resume" />
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        {!!candidate?.assessment_modified_after_submission && (
          <div className="custom-alert">
            <div className="text-content">
              {currentAssessment?.purpose === 'ld' ? (
                <p>
                  The exercise configuration was changed after this employee
                  took the exercise.
                </p>
              ) : (
                <p>
                  The assessment configuration was changed after this candidate
                  took the assessment.
                </p>
              )}
            </div>
          </div>
        )}
        <div className="score-boxes candidate-scores-wrapper">
          {candidate?.score_percent !== null && (
            <div className="score-box">
              <div className="score-box-content overall-score">
                {candidate?.status && <h3>{scoreTitle}</h3>}
                <div className="score">
                  <h2>
                    <TextSkeleton loading={loading.candidate} width="60px">
                      {candidate?.score_percent ||
                      candidate?.score_percent === 0
                        ? `${Math.round(candidate?.score_percent)}%`
                        : undefined}
                    </TextSkeleton>
                    {candidate?.status === 'Requires Evaluation' ? (
                      <LightTooltip title="Requires Evaluation" arrow>
                        <img
                          className="ml2"
                          src={RequiresEvaluationIcon}
                          alt="Requires Evaluation Icon"
                        />
                      </LightTooltip>
                    ) : (
                      ''
                    )}
                  </h2>
                </div>
              </div>
            </div>
          )}
          {candidate?.percentile !== null && (
            <div className="score-box">
              <div className="score-box-content overall-score">
                {candidate?.percentile !== null && <h3>Percentile Rank</h3>}
                <div className="score">
                  <h2>
                    <TextSkeleton loading={loading.candidate} width="60px">
                      {candidate?.percentile || candidate?.percentile === 0 ? (
                        <span className="percentile-text">
                          <span>{Math.round(candidate?.percentile * 100)}</span>
                          <span className="percentile-ordinal-indicator">
                            {getPercentileOrdinalIndicator(
                              candidate?.percentile
                            )}
                          </span>
                        </span>
                      ) : undefined}
                    </TextSkeleton>
                    {candidate?.percentile !== null && (
                      <LightTooltip title={percentileTooltip} arrow>
                        <img
                          width={18}
                          className="ml2"
                          src={QuestionIcon}
                          alt={percentileTooltip}
                        />
                      </LightTooltip>
                    )}
                  </h2>
                </div>
              </div>
            </div>
          )}
          {candidate?.candidates_scores_buckets !== null &&
            candidate?.score_percent !== null && (
              <CandidatePosition
                candidates_scores_buckets={candidate?.candidates_scores_buckets}
                score_percent={candidate?.score_percent}
                loading={loading}
              />
            )}
        </div>
        {candidate?.score_percent !== null && <hr />}
        <div className="candidate-information text-dark mb5">
          <div className="private-information grid grid-3 py2">
            {!candidate?.is_erased && (
              <div className="cloakable" id="candidate-email">
                <>
                  <img
                    className="personal-info-icon"
                    src={EmailIcon}
                    alt="Email Icon"
                  />
                  <TextSkeleton loading={loading.candidate} width="250px">
                    {candidate?.email_address ? (
                      <a href={`mailto:${candidate?.email_address}`}>
                        {candidate?.email_address}
                      </a>
                    ) : undefined}
                  </TextSkeleton>
                </>
              </div>
            )}
            {candidate?.phone === null ? (
              ''
            ) : (
              <div className="cloakable" id="candidate-phone">
                {!candidate?.is_erased && (
                  <>
                    <img
                      className="personal-info-icon"
                      src={MobileIcon}
                      alt="Phone Icon"
                    />
                    <TextSkeleton loading={loading.candidate} width="121px">
                      {candidate?.phone}
                    </TextSkeleton>
                  </>
                )}
              </div>
            )}
            {!hideSensitiveInfo && (
              <div>
                <span className="md-display-block text-slate mr1">
                  <img
                    className="personal-info-icon"
                    src={StatusIcon}
                    alt="Assessment Status Icon"
                  />
                  Assessment Status
                </span>
                <span id="assessment-status" className="ml1">
                  {candidate?.status === 'Requires Evaluation' ? (
                    <LightTooltip title="Requires Evaluation" arrow>
                      <img
                        className="personal-info-icon"
                        src={RequiresEvaluationIcon}
                        alt="Requires Evaluation Icon"
                      />
                    </LightTooltip>
                  ) : (
                    ''
                  )}
                  <TextSkeleton loading={loading.candidate} width="150px">
                    {candidate?.status}
                  </TextSkeleton>
                </span>
              </div>
            )}
          </div>

          <div className="grid grid-3 py2">
            {!hideSensitiveInfo && (
              <div>
                <span className="md-display-block text-slate mr1">
                  <img
                    className="personal-info-icon"
                    src={UserIcon}
                    alt="Invited By Icon"
                  />
                  Invited{' '}
                  {invitedBySomeoneTypes.includes(candidate?.invite_source)
                    ? 'By'
                    : 'Via'}
                </span>
                <span
                  className={
                    candidate?.recruiter?.is_deleted ? 'inviter-deleted' : ''
                  }
                >
                  <LightTooltip
                    title={
                      invitedBySomeoneTypes.includes(candidate?.invite_source)
                        ? `${candidate.recruiter?.email} ${
                            candidate?.recruiter?.is_deleted ? '(Deleted)' : ''
                          }`
                        : ''
                    }
                    arrow
                  >
                    <span>
                      <TextSkeleton loading={loading.candidate} width="150px">
                        {invitedBy()}
                      </TextSkeleton>
                    </span>
                  </LightTooltip>
                </span>
              </div>
            )}
            <div>
              <span className="text-slate md-display-block">
                <img
                  className="personal-info-icon"
                  src={CalendarIcon}
                  alt="Calendar Icon"
                />
                Invited On
              </span>
              <span id="invited-at">
                <TextSkeleton loading={loading.candidate} width="150px">
                  {candidate?.invited_at
                    ? moment(candidate.invited_at).format('MMMM Do YYYY h:mm A')
                    : undefined}
                </TextSkeleton>
              </span>
            </div>
            {candidate?.submitted_at !== null ? (
              <div>
                <span className="text-slate md-display-block">
                  <img
                    className="personal-info-icon"
                    src={CalendarIcon}
                    alt="Calendar Icon"
                  />
                  Submitted On
                </span>
                <span id="submitted-at">
                  <TextSkeleton loading={loading.candidate} width="150px">
                    {candidate?.submitted_at
                      ? moment(candidate.submitted_at).format(
                          'MMMM Do YYYY h:mm A'
                        )
                      : undefined}
                  </TextSkeleton>
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="grid grid-3 py2">
            {restrictedTo() !== null && (
              <div>
                <span className="md-display-block text-slate mr1">
                  <img
                    className="personal-info-icon"
                    src={UserGroupIcon}
                    alt="Restricted To Icon"
                  />
                  Restricted To
                </span>
                <span id="restricted-to">
                  <TextSkeleton loading={loading.candidate} width="150px">
                    {(!loading.candidate && restrictedTo()) || undefined}
                  </TextSkeleton>
                </span>
              </div>
            )}
          </div>
        </div>
        {candidate?.subject_scores?.length ? (
          <div>
            <hr />
            <SubjectScores subject_scores={candidate.subject_scores} />
          </div>
        ) : (
          ''
        )}
        <hr />
        <div>
          <div className="information-fields">
            {loading.candidate
              ? new Array(5).fill('').map(() => (
                  <div className="information-field-container my2">
                    <div className="information-field">
                      <Skeleton width="95%" />
                    </div>
                    <div className="information-field-value">
                      <Skeleton width={320} />
                    </div>
                  </div>
                ))
              : ''}
            {informationFields.map(({ label, value, type }) => {
              if (candidateDetailsHidden) {
                return null;
              }
              return (
                <InformationField
                  key={label}
                  label={label}
                  type={type as InformationFieldType}
                  value={value}
                />
              );
            })}
          </div>
        </div>
        {loading.candidate ||
        (informationFields.length > 0 && !candidateDetailsHidden) ? (
          <hr />
        ) : (
          ''
        )}
        {!hideSensitiveInfo && currentAssessment.purpose !== 'ld' && (
          <div className="grid grid-3 py4">
            <div className="hiring-status-container">
              <div className="text-slate information-field">Hiring Status</div>
              <div className="text-slate information-field-value">
                {candidate?.hiring_status && !loading.candidate ? (
                  <CustomSelect
                    fullWidth
                    value={candidate?.hiring_status}
                    inputProps={{ 'data-testid': 'hiring-status-select' }}
                    SelectDisplayProps={
                      { 'data-testid': 'hiring-status-select-button' } as any
                    }
                    onChange={onHiringStatusChanged}
                  >
                    {hiringStatusChoices.map((status) => {
                      return (
                        <MenuItem value={status.status}>
                          {status.status}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                ) : (
                  <Skeleton width="150px" />
                )}
              </div>
            </div>
          </div>
        )}
        {hideSensitiveInfo || suspiciousActivities.length < 1 ? (
          ''
        ) : (
          <SuspiciousActivities
            candidateReference={candidate?.reference}
            suspiciousActivities={suspiciousActivities}
            candidateDetailsHidden={candidateDetailsHidden}
          />
        )}
      </CardContainer>
      {!loading.candidate &&
      candidate?.enable_snapshot &&
      !hideSensitiveInfo &&
      (candidate?.submitted_at || candidate?.snapshots?.length > 0) ? (
        <div className="candidate-snapshots-container cloakable">
          <h2>Candidate Snapshots</h2>
          {candidate.snapshots && candidate.snapshots.length > 0 ? (
            <>
              <div className="candidate-snapshots-carousel-container">
                <Carousel>
                  {candidate.snapshots.map((snapshot: Snapshot, i: number) => (
                    <span className="candidate-snapshot">
                      <img
                        src={snapshot.image_url}
                        alt="candidate snapshot"
                        onClick={() => {
                          setSelectedSnapshot(i);
                        }}
                      />
                      <SuspiciousSnapshot snapshot={snapshot} />
                    </span>
                  ))}
                </Carousel>
              </div>
              {selectedSnapshot !== null && (
                <Lightbox
                  enableZoom={false}
                  imageTitle={`${
                    candidate.snapshots[selectedSnapshot].test
                  } (${moment(
                    candidate.snapshots[selectedSnapshot].created_at
                  ).format('MMMM Do YYYY h:mm A')})`}
                  mainSrc={lightboxImages[selectedSnapshot]}
                  nextSrc={
                    lightboxImages[
                      (selectedSnapshot + 1) % lightboxImages.length
                    ]
                  }
                  prevSrc={
                    lightboxImages[
                      (selectedSnapshot + lightboxImages.length - 1) %
                        lightboxImages.length
                    ]
                  }
                  onCloseRequest={() => setSelectedSnapshot(null)}
                  onMovePrevRequest={() =>
                    setSelectedSnapshot(
                      (selectedSnapshot + lightboxImages.length - 1) %
                        lightboxImages.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setSelectedSnapshot(
                      (selectedSnapshot + lightboxImages.length + 1) %
                        lightboxImages.length
                    )
                  }
                />
              )}
            </>
          ) : (
            <div className="suspicious-activity-card">
              <p>
                The enable snapshots option was turned on for this assessment,
                but no images were uploaded. That means the candidate
                didn&apos;t have a webcam enabled while taking the assessment.
              </p>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      {!hideSensitiveInfo &&
        candidate?.enable_screen_recording &&
        candidate?.playback_url &&
        candidate?.status === 'In Progress' && (
          <div className="internal-notes-container">
            <h2 className="heading">Candidate Live Monitoring</h2>
            <CardContainer className="video-container">
              <LiveVideoPlayer autoPlay playbackUrl={candidate?.playback_url} />
            </CardContainer>
          </div>
        )}
      {!loading.candidate &&
      candidate?.enable_screen_recording &&
      candidate?.status !== 'In Progress' &&
      !hideSensitiveInfo &&
      hasOrgFeature('video_screen_proctoring') &&
      (candidate?.submitted_at || candidate?.video_records?.length > 0) ? (
        <div className="internal-notes-container cloakable">
          <h2 className="heading">Candidate Video Records</h2>
          {candidate.video_records && candidate.video_records.length > 0 ? (
            <CardContainer className="video-container cloakable multiple">
              {candidate.video_records.map((record) => (
                <LiveVideoPlayer key={record} playbackUrl={record} />
              ))}
            </CardContainer>
          ) : (
            <div className="suspicious-activity-card">
              <p>
                The live monitoring option was turned on for this assessment,
                but no videos were uploaded. That means the candidate
                didn&apos;t have a webcam enabled while taking the assessment.
              </p>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      {relatedAssessments.length > 0 ? (
        <OtherAssessments
          isVerificationCode={hideSensitiveInfo}
          otherAssessments={relatedAssessments}
        />
      ) : (
        ''
      )}
      {!hideSensitiveInfo && (
        <InternalNotes notes={internalNotes} reference={candidate?.reference} />
      )}
    </div>
  );
};

export default OverviewTab;
