import React from 'react';
import MultiSelectFilter from '../../Shared/Filters/MultiSelectFilter';
import DateFilter from '../../Shared/Filters/DateFilter';
import ScoreFilter from '../../Shared/Filters/ScoreFilter';
import TextFilter from '../../Shared/Filters/TextFilter';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FilterMapper = (props): JSX.Element => {
  switch (props.type) {
    case 'MultiSelectFilter':
      return <MultiSelectFilter {...props} />;
    case 'DateFilter':
      return <DateFilter {...props} />;
    case 'ScoreFilter':
      return <ScoreFilter {...props} />;
    default:
      return <TextFilter {...props} />;
  }
};
export default FilterMapper;
