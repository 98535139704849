import React, { useCallback, useEffect, useState } from 'react';
import './CreditUsage.scss';
import { RootState } from 'store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import useDynamicTable from 'hooks/useDynamicTable';
import ParticipantsTable from 'components/Participants/ParticipantsTable';
import { DynamicTablePropsContext } from 'components/Shared/DynamicTable/context/DynamicTablePropsContext';
import { getCreditHistory } from 'api/subscription.api';
import {
  getCreditHistorySuccess,
  updateCreditHistoryQueryParams,
} from 'store/reducers/subscription';
import TextWithTooltip from 'components/Shared/Tooltip/TextWithTooltip';
import { fullDateTime, relativeDate } from 'helpers/datetime';
import TableBlank from 'components/Participants/TableBlank';
import { LoaderContext } from 'context/loader';
import { getOrgDetails } from 'store/actions/organization.actions';
import CreditUsage from './CreditUsage';
import { tableFields } from './creditHistoryTableFields';

const getDataCallback = {
  callback: (data: any): any => {
    return data;
  },
};

export default function CreditUsageTab(): any {
  const { creditHistory, creditHistoryQueryParams } = useSelector(
    (state: RootState) => state.subscription
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state: RootState) => state.profile);

  const [creditHistoryTableProps, setCreditHistoryTableProps] = useState<any>({
    loading: isLoading,
    fields: [],
    data: [],
    creditHistory,
  });

  const [blank, setBlank] = useState<boolean>(true);
  const getData = async (data: any): Promise<any> => {
    setIsError(false);
    setIsLoading(true);
    const state = { ...creditHistoryQueryParams, ...data };
    dispatch(updateCreditHistoryQueryParams(state));
    try {
      const res = await getCreditHistory(state);
      if (res?.data?.data) {
        dispatch(getCreditHistorySuccess(res.data.data));
        setBlank(res.data.data.total === 0);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
      setLoaded(true);
    } catch (err) {
      setIsError(true);
      setIsLoading(false);
      setLoaded(true);
    }
  };

  getDataCallback.callback = useCallback(getData, [
    dispatch,
    creditHistoryQueryParams,
  ]);

  useEffect(() => {
    const orgId = userDetails?.recruiter_detail?.organisation_id;
    if (orgId) {
      dispatch(getOrgDetails(orgId));
    }
  }, [dispatch, userDetails]);

  const {
    isLast,
    nextPage,
    changePerPage,
    sortingAction,
    tablePaginatorProps,
  } = useDynamicTable(
    getDataCallback,
    creditHistory,
    creditHistoryQueryParams.page
  );

  const processCell = useCallback(
    (row: any, field: any): any => {
      if (!row[field.label] && row[field.label] !== 0) {
        return 'N/A';
      }
      const value = row[field.label];
      if (field.type === 'datetime') {
        return (
          <TextWithTooltip
            text={relativeDate(value, userDetails.user_timezone)}
            tooltip={fullDateTime(
              value,
              userDetails.user_timezone,
              'll [at] LT [(GMT]Z[)]'
            )}
          />
        );
      }
      if (field.type === 'number') {
        if (value < 0) {
          return (
            <div className="text-right">
              <span className="negative-credit">- </span>
              <span>{Math.abs(value).toLocaleString()}</span>
            </div>
          );
        } else if (value > 0) {
          return (
            <div className="text-right">
              <span className="positive-credit">+ </span>
              <span>{Math.abs(value).toLocaleString()}</span>
            </div>
          );
        }
        return (
          <div className="text-right">
            <span>{value.toLocaleString()}</span>
          </div>
        );
      }
      return value;
    },
    [userDetails.user_timezone]
  );

  useEffect(() => {
    if (!loaded || blank) {
      return;
    }
    const sortingProps = {
      sortingAction,
      orderBy: creditHistoryQueryParams.orderBy,
      orderDirection: creditHistoryQueryParams.orderDirection,
    };
    setCreditHistoryTableProps({
      loading: false,
      data: creditHistory.data,
      fields: tableFields,
      tablePaginatorProps,
      sortingProps,
      processCell,
    });
    setLoaded(false);
  }, [
    loaded,
    creditHistory.current_page,
    creditHistory,
    creditHistoryQueryParams,
    blank,
    userDetails,
    nextPage,
    changePerPage,
    sortingAction,
    processCell,
    isLast,
    tablePaginatorProps,
  ]);

  const retry = (): void => {
    getDataCallback.callback({});
  };

  // every time the user navigates to the credit usage tab, we want to fetch the credit history with the default params
  useEffect(() => {
    setIsLoading(true);
    setLoaded(false);
    getDataCallback.callback({
      page: 1,
      orderBy: 'Date',
      orderDirection: 'desc',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="credit-usage-page">
      <CreditUsage />
      <h2>Credit Usage</h2>
      <LoaderContext.Provider value={[isLoading, setIsLoading]}>
        {isError && (
          <TableBlank
            {...{ loading: isLoading }}
            message="Unable to fetch credit history"
            onRetry={retry}
          />
        )}
        {!isError &&
          (blank ? (
            <TableBlank
              {...{ loading: isLoading }}
              message="No credit transactions found"
            />
          ) : (
            <DynamicTablePropsContext.Provider
              value={[creditHistoryTableProps, setCreditHistoryTableProps]}
            >
              <ParticipantsTable />
            </DynamicTablePropsContext.Provider>
          ))}
      </LoaderContext.Provider>
    </div>
  );
}
