import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import { ChevronLeftIcon } from '../Icon/Chevron';
import './index.scss';

export const round = (n: number, width: number): number => {
  if (n > 0) return Math.ceil(n / width) * width;
  if (n < 0) return Math.floor(n / width) * width;
  return 0;
};

export const checkScroll = (
  e: any,
  setLeftDisabled: any,
  setRightDisabled: any
): void => {
  if (e.target.scrollLeft === 0) {
    setLeftDisabled(true);
  } else {
    setLeftDisabled(false);
  }

  if (e.target.scrollLeft >= e.target.scrollWidth - e.target.clientWidth) {
    setRightDisabled(true);
  } else {
    setRightDisabled(false);
  }
};

const Carousel: React.FC<{
  children: React.ReactNode;
  childWidth?: number;
  title?: string;
}> = ({ children, childWidth = 150, title }) => {
  const ref = useRef(null);
  const [leftDisabled, setLeftDisabled] = useState(false);
  const [rightDisabled, setRightDisabled] = useState(false);
  const [shouldCenter, setShouldCenter] = useState(true);
  const handleResize = (): void => {
    ref.current.dispatchEvent(new Event('scroll'));
  };
  useWindowSize(handleResize);
  const updateCursor = (callback): void => {
    ref.current.scrollTo({
      left: callback(ref.current.scrollLeft),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (leftDisabled && rightDisabled) {
      setShouldCenter(true);
    } else {
      setShouldCenter(false);
    }
  }, [leftDisabled, rightDisabled]);

  useLayoutEffect(() => {
    ref.current.dispatchEvent(new Event('scroll'));
  }, []);

  return (
    <div className="carousel-wrapper">
      {title ? <h3 className="carousel-title">{title}</h3> : ''}
      <div className="carousel">
        {!leftDisabled ? (
          <button
            className="button-left"
            type="button"
            onClick={() => {
              updateCursor((state) =>
                round(state - childWidth * 4, childWidth)
              );
            }}
          >
            <ChevronLeftIcon size={14} fill="#004385" />
          </button>
        ) : (
          ''
        )}
        <div
          className="slider"
          ref={ref}
          onScroll={(e) => checkScroll(e, setLeftDisabled, setRightDisabled)}
          style={{ justifyContent: shouldCenter ? 'center' : 'flex-start' }}
        >
          {children}
        </div>
        {!rightDisabled ? (
          <button
            className="button-right"
            type="button"
            onClick={() => {
              updateCursor((state) =>
                round(state + childWidth * 4, childWidth)
              );
            }}
          >
            <ChevronLeftIcon size={14} fill="#004385" />
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Carousel;
