import MenuItem from '@material-ui/core/MenuItem';
import { styled } from '@material-ui/core/styles';

const CustomMenuItem = styled(MenuItem)({
  height: '30px',
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    '& .MuiListItemText-primary': {
      fontWeight: 600,
    },
  },
});
export default CustomMenuItem;
