import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useParams } from 'react-router-dom';
import { getAssessment, deleteAssessment } from 'api/assessment.api';
import { selectAssessment } from 'store/reducers/assessment';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getOrgDetails } from 'store/actions/organization.actions';
import PageHeader from 'components/Shared/PageHeader';
import DeleteModal from 'components/Shared/DeleteModal';
import Skeleton from 'react-loading-skeleton';
import Participants from 'components/Participants';
import ResultsOverview from 'components/ResultsOverview';
import CandidateResultsList from 'components/CandidateResultsList';
import NoCandidates from 'components/NoCandidates/NoCandidates';
import FreeResponseFeed from 'components/FreeResponseFeed';
import AssessmentInterviews from 'components/AssessmentInterviews';
import AssessmentTabs from './assessmentTabs';
import AssessmentActionList from '../AssessmentActionList';
import authorize from '../../Authorization/Check';
import './assessment.scss';
import { emptyJwt, isObjectEmpty } from '../../helpers';
import styles from './Assessment.module.scss';
import NoAccess from './NoAccess';

interface UrlParams {
  assessmentId?: string;
  tabName?: string;
  formattedAssessmentName?: string;
}

const initialContextValue: any = {
  assessment: {},
  setAssessment: () => {
    /* set assessment */
  },
  loading: true,
};
export const assessmentContext = React.createContext(initialContextValue);

const Assessment: React.FC = () => {
  const { assessmentId, formattedAssessmentName }: UrlParams = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [assessment, setAssessment] = useState<any>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [hasAccess, setHasAccess] = useState<boolean>(true);
  const [showRestrictedWarning, setShowRestrictedWarning] = useState<boolean>(
    false
  );
  const { addToast } = useToasts();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { cloak_candidates } = useSelector(
    (state: RootState) => state.organization.organizationDetails
  );
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const decodedToken = jwt_decode(accessToken || emptyJwt);
  const { currentAssessment } = useSelector(
    (state: RootState) => state.assessment
  );
  const subscription = useSelector((state: RootState) => state.subscription);
  const [trialBannerShow, setTrialBannerShow] = useState<boolean>(false);
  const [assessmentTabProps, setAssessmentTabProps] = useState({
    tabsData: [],
    trialBannerShow: false,
  });

  useEffect(() => {
    setAssessment(currentAssessment);
  }, [currentAssessment]);

  useEffect(() => {
    setTrialBannerShow(subscription?.subscription?.status === 'trialing');
  }, [subscription?.subscription?.status]);

  useEffect(() => {
    const decoded: { pl: any[] } = jwt_decode(accessToken || emptyJwt);
    if (decoded.pl.includes('Restricted')) {
      setShowRestrictedWarning(true);
    }
  }, [accessToken]);

  const handleDeleteModal = (e): void => {
    e.preventDefault();
    setShowDeleteModal(true);
  };

  const handleDelete = async (): Promise<void> => {
    const response = await deleteAssessment(assessment.id);
    if (response.status === 200) {
      setShowDeleteModal(false);
      setDeleting(false);
      addToast({
        type: 'success',
        msg: `Assessment with the name ${assessment.recruiter_test_name} has been deleted!`,
      });
      setTimeout(() => {
        window.location.href = '/alooba-assess';
      }, 1000);
    } else {
      setShowDeleteModal(false);
      setDeleting(false);
      addToast({
        type: 'error',
        msg: `Error deleting assessment with the name ${assessment.recruiter_test_name}.`,
      });
    }
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!assessment.id && assessmentId && hasAccess) {
        try {
          if (
            !isObjectEmpty(currentAssessment) &&
            currentAssessment.id !== assessmentId
          ) {
            setAssessment(currentAssessment);
            setLoading(false);
            return;
          }
          const res: any = await getAssessment({ id: assessmentId });
          setAssessment(res.data.data);
          dispatch(selectAssessment({ assessment: res.data.data }));
          setHasAccess(true);
        } catch (e) {
          if (e.response && e.response.status === 403) {
            setHasAccess(false);
          }
          window.location.replace('/forbidden');
        }
      }
      setLoading(false);
    })();
  }, [
    assessment.id,
    assessmentId,
    currentAssessment,
    decodedToken,
    dispatch,
    hasAccess,
  ]);
  const AssessmentActionListProps = {
    assessment,
    showDeleteModal: (e) => handleDeleteModal(e),
  };

  useEffect(() => {
    const orgId = userDetails?.recruiter_detail?.organisation_id;
    if (orgId && cloak_candidates === null) {
      dispatch(getOrgDetails(orgId));
    }
  }, [dispatch, userDetails, cloak_candidates]);

  const noCandidatesName =
    assessment.purpose === 'ld' ? 'team member' : 'candidate';

  const PageHeaderProps = {
    assessment,
    loading,
    trialBannerShow,
    AssessmentActionListProps,
    ActionListComponent: (
      <AssessmentActionList {...AssessmentActionListProps} />
    ),
  };
  useEffect(() => {
    if (loading || !assessment) {
      return;
    }
    const overviewTab = {
      tabTitle: 'Overview',
      contentLink: `/assessment/${assessment.id}-${formattedAssessmentName}/overview`,
      component:
        assessment?.candidates_count < 1 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 10,
            }}
          >
            <div style={{ maxWidth: 1000 }}>
              <NoCandidates
                assessment={assessment}
                userDetails={userDetails}
                name={noCandidatesName}
                hideAts={!['hiring', 'junior'].includes(assessment.purpose)}
                link={assessment.public_url_active && assessment.public_url}
              />
            </div>
          </div>
        ) : (
          <assessmentContext.Provider
            value={{ assessment, setAssessment, loading }}
          >
            <ResultsOverview />
          </assessmentContext.Provider>
        ),
    };

    const manageParticipantTabs = {
      tabTitle:
        assessment.purpose === 'ld'
          ? 'Manage Participants'
          : 'Manage Candidates',
      contentLink: `/assessment/${assessment.id}-${formattedAssessmentName}/manage-participants`,
      component: (
        <assessmentContext.Provider
          value={{ assessment, setAssessment, loading }}
        >
          <Participants />
        </assessmentContext.Provider>
      ),
    };

    const viewResultsTab = {
      tabTitle:
        assessment.purpose === 'ld' ? 'Exercise Results' : 'Assessment Results',
      contentLink: `/assessment/${assessment.id}-${formattedAssessmentName}/results`,
      component: (
        <assessmentContext.Provider
          value={{ assessment, setAssessment, loading }}
        >
          <CandidateResultsList />
        </assessmentContext.Provider>
      ),
    };

    const responseFeedTab = {
      tabTitle: 'Response Evaluations',
      contentLink: `/assessment/${assessment.id}-${formattedAssessmentName}/response-evaluations`,
      component: (
        <assessmentContext.Provider
          value={{ assessment, setAssessment, loading }}
        >
          <FreeResponseFeed />
        </assessmentContext.Provider>
      ),
    };

    const interviewsTab = {
      tabTitle: 'Interviews',
      contentLink: `/assessment/${assessment.id}-${formattedAssessmentName}/interviews`,
      component: (
        <assessmentContext.Provider
          value={{ assessment, setAssessment, loading }}
        >
          <AssessmentInterviews />
        </assessmentContext.Provider>
      ),
    };

    const tabs = [];

    tabs.push(overviewTab);
    tabs.push(manageParticipantTabs);
    if (
      assessment.free_response_feed_allowed &&
      assessment.has_free_response_test
    ) {
      tabs.push(responseFeedTab);
    }
    tabs.push(viewResultsTab);
    if (assessment.purpose !== 'ld' && authorize('interview', 'view')) {
      tabs.push(interviewsTab);
    }

    setAssessmentTabProps({
      tabsData: tabs,
      trialBannerShow,
    });
  }, [
    assessment,
    formattedAssessmentName,
    loading,
    noCandidatesName,
    userDetails,
    trialBannerShow,
  ]);
  const deleteModalBody = (
    <>
      <p>
        {`Are you sure you want to delete the assessment called "${assessment.recruiter_test_name}"?`}
      </p>
      <p>
        All associated{' '}
        {assessment.purpose === 'ld' ? 'employees' : 'candidates'} will also be
        removed.
      </p>
      <p>This action is not reversible.</p>
    </>
  );
  const deleteModalProps = {
    loadingTxt: 'Deleting Assessment...',
    loading: deleting,
    isShown: showDeleteModal,
    actionText: 'Delete Assessment',
    handleButtonAction: handleDelete,
    setModalVisibility: setShowDeleteModal,
    title: 'Delete Assessment',
    body: deleteModalBody,
    showCancel: true,
  };

  return (
    <div
      id="assessment"
      className={`assessment-container ${trialBannerShow ? 'has-banner' : ''}`}
    >
      {hasAccess && <PageHeader {...PageHeaderProps} />}
      <>
        {loading ? (
          <Skeleton count={1} />
        ) : (
          <>
            {showRestrictedWarning ? (
              <div className={styles.warningBar}>
                <div className={styles.textContent}>
                  <p>
                    Your account only has access to the candidates you added
                    yourself. There may be candidates in this assessment that
                    aren&apos;t shown.
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
            {hasAccess ? (
              <AssessmentTabs {...assessmentTabProps} />
            ) : (
              <NoAccess />
            )}
          </>
        )}
      </>
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};

export type AssessmentPurpose = 'hiring' | 'junior' | 'ld';
export type ProductFeature = 'assess' | 'junior' | 'growth';

export default Assessment;
