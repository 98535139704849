import HiringTeam from 'components/HiringTeam';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { deactivatePageHeader } from 'store/reducers/layout';

const HiringTeamPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <div className="container">
      <Helmet>
        <title>Manage Users | alooba.com</title>
      </Helmet>
      <HiringTeam />
    </div>
  );
};

export default HiringTeamPage;
