import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { putOnClipboard } from '../../../helpers';
import PublicLinkIcon from '../../../images/icons/public-link-blue.svg';

const InviteLinkButton = (props: { assessment: any }): JSX.Element => {
  const { assessment } = props;
  const { addToast } = useToasts();
  const label = 'Invite Link';
  const handleButtonClick = async (): Promise<void> => {
    try {
      putOnClipboard(assessment.public_url);
      addToast({
        type: 'success',
        msg: `Link copied to your clipboard!`,
      });
    } catch (se) {
      addToast({
        type: 'error',
        msg: 'Unable to copy link to clipboard',
      });
    }
  };
  return (
    <>
      <div
        role="button"
        onClick={handleButtonClick}
        className="button-bar-item"
        tabIndex={-1}
      >
        <img src={PublicLinkIcon} alt={label || 'tooltip'} />
        <span className="button-bar-label">{label}</span>
      </div>
    </>
  );
};

export default InviteLinkButton;
