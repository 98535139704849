import Dropzone from 'react-dropzone';
import React, { useState } from 'react';
import Profile from '../../../images/profile_upload.svg';
import FileUploadIcon from '../../../images/file_upload.svg';
import './fileUpload.scss';

interface Props {
  addFile?: any;
  profile?: boolean;
  files?: any[];
  accept?: string;
  helperText?: string;
  label?: string;
}

export class file extends File {
  preview: string;
}
const FileUpload = ({
  addFile,
  profile,
  files,
  accept = '.pdf, .xsl, .csv, .xlsx',
  label = 'Drag and drop your file here',
  helperText = 'Support file format: .pdf, .xsl, .csv',
}: Props): JSX.Element => {
  const [warningMsg, setWarningMsg] = useState('');

  const onDrop = (accepted, rejected): any => {
    if (Object.keys(rejected).length !== 0) {
      const message = 'Please submit valid file type';
      setWarningMsg(message);
    } else {
      const blobPromise = new Promise((resolve) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then(() => {
        // carry-on your actions here
      });
      addFile(accepted[0]);
    }
  };

  const renderProfile =
    files && Object.keys(files).length !== 0 ? (
      files.map((file) => (
        <div className="profile_upload">
          <img src={file.preview} alt="profile" />
        </div>
      ))
    ) : (
      <div className="profile_upload">
        <img src={Profile} alt="upload" />
      </div>
    );

  const renderFile =
    files && Object.keys(files).length !== 0 ? (
      files.map((file) => {
        if (file.preview) {
          return <img src={file.preview} alt="file_upload" />;
        }
        return <p key={file.name}>{file.name}</p>;
      })
    ) : (
      <div className="file_upload_container">
        <img src={FileUploadIcon} alt="file_upload" />
        <p>{label}</p>
      </div>
    );

  return (
    <div id="file_upload">
      <p>{warningMsg}</p>
      <div className={`${profile ? 'profile_wrapper' : 'file_wrapper'}`}>
        <Dropzone
          data-testid="dropzone"
          className={`${profile ? 'profile_upload' : 'file_upload'}`}
          multiple={false}
          accept={`${profile ? 'image/*' : accept}`}
          onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
        >
          {({ isDragReject }) => {
            if (isDragReject) return 'Please upload a valid file type';
            return profile ? renderProfile : renderFile;
          }}
        </Dropzone>
        {profile ? (
          <p>
            {`${
              files && Object.keys(files).length !== 0
                ? 'Change Profile Photo'
                : 'Upload Profile Photo'
            }`}
          </p>
        ) : (
          <p className="supported_file">{helperText}</p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
