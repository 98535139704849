import React, {
  ButtonHTMLAttributes,
  forwardRef,
  useState,
  useRef,
} from 'react';
import DatePicker from 'react-datepicker';
import './index.scss';
import moment from 'moment-timezone';
import FieldContainer from '../FieldContainer';
import calendarImage from '../../../../assets/calendar_icon.svg';
import Button from '../../Button';

export type dateFilterProps = {
  name: string;
  label: string;
  description?: string;
  canHide: boolean;
  visible: boolean;
  initialValue?: {
    from?: string;
    to?: string;
  };
  onChange: any;
  hide: any;
};
const DateFilter = ({
  name,
  label,
  description,
  canHide,
  visible,
  initialValue,
  onChange,
  hide,
}: dateFilterProps): JSX.Element => {
  const datepicker = useRef(null);
  const [startDate, setStartDate] = useState<Date | null>(
    initialValue?.from ? moment.utc(initialValue?.from).toDate() : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    initialValue?.to ? moment.utc(initialValue?.to).toDate() : null
  );
  const [isChanged, setIsChanged] = useState(false);
  const handleChange = (dates): void => {
    const [fromDate, toDate] = dates;
    if (fromDate !== startDate || toDate !== endDate) {
      setIsChanged(true);
    }
    setStartDate(fromDate);
    setEndDate(toDate);
  };
  const submit = (): void => {
    if (onChange && isChanged) {
      const dateParams = {};
      if (startDate && endDate) {
        dateParams[`${name}[to]`] = moment(endDate)
          .add('1', 'days')
          .subtract('1', 'seconds')
          .tz('utc')
          .format('YYYY-MM-DD HH:mm:ss');
        dateParams[`${name}[from]`] = moment(startDate)
          .tz('utc')
          .format('YYYY-MM-DD HH:mm:ss');
        onChange(dateParams);
        setIsChanged(false);
      } else {
        reset();
      }
    }
    datepicker.current.setOpen(false);
  };
  const reset = (): void => {
    setStartDate(null);
    setEndDate(null);
    onChange({
      [`${name}[from]`]: null,
      [`${name}[to]`]: null,
    });
    setIsChanged(false);
    datepicker.current.setOpen(false);
  };
  const resetAndHide = (): void => {
    reset();
    hide();
  };
  const CustomDatePickerInput = forwardRef(
    (
      {
        onClick,
        value,
        onChange: onInputChange,
      }: React.DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      ref: React.Ref<HTMLButtonElement>
    ) => (
      <button
        className="md btn-40-height btn-filter more-btn"
        type="button"
        ref={ref}
        onClick={onClick}
      >
        {startDate && endDate
          ? `${moment(startDate).format('MMM DD, YYYY')} - ${moment(
              endDate
            ).format('MMM DD, YYYY')}`
          : 'All'}
        <img src={calendarImage} alt="" />
        <input
          className="datepicker-input"
          type="text"
          value={value}
          onChange={onInputChange}
        />
      </button>
    )
  );
  return (
    <FieldContainer
      hide={resetAndHide}
      label={label}
      description={description}
      canHide={canHide}
      visible={visible}
    >
      <div className="custom-date-picker">
        <DatePicker
          ref={datepicker}
          dateFormat="dd MMMM, yyyy"
          onChange={handleChange}
          onCalendarClose={submit}
          startDate={startDate}
          endDate={endDate}
          customInput={<CustomDatePickerInput />}
          selectsRange
          placeholderText="All"
          shouldCloseOnSelect={false}
        >
          <div className="actions-buttons">
            <Button
              type="button"
              onClick={reset}
              variant="sub-primary md"
              text="Clear"
            />
            <Button
              type="button"
              onClick={submit}
              variant="primary md"
              text="Done"
              disabled={!startDate || !endDate}
            />
          </div>
        </DatePicker>
      </div>
    </FieldContainer>
  );
};
export default DateFilter;
