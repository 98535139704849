import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  getAllSubjects,
  getQuestionsForSubject,
  addQuestionsToPart,
} from 'api/assessment.api';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal } from 'components/Shared/Modal';
import TextButton from 'components/Shared/Button/TextButton';
import Checkbox from 'components/Shared/Input/checkbox';
import InterviewSkillIcon from 'images/icons/interview-skill.svg';
import AddIcon from 'images/icons/add.svg';
import DeleteIcon from 'images/icons/delete.svg';
import { Button } from 'components/Shared';
import QuestionColorIcon from 'images/icons/question-color.svg';
import { isObjectEmpty } from 'helpers';
import { handleError } from 'handleError';
import {
  setAdditionalCriteriaSkills,
  setSkills,
} from 'store/reducers/interview';
import { QuestionType } from 'helpers/constants';
import { RootState } from '../../../../store/rootReducer';
import './index.scss';
import InterviewQuestionDetails from '../../InterviewQuestionDetails';

interface AddSectionModalProps {
  isShown: boolean;
  setModalVisibility: (isShown: boolean) => void;
  interview: any;
  skillMap: any;
  additionalCriteriaSkillMap: any;
  assessment: any;
  position: number;
  setNewTopicPosition: any;
  setNewQuestionPosition?: any;
  preSelectedSkill?: any;
  setPreSelectedSkill?: any;
  questionMap: any;
  skillQuestions: any;
  forceRefetch: any;
  setSkillLength: any;
  setAdditionalCriteriaSkillLength: any;
  setShowAddQuestionModal: any;
  selectedSkill: any;
  setSelectedSkill: any;
  addedQuestionId: any;
  setAddedQuestionId: any;
}
const AddSectionModal = (props: AddSectionModalProps): JSX.Element => {
  const {
    setModalVisibility,
    interview,
    assessment,
    position,
    setNewTopicPosition,
    setNewQuestionPosition,
    preSelectedSkill,
    setPreSelectedSkill,
    questionMap,
    skillQuestions,
    forceRefetch,
    skillMap,
    additionalCriteriaSkillMap,
    setSkillLength,
    setAdditionalCriteriaSkillLength,
    setShowAddQuestionModal,
    selectedSkill,
    setSelectedSkill,
    addedQuestionId,
    setAddedQuestionId,
  } = props;

  const [loading, setLoading] = useState(false);
  const { skills, additionalCriteriaSkills } = useSelector(
    (state: RootState) => state.interview
  );
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { questionContext }: any = useSelector(
    (state: RootState) => state.interview
  );

  const handleSelectSkill = (skillIndex: number): void => {
    setSelectedSkill(skillIndex);
  };

  const handleClickQuestion = (questionIndex: number): void => {
    setCurrentQuestion(questionIndex);
  };

  const handleSelectQuestion = (question: any): void => {
    setSelectedQuestions((selectedQuestions) => {
      const tempSelectedQuestions = {
        ...selectedQuestions,
      };
      if (tempSelectedQuestions[question.question_id]) {
        delete tempSelectedQuestions[question.question_id];
      } else {
        tempSelectedQuestions[question.question_id] = question;
      }
      return {
        ...tempSelectedQuestions,
      };
    });
  };

  const handleAddNewQuestion = (): void => {
    setModalVisibility(false);
    setShowAddQuestionModal(true);
  };

  const isButtonDisabled = useMemo(() => {
    if (currentStep === 0) {
      return selectedSkill === null;
    }
    return isObjectEmpty(selectedQuestions);
  }, [currentStep, selectedSkill, selectedQuestions]);

  const questionCount = useMemo(() => Object.keys(selectedQuestions).length, [
    selectedQuestions,
  ]);

  const steps = [
    {
      buttonText: 'Select Questions',
    },
    {
      buttonText: `Add Question${questionCount !== 1 ? 's' : ''}`,
      infoIcon: QuestionColorIcon,
      infoText: `${questionCount} Question${questionCount !== 1 ? 's' : ''}`,
    },
  ];

  const handleClose = useCallback((): void => {
    // Reset modal state
    setCurrentStep(0);
    setSelectedSkill(null);
    setCurrentQuestion(null);
    setQuestions([]);
    setSelectedQuestions({});
    setNewQuestionPosition(0);
    setPreSelectedSkill(null);

    // Hide modal
    setModalVisibility(false);
  }, [
    setCurrentStep,
    setSelectedSkill,
    setCurrentQuestion,
    setSelectedQuestions,
    setModalVisibility,
    setNewQuestionPosition,
    setPreSelectedSkill,
  ]);

  useEffect(() => {
    if (skills.length !== 0 && additionalCriteriaSkills.length !== 0) {
      return;
    }
    // If no skill was passed in the props, fetch list of skills for selection
    setLoading(true);
    const handleFetchError = (error): void => {
      setLoading(false);
      setModalVisibility(false);
      addToast({
        type: 'error',
        msg:
          'Unable to fetch skills for this interview, please refresh the page.',
      });
      handleClose();
      handleError(error);
    };
    getAllSubjects(3, 1, 0)
      .then((res) => {
        const newSkills = res.data.data.filter((skill) => !skillMap[skill.id]);
        setSkillLength(newSkills.length);
        dispatch(setSkills(newSkills));
        setLoading(false);
      })
      .catch((error) => {
        handleFetchError(error);
      });
    getAllSubjects(3, 1, 1)
      .then((res) => {
        const newSkills = res.data.data.filter(
          (skill) => !additionalCriteriaSkillMap[skill.id]
        );
        setAdditionalCriteriaSkillLength(newSkills.length);
        dispatch(setAdditionalCriteriaSkills(newSkills));
      })
      .catch((error) => {
        handleFetchError(error);
      });
  }, [
    setModalVisibility,
    setLoading,
    addToast,
    handleClose,
    skillMap,
    setSkillLength,
    questionContext,
    additionalCriteriaSkillMap,
    setAdditionalCriteriaSkillLength,
    skills.length,
    additionalCriteriaSkills.length,
    dispatch,
  ]);

  const skillsOrSections = useMemo(() => {
    return questionContext === 'additional-criteria'
      ? additionalCriteriaSkills
      : skills;
  }, [questionContext, additionalCriteriaSkills, skills]);

  const getQuestions = useCallback(
    (skill): void => {
      if (!addedQuestionId && Object.keys(selectedQuestions).length !== 0) {
        return;
      }
      setLoading(true);
      const responseType =
        questionContext === 'interview-content' &&
        skill.questions_type !== QuestionType.Informational
          ? 'text'
          : null;
      const mainSkillId =
        skill.questions_type === QuestionType.Informational
          ? interview.informational_subject.id
          : skill.id;
      getQuestionsForSubject(mainSkillId, responseType, 1)
        .then((res) => {
          setQuestions(res.data.data);
          setLoading(false);
          if (addedQuestionId) {
            const question = res.data.data?.find(
              (question) => question.question_id === addedQuestionId
            );
            if (question) {
              handleSelectQuestion(question);
            }
            setAddedQuestionId(null);
          }
        })
        .catch((error) => {
          setLoading(false);
          addToast({
            type: 'error',
            msg:
              'Unable to fetch questions for this skill, please refresh the page.',
          });
          handleClose();
          handleError(error);
        });
    },
    [
      addedQuestionId,
      questionContext,
      interview?.informational_subject.id,
      addToast,
      handleClose,
      setAddedQuestionId,
      selectedQuestions,
    ]
  );

  useEffect(() => {
    if (addedQuestionId) {
      getQuestions(preSelectedSkill ?? skillsOrSections[selectedSkill]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedQuestionId]);

  useEffect(() => {
    // If a skill was passed in the props, skip first step and go to select questions
    if (preSelectedSkill && currentStep === 0) {
      // set selected skill index based on pre-selected skill
      for (let i = 0; i < skillsOrSections.length; i += 1) {
        if (skillsOrSections[i].id === preSelectedSkill.id) {
          setSelectedSkill(i);
          break;
        }
      }
      setCurrentStep(1);
      getQuestions(preSelectedSkill);
    }
  }, [
    preSelectedSkill,
    setLoading,
    addToast,
    handleClose,
    skills,
    questionContext,
    skillsOrSections,
    getQuestions,
    setSelectedSkill,
    currentStep,
  ]);
  const handleButtonAction = (): void => {
    if (currentStep < 1) setCurrentStep(currentStep + 1);
    if (currentStep === 0) {
      getQuestions(skillsOrSections[selectedSkill]);
    } else if (currentStep === 1) {
      setLoading(true);
      const skill = skillsOrSections[selectedSkill] || preSelectedSkill;
      let insertionPosition = position;
      if (
        skill.id === interview.intro_subject.id &&
        skillQuestions[skill.id]?.length === 0
      ) {
        insertionPosition = 0;
      }
      let newLastQuestionPosition = insertionPosition;
      const questionsToSubmit = Object.keys(selectedQuestions).map((i) => {
        newLastQuestionPosition += 1;
        return selectedQuestions[i];
      });
      addQuestionsToPart(
        assessment.id,
        interview.id,
        insertionPosition + 1,
        questionsToSubmit.map((q) => {
          return { ...q, primary_skill_id: skill.id };
        })
      )
        .then(() => {
          addToast({
            type: 'success',
            msg: 'Questions added successfully',
          });
          setNewTopicPosition(newLastQuestionPosition);
          setLoading(false);
          handleClose();
          forceRefetch();
        })
        .catch((error) => {
          addToast({
            type: 'error',
            msg: 'Unable to add questions to interview, please try again.',
          });
          handleClose();
          handleError(error);
        });
    }
  };

  const currentQuestionSelected = useMemo(() => {
    if (currentQuestion === null || !questions[currentQuestion]) {
      return false;
    }
    const currentQuestionId = questions[currentQuestion].question_id;
    if (selectedQuestions[currentQuestionId]) {
      return true;
    }
    return false;
  }, [currentQuestion, selectedQuestions, questions]);

  const customProps = {
    loading,
    setModalVisibility,
    containerClass: 'participants-modal add-interview-topic-modal',
    actionText: steps[currentStep].buttonText,
    infoIcon: steps[currentStep].infoIcon,
    infoText: steps[currentStep].infoText,
    handleButtonAction,
    disabled: isButtonDisabled,
    showCancel: true,
    cancelButtonText: 'Cancel',
    cancelVariant: 'sub-primary',
    handleClose: () => handleClose(),
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      {currentStep === 0 ? (
        <>
          <div className="questions-modal-header">
            {questionContext === 'additional-criteria' ? (
              <>
                <h2>Add some additional criteria to score the candidates on</h2>
                <small>
                  Select which type of additional criteria to include in this
                  interview. You will then select the speicific criteria to
                  score them on.
                </small>
              </>
            ) : (
              <>
                <h2>
                  Add an experience or skill section to include in this
                  interview
                </h2>
                <small>
                  Select an area to include questions in this interview. You
                  will then select the related questions to include
                </small>
              </>
            )}
          </div>

          <div className="skill-list-section">
            <div className="skills">
              {skillsOrSections.map((skill, index) => {
                return (
                  <div
                    className={`skills-item ${
                      index === selectedSkill ? 'selected' : ''
                    }`}
                    onClick={() => handleSelectSkill(index)}
                    role="button"
                    tabIndex={-1}
                    key={skill.name}
                  >
                    {skill.name}
                  </div>
                );
              })}
            </div>
            <div className="details">
              {selectedSkill !== null ? (
                <div className="skill-details">
                  <div className="skill-details-title">
                    {skillsOrSections[selectedSkill]?.name}
                  </div>
                  <div className="skill-details-description">
                    {!skillsOrSections[selectedSkill]?.description && (
                      <p className="grey-text">No description available</p>
                    )}
                    {skillsOrSections[selectedSkill]?.description}
                  </div>
                </div>
              ) : (
                <div className="select-skill-message">
                  <img src={InterviewSkillIcon} alt="Interview skill" />
                  {questionContext === 'additional-criteria' ? (
                    <p>Select additional criteria to add to the interview.</p>
                  ) : (
                    <p>Select a skill to add to the interview.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="questions-modal-header">
            <h2>Add Questions</h2>
            <small>
              Select one or more {skillsOrSections[selectedSkill]?.name}{' '}
              questions to include in this interview.
            </small>
          </div>
          <div className="question-list-section">
            <div className="questions">
              {questions.map((question, index) => {
                return (
                  <div
                    className={`questions-item ${
                      index === currentQuestion ? 'selected' : ''
                    }`}
                    onClick={() => handleClickQuestion(index)}
                    role="button"
                    tabIndex={-1}
                    key={question.question_id}
                  >
                    <span className="item-text">
                      {question.title.length > 36
                        ? `${question.title.slice(0, 36)}...`
                        : question.title}
                    </span>
                    {!questionMap[question.question_id] && (
                      <Checkbox
                        isChecked={
                          selectedQuestions[question.question_id] ?? false
                        }
                        handleChange={() => {
                          handleSelectQuestion(question);
                        }}
                      />
                    )}
                  </div>
                );
              })}
              {!loading && (
                <div style={{ marginLeft: '10px' }}>
                  <Button
                    text="Add Custom Question"
                    variant="sub-primary md"
                    onClick={handleAddNewQuestion}
                  />
                </div>
              )}
            </div>
            <div className="details">
              {currentQuestion !== null ? (
                <div className="question-details">
                  <div className="question-details-top">
                    <div />
                    {questionMap[questions[currentQuestion]?.question_id] ? (
                      'Question already added to this interview'
                    ) : (
                      <TextButton
                        text={`${
                          currentQuestionSelected ? 'Uns' : 'S'
                        }elect Question`}
                        variant={currentQuestionSelected ? 'delete' : 'add'}
                        icon={currentQuestionSelected ? DeleteIcon : AddIcon}
                        onClick={() =>
                          handleSelectQuestion(questions[currentQuestion])
                        }
                      />
                    )}
                  </div>
                  <div className="question-details-info">
                    <InterviewQuestionDetails
                      interviewId={interview.id}
                      question={questions[currentQuestion]}
                    />
                  </div>
                </div>
              ) : (
                <div className="select-skill-message">
                  {/* Icon */}
                  {loading
                    ? 'Loading questions...'
                    : 'Select a question to add to the interview.'}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
export default AddSectionModal;
