import React from 'react';
import Skeleton from 'react-loading-skeleton';
import InviteYourselfButton from './Buttons/InviteYourselfButton';
import InviteCandidateButton from './Buttons/InviteCandidateButton';
import InviteLinkButton from './Buttons/InviteLinkButton';
import ImportCandidatesButton from './Buttons/ImportCandidatesButton';
import authorize from '../../Authorization/Check';
import { AssessmentTabProps } from '../Assessment/assessment-tab-props';
import './index.scss';

const AddParticipants = ({
  assessment,
  loading,
  userDetails,
  setAssessment,
}: AssessmentTabProps & { userDetails?: any }): JSX.Element => {
  if (loading || !assessment) {
    return (
      <div className="add-participants">
        <div className="add-participants-bar">
          <Skeleton height={20} width={200} />
        </div>
      </div>
    );
  }
  if (assessment.is_archived) {
    return null;
  }
  return (
    <div className="add-participants">
      <div className="add-participants-bar">
        {!assessment.has_invited_yourself && (
          <InviteYourselfButton {...{ assessment, setAssessment }} />
        )}
        {authorize('assessment-candidate', 'add', assessment.role.user_id) && (
          <InviteCandidateButton {...{ assessment, userDetails }} />
        )}
        {authorize('assessment-candidate', 'add', assessment.role.user_id) && (
          <ImportCandidatesButton
            {...{
              assessment,
              divider: assessment.public_url_active,
            }}
          />
        )}
        {assessment.public_url_active &&
          authorize('assessment-candidate', 'add', assessment.role.user_id) && (
            <InviteLinkButton {...{ assessment }} />
          )}
      </div>
    </div>
  );
};
export default AddParticipants;
