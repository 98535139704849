import React, { ReactElement, useEffect } from 'react';
import ActionMenu, {
  MenuPositions,
} from 'components/Shared/Dropdown/ActionMenu';
import CardContainer from 'components/Shared/MainContainer/CardContainer';
import { Button } from 'components/Shared';
import { goToLegacyApp } from 'hooks';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getOrgDetails } from 'store/actions/organization.actions';
import useTokenData from 'hooks/useTokenData';
import TextSkeleton from 'components/Shared/TextSkeleton';
import DurationIcon from '../../images/icons/duration.svg';

interface Props {
  template: any;
  type: string;
  creatingAssessment: boolean;
  isPaid: boolean;
  createAction(e, template): void;
  viewDetailsAction(): void;
  showDeleteModal(e, template): void;
}

const TemplateCard: React.FC<Props> = ({
  template,
  type,
  creatingAssessment,
  createAction,
  isPaid,
  viewDetailsAction,
  showDeleteModal,
}) => {
  const parts = [...template.parts];
  parts.sort((a, b) => {
    return a.index - b.index;
  });
  const dispatch = useDispatch();
  const organizationName = useSelector(
    (state: RootState) => state?.organization?.organizationDetails?.name
  );
  const tokenData = useTokenData();
  useEffect(() => {
    if (!organizationName && tokenData?.org) {
      dispatch(getOrgDetails(tokenData.org));
    }
  }, [organizationName, tokenData, dispatch]);

  let actionMenuItems: JSX.Element[] = [];
  // only add edit option if the belongs to the current organization and has an assessment associated with it
  if (template.assessment_id && template.is_organization_template) {
    actionMenuItems = [
      ...actionMenuItems,
      <p
        onClick={(e) =>
          goToLegacyApp(
            e,
            `edit-assessment-customized-content/${template.assessment_id}`
          )
        }
        onKeyPress={(e) =>
          goToLegacyApp(
            e,
            `edit-assessment-customized-content/${template.assessment_id}`
          )
        }
        role="presentation"
        tabIndex={-1}
      >
        <FontAwesomeIcon className="icon fa-w-16" icon={faPencilAlt} />
        <span>Edit Configuration</span>
      </p>,
      <p
        onClick={(e) =>
          goToLegacyApp(
            e,
            `edit-assessment-customized-questions/${template.assessment_id}`
          )
        }
        onKeyPress={(e) =>
          goToLegacyApp(
            e,
            `edit-assessment-customized-questions/${template.assessment_id}`
          )
        }
        role="presentation"
        tabIndex={-1}
      >
        <FontAwesomeIcon className="icon fa-w-16" icon={faPencilAlt} />
        <span>Edit Questions</span>
      </p>,
      <p
        onClick={(e) => showDeleteModal(e, template)}
        onKeyPress={(e) => showDeleteModal(e, template)}
        role="presentation"
        tabIndex={-1}
      >
        <FontAwesomeIcon className="icon fa-w-16" icon={faTrashAlt} />
        <span>Delete</span>
      </p>,
    ];
  }

  const formatDuration = (duration): ReactElement => {
    const hours = Math.trunc(duration / 60 / 60);
    const minutes = (duration - hours * 60 * 60) / 60;
    return (
      <>
        {hours > 0 && (
          <>
            <div className="value">{hours}&nbsp;</div>
            <div className="label">{hours > 1 ? 'hours' : 'hour'}</div>
          </>
        )}
        {minutes > 0 && (
          <>
            <div className="value">{minutes}&nbsp;</div>
            <div className="label">{minutes > 1 ? 'minutes' : 'minute'}</div>
          </>
        )}
      </>
    );
  };
  const skillPills = (part): ReactElement => {
    return (
      <div className="skills">
        {part.subjects.map((subject) => {
          return (
            <div key={`skill-${part.id}-${subject.id}`} className="skill">
              {subject.name}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <CardContainer className="template">
      <div className="main-card-content">
        <div className="template-header">
          <h3 className="card-title">{template.name}</h3>
          {actionMenuItems.length > 0 && (
            <ActionMenu
              id="action-menu"
              customStyles={{ width: '210px' }}
              items={actionMenuItems}
              position={MenuPositions.BottomLeft}
            />
          )}
        </div>
        <div className="template-sub-header">
          <TextSkeleton loading={organizationName === ''} width="80px">
            {organizationName ? (
              <i>
                {template.is_organization_template
                  ? `${organizationName} Template`
                  : 'Alooba Template'}
              </i>
            ) : undefined}
          </TextSkeleton>
        </div>
        {parts.map((part, i) => {
          return (
            <div key={`template-part-${part.id}`} className="parts">
              {part.type === 'MCQ' && (
                <div className="part">
                  {i === 0 &&
                    template.parts.length > 1 &&
                    `This ${type.toLowerCase()} starts with a `}
                  {i === 0 &&
                    template.parts.length === 1 &&
                    `This ${type.toLowerCase()} is a `}
                  {i > 0 && `The ${type.toLowerCase()} also includes a `}
                  <strong>Concepts &amp; Knowledge</strong> test covering:
                  {skillPills(part)}
                </div>
              )}
              {part.type === 'Data Analysis' && (
                <div className="part">
                  {i === 0 &&
                    template.parts.length > 1 &&
                    `This ${type.toLowerCase()} starts with a `}
                  {i === 0 &&
                    template.parts.length === 1 &&
                    `This ${type.toLowerCase()} is a `}
                  {i > 0 && `The ${type.toLowerCase()} also includes a `}
                  <strong>Data Analysis</strong> test requiring candidates to
                  analyse some data to answer some simple questions to solve
                  business problems.
                </div>
              )}
              {part.type === 'SQL' && (
                <div className="part">
                  {i === 0 &&
                    template.parts.length > 1 &&
                    `This ${type.toLowerCase()} starts with an `}
                  {i === 0 &&
                    template.parts.length === 1 &&
                    `This ${type.toLowerCase()} is an `}
                  {i > 0 && `Candidates will also be required to do a `}
                  <strong>SQL</strong> test requiring them to write SQL queries
                  to query some data.
                </div>
              )}
              {(part.type === 'Python Coding' ||
                part.type === 'Analytics Coding') && (
                <div className="part">
                  {i === 0 &&
                    template.parts.length > 1 &&
                    `This ${type.toLowerCase()} starts with an `}
                  {i === 0 &&
                    template.parts.length === 1 &&
                    `This ${type.toLowerCase()} is an `}
                  {i > 0 && `Candidates will also be required to do an `}
                  <strong>Analytics Coding</strong> test where they will be
                  asked to write functions in Python to solve various
                  challenges.
                </div>
              )}
              {part.type === 'Free Response' && (
                <div className="part">
                  {i === 0 &&
                    template.parts.length > 1 &&
                    `This ${type.toLowerCase()} starts with a `}
                  {i === 0 &&
                    template.parts.length === 1 &&
                    `This ${type.toLowerCase()} is a `}
                  {i > 0 && `Candidates will also be required to do a `}
                  <strong>Free Response</strong> test where they will dive
                  deaper into providing more complex responses covering:
                  {skillPills(part)}
                </div>
              )}
              {part.type === 'Personality Profiling' && (
                <div className="part">
                  {i === 0 &&
                    template.parts.length > 1 &&
                    `This ${type.toLowerCase()} starts with a `}
                  {i === 0 &&
                    template.parts.length === 1 &&
                    `This ${type.toLowerCase()} is a `}
                  {i > 0 && `Candidates will also be required to do a `}
                  <strong>Personality</strong> test to help gain insight into
                  your candidates personalities.
                </div>
              )}
            </div>
          );
        })}
        <div className="duration">
          <img src={DurationIcon} alt="assessment duration" />
          {formatDuration(template.total_duration)}
        </div>
      </div>
      <Button
        onClick={viewDetailsAction}
        variant="sub-primary md"
        text="Details"
      />
      <Button
        onClick={(e) => {
          createAction(e, template);
        }}
        variant="primary md"
        text={isPaid || template.is_sample ? 'Create' : 'Upgrade Your Account'}
        loading={creatingAssessment}
        loadingTxt="Creating..."
        disabled={creatingAssessment}
      />
    </CardContainer>
  );
};

export default TemplateCard;
