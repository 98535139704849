import React from 'react';

import DefinitelyImage from '../../../../images/icons/definitely.svg';
import YesImage from '../../../../images/icons/thumbsup.svg';
import MaybeImage from '../../../../images/icons/maybe.svg';
import NoImage from '../../../../images/icons/thumbsdown.svg';
import './index.scss';

export default function RatingQuestion({
  question,
  onChange,
  name,
}: {
  question: any;
  onChange(any): any;
  name?: string;
}): any {
  name = name || 'rating';
  return (
    <div className="rating-question">
      {question.answers.map((answer) => {
        return (
          <label key={answer.id} className={answer.selected ? 'checked' : ''}>
            <input
              type="radio"
              name={name}
              value={answer.id}
              checked={answer.selected}
              onChange={(e) => onChange(e.target.value)}
            />
            {answer.answer === 'Definitely' && (
              <img src={DefinitelyImage} alt={answer.answer} />
            )}
            {answer.answer === 'Yes' && (
              <img src={YesImage} alt={answer.answer} />
            )}
            {answer.answer === 'Maybe' && (
              <img src={MaybeImage} alt={answer.answer} />
            )}
            {answer.answer === 'No' && (
              <img src={NoImage} alt={answer.answer} />
            )}
            <h5>{answer.answer}</h5>
          </label>
        );
      })}
    </div>
  );
}
