import React from 'react';
import TestTabLayout from './TestTabLayout';
import './TestLayout.scss';

export default function PersonalityTest({
  testId,
  testType,
  recruiterTestId,
  candidateTestId,
  toggleShowTestRetakeModal,
  toggleShowAddTimeModal,
  hideSensitiveInfo = false,
}: {
  testId: number;
  testType: string;
  recruiterTestId: number;
  candidateTestId: number;
  toggleShowTestRetakeModal: any;
  toggleShowAddTimeModal: any;
  hideSensitiveInfo: boolean;
}): any {
  return (
    <TestTabLayout
      testId={testId}
      testType={testType}
      recruiterTestId={recruiterTestId}
      candidateTestId={candidateTestId}
      toggleShowTestRetakeModal={toggleShowTestRetakeModal}
      toggleShowAddTimeModal={toggleShowAddTimeModal}
      hideSensitiveInfo={hideSensitiveInfo}
    />
  );
}
