import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Shared/Button';
import './SubscriptionPricingTable.scss';
import { RootState } from 'store/rootReducer';
import SubscribeModal from './SubscribeModal';

export interface PlanPriceProps {
  stripe_price_id: string;
  period_months: number;
  subscription_credits: number;
  period: string;
  price: number;
  monthly_price: number;
  currency: string;
  currency_symbol: string;
}

export interface PlanPriceOptions {
  [key: string]: PlanPriceProps[];
}

export interface PlanProps {
  id: number;
  name: string;
  description: string;
  stripe_product_id: string;
  monthly_credits: number;
  fromPrice: number;
  prices: PlanPriceOptions;
}

interface SubscriptionPlanOptionProps {
  plan: PlanProps;
  currency: string;
  setCurrency: any;
}

const SubscriptionPlanOption: React.FC<SubscriptionPlanOptionProps> = ({
  plan,
  currency,
  setCurrency,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleSelectPlan = (): void => {
    setModalVisible(true);
  };
  const { stripePaymentDetailsShown } = useSelector(
    (state: RootState) => state.app
  );

  useEffect(() => {
    if (!modalVisible && stripePaymentDetailsShown) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  return (
    <div key={plan.id} className="plan">
      <h3 className="plan-name">{plan.name}</h3>
      <p className="plan-description">{plan.description}</p>
      {plan.fromPrice > 0 && (
        <div className="plan-price">
          <span>From</span>
          <span className="plan-price-value">
            {plan.prices[currency][0].currency_symbol}
            {plan.fromPrice}
          </span>
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            {Object.keys(plan.prices).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>
          <span>per month</span>
        </div>
      )}
      {!!plan.monthly_credits && (
        <p className="plan-credits">
          <a
            href={`${process.env.REACT_APP_LANDING_PAGE_URL}/credits/`}
            target="__blank"
          >
            Included Monthly Credits
          </a>
          : <strong>{plan.monthly_credits.toLocaleString()}</strong>
        </p>
      )}
      <Button
        variant="primary md"
        text="Select Plan"
        onClick={handleSelectPlan}
      />
      <div>
        <SubscribeModal
          setModalVisibility={setModalVisible}
          isShown={modalVisible}
          plan={plan}
          currency={currency}
        />
      </div>
    </div>
  );
};

export default SubscriptionPlanOption;
