import './custom-select.scss';
import Select from '@material-ui/core/Select';
import { styled } from '@material-ui/core/styles';
import { dark, mainBackground, blue } from '../../../styles/colors';

const CustomSelect = styled(Select)({
  minWidth: '70px',
  flex: 'auto',
  background: '#FFFFFF',
  border: `1px solid ${mainBackground}`,
  boxSizing: 'border-box',
  boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
  maxWidth: '250px',
  height: '40px',
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  letterSpacing: '0.02em',
  color: dark,
  '&::before': {
    display: 'none',
  },
  '&::after': {
    borderBottom: 'none',
  },
  '& .MuiSelect-select': {
    padding: '3px 5px',
    paddingRight: '24px',
    '&:focus': {
      background: '#FFFFFF',
      borderRadius: '5px',
    },
  },
  '& .MuiSelect-icon': {
    color: blue,
  },
});
export default CustomSelect;
