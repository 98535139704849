import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { RootState } from '../../store/rootReducer';
import Input from '../Shared/Input';
import {
  getCurrentTokens,
  loginWithMFA,
} from '../../store/actions/auth.actions';
import Wrapper from './Wrapper';

interface MFAProps {
  mfaId: string;
  redirectUrl: string;
  message: string;
  email: string;
}
export const redirectAfterLogin = (
  redirectUrl: string,
  data: { dashboard_url: string | null }
): string => {
  if (redirectUrl) {
    if (
      redirectUrl.startsWith('/add-candidate') ||
      redirectUrl.startsWith('/alooba-for-work-product-overview') ||
      redirectUrl.startsWith('/alooba-for-work-welcome-email-confirmation') ||
      redirectUrl.startsWith('/alooba-for-work-welcome-goals') ||
      redirectUrl.startsWith('/alooba-for-work-welcome-personal-profile') ||
      redirectUrl.startsWith('/create-assessment-customized') ||
      redirectUrl.startsWith('/view-assessment-customized') ||
      redirectUrl.startsWith('/edit-assessment-customized') ||
      redirectUrl.startsWith('/question-bank') ||
      redirectUrl.startsWith('/add-question') ||
      redirectUrl.startsWith('/edit-question')
    ) {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}${redirectUrl}`;
      }, 10);
    } else {
      return `${redirectUrl}`;
    }
  } else {
    // We still need this to be a redirect to the old app, since there are other
    // dashboards there. The old app will redirect back to the new app if necessary
    localStorage.setItem('@alooba/dashboard', data.dashboard_url);
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/${data.dashboard_url}`;
    }, 10);
  }
  return '';
};
const MFA: React.FC<MFAProps> = ({ mfaId, redirectUrl, message, email }) => {
  const history = useHistory();
  const [socialError, setSocialError] = useState<any>('');

  const { loading, error } = useSelector((state: RootState) => state.auth);

  /*
    The user can be logged out in the old app and still have a valid token in the new one.
    We need to allow the user to log in again to get a new session on the old app avoiding endless loop.
    If the user comes without a redirectUrl, then the user should be redirect to the dashboard.
    if accessToken is there and redirectUrl too, redirect the user to /
  */

  const { accessToken } = getCurrentTokens();

  if (accessToken && !loading) {
    if (!redirectUrl) {
      history.push('/');
    }
  }

  const formik = useFormik({
    initialValues: {
      code: '',
      mfaId,
      email,
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Code field is required'),
      mfaId: Yup.string().required('MFA ID field is required'),
    }),
    onSubmit: async (values) => {
      setSocialError('');
      const data = await loginWithMFA(values);

      if (data) {
        const url = redirectAfterLogin(redirectUrl, data);
        if (url) {
          history.push(url);
        }
      }
    },
  });
  const baseTextMsg = (): JSX.Element => {
    return (
      <div className="mt2" style={{ position: 'absolute' }}>
        <p>
          Messages should be received within seconds, however sometimes it may
          take longer depending on your network. Please give it a few minutes
          for the message to arrive. If you are still unable to receive the
          code, you can request an account admin to update your phone number. If
          you are still unable to access your account please{' '}
          <a href={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/contact-us`}>
            contact us.
          </a>
        </p>
      </div>
    );
  };

  return (
    <LoadingOverlay active={loading} spinner={<BounceLoader color="gray" />}>
      <Wrapper
        heading="Enter Code"
        text={message}
        action=""
        actionLink=""
        buttonText="Continue"
        baseText="Didn't get the code?"
        baseTextLink="#"
        baseTextMsg={baseTextMsg}
        onFormSubmit={formik.handleSubmit}
      >
        <div id="login_page">
          {socialError && !loading && (
            <span className="auth-error">{socialError}</span>
          )}
          <Input
            name="code"
            type="text"
            value={formik.values.code}
            placeholder=""
            label="Code"
            onChange={formik.handleChange}
            errorTxt={formik.touched.code ? formik.errors.code : ''}
          />
          {error && !loading ? <span className="auth-error">{error}</span> : ''}
        </div>
      </Wrapper>
    </LoadingOverlay>
  );
};

export default MFA;
