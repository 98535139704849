import React from 'react';
import { useHistory } from 'react-router-dom';

import authorize from 'Authorization/Check';
import { Modal } from '../Modal';

interface Props {
  isShown?: boolean;
  setModalVisibility?: any;
  title?: string;
  children?: any;
  hasActionButton?: boolean;
  actionText?: string;
}
const customProps = {
  actionText: 'Upgrade Subscription',
  showCancel: true,
  cancelButtonText: 'Close',
  containerClass: 'small-modal',
};
const PlanUpgradeModal = ({
  hasActionButton = true,
  ...props
}: Props): JSX.Element => {
  const history = useHistory();
  const { title, children } = props;

  if (!authorize('company-billing', 'view') || !hasActionButton) {
    customProps.actionText = null;
  }

  const clickUpgrade = (): void => {
    history.push('/billing');
  };

  return (
    <Modal handleButtonAction={clickUpgrade} {...customProps} {...props}>
      <div>
        <h2>{title}</h2>
        <br />
        {children}
        <hr />
      </div>
    </Modal>
  );
};

export default PlanUpgradeModal;
