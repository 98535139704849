import { RootState } from 'store/rootReducer';
import { fullDateTime, getMoment, getRelativeDate } from 'helpers/datetime';
import TextWithTooltip from 'components/Shared/Tooltip/TextWithTooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ClockIcon from '../../../../images/icons/clock.svg';
import InfoIcon from '../../../../images/icons/info.svg';
import UploadedFileCard from './UploadedFileCard';
import './UploadedFiles.scss';

export interface Props {
  candidate: any;
  files?: any;
  testEnd?: string;
}

export default function UploadedFiles({
  candidate,
  files,
  testEnd,
}: Props): any {
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const uploadedAfter = useMemo(() => {
    if (files && files.length && testEnd) {
      const date1 = getMoment(files[files.length - 1].created_at);
      const date2 = getMoment(testEnd);
      return getRelativeDate(date1, date2);
    }
    return '0 seconds';
  }, [files, testEnd]);

  const [isCloaked, setIsCloaked] = useState(false);

  const organizationCloakedCandidates = useSelector(
    (state: RootState) =>
      state.organization.organizationDetails?.cloak_candidates
  );
  const recruiterCloakedCandidates = useSelector(
    (state: RootState) =>
      state.profile.userDetails.recruiter_detail?.cloak_candidates
  );
  const assessmentCloakedCandidates = useSelector(
    (state: RootState) => state.assessment.currentAssessment?.cloak_candidates
  );

  useEffect(() => {
    setIsCloaked(
      organizationCloakedCandidates ||
        recruiterCloakedCandidates ||
        assessmentCloakedCandidates
    );
  }, [
    organizationCloakedCandidates,
    recruiterCloakedCandidates,
    assessmentCloakedCandidates,
  ]);

  return (
    <>
      {files?.length === 0 && (
        <div className="uploaded-files-empty">
          <img src={InfoIcon} alt="Information icon" />
          {isCloaked ? candidate.reference : candidate.full_name} hasn&apos;t
          uploaded any working files for their data analysis.
        </div>
      )}
      {files?.length > 0 && (
        <div className="uploaded-files">
          <div className="question-response-section">
            <div>
              <h4>UPLOADED FILES</h4>
            </div>
            <div className="info-bar">
              <img src={ClockIcon} alt="Clock icon" />
              <p>
                Uploaded{' '}
                <TextWithTooltip
                  text={uploadedAfter}
                  tooltip={fullDateTime(
                    files[files.length - 1].created_at,
                    userDetails.user_timezone
                  )}
                />{' '}
                after the test.
              </p>
              {/* <a href="/">Download All Files</a> */}
            </div>
            <div className="file-list">
              {files.map((file) => (
                <UploadedFileCard file={file} candidateId={candidate.id} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
