import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ApiTokenIntegrationPartner } from 'components/Integration/ApiTokenIntegrationPartner';
import FormContainer from '../Shared/MainContainer/FormContainer';
import {
  OAuthProps,
  OAuthIntegrationPartner,
} from '../Integration/OAuthIntegrationPartner';
import {
  ApiKey,
  ApiKeyIntegrationPartner,
} from '../Integration/ApiKeyIntegrationPartner';
import RemoteIntegrationPartner from '../Integration/RemoteIntegrationPartner';
import DisabledIntegrationPartner from '../Integration/DisabledIntegrationPartner';
import LeverLogo from '../../assets/partner/lever-logo.svg';
import GreenhouseLogo from '../../assets/partner/greenhouse-logo.svg';
import SmartRecruitersLogo from '../../assets/partner/smartrecruiters-logo.svg';
import { getIntegrations } from '../../api/integration.api';
import { hasOrgFeature } from '../../Authorization/Check';

const ATS = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [greenhouseApiKey, setGreenhouseApiKey] = useState<ApiKey>(null);
  const [
    greenhouseHarvestApiKey,
    setGreenhouseHarvestApiKey,
  ] = useState<ApiKey>(null);
  const [leverConnected, setLeverConnected] = useState<string>(null);
  const [smartRecruitersApiKey, setSmartRecruitersApiKey] = useState<ApiKey>(
    null
  );
  const { addToast } = useToasts();

  const leverAuth: OAuthProps = {
    authUri: process.env.REACT_APP_LEVER_AUTH_URL,
    clientId: process.env.REACT_APP_LEVER_CLIENT_ID,
    redirectUri: process.env.REACT_APP_LEVER_REDIRECT_URI,
    audience: process.env.REACT_APP_LEVER_API_BASE_URL,
    scopes: (process.env.REACT_APP_LEVER_SCOPES ?? '').split(','),
    responseType: 'code',
    prompt: 'consent',
  };

  useEffect(() => {
    getIntegrations()
      .then((response) => {
        if (
          response.data.integrations.greenhouse &&
          response.data.integrations.greenhouse.key
        ) {
          setGreenhouseApiKey({
            ...response.data.integrations.greenhouse.key,
            masked: true,
          });
        }
        if (
          response.data.integrations['greenhouse-harvest'] &&
          response.data.integrations['greenhouse-harvest'].key
        ) {
          setGreenhouseHarvestApiKey({
            ...response.data.integrations['greenhouse-harvest'].key,
            masked: true,
          });
        }
        if (
          response.data.integrations.lever &&
          response.data.integrations.lever.token
        ) {
          setLeverConnected('connected');
        }
        if (response.data.integrations.smartrecruiters?.key) {
          setSmartRecruitersApiKey({
            ...response.data.integrations.smartrecruiters.key,
            masked: true,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        addToast({
          type: 'error',
          msg: `Unable to fetch the integrations`,
        });
        throw err;
      });
  }, [addToast]);

  const smartRecruitersHelp = (
    <p>
      Alooba has partnered with SmartRecruiters to provide seamless integration
      between our platforms. Once connected to SmartRecruiters, candidates can
      be invited to Alooba assessments directly from SmartRecruiters. This
      integration must be enabled from within your SmartRecruiters account.
      Follow the &nbsp;
      <a
        href="https://support.alooba.com/article/28-smartrecruiters-integration"
        target="_blank"
        rel="noopener noreferrer"
      >
        Enable SmartRecruiters ATS Integration
      </a>
      &nbsp; process to connect your account.
    </p>
  );

  return (
    <>
      <h2 className="mb4 mt8" id="ats-integrations">
        ATS Integration
      </h2>
      <FormContainer>
        <div className="container">
          <h3 className="mb2">
            Is your organization currently using a supported Applicant Tracking
            System (ATS)?
          </h3>
          <p className="mb3 small-text">
            Enable your ATS integration to trigger assessment invites to
            candidates added through your ATS.
          </p>
          {leverAuth.clientId &&
            (hasOrgFeature('ats') ? (
              <OAuthIntegrationPartner
                name="Lever"
                logo={LeverLogo}
                loading={loading}
                auth={leverAuth}
                connected={leverConnected}
                setConnected={setLeverConnected}
              />
            ) : (
              <DisabledIntegrationPartner
                name="Enable"
                logo={LeverLogo}
                loading={loading}
              />
            ))}
          {hasOrgFeature('ats') ? (
            <ApiKeyIntegrationPartner
              name="Greenhouse"
              logo={GreenhouseLogo}
              loading={loading}
              apiKey={greenhouseApiKey}
              setApiKey={setGreenhouseApiKey}
            >
              {greenhouseApiKey && (
                <ApiTokenIntegrationPartner
                  name="Harvest API"
                  loading={loading}
                  apiKey={greenhouseHarvestApiKey}
                  setApiKey={setGreenhouseHarvestApiKey}
                  integration="greenhouse-harvest"
                />
              )}
            </ApiKeyIntegrationPartner>
          ) : (
            <DisabledIntegrationPartner
              name="Generate API Key"
              logo={GreenhouseLogo}
              loading={loading}
            />
          )}
          {hasOrgFeature('ats') ? (
            <RemoteIntegrationPartner
              name="SmartRecruiters"
              logo={SmartRecruitersLogo}
              loading={loading}
              enabled={smartRecruitersApiKey !== null}
              help={smartRecruitersHelp}
            />
          ) : (
            <DisabledIntegrationPartner
              name="Connect"
              logo={SmartRecruitersLogo}
              loading={loading}
            />
          )}
          <hr />
          <h3 className="mb2">Is your ATS not listed above?</h3>
          <p>
            Please&nbsp;
            <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}/contact-us`}>
              Contact Us
            </a>
            &nbsp;to discuss integration with your ATS.
          </p>
        </div>
      </FormContainer>
    </>
  );
};

export default ATS;
