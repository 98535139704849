import React, { useState } from 'react';
import AuthIcon from 'images/auth.svg';
import MainLogo from 'images/logo.svg';
import Button from 'components/Shared/Button';
import './auth.scss';

interface Props {
  children: JSX.Element;
  heading?: string;
  text?: string;
  action?: string;
  actionLink?: string;
  buttonText?: string;
  onFormSubmit?: any;
  baseText?: string | JSX.Element;
  baseTextLink?: string;
  baseTextMsg?: any;
  errorText?: string;
}

const Input = ({
  children,
  heading,
  text,
  actionLink,
  action,
  onFormSubmit,
  buttonText,
  baseText,
  baseTextLink,
  baseTextMsg,
  errorText = null,
}: Props): JSX.Element => {
  const [showBaseTextMsg, setShowBaseTextMsg] = useState<boolean>(false);
  const toggleBaseTextMsg = (e): boolean => {
    if (baseTextMsg) {
      e.preventDefault();
      setShowBaseTextMsg(!showBaseTextMsg);
      return false;
    }
    return true;
  };
  return (
    <div className="auth-wrapper">
      <div className="left">
        <div className="intro">
          <h3>Data & Analytics Skills Assessments</h3>
          <p className="mt5 mb12">
            Efficiently and fairly assess skills in over 20 core areas in data
            (including Analytics, Python, R, SQL, Statistics, Visualisations and
            more)
          </p>
          <img src={AuthIcon} alt="Auth Logo" />
        </div>
      </div>
      <div className="right">
        <div>
          <img src={MainLogo} alt="Main Logo" style={{ width: '142px' }} />
          {errorText && <div className="alert">{errorText}</div>}
          <h2 className="mt10 mb4">{heading}</h2>
          <p className="mb7">
            {text}
            <a
              className="mx2"
              href={`${process.env.REACT_APP_LANDING_PAGE_URL}/${actionLink}`}
            >
              {action}
            </a>
          </p>
          <form onSubmit={onFormSubmit} style={{ position: 'relative' }}>
            {children}
            <div className="action-bar mt8">
              {baseText ? (
                <a href={baseTextLink} onClick={toggleBaseTextMsg}>
                  {baseText}
                </a>
              ) : null}
              <Button type="submit" variant="primary md" text={buttonText} />
            </div>
            {showBaseTextMsg && baseTextMsg && baseTextMsg()}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Input;
