import React from 'react';

const dynamicTablePropsContextProps: any[] = [
  {},
  () => {
    /* set */
  },
];
export const DynamicTablePropsContext = React.createContext(
  dynamicTablePropsContextProps
);
