import api from './index';

export async function saveNewComment(
  comment: string,
  reference: string,
  testId?: number,
  testQuestionId?: number
): Promise<any> {
  let url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}`;
  if (testId) {
    url += `/tests/${testId}`;
    if (testQuestionId) {
      url += `/questions/${testQuestionId}`;
    }
  }
  url += `/comments?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    comment,
  });
}
export async function deleteComment(
  reference: string,
  commentId: number
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/comments/${commentId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.delete(url);
}
export async function updateComment(
  reference: string,
  commentId: number,
  comment: string
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/comments/${commentId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url, { comment });
}
