import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { startLoading } from '../../store/reducers/app';
import { logoutUser } from '../../store/actions/auth.actions';
import { revokeToken } from '../../api-auth/auth-api';

const Logout: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startLoading());
    revokeToken().then(() => {
      logoutUser();
      window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/do-logout`;
    });
  }, [dispatch]);

  return null;
};

export default Logout;
