import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getTestsPartList, increaseTestDuration } from 'api/assessment.api';
import Skeleton from 'react-loading-skeleton';
import { useErrorHandler } from 'react-error-boundary';
import NumberInput from 'components/Shared/Input/NumberInput';
import { arraySum } from 'helpers/numbers';
import { stopEvent } from 'helpers/events';
import { getMinutesLabel, getMinutesPerSeconds } from 'helpers/datetime';
import { NumberContext } from 'components/Shared/Input/context/NumberContext';
import ParticipantModal from './ParticipantModal';
import './index.scss';
import { RefreshContext as ParticipantsRefreshContext } from '../context/refresh';

interface TestDurationModalProps {
  row: any;
  setModalVisibility: any;
  isShown: boolean;
  shouldCloakPII: boolean;
}
const TestDurationModal = (props: TestDurationModalProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [mustConfirm, setMustConfirm] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [tests, setTests] = useState<any>([]);
  const [errorText, setErrorText] = useState<any>();
  const [totalAdditionalTime, setTotalAdditionalTime] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>();
  const [displayName, setDisplayName] = useState<string>();
  const { addToast } = useToasts();
  const [, setFirstLoad] = useContext(ParticipantsRefreshContext);
  const [inputNumber, setInputNumber] = React.useState<{
    id: any;
    value: any;
  }>();
  const [newTestTime, setNewTestTime] = useState<
    { id: number; new_time_length: number }[]
  >([]);
  const handleError = useErrorHandler();
  const { isShown, row, setModalVisibility } = props;

  const { shouldCloakPII } = props;
  useEffect(() => {
    if (shouldCloakPII) {
      setDisplayName(row.Reference);
    } else {
      setDisplayName(row.Name);
    }
  }, [shouldCloakPII, row]);

  const hasErrors = (): any => {
    let hasError = false;
    setErrorText(
      Object.values(newTestTime).map((rowTestTime: any) => {
        if (Number.isNaN(rowTestTime.new_time_length)) {
          hasError = true;
          return 'Must be number';
        }
        return null;
      })
    );
    return hasError;
  };
  const handleButtonAction = async (
    e: React.SyntheticEvent<EventTarget>
  ): Promise<any> => {
    stopEvent(e);
    if (totalAdditionalTime === 0) {
      return;
    }
    if (hasErrors()) {
      return;
    }
    if (confirming) {
      return;
    }
    setConfirming(true);
    if (!mustConfirm) {
      setConfirming(false);
      setMustConfirm(true);
      return;
    }
    const errorToast = (msg: string): void => {
      addToast({
        type: 'error',
        msg,
      });
    };

    try {
      const product_parts = Object.values(tests).map(
        (rowTestTime: any, idx: number) => {
          return {
            id: String(rowTestTime.id),
            new_time_length:
              rowTestTime.time_length + newTestTime[idx]?.new_time_length,
          };
        }
      );
      const response = await increaseTestDuration(
        {
          product_parts,
          additional_time_length: totalAdditionalTime / 60,
        },
        row.candidate_test_id
      );
      if (response.data.error) {
        errorToast(response.data.message);
      } else {
        addToast({
          type: 'success',
          msg: `Assessment duration has been increased for ${displayName}.`,
        });
      }
      setConfirming(false);
      setTests([]);
      setModalVisibility(false);
      setFirstLoad(true);
    } catch (se) {
      errorToast('Unable to increase time');
      handleError(se);
      setConfirming(false);
    }
    setMustConfirm(false);
  };
  const actionText = mustConfirm ? 'Confirm' : 'Add Time';
  const handleBack = (e): void => {
    stopEvent(e);
    setMustConfirm(false);
  };
  const extraButton = (
    <button
      className="md sub-primary"
      onClick={handleBack}
      onKeyPress={handleBack}
      tabIndex={-1}
      type="button"
    >
      Back
    </button>
  );
  const customProps = {
    loading: loading || confirming,
    actionText: loading ? null : actionText,
    showCancel: true,
    cancelVariant: 'sub-primary',
    handleButtonAction,
    containerClass: 'participants-modal increase-test-duration',
    extraButton: mustConfirm && extraButton,
    buttonVariant: totalAdditionalTime === 0 ? 'sub-primary action-bt' : '',
  };
  useEffect(() => {
    if (confirming) {
      return;
    }
    if (loading) {
      return;
    }
    if (!isShown) {
      return;
    }
    if (tests.length !== 0) {
      return;
    }
    setLoading(true);
    getTestsPartList(row.candidate_test_id)
      .then((res) => {
        setLoading(false);
        const currentTests = res.data.parts;
        const testsWithPrep = currentTests.findIndex((test) => test.with_prep);
        if (testsWithPrep !== -1) {
          const test = currentTests[testsWithPrep];
          currentTests.splice(testsWithPrep, 0, {
            id: `${test.id}-prep`,
            for: test.id,
            time_length: test.prep_time_length,
            prep_time_length: test.prep_time_length,
            test_type: `${test.test_type} Preparation`,
          });
        }
        setTests(currentTests);
      })
      .catch((err) => handleError(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tests, isShown]);

  useEffect(() => {
    setNewTestTime(
      Object.values(tests).map((test: any) => {
        return {
          id: test?.id,
          new_time_length: 0,
        };
      })
    );
  }, [tests]);

  useEffect(() => {
    setErrorText(
      Object.values(tests).map(() => {
        return null;
      })
    );
  }, [tests]);

  useEffect(() => {
    setTotalTime(arraySum(tests, 'time_length'));
  }, [tests]);

  const updateTestTime = useCallback(() => {
    if (!inputNumber || tests.length === 0) {
      return;
    }
    const { id } = inputNumber;
    const value = parseInt(inputNumber.value, 10);
    setNewTestTime(
      Object.values(newTestTime).map((test: any) => {
        const testId = test.id;
        if (testId === id) {
          return {
            id: testId,
            new_time_length: value * 60,
          };
        }
        return {
          id: testId,
          new_time_length: parseInt(test.new_time_length, 10),
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputNumber]);

  useEffect(() => {
    updateTestTime();
  }, [inputNumber, updateTestTime]);

  const updateNewTestTime = useCallback(() => {
    setTotalAdditionalTime(arraySum(newTestTime, 'new_time_length'));
  }, [newTestTime]);

  useEffect(() => {
    updateNewTestTime();
  }, [newTestTime, updateNewTestTime]);

  const headerText = mustConfirm ? (
    <p>
      {`Please triple check the details. As soon as you confirm, we'll email ${displayName} ${
        shouldCloakPII ? '' : `at ${row.Email}`
      } to confirm the increased duration.`}
    </p>
  ) : (
    <p>
      {`You can add additional time to one or more tests for ${displayName}. Once confirmed, ${displayName} will be emailed informing them of the additional time.`}
    </p>
  );
  const newTimeDuration = (test: any, idx: number): any => {
    if (!mustConfirm) {
      const defaultValue = newTestTime
        ? `${getMinutesPerSeconds(newTestTime[idx]?.new_time_length)}`
        : '0';
      const disabled = test.start && test.start !== null;
      return (
        <>
          <NumberContext.Provider value={[inputNumber, setInputNumber]}>
            <NumberInput
              {...{
                min: 0,
                loading: confirming,
                defaultValue,
                id: test.id,
                className: 'additional-time-input',
                errorTxt: errorText[idx],
                disabled,
              }}
            />
          </NumberContext.Provider>
        </>
      );
    }
    const minutesAdded = getMinutesPerSeconds(newTestTime[idx].new_time_length);
    if (minutesAdded === 0) {
      return <p>Unchanged</p>;
    }
    return <p>{`+${minutesAdded} ${getMinutesLabel(minutesAdded)}`}</p>;
  };
  return (
    <>
      <ParticipantModal {...{ ...props, ...customProps }}>
        {loading || tests.length === 0 ? (
          <Skeleton height={12} width={300} />
        ) : (
          <>
            <h2>Increase Test Duration</h2>
            <br />
            {headerText}
            <hr />
            <div className="data-row data-row-header">
              <div className="row center title">&nbsp;</div>
              <div className="row center current-duration">
                Current Duration (Minutes)
              </div>
              <div className="row center value">Additional Time (Minutes)</div>
              <div className="row center new-duration">
                New Duration (Minutes)
              </div>
            </div>
            {tests.map((test: any, idx: number) => (
              <div key={test.id} className="data-row data-row-values">
                <div className="row title">{`${test.test_type}:`}</div>
                <div className="row current-duration">
                  {getMinutesPerSeconds(test.time_length)}
                </div>
                <div className="row value">{newTimeDuration(test, idx)}</div>
                <div className="row new-duration">
                  {getMinutesPerSeconds(test.time_length) +
                    getMinutesPerSeconds(newTestTime[idx]?.new_time_length)}
                </div>
              </div>
            ))}
            <div className="data-row data-row-footer">
              <div className="row title">
                <strong>Total</strong>
              </div>
              <div className="row current-duration">
                {`${getMinutesPerSeconds(totalTime)} ${getMinutesLabel(
                  getMinutesPerSeconds(totalTime)
                )}`}
              </div>
              <div className="row value">
                {`+${getMinutesPerSeconds(
                  totalAdditionalTime
                )} ${getMinutesLabel(
                  getMinutesPerSeconds(totalAdditionalTime)
                )}`}
              </div>
              <div className="row new-duration">
                {`${getMinutesPerSeconds(
                  totalTime + totalAdditionalTime
                )} ${getMinutesLabel(
                  getMinutesPerSeconds(totalTime + totalAdditionalTime)
                )}`}
              </div>
            </div>
          </>
        )}
      </ParticipantModal>
    </>
  );
};
export default TestDurationModal;
