import React from 'react';

type IconProps = {
  size?: string | number;
  fill?: string;
};

const Search: React.FC<IconProps> = ({ size = 15, fill = '#8C8CA1' }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1445 9.17969L15.2852 13.3203L14.0352 14.5703L9.89453 10.4297V9.76562L9.66016 9.53125C8.64453 10.3906 7.47266 10.8203 6.14453 10.8203C4.63411 10.8203 3.34505 10.2995 2.27734 9.25781C1.23568 8.1901 0.714844 6.91406 0.714844 5.42969C0.714844 3.91927 1.23568 2.64323 2.27734 1.60156C3.34505 0.533854 4.62109 0 6.10547 0C7.61589 0 8.89193 0.533854 9.93359 1.60156C11.0013 2.64323 11.5352 3.91927 11.5352 5.42969C11.5352 6.75781 11.1055 7.92969 10.2461 8.94531L10.4805 9.17969H11.1445ZM3.48828 8.08594C4.21745 8.8151 5.10286 9.17969 6.14453 9.17969C7.1862 9.17969 8.07161 8.8151 8.80078 8.08594C9.52995 7.35677 9.89453 6.47135 9.89453 5.42969C9.89453 4.38802 9.52995 3.5026 8.80078 2.77344C8.07161 2.04427 7.1862 1.67969 6.14453 1.67969C5.10286 1.67969 4.21745 2.04427 3.48828 2.77344C2.75911 3.5026 2.39453 4.38802 2.39453 5.42969C2.39453 6.47135 2.75911 7.35677 3.48828 8.08594Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export const SearchIcon = React.memo(Search);
