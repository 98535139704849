import React, { useState, useEffect, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import {
  inviteCandidateManual,
  inviteCandidateManualConfirm,
} from 'api/candidate.api';
import { handleApiError } from 'helpers/auth-flow';
import { inviteYourself } from 'api/assessment.api';
import { Modal } from 'components/Shared/Modal';
import {
  incrementCandidateCount,
  updateAssessmentInvitedStatus,
} from 'store/reducers/assessment';
import { checkObjectEquality } from 'helpers';
import { stopEvent } from 'helpers/events';
import { phoneNumberRegex } from 'helpers/numbers';
import { capitalizeFirstLetter } from 'helpers/text';
import { triggerRefresh } from '../../../../store/reducers/utils';
import { handleError } from '../../../../handleError';
import SelectInviteType from './Steps/SelectInviteType';
import InviteCandidate from './Steps/InviteCandidate';
import InviteCandidateSuccess from './Steps/InviteCandidateSuccess';
import './InviteCandidateModal.scss';

type InviteCandidateModalStep =
  | 'select-invite-type'
  | 'invite-candidate'
  | 'invite-candidate-success';

interface InviteCandidateModalProps {
  userDetails: any;
  assessment: any;
  defaultDaysToComplete: number;
  setModalVisibility: any;
  isShown: boolean;
  purpose: string;
  initialStep?: InviteCandidateModalStep;
}

const initialValues = {
  recruiter_test_id: undefined,
  has_invitation_date: 0,
  has_expiration_date: 0,
  expiration_date: '',
  tz_code: 'UTC',
  tz_offset: '',
  tz_code_expiration: 'UTC',
  tz_offset_expiration: '',
  email_address: '',
  first_name: '',
  last_name: '',
  invitation_date: '',
  phone_number: '',
};

const initialErrorMap = {
  first_name: '',
  last_name: '',
  email_address: '',
  phone_number: '',
  invitation_date: '',
  expiration_date: '',
};

const InviteCandidateModal = (
  props: InviteCandidateModalProps
): JSX.Element => {
  const {
    userDetails,
    assessment,
    defaultDaysToComplete,
    setModalVisibility,
    purpose,
    initialStep,
  } = props;
  const recruiterTestId = assessment.id;
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState<InviteCandidateModalStep>(
    initialStep ?? 'select-invite-type'
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [invitingSelf, setInvitingSelf] = useState<boolean>(false);
  const [canInviteSelf, setCanInviteSelf] = useState<boolean>(
    !assessment.has_invited_yourself
  );

  const { addToast } = useToasts();
  const showToast = (msg: string, success: boolean): void => {
    addToast({
      type: success ? 'success' : 'error',
      msg,
    });
  };

  const candidateOrEmployee = purpose === 'ld' ? 'employee' : 'candidate';
  const assessmentOrExercise = purpose === 'ld' ? 'exercise' : 'assessment';

  useEffect(() => {
    setCanInviteSelf(!assessment.has_invited_yourself);
  }, [assessment.has_invited_yourself]);

  const handleInviteSelf = (): void => {
    setInvitingSelf(true);
    const bodyFormData = new FormData();
    bodyFormData.append('recruiter_test_id', `${recruiterTestId}`);
    inviteYourself(bodyFormData)
      .then((res) => {
        if (res.data.success) {
          showToast(
            `You have been added to the ${assessmentOrExercise}.\nAn invitation has been sent to <${userDetails.email}>.`,
            true
          );
          dispatch(
            incrementCandidateCount({
              id: recruiterTestId,
              purpose,
            })
          );
          dispatch(
            updateAssessmentInvitedStatus({
              id: assessment.id,
              purpose: assessment.purpose,
              invited_status: true,
            })
          );
          setCanInviteSelf(false);
        } else {
          showToast(res.data.errors.email_address, false);
        }
      })
      .catch(() => {
        showToast(
          `Unable to invite you to the ${assessmentOrExercise}.`,
          false
        );
      })
      .finally(() => {
        setInvitingSelf(false);
      });
  };

  // Form state
  const [values, setValues] = useState<{
    recruiter_test_id: number;
    has_invitation_date: number;
    has_expiration_date: number;
    expiration_date: string;
    tz_code: string;
    tz_offset: string;
    tz_code_expiration: string;
    tz_offset_expiration: string;
    email_address: string;
    first_name: string;
    last_name: string;
    invitation_date: string;
    phone_number: string;
  }>({
    ...initialValues,
  });

  const [errorMap, setErrorMap] = useState({
    ...initialErrorMap,
  });

  const setFormValue = (key: string, value: any): void => {
    setValues((prevValues) => {
      const newValues = { ...prevValues };
      newValues[key] = value;
      newValues.recruiter_test_id = recruiterTestId;
      return newValues;
    });
  };

  useEffect(() => {
    if (userDetails?.user_timezone) {
      const tzOffset = moment()
        .tz(userDetails.user_timezone)
        .format()
        .slice(-6);

      initialValues.tz_code = userDetails.user_timezone;
      initialValues.tz_offset = tzOffset;
      initialValues.tz_code_expiration = userDetails.user_timezone;
      initialValues.tz_offset_expiration = tzOffset;

      setValues({
        ...values,
        tz_code: userDetails.user_timezone,
        tz_code_expiration: userDetails.user_timezone,
        tz_offset: tzOffset,
        tz_offset_expiration: tzOffset,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, setValues]);

  const handleInviteConfirm = async (): Promise<void> => {
    inviteCandidateManualConfirm(values)
      .then((response) => {
        if (response.data.errors) {
          showToast(`Unable to invite ${candidateOrEmployee}.`, false);
        } else {
          showToast(
            `${capitalizeFirstLetter(candidateOrEmployee)} ${
              values.first_name
            } ${values.last_name} invited successfully.`,
            true
          );
          dispatch(
            incrementCandidateCount({
              id: recruiterTestId,
              purpose,
            })
          );
          dispatch(triggerRefresh());
        }
      })
      .catch((e) => {
        showToast(`Unable to invite ${candidateOrEmployee}.`, false);
        handleError(e);
      });
    setCurrentStep('invite-candidate-success');
  };

  const handleButtonAction = async (
    e: React.SyntheticEvent<EventTarget>
  ): Promise<void> => {
    stopEvent(e);
    if (currentStep === 'invite-candidate') {
      if (validate()) {
        setLoading(true);
        inviteCandidateManual(values, purpose)
          .then((res) => {
            setLoading(false);
            if (res.data.errors) {
              setErrorMap({
                ...res.data.errors,
              });
            } else {
              handleInviteConfirm();
            }
          })
          .catch((e) => {
            // if the user is unauthenticated in the old app, redirect them to the login page
            const authenticationErrorMessage = `You are logged out. Please login and try adding the ${candidateOrEmployee} again.`;
            const errorMessage = `Unable to invite ${candidateOrEmployee}.`;
            handleApiError(
              e,
              dispatch,
              () => {
                setLoading(false);
              },
              showToast,
              errorMessage,
              authenticationErrorMessage
            );
          });
      }
    } else {
      handleClose();
    }
  };

  const validate = (): boolean => {
    const newErrorMap = errorMap;
    if (!values.first_name) {
      newErrorMap.first_name = 'First name is required.';
    } else if (values.first_name.length > 50) {
      newErrorMap.first_name =
        'First name may not be greater than 50 characters.';
    } else {
      newErrorMap.first_name = '';
    }
    if (!values.last_name) {
      newErrorMap.last_name = 'Last name is required.';
    } else if (values.last_name.length > 50) {
      newErrorMap.last_name =
        'Last name may not be greater than 50 characters.';
    } else {
      newErrorMap.last_name = '';
    }
    if (!values.email_address) {
      newErrorMap.email_address = 'Email address is required.';
    } else {
      newErrorMap.email_address = '';
    }
    if (values.phone_number && !values.phone_number.startsWith('+')) {
      newErrorMap.phone_number =
        'Country code is required in the phone number.';
    } else if (
      values.phone_number &&
      !values.phone_number.match(phoneNumberRegex)
    ) {
      newErrorMap.phone_number = 'The phone number is invalid.';
    } else {
      newErrorMap.phone_number = '';
    }
    if (values.has_invitation_date && !values.invitation_date) {
      newErrorMap.invitation_date = 'The invitation date is required.';
    } else {
      const selectedTime = moment
        .tz(values.invitation_date, 'YYYY/MM/DD HH:mm:ss', values.tz_code)
        .toDate()
        .getTime();
      if (values.has_invitation_date && selectedTime < new Date().getTime()) {
        newErrorMap.invitation_date =
          'The invitation date cannot be in the past';
      } else {
        newErrorMap.invitation_date = '';
      }
    }
    if (values.has_expiration_date && !values.expiration_date) {
      newErrorMap.expiration_date = 'The expiration date is required.';
    } else if (values.has_invitation_date) {
      const minTime = moment
        .tz(values.invitation_date, 'YYYY/MM/DD HH:mm:ss', values.tz_code)
        .add(1, 'days')
        .toDate()
        .getTime();
      const selectedTime = moment
        .tz(
          values.expiration_date,
          'YYYY/MM/DD HH:mm:ss',
          values.tz_code_expiration
        )
        .toDate()
        .getTime();
      if (values.has_expiration_date && selectedTime < minTime) {
        newErrorMap.expiration_date =
          'The expiration date needs to be set to at least 24 hours after the invitation';
      } else {
        newErrorMap.expiration_date = '';
      }
    } else {
      const minTime = moment()
        .tz(values.tz_code)
        .add(1, 'days')
        .toDate()
        .getTime();
      const selectedTime = moment
        .tz(
          values.expiration_date,
          'YYYY/MM/DD HH:mm:ss',
          values.tz_code_expiration
        )
        .toDate()
        .getTime();
      if (values.has_expiration_date && selectedTime < minTime) {
        newErrorMap.expiration_date =
          'The expiration date must be at least 24 hours from now.';
      } else {
        newErrorMap.expiration_date = '';
      }
    }

    setErrorMap({
      ...newErrorMap,
    });
    return checkObjectEquality(newErrorMap, initialErrorMap);
  };

  const handleClose = (): void => {
    setCurrentStep('select-invite-type');
    setValues({
      ...initialValues,
    });
    setErrorMap({
      ...initialErrorMap,
    });
    setModalVisibility(false);
  };

  const actionText = useMemo(() => {
    if (currentStep === 'select-invite-type') {
      return undefined;
    } else if (currentStep === 'invite-candidate') {
      return `Invite ${capitalizeFirstLetter(
        candidateOrEmployee
      )} and Assign Assessment`;
    } else {
      return 'OK';
    }
  }, [currentStep, candidateOrEmployee]);

  const customProps = {
    loading,
    actionText,
    showCancel: currentStep !== 'invite-candidate-success',
    cancelVariant: 'sub-primary',
    hideCloseButton: true,
    handleClose,
    handleButtonAction,
    containerClass: 'participants-modal invite-participant-modal',
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        {/* Step 1 (select invite type) */}
        {currentStep === 'select-invite-type' && (
          <SelectInviteType
            assessment={assessment}
            candidateOrEmployee={candidateOrEmployee}
            setCurrentStep={setCurrentStep}
          />
        )}

        {/* Steps 2 & 3 (form & confirmation) */}
        {['invite-candidate', 'invite-candidate-confirm'].includes(
          currentStep
        ) && (
          <InviteCandidate
            assessment={assessment}
            setFormValue={setFormValue}
            candidateOrEmployee={candidateOrEmployee}
            assessmentOrExercise={assessmentOrExercise}
            daysToComplete={assessment?.days_to_complete}
            values={values}
            errorMap={errorMap}
            handleInviteSelf={handleInviteSelf}
            canInviteSelf={canInviteSelf}
            invitingSelf={invitingSelf}
          />
        )}

        {/* Step 4 (success) */}
        {currentStep === 'invite-candidate-success' && (
          <InviteCandidateSuccess
            values={values}
            defaultDaysToComplete={defaultDaysToComplete}
            assessmentOrExercise={assessmentOrExercise}
            softExpiration={assessment?.soft_expiration}
          />
        )}
        <hr />
      </>
    </Modal>
  );
};
export default InviteCandidateModal;
