import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StripeCheckoutResult, PaymentMethodResult } from 'api/payment.api';

interface PaymentState {
  clientToken: string | null;
  loading: boolean;
  paymentMethods: any[];
  error: string | null;
}

const initialState: PaymentState = {
  clientToken: null,
  loading: null,
  paymentMethods: [],
  error: null,
};

const paymentReducer = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    getCheckoutTokenStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getCheckoutTokenSuccess: (
      state,
      action: PayloadAction<StripeCheckoutResult>
    ) => {
      state.clientToken = action.payload.token;
      state.loading = false;
    },
    getCheckoutTokenFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    getPaymentMethodStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getPaymentMethodSuccess: (
      state,
      action: PayloadAction<PaymentMethodResult>
    ) => {
      state.paymentMethods = action.payload.data;
      state.loading = false;
    },
    getPaymentMethodFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  getCheckoutTokenFailure,
  getCheckoutTokenSuccess,
  getCheckoutTokenStart,
  getPaymentMethodFailure,
  getPaymentMethodSuccess,
  getPaymentMethodStart,
} = paymentReducer.actions;
export default paymentReducer.reducer;
