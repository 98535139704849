import React from 'react';
import { Modal } from '../Modal';

interface Props {
  loadingTxt?: string;
  loading?: boolean;
  isShown?: boolean;
  actionText?: string;
  handleButtonAction?: any;
  setModalVisibility?: any;
  title?: string;
  body?: any;
  showCancel?: boolean;

  cancelButtonText?: string;
}
const customProps = {
  actionText: 'Delete',
  showCancel: true,
  hideCloseButton: true,
  containerClass: 'assessments-modal delete-assessment-modal',
};
const DeleteModal = (props: Props): JSX.Element => {
  const { title, body } = props;
  return (
    <Modal {...props} {...customProps}>
      <div>
        <h2>{title}</h2>
        <br />
        {body}
        <hr />
      </div>
    </Modal>
  );
};

export default DeleteModal;
