import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import InviteCandidateIcon from 'images/icons/invite-candidate-blue.svg';
import PublicLinkIcon from 'images/icons/public-link-blue.svg';
import ConfigurationIcon from 'images/icons/configuration-blue.svg';
import DownloadResultsIcon from 'images/icons/download-results-blue.svg';
import {
  faTrashAlt,
  faCog,
  faDownload,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOrgDetails } from 'store/actions/organization.actions';
import { capitalizeFirstLetter } from 'helpers/text';
import { getResultReport } from 'api/assessment.api';
import { useToasts } from 'react-toast-notifications';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import StatIcon from '../Shared/StatIcon';
import InviteCandidateModal from '../Shared/Modals/InviteCandidateModal/InviteCandidateModal';
import ActionMenu from '../Shared/Dropdown/ActionMenu';
import authorize, { hasOrgFeature } from '../../Authorization/Check';
import useWindowSize from '../../hooks/useWindowSize';

import './index.scss';

interface Props {
  assessment: any;
  showDeleteModal?: any;
}
const AssessmentActionList = ({
  assessment,
  showDeleteModal,
}: Props): JSX.Element => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState<boolean>(
    false
  );
  const handleInviteClick = (): void => {
    setShowInviteModal(true);
  };
  const handleResize = (): void => {
    setWindowWidth(window.innerWidth);
  };
  useWindowSize(handleResize);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { userDetails } = useSelector((state: RootState) => state.profile);

  const organizationCloakedCandidates = useSelector(
    (state: RootState) =>
      state.organization.organizationDetails?.cloak_candidates
  );
  const recruiterCloakedCandidates = useSelector(
    (state: RootState) =>
      state.profile.userDetails.recruiter_detail?.cloak_candidates
  );
  const assessmentCloakedCandidates = useSelector(
    (state: RootState) => state.assessment.currentAssessment?.cloak_candidates
  );

  const organization_id = useSelector(
    (state: RootState) =>
      state.profile.userDetails.recruiter_detail?.organisation_id
  );
  const assessmentWord =
    assessment.purpose === 'ld' ? 'exercise' : 'assessment';

  useEffect(() => {
    if (organization_id && organizationCloakedCandidates === null) {
      dispatch(getOrgDetails(organization_id));
    }
  }, [dispatch, organization_id, organizationCloakedCandidates]);

  const legacyUrl = process.env.REACT_APP_ALOOBA_LEGACY_URL;
  const editSampleRoute = 'view-assessment-customized';
  const handleDownload = async (
    e: React.SyntheticEvent<EventTarget>
  ): Promise<any> => {
    e.stopPropagation();
    try {
      setReportLoading(true);
      if (!hasOrgFeature('export_results')) {
        setShowUpgradePlanModal(true);
        return;
      }
      const res: any = await getResultReport(assessment.id, false);
      addToast({
        type: 'success',
        msg: res.data.data.message,
      });
      if (res.data.data.canDirectDownload) {
        const res = await getResultReport(assessment.id, true);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.href = url;
        a.download = `${
          assessment.recruiter_test_name
        } Report ${new Date().toISOString().slice(0, 10)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (e) {
      addToast({
        type: 'error',
        msg: 'Sorry, something went wrong. Please try again',
      });
    } finally {
      setReportLoading(false);
    }
  };
  const tooltip =
    assessment.purpose === 'ld' ? 'Invite Team Member' : 'Invite Candidate';

  // Define actionMenuItems as just the delete button
  let actionMenuItems = [
    <p
      onClick={(e) => showDeleteModal(e)}
      onKeyDown={(e) => showDeleteModal(e)}
      role="navigation"
      aria-hidden
    >
      <FontAwesomeIcon className="icon" icon={faTrashAlt} />
      &ensp;
      <span>Delete Assessment</span>
    </p>,
  ];

  let downloadReportBtn = (
    <StatIcon
      tooltip="Download Results"
      icon={DownloadResultsIcon}
      alt="configuration"
      isLink
      handleImageClick={(e) => handleDownload(e)}
    />
  );
  let downloadReportMenuItem = (
    <p
      onClick={(e) => {
        handleDownload(e);
      }}
      onKeyDown={(e) => {
        handleDownload(e);
      }}
      role="navigation"
      aria-hidden
    >
      <FontAwesomeIcon className="icon" icon={faDownload} />
      &ensp;
      <span>Download Results</span>
    </p>
  );
  if (
    !authorize('assessment', 'view', assessment.role?.user_id) ||
    organizationCloakedCandidates === null ||
    organizationCloakedCandidates ||
    assessmentCloakedCandidates ||
    recruiterCloakedCandidates
  ) {
    downloadReportBtn = null;
    downloadReportMenuItem = null;
  } else if (reportLoading) {
    downloadReportBtn = (
      <StatIcon tooltip="Checking Results..." disabled>
        <FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
      </StatIcon>
    );
    downloadReportMenuItem = (
      <p className="disabled" role="navigation" aria-hidden>
        <FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
        &ensp;
        <span>Checking Results...</span>
      </p>
    );
  }

  /**
   * If the window width is smaller than the tablet breakpoint, we should add
   * the action icons that will be hidden inside the ActionMenu, in the order
   * that they appear, so we update the actionMenuItems variable with the following:
   */
  if (windowWidth < 768) {
    actionMenuItems = [
      ...[
        <p
          onClick={() => {
            window.location.href = `${legacyUrl}/${editSampleRoute}/${assessment.id}`;
          }}
          onKeyDown={() => {
            window.location.href = `${legacyUrl}/${editSampleRoute}/${assessment.id}`;
          }}
          role="navigation"
          aria-hidden
        >
          <FontAwesomeIcon className="icon" icon={faCog} />
          &ensp;
          <span>Assessment Settings</span>
        </p>,
        downloadReportMenuItem,
      ],
      ...actionMenuItems,
    ];
  }

  const productName = assessment.purpose === 'ld' ? 'exercise' : 'assessment';

  return (
    <>
      <div
        className="assessment-page-actions"
        onClick={(e: React.SyntheticEvent<EventTarget>) => e.stopPropagation()}
        onKeyDown={(e: React.SyntheticEvent<EventTarget>) =>
          e.stopPropagation()
        }
        role="button"
        tabIndex={0}
      >
        <>
          {authorize('assessment-candidate', 'add', assessment.role?.user_id) &&
            !assessment.is_archived && (
              <StatIcon
                tooltip={tooltip}
                icon={InviteCandidateIcon}
                handleImageClick={handleInviteClick}
                alt="invite-candidate"
              />
            )}
          {assessment.public_url_active &&
            !assessment.is_archived &&
            authorize(
              'assessment-candidate',
              'add',
              assessment.role?.user_id
            ) && (
              <StatIcon
                tooltip={`Copy public ${productName} link`}
                copiedTxt="Link copied to your clipboard!"
                icon={PublicLinkIcon}
                alt="configuration"
                linkTo={assessment.public_url}
                copyLink
              />
            )}
          {windowWidth > 768 && downloadReportBtn}
          {windowWidth > 768 &&
          authorize('assessment', 'edit', assessment.role?.user_id) ? (
            <StatIcon
              tooltip={`${capitalizeFirstLetter(productName)} Settings`}
              icon={ConfigurationIcon}
              alt="configuration"
              isLink
              linkTo={`${legacyUrl}/${editSampleRoute}/${assessment.id}`}
            />
          ) : (
            ''
          )}
        </>
        {assessment.id &&
          authorize('assessment', 'delete', assessment.role.user_id) && (
            <ActionMenu
              id="delete"
              customStyles={{ width: '210px', top: '38px' }}
              items={actionMenuItems}
            />
          )}
      </div>
      <InviteCandidateModal
        userDetails={userDetails}
        assessment={assessment}
        defaultDaysToComplete={assessment.days_to_complete}
        isShown={showInviteModal}
        setModalVisibility={setShowInviteModal}
        purpose={assessment.purpose}
      />
      <PlanUpgradeModal
        isShown={showUpgradePlanModal}
        setModalVisibility={setShowUpgradePlanModal}
        title={`Upgrade Your Account to Download ${
          assessmentWord[0].toUpperCase() + assessmentWord.slice(1)
        } Results`}
      >
        <p>
          Exporting results is not supported on your current subscription plan.
          Upgrade your subscription to gain access to {assessmentWord}
          {` `}
          downloadable results as well as many other amazing features Alooba has
          to offer.
        </p>
      </PlanUpgradeModal>
    </>
  );
};

export default AssessmentActionList;
