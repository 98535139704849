import React from 'react';
import { Helmet } from 'react-helmet';
import CandidateDetails from '../CandidateDetails';

const CandidateResults: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Assessment Results | alooba.com</title>
      </Helmet>
      <CandidateDetails isPublic />
    </div>
  );
};

export default CandidateResults;
