import { useLayoutEffect } from 'react';

export const goToLegacyApp = (
  e: React.SyntheticEvent<EventTarget>,
  url: string
): void => {
  e.preventDefault();
  e.stopPropagation();
  const legacyUrl = process.env.REACT_APP_ALOOBA_LEGACY_URL;
  window.location.assign(`${legacyUrl}/${url}`);
};

export const goToLandingPageApp = (
  e: React.SyntheticEvent<EventTarget>,
  url: string
): void => {
  e.preventDefault();
  e.stopPropagation();
  window.location.assign(`${process.env.REACT_APP_LANDING_PAGE_URL}/${url}`);
};

export const useChatbot = (enable = true): void => {
  useLayoutEffect(() => {
    if (!(enable && window.innerWidth > 600)) return;

    // Inject chatbot scripts into body
    const scripts = [
      '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});',
      "window.Beacon('init', 'cd1999dd-8f4d-477d-b328-c7e6ae3cf9af');",
    ];

    const scriptElements = scripts.map((script) => {
      const scriptElement = document.createElement('script');
      scriptElement.innerHTML = script;
      scriptElement.async = true;
      scriptElement.defer = true;
      return scriptElement;
    });

    scriptElements.forEach((script) => {
      document.body.appendChild(script);
    });
  }, [enable]);
};
