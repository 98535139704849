/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const doesEventHasNumber = (evt): boolean => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};
export const arraySum = (arr: Array<any>, field: string): number => {
  const sumElements = (total, num): void => {
    return Number.isNaN(num[field]) ? total : total + num[field];
  };
  return arr.reduce(sumElements, 0);
};

export const ordinal_number = (i: number): string => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export const getPercentileOrdinalIndicator = (value: number): string => {
  let indicator: string;
  if (value === 0) return 'th';
  if (!value) return '';
  const percentileRank: number = Math.round(value * 100);
  if (percentileRank % 10 === 1 && percentileRank !== 11) {
    indicator = 'st';
  } else if (percentileRank % 10 === 2 && percentileRank !== 12) {
    indicator = 'nd';
  } else if (percentileRank % 10 === 3 && percentileRank !== 13) {
    indicator = 'rd';
  } else {
    indicator = 'th';
  }
  return indicator;
};

export const phoneNumberCountryCode = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)$/g;
export const phoneNumberRegex = /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/g;
export const optionalPhoneNumberRegex = /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{0,14}$/g;
