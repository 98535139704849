import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type QuestionContext = 'interview-content' | 'additional-criteria';

interface InterviewState {
  interviews: any;
  interviewCandidatesPaginator: any;
  interviewCandidatesQueryParams: any;
  interviewCandidatesCurrentPage: number;
  skills: any;
  additionalCriteriaSkills: any;
  questionContext: QuestionContext;
}

const initialState: InterviewState = {
  interviews: {},
  interviewCandidatesPaginator: {
    data: [],
  },
  skills: [],
  additionalCriteriaSkills: [],
  interviewCandidatesCurrentPage: 1,
  interviewCandidatesQueryParams: null,
  questionContext: 'interview-content',
};

const interviewReducer = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setAssessmentInterviews: (
      state: InterviewState,
      action: PayloadAction<any>
    ) => {
      if (action.payload.assessment_id) {
        state.interviews[action.payload.assessment_id] = action.payload;
      } else {
        throw Error(
          'Invalid setAssessmentInterviews payload. Missing assessmentId'
        );
      }
    },
    appendAssessmentInterview: (
      state: InterviewState,
      action: PayloadAction<{ assessmentId: number; interview: any }>
    ) => {
      if (action.payload.assessmentId) {
        state.interviews[action.payload.assessmentId].interviews = [
          ...state.interviews[action.payload.assessmentId].interviews,
          {
            num_candidates: 0,
            ...action.payload.interview,
          },
        ];
      } else {
        throw Error(
          'Invalid appendAssessmentInterview payload. Missing assessmentId'
        );
      }
    },
    updateAssessmentInterview: (
      state: InterviewState,
      action: PayloadAction<{
        assessmentId: number;
        interviewId: number;
        values: any;
      }>
    ) => {
      if (action.payload.assessmentId) {
        if (state.interviews[action.payload.assessmentId]) {
          const assessmentInterviews =
            state.interviews[action.payload.assessmentId].interviews;
          for (let i = 0; i < assessmentInterviews.length; i += 1) {
            if (assessmentInterviews[i].id === action.payload.interviewId) {
              const previousInterviewData =
                state.interviews[action.payload.assessmentId].interviews[i];

              state.interviews[action.payload.assessmentId].interviews[i] = {
                ...previousInterviewData,
                ...action.payload.values,
              };
            }
          }
        }
      } else {
        throw Error(
          'Invalid updateAssessmentInterview payload. Missing assessmentId'
        );
      }
    },

    increaseInvitedUsersCount: (
      state: InterviewState,
      action: PayloadAction<{
        assessmentId: number;
        interviewId: number;
        amount?: number;
      }>
    ) => {
      const assessmentInterviews =
        state.interviews[action.payload.assessmentId].interviews;
      for (let i = 0; i < assessmentInterviews.length; i += 1) {
        if (assessmentInterviews[i].id === action.payload.interviewId) {
          const previousInterviewData =
            state.interviews[action.payload.assessmentId].interviews[i];

          const amountToIncrease = action.payload.amount
            ? action.payload.amount
            : 1;

          state.interviews[action.payload.assessmentId].interviews[i] = {
            ...previousInterviewData,
            num_candidates:
              previousInterviewData.num_candidates + amountToIncrease,
          };
        }
      }
    },
    updateInterviewCandidatesQueryParams: (
      state,
      action: PayloadAction<any>
    ) => {
      state.interviewCandidatesQueryParams = {
        ...state.interviewCandidatesQueryParams,
        ...action.payload,
      };
    },
    setInterviewCandidatesPaginator: (state, action: PayloadAction<any>) => {
      state.interviewCandidatesPaginator = action.payload;
    },
    setInterviewCandidatesCurrentPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.interviewCandidatesCurrentPage = action.payload;
    },
    setQuestionContext: (state, action: PayloadAction<QuestionContext>) => {
      state.questionContext = action.payload;
    },
    setSkills: (state, action: PayloadAction<any>) => {
      state.skills = action.payload;
    },
    setAdditionalCriteriaSkills: (state, action: PayloadAction<any>) => {
      state.additionalCriteriaSkills = action.payload;
    },
  },
});
export const {
  setAssessmentInterviews,
  appendAssessmentInterview,
  updateAssessmentInterview,
  increaseInvitedUsersCount,
  updateInterviewCandidatesQueryParams,
  setInterviewCandidatesPaginator,
  setInterviewCandidatesCurrentPage,
  setQuestionContext,
  setSkills,
  setAdditionalCriteriaSkills,
} = interviewReducer.actions;
export default interviewReducer.reducer;
