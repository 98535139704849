import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import TemplateEditor from 'components/OrganizationTemplates/TemplateEditor';
import { deactivatePageHeader } from '../store/reducers/layout';

interface UrlParams {
  templateName?: string;
}

const EditTemplate: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  const { templateName }: UrlParams = useParams();
  return (
    <div className="container">
      <Helmet>
        <title>Edit Template | alooba.com</title>
      </Helmet>
      <TemplateEditor template={templateName} />
    </div>
  );
};

// Alooba Assess Assessment Introduction, Alooba Growth Exercise Introduction
export default EditTemplate;
