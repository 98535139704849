import React from 'react';
import './switch.scss';
import Skeleton from 'react-loading-skeleton';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { calculateRgba } from 'react-spinners/helpers';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface SwitcherProps extends SwitchProps {
  classes: Styles;
}
interface Props {
  checked?: boolean;
  label?: any;
  disabled?: boolean;
  id?: string;
  inputProps?: any;
  inputRef?: any;
  onChange?: any;
  value?: any;
  loading?: boolean;
}

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 52,
      height: 30,
      padding: 0,
      margin: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      float: 'left',
      lineHeight: '40px',
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(26px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#004385',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: calculateRgba('#fff', 0.7),
        border: '1px solid #fff',
        width: '22px',
        height: '22px',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#ccc',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
      height: 26,
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: SwitcherProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Switcher = ({
  label,
  checked,
  disabled,
  id,
  inputProps,
  inputRef,
  onChange,
  value,
  loading,
}: Props): JSX.Element => (
  <div className="alooba-input alooba-switch">
    {loading ? (
      <>
        <Skeleton height={20} width={60} />
        &ensp;
      </>
    ) : (
      <CustomSwitch
        checked={checked}
        disabled={disabled}
        id={id}
        inputProps={inputProps}
        inputRef={inputRef}
        onChange={onChange}
        value={value}
      />
    )}
    {label && <label htmlFor={label}>{label}</label>}
  </div>
);

export default Switcher;
