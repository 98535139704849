import React from 'react';
import DynamicTable from 'components/Shared/DynamicTable';
import '../Participants/index.scss';

const OrganizationUsersTable = (): JSX.Element => {
  return (
    <div className="participants-container">
      <DynamicTable />
    </div>
  );
};
export default OrganizationUsersTable;
