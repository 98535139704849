import React from 'react';
import Skeleton from 'react-loading-skeleton';

import QuestionInsightBar from './bar';
import Histogram from '../Histogram';
import './questionInsights.scss';
import GraphicPlaceholder from '../GraphicPlaceholder';

export interface Highlight {
  time_taken: number;
  correct: boolean;
}

const QuestionInsights: React.FC<{
  data: Record<string, any>[];
  durationPerBucket: number;
  bucketsLength: number;
  highlight?: Highlight;
  isLoading?: boolean;
}> = ({
  data,
  durationPerBucket,
  bucketsLength,
  highlight,
  isLoading = false,
}): JSX.Element => {
  const buckets = [];

  for (let i = 0; i < bucketsLength; i += 1) {
    const rows = data.filter((r) => r.bucket_index === i);
    let value = 0;
    const min = i * durationPerBucket;
    const max = min + durationPerBucket;
    let correctCount = 0;
    let incorrectCount = 0;
    rows.forEach((row) => {
      value += row.count;
      if (row.correct) {
        correctCount = row.count;
      }
      if (!row.correct) {
        incorrectCount = row.count;
      }
    });

    buckets[i] = {
      label: `${min / 60}-${max / 60} mins`,
      value,
      min,
      max,
      correctCount,
      incorrectCount,
    };
  }

  const bucketResolver = (index: number): any => {
    return buckets[index];
  };

  if (isLoading) {
    return (
      <div className="question-insight-skeleton">
        <Skeleton height={100} />
        <Skeleton height={20} />
        <Skeleton height={75} />
        <Skeleton height={40} />
        <Skeleton height={30} />
        <Skeleton height={90} />
        <Skeleton height={55} />
        <Skeleton height={20} />
        <Skeleton height={100} />
      </div>
    );
  }

  return (
    <>
      {data.length > 0 ? (
        <>
          <Histogram
            data={buckets}
            lineAmount={8}
            yaxis=""
            xaxis=""
            xAxisLabelsRotation={-45}
            xAxisLabelMarginTop={30}
            verticalGrids
          >
            <QuestionInsightBar
              height={0}
              width={0}
              y={0}
              index={0}
              highlight={highlight}
              bucketResolver={bucketResolver}
            />
          </Histogram>

          <div className="legend">
            <div className="correct">Correct</div>
            <div className="incorrect">Incorrect</div>
          </div>
        </>
      ) : (
        <GraphicPlaceholder variant="bar" />
      )}
    </>
  );
};
export default QuestionInsights;
