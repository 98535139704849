import React, { useState } from 'react';
import './integration.scss';
import { useToasts } from 'react-toast-notifications';
import { Modal } from '../Shared/Modal';
import { Button, Input } from '../Shared';
import { saveToken } from '../../api/integration.api';

export interface ApiKey {
  id?: number;
  api_key?: string;
  integration?: string;
  masked?: boolean;
}

interface ApiKeyIntegrationProps {
  name: string;
  integration: string;
  loading?: boolean;
  apiKey?: ApiKey;
  setApiKey?: any;
}

export const ApiTokenIntegrationPartner = ({
  name,
  loading,
  apiKey,
  setApiKey,
  integration,
}: ApiKeyIntegrationProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [currentToken, setCurrentToken] = useState<string>(apiKey?.api_key);
  const { addToast } = useToasts();

  const handleAction = (): void => {
    setIsSaving(true);
    saveToken(integration, currentToken)
      .then((response) => {
        addToast({
          type: 'success',
          msg: `${name} API Token Saved.`,
        });
        setApiKey(response.data);
      })
      .catch(() => {
        addToast({
          type: 'error',
          msg: `${name} API Token could not be saved.`,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <Button
        loading={loading}
        loadingPlaceholder={loading}
        variant="sub-primary md"
        text={name}
        onClick={() => setShowModal(true)}
      />
      <Modal
        loadingTxt="Saving token..."
        loading={loading || isSaving}
        isShown={showModal}
        setModalVisibility={setShowModal}
        actionText={!apiKey && 'Save Token'}
        handleButtonAction={handleAction}
        showCancel
        cancelButtonText="Close"
        cancelVariant="sub-primary"
      >
        <div>
          <h4 className="mb4">{name} Integration API Token</h4>
          <div className="form-row">
            {!apiKey && (
              <Input
                type="text"
                label="Harvest API Token:"
                disabled={loading || isSaving}
                value={currentToken}
                onChange={(e) => setCurrentToken(e.target.value)}
              />
            )}
            {apiKey && (
              <p>
                {`An API Key matching `}
                <code>{apiKey.api_key}</code>
                {` has been added for ${name}`}
              </p>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
