import './index.scss';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { updateNpsRequestStatus } from 'api/assessment.api';
import { handleError } from 'handleError';
import CardContainer from 'components/Shared/MainContainer/CardContainer';
import MainLogo from 'images/logo-cloud-only.svg';
import G2Logo from 'images/g2.svg';
import { Button } from '../Shared';
import ThankYou from './ThankYou';

const AddG2Review = (): JSX.Element => {
  const location = useLocation();
  const [willWriteReview, setWillWriteReview] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [savingStatus, setSavingStatus] = useState(false);
  const { code } = useParams<{ code?: string }>();

  useEffect(() => {
    updateNpsRequestStatus(code, 'Requested').catch((error) => {
      handleError(error);
    });
  }, [code]);

  const params = new URLSearchParams(location.search);
  const firstName = params.get('firstName');
  const isCandidate = params.get('isCandidate');

  const handleWriteReview = (): void => {
    updateNpsRequestStatus(code, 'Agreed').catch((error) => {
      handleError(error);
    });
    setWillWriteReview(true);
  };

  const dismissReview = (): void => {
    updateNpsRequestStatus(code, 'Declined').catch((error) => {
      handleError(error);
    });
    setShowThankYou(true);
  };

  const writeReview = (): void => {
    setSavingStatus(true);
    updateNpsRequestStatus(code, 'Forwarded')
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        window.location.href = 'https://www.g2.com/products/alooba';
      });
  };

  if (showThankYou) {
    return (
      <div className="nps-landing">
        <CardContainer>
          <div className="images">
            <div className="logo-container">
              <img src={MainLogo} alt="Main Logo" className="main-logo" />
              <img src={G2Logo} alt="Main Logo" className="g2-logo" />
            </div>
          </div>
          <hr />
          <ThankYou />
        </CardContainer>
      </div>
    );
  }

  return (
    <div className="nps-landing">
      <CardContainer>
        <div className="images">
          <div className="logo-container">
            <img src={MainLogo} alt="Main Logo" className="main-logo" />
            <img src={G2Logo} alt="Main Logo" className="g2-logo" />
          </div>
        </div>
        <hr />
        {willWriteReview ? (
          <>
            <h2>How to leave a G2 review of Alooba</h2>
            <iframe
              className="g2-review-video"
              title="How to leave a G2 review of Alooba"
              src={
                isCandidate === 'true'
                  ? 'https://www.loom.com/embed/27e69632adac4d2f9e6cf9980c5954b9'
                  : 'https://www.loom.com/embed/50a7c321e9e9453b9fab892e9409ced7'
              }
              allowFullScreen
              allow="autoplay; fullscreen; picture-in-picture"
            />
            <button
              type="button"
              className={`write-review-button${
                savingStatus ? ' disabled' : ''
              }`}
              disabled={savingStatus}
              onClick={writeReview}
            >
              Write a G2 review
            </button>
          </>
        ) : (
          <>
            <h2>
              Thank you so much
              {firstName && firstName !== 'undefined' ? ` ${firstName}` : ''}!
            </h2>
            <h2>We&apos;re glad we&apos;re on the right track.</h2>
            <div className="details">
              <p>
                How would you feel about spreading the word and writing a quick{' '}
                <b>review on G2</b>?
              </p>
              <p>
                It will take literally <b>5 minutes</b>, and the review would
                really help us to improve our product and ultimately achieve our
                vision of a world where everyone can get the job they deserve.
              </p>
            </div>
            <div className="actions">
              <Button
                variant="primary md"
                text="I'd be happy to write a review"
                onClick={handleWriteReview}
              />
              <button
                type="button"
                className="ignore-review"
                onClick={dismissReview}
              >
                Not now
              </button>
            </div>
          </>
        )}
      </CardContainer>
    </div>
  );
};
export default AddG2Review;
