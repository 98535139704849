import { Modal } from 'components/Shared/Modal';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Button } from 'components/Shared';
import './FreeTrialModal.scss';
import { startFreeTrial } from 'api/subscription.api';
import { refreshCurrentTokens } from 'store/actions/auth.actions';
import { getSubscription } from 'store/actions/subscription.actions';
import { useToasts } from 'react-toast-notifications';
import { sleep } from 'helpers';
import BillingDetails from './BillingDetails';

interface SubscribeModalProps {
  isShown: boolean;
  setModalVisibility: (isShown: boolean) => void;
  trialCode: string;
}

export default function FreeTrialModal(
  props: SubscribeModalProps
): JSX.Element {
  const { setModalVisibility, trialCode } = props;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [billingEmail, setBillingEmail] = useState('');
  const [billingCountry, setBillingCountry] = useState('');
  const [abn, setAbn] = useState('');
  const [postalcode, setPostalcode] = useState('');
  const [billingName, setBillingName] = useState('');
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const orgId = userDetails?.recruiter_detail?.organisation_id;

  const customProps = {
    loading,
    cancelVariant: 'secondary md',
    containerClass: 'free-trial-modal',
  };

  const handleStartTrial = async (): Promise<void> => {
    setLoading(true);
    try {
      await startFreeTrial(
        orgId,
        trialCode,
        billingName,
        billingEmail,
        billingCountry,
        abn,
        postalcode
      );
      // this is because stripe webhook for free trial subscription takes some time to update the organization subscription status
      await sleep(3000);
      await dispatch(refreshCurrentTokens());
      await dispatch(getSubscription(orgId)); // update subscription status
      addToast({
        type: 'success',
        msg: 'Your free trial is now active.',
      });
      setLoading(false);
      setModalVisibility(false);
    } catch (error) {
      setLoading(false);
      if (error.response?.data?.errors?.message) {
        addToast({
          type: 'error',
          msg: error.response.data.errors.message,
        });
      } else {
        addToast({
          type: 'error',
          msg: 'Failed to start free trial. Please try again.',
        });
      }
    }
  };

  const canSubscribe = !!(
    billingName &&
    billingEmail &&
    billingCountry &&
    (!['US', 'CA'].includes(billingCountry) || postalcode)
  );

  return (
    <div>
      <Modal {...{ ...props, ...customProps }}>
        <>
          <div className="trial-modal-header">
            <h2>Start your free trial</h2>
          </div>
          <BillingDetails
            orgId={orgId}
            name={billingName}
            email={billingEmail}
            country={billingCountry}
            abn={abn}
            postalcode={postalcode}
            setName={setBillingName}
            setEmail={setBillingEmail}
            setCountry={setBillingCountry}
            setAbn={setAbn}
            setPostalcode={setPostalcode}
          />
          <div className="modal-footer">
            <div className="modal-footer-actions">
              <Button
                variant="sub-primary lg"
                text="Cancel"
                disabled={loading}
                onClick={() => setModalVisibility(false)}
              />
              <Button
                variant="primary lg"
                text="Start Free Trial"
                onClick={handleStartTrial}
                disabled={!canSubscribe || loading}
                loading={loading}
                loadingTxt="Starting trial..."
              />
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
}
