import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { setQuestionScore, updateQuestionInPart } from 'api/assessment.api';
import { updateQuestionScore } from 'store/actions/candidate.actions';
import { secondsToHms } from 'helpers/datetime';
import SuspiciousActivities from 'components/Shared/SuspiciousActivityCard';
import LightTooltip from 'components/Shared/Tooltip/LightTooltip';
import QuestionBackground from './QuestionBackground';
import QuestionMarks from './QuestionMarks';
import QuestionMarksIcon from '../../../../images/icons/question-marks.svg';
import TimeIcon from '../../../../images/icons/clock.svg';
import EasyDifficultyIcon from '../../../../images/icons/difficulty-easy.svg';
import MediumDifficultyIcon from '../../../../images/icons/difficulty-medium.svg';
import HardDifficultyIcon from '../../../../images/icons/difficulty-hard.svg';
import EditableNumber from './EditableNumber';
import './QuestionDetails.scss';

interface QuestionDetailsProps {
  question?: any;
  onQuestionUpdate?: (newValues: any) => void;
  loading?: boolean;
  testId?: number;
  testType?: string;
  recruiterTestId?: number;
  candidateTestId?: number;
  selectedIndex?: number;
  disableScoring?: boolean;
  isPreview?: boolean;
  isEditable?: boolean;
  isMCQ?: boolean;
  children?: React.ReactNode;
}

const MCQTestTypes = ['Concepts & Knowledge', 'Data Analysis'];
const TestsWithEvaluation = ['Free Response'];

const LoadingSkeleton = (): any => (
  <div>
    <br />
    <Skeleton width={120} height={20} />
    <br />
    <br />
    <Skeleton width={100} height={20} />
    &ensp;
    <Skeleton width={150} height={20} />
    &ensp;
    <Skeleton width={100} height={20} />
    <br />
    <br />
    <Skeleton width="100%" height={100} />
    <br />
    <br />
    <Skeleton width={120} height={20} />
    <br />
    <br />
    <Skeleton width="100%" height={100} />
  </div>
);

export default function QuestionDetails({
  question,
  onQuestionUpdate,
  loading,
  testId,
  testType,
  recruiterTestId,
  candidateTestId,
  selectedIndex,
  disableScoring = false,
  isPreview = false,
  isEditable = false,
  isMCQ = false,
  children,
}: QuestionDetailsProps): any {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [questionBackground, setQuestionBackground] = useState(null);

  const setQuestionMarks = (marks): void => {
    const payload = {
      candidate_test_id: candidateTestId,
      test_question_id: parseInt(question.test_question_id, 10),
      score: parseInt(marks, 10),
      recruiter_test_id: recruiterTestId,
    };
    const currentSelectedIndex = selectedIndex;
    setQuestionScore(payload)
      .then((res) => {
        addToast({
          type: 'success',
          msg: `Score updated successfully.`,
        });
        dispatch(
          updateQuestionScore(
            question,
            testId,
            currentSelectedIndex,
            marks,
            res.data.scored_percent,
            res.data.test_scores,
            res.data.subject_scores
          )
        );
      })
      .catch((e) => {
        addToast({
          type: 'error',
          msg: `Error setting the score for this response.`,
        });
        throw e;
      });
  };

  const onQuestionDurationError = (msg: string): void => {
    addToast({
      type: 'error',
      msg,
    });
  };

  const onSaveQuestion = async (
    value: number,
    type: 'duration' | 'marks'
  ): Promise<boolean> => {
    let success = false;
    if (!question.assessment_id || !testId || !question.question_id) {
      onQuestionDurationError(
        `Failed to update the ${
          type === 'duration' ? 'duration' : 'marks'
        } for this question.`
      );
      return success;
    }
    if (type === 'duration') value *= 60;
    const updates =
      type === 'duration'
        ? { duration: value }
        : { marks: value, max_score: value };
    await updateQuestionInPart(
      question.assessment_id,
      testId,
      question.id,
      updates
    )
      .then(() => {
        success = true;
        addToast({
          type: 'success',
          msg: `Question ${
            type === 'duration' ? 'duration' : 'marks'
          } updated successfully.`,
        });
        onQuestionUpdate?.(updates);
      })
      .catch((error) => {
        if (error?.response?.data?.errors?.detail) {
          onQuestionDurationError(error.response.data.errors.detail);
        } else {
          onQuestionDurationError(
            `Failed to update the ${
              type === 'duration' ? 'duration' : 'marks'
            } for this question.`
          );
        }
      });
    return success;
  };

  const questionMarksIsUnanswered = useMemo(() => {
    return (
      !question.response_submitted ||
      (question.response?.response === '' && !question.response?.file)
    );
  }, [question.response, question.response_submitted]);

  const marksDisabled = useMemo(() => {
    const isFreeResponseQuestion = !MCQTestTypes.includes(testType);
    const isUnansweredFreeResponseQuestion =
      isFreeResponseQuestion &&
      question.response &&
      !question.response.response &&
      !question.response.file;
    return (
      testType !== 'Interview' &&
      (MCQTestTypes.includes(testType) || isUnansweredFreeResponseQuestion)
    );
  }, [testType, question?.response]);

  useEffect(() => {
    if (question?.background) {
      setQuestionBackground(question?.background);
    } else {
      setQuestionBackground('');
    }
  }, [testType, question?.background]);

  const requiresEvaluation =
    TestsWithEvaluation.includes(testType) &&
    (question?.score === '' || question?.score === null);

  const timeTaken = useMemo(() => {
    if (question?.time_taken) {
      return secondsToHms(question.time_taken);
    } else {
      return '';
    }
  }, [question?.time_taken]);

  const difficultyIconMap = {
    Easy: EasyDifficultyIcon,
    Medium: MediumDifficultyIcon,
    Hard: HardDifficultyIcon,
  };

  const skills = useMemo(() => {
    if (question?.skills) {
      return question.skills;
    }
    return question?.subjects?.map((skill) => ({
      id: skill.id,
      name: skill.subject,
    }));
  }, [question?.subjects, question?.skills]);

  const questionDuration = question.duration || 0;
  const questionDurationEstimate = question.time_estimate || 0;
  const questionDurationIcon = (
    <img src={TimeIcon} alt="Question duration icon" className="mt1 mr2" />
  );
  const questionMarksIcon = (
    <img
      src={QuestionMarksIcon}
      alt="Question marks icon"
      className="mt1 mr2"
    />
  );

  const isAdditionalCriteria =
    question?.question_type === 'Additional Criteria' ||
    question?.question_type?.question_type === 'Additional Criteria';

  const isLikelyAIGeneratedSusActivity = {
    name: 'Likely Generated by AI',
    explanation:
      'This response is likely generated using AI models. This prediction is not perfect: 1 in every 100 predictions is falsely flagged. Please review the response carefully.',
  };
  return (
    <div className="test-question-details">
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          {!isPreview && <h4>QUESTION - {question?.position}</h4>}
          <div className="question-details-heading">
            {!isPreview && (
              <>
                {question?.difficulty && (
                  <div className="question-difficulty">
                    <img
                      width="20px"
                      src={difficultyIconMap[question?.difficulty]}
                      alt="Question difficulty icon"
                      className="mt1 mr2"
                    />
                    {question?.difficulty}
                  </div>
                )}
                {timeTaken && (
                  <div className="question-time">
                    <img
                      src={TimeIcon}
                      alt="Time taken icon"
                      className="mt1 mr2"
                    />
                    {timeTaken}
                  </div>
                )}
              </>
            )}
            <div className="subjects">
              {skills &&
                skills
                  .filter((skill) => skill.name !== question?.topic?.topic)
                  .map((skill) => {
                    return (
                      <span className="subject" key={`${skill.id}`}>
                        {skill.name}
                      </span>
                    );
                  })}
              {question?.topic && (
                <>
                  {question?.topic.topic ? (
                    <span className="subject">{question?.topic.topic}</span>
                  ) : (
                    <span className="subject">{question?.topic}</span>
                  )}
                </>
              )}
              {question?.organisation && (
                <LightTooltip
                  arrow
                  title={`This is a custom question added by a user at ${question.organisation} via the Question Bank. Alooba takes no responsibility for the quality or correctness of the question.`}
                >
                  <span className="subject organisation">
                    {question?.organisation} Question
                  </span>
                </LightTooltip>
              )}
            </div>
            {!isPreview && question?.max_score && question?.max_score !== '0' && (
              <div className="question-marks">
                Awarded Marks:&ensp;
                <QuestionMarks
                  key={question?.test_question_id}
                  value={question?.score}
                  maxScore={question?.max_score}
                  disabled={marksDisabled || disableScoring}
                  onScoreChange={setQuestionMarks}
                  evaluate={requiresEvaluation}
                  testType={testType}
                  unanswered={questionMarksIsUnanswered}
                />
              </div>
            )}
            {isPreview && (
              <div className="question-specs">
                {!isEditable && question.max_score > 0 && (
                  <div className="question-marks">
                    <img
                      src={QuestionMarksIcon}
                      alt="Question marks icon"
                      className="mt1 mr2"
                    />
                    {question.max_score} marks
                  </div>
                )}
                {isEditable && question.max_score > 0 && (
                  <EditableNumber
                    key={question.id}
                    name="Marks"
                    tooltip="Maximum marks"
                    prefixIcon={questionMarksIcon}
                    suffixText="marks"
                    minValue={1}
                    maxValue={10}
                    defaultValue={question.default_max_score}
                    initialValue={question.max_score}
                    onSave={(value) => onSaveQuestion(value, 'marks')}
                    onErrorMsg={onQuestionDurationError}
                  />
                )}
                {!isEditable && !isAdditionalCriteria && (
                  <>
                    {questionDurationEstimate > 0 && (
                      <div className="question-time">
                        <img
                          src={TimeIcon}
                          alt="Question duration icon"
                          className="mt1 mr2"
                        />
                        {Math.round(questionDurationEstimate / 60)} min
                      </div>
                    )}
                  </>
                )}
                {isEditable && !isAdditionalCriteria && (
                  <>
                    {questionDuration > 0 && (
                      <EditableNumber
                        key={question.id}
                        name="Duration"
                        tooltip="Time allocated"
                        prefixIcon={questionDurationIcon}
                        suffixText="mins"
                        minValue={1}
                        maxValue={30}
                        defaultValue={Math.round(questionDurationEstimate / 60)}
                        initialValue={Math.round(questionDuration / 60)}
                        onSave={(value) => onSaveQuestion(value, 'duration')}
                        onErrorMsg={onQuestionDurationError}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {question && question.is_likely_ai_generated && (
            <SuspiciousActivities
              suspiciousActivities={[isLikelyAIGeneratedSusActivity]}
            />
          )}
          {question && (question.question || questionBackground) && (
            <div
              className={`question-content-wrapper ${isMCQ ? 'mcq-test' : ''}`}
            >
              <QuestionBackground
                key={question?.test_question_id}
                background={
                  testType !== 'Data Analysis' ? questionBackground : null
                }
                hasImage={question.has_image}
                question={question.question}
                questionId={question.question_id}
                explanationText={question.explanation_text}
                subjects={question?.subjects}
              />
              {children}
            </div>
          )}
        </>
      )}
    </div>
  );
}
