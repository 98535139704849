import { useCallback } from 'react';

const useDynamicTable = (
  getDataCallback: {
    callback: (data: any) => any;
  },
  paginator?: any,
  currentPage?: number,
  setSearch?: (value: string) => void
): any => {
  const isFirst = currentPage > 1;
  const prevPage = (e: React.SyntheticEvent<EventTarget>): void => {
    e.stopPropagation();
    if (isFirst) {
      getDataCallback.callback({
        page: currentPage - 1,
        requestTime: Date.now(),
      });
    }
  };
  const isLast = currentPage < paginator.last_page;
  const nextPage = useCallback(
    (e: React.SyntheticEvent<EventTarget>): void => {
      e.stopPropagation();
      if (isLast) {
        getDataCallback.callback({
          page: currentPage + 1,
          requestTime: Date.now(),
        });
      }
    },
    [currentPage, getDataCallback, isLast]
  );
  const filterSearch = useCallback(
    (value: string): void => {
      setSearch(value);
      getDataCallback.callback({
        searchTerm: value,
        page: 1,
        requestTime: Date.now(),
      });
    },
    [getDataCallback, setSearch]
  );
  const changePerPage = useCallback(
    (data: any): void => {
      getDataCallback.callback({
        perPage: data.value,
        page: 1,
        requestTime: Date.now(),
      });
    },
    [getDataCallback]
  );
  const sortingAction = useCallback(
    (data: any): void => {
      getDataCallback.callback({
        orderBy: data.field,
        orderDirection: data.direction,
        requestTime: Date.now(),
      });
    },
    [getDataCallback]
  );

  const tablePaginatorProps = {
    paginator,
    prevPage,
    nextPage,
    changePerPage,
    isFirst,
    isLast,
  };

  return {
    paginator,
    currentPage,
    isFirst,
    isLast,
    prevPage,
    nextPage,
    filterSearch,
    changePerPage,
    sortingAction,
    tablePaginatorProps,
  };
};

export default useDynamicTable;
