export const blue = '#004385';
export const dark = '#0e0e2c';
export const success = '#03cea4';
export const link = '#004385';
export const grey = '#4a4a68';
export const orange = '#f68c3e';
export const disabled = '#8c8ca1';
export const slate = '#8c8ca1';
export const error = '#d62828';
export const mainBackground = '#ecf1f4';
export const hover = '#fafafa';
export const line = '#dedfe1';
