import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goToLegacyApp } from 'hooks';
import { RootState } from 'store/rootReducer';
import { useHistory } from 'react-router-dom';
import { fetchTemplates } from 'store/actions/template.actions';
import { Button } from 'components/Shared';
import { Modal } from 'components/Shared/Modal';
import { setTemplates } from 'store/reducers/template';
import { getFormattedAssessmentName, emptyJwt } from 'helpers';
import jwt_decode from 'jwt-decode';
import { updateCurrentAssessment } from 'store/reducers/assessment';
import {
  createAssessmentFromTemplate,
  deleteAssessment,
  getAssessmentSuggestions,
} from 'api/assessment.api';
import DeleteModal from 'components/Shared/DeleteModal';
import { useToasts } from 'react-toast-notifications';
import { AssessmentPurpose } from 'components/Assessment';
import { getOrgGroups } from 'store/actions/organization.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { hasOrgFeature } from 'Authorization/Check';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import MultiSelectFilter from '../Shared/Filters/MultiSelectFilter';
import TextFilter from '../Shared/Filters/TextFilter';
import { debounce } from '../../helpers/events';
import TemplateCard from './TemplateCard';
import TemplateDetailsModal from './TemplateDetailsModal';
import HeroImage from '../../images/template_hero_image.svg';
import './index.scss';
import SubHeader from '../Shared/SubHeader';
import CreateTemplateModal from './CreateTemplateModal';
import TemplatesLoader from './TemplatesLoader';

interface Props {
  assessmentPurpose: AssessmentPurpose | null;
  header?: string;
  description?: string;
}

const TEST_TYPE_TO_FEATURE = {
  MCQ: 'concepts_test',
  'Data Analysis': 'data_analysis_test',
  SQL: 'sql_test',
  'Analytics Coding': 'coding_test',
  'Free Response': 'free_response_test',
  'Personality Profiling': 'personality_test',
};

const TemplateList: React.FC<Props> = ({
  assessmentPurpose,
  header,
  description,
}) => {
  const { templates, loading } = useSelector(
    (state: RootState) => state.template
  );
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { groups } = useSelector((state: RootState) => state.organization);
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>(null);
  const selectedTemplate = useSelector(
    (state: RootState) =>
      state.template.templates?.filter((t) => t.id === selectedTemplateId)[0]
  );
  const [creatingFromTemplateId, setCreatingFromTemplateId] = useState<
    number | null
  >(null);
  const history = useHistory();
  const [detailsModalVisibility, setDetailsModalVisibility] = useState<boolean>(
    false
  );
  const [
    createAssessmentModalVisibility,
    setCreateAssessmentModalVisibility,
  ] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [jobTitleOptions, setJobTitleOptions] = useState<any>([]);
  const [textFilter, setTextFilter] = useState<string>('');
  const [jobTitleFilter, setJobTitleFilter] = useState<any>([]);
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const [isPaid, setIsPaid] = useState<boolean>(true);

  const type = assessmentPurpose === 'ld' ? 'Exercise' : 'Assessment';
  const tests = assessmentPurpose === 'ld' ? 'exercises' : 'tests';
  const [
    assessmentGeneratorLoadingText,
    setAssessmentGeneratorLoadingText,
  ] = useState<string>('');
  const [
    showAssessmentGeneratorLoadingModal,
    setShowAssessmentGeneratorLoadingModal,
  ] = useState<boolean>(false);

  useEffect(() => {
    const decoded: { paid: boolean } = jwt_decode(accessToken || emptyJwt);
    setIsPaid(decoded.paid);
  }, [accessToken]);

  useEffect(() => {
    if (groups === null) {
      dispatch(getOrgGroups());
    }
  }, [dispatch, groups]);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  // Initialize templates
  useEffect(() => {
    const jobTitles = {};
    if (templates) {
      templates.forEach((template) => {
        if (template.job_titles) {
          template.job_titles.forEach((item) => {
            jobTitles[item] = true;
          });
        }
      });
    }
    const options = [];
    Object.keys(jobTitles).forEach((jobTitle) => {
      options.push({ id: jobTitle, name: jobTitle });
    });
    options.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    setJobTitleOptions(options);
  }, [templates]);

  const internalTemplate = useMemo(() => {
    if (templates) {
      return templates.find((template) => template.is_internal);
    }
    return null;
  }, [templates]);

  // Filter templates
  const filteredTemplates = useMemo(() => {
    if (templates) {
      let newFilteredTemplates = [...templates].filter(
        (template) => !template.is_internal
      );
      if (textFilter) {
        textFilter
          .toLowerCase()
          .split(' ')
          .forEach((filterWord) => {
            newFilteredTemplates = newFilteredTemplates.filter((template) => {
              return template.name.toLowerCase().includes(filterWord);
            });
          });
      }
      if (jobTitleFilter && jobTitleFilter.length > 0) {
        newFilteredTemplates = newFilteredTemplates.filter((template) => {
          return (
            template.job_titles.filter((templateJobTitle) =>
              jobTitleFilter.includes(templateJobTitle)
            ).length > 0
          );
        });
      }
      return newFilteredTemplates;
    }
    return [];
  }, [templates, textFilter, jobTitleFilter]);

  const canUseTemplate = (template): boolean => {
    const partNames = template.parts.map((part) => part.type);
    for (let i = 0; i < partNames.length; i += 1) {
      const partName = partNames[i];
      const feature = TEST_TYPE_TO_FEATURE[partName];
      if (!hasOrgFeature(feature)) {
        return false;
      }
    }
    return true;
  };

  const handleDelete = async (): Promise<void> => {
    const remainingTemplates = templates.filter(
      (template) => template.id !== selectedTemplate.id
    );
    setDeleting(true);
    try {
      await deleteAssessment(selectedTemplate.assessment_id);

      setShowDeleteModal(false);
      setDeleting(false);
      addToast({
        type: 'success',
        msg: `Assessment template with the name ${selectedTemplate.name} has been deleted!`,
      });
      dispatch(setTemplates(remainingTemplates));
    } catch (error) {
      setShowDeleteModal(false);
      setDeleting(false);
      addToast({
        type: 'error',
        msg: `Error deleting assessment template with the name ${selectedTemplate.name}.`,
      });
    }
  };

  const showCreateModal = (e, template): void => {
    if (!canUseTemplate(template) && !template.is_sample) {
      setSelectedTemplateId(template.id);
      setDetailsModalVisibility(false);
      setShowUpgradeModal(true);
      return;
    }
    setSelectedTemplateId(template.id);
    setDetailsModalVisibility(false);
    setCreateAssessmentModalVisibility(true);
  };

  const handleDeleteModal = (e, template): void => {
    e.preventDefault();
    setShowDeleteModal(true);
    setSelectedTemplateId(template.id);
  };

  // display a modal of the recently created template (grab its id from the query param)
  useEffect(() => {
    const template_id = new URLSearchParams(window.location.search).get(
      'template_id'
    );
    if (template_id && filteredTemplates && !selectedTemplateId) {
      const targetTemplate = filteredTemplates.filter(
        (template) => template.id === parseInt(template_id, 10)
      )[0];
      if (targetTemplate) {
        setSelectedTemplateId(targetTemplate.id);
        setDetailsModalVisibility(true);
      }
    }
  }, [filteredTemplates, selectedTemplateId]);

  const onFilterChange = (data): void => {
    if (data.textFilter !== undefined) {
      setTextFilter(data.textFilter);
    }
    if (data.jobTitleFilter !== undefined) {
      setJobTitleFilter(data.jobTitleFilter);
    }
  };

  const handleCreateFromTemplateClick = async (
    e,
    template,
    customizedFields: {
      assessment_name: string;
      role_summary?: string;
      role_title: string;
      group_id: string;
      location: any;
      tests?: any[];
    }
  ): Promise<void> => {
    setCreatingFromTemplateId(template.id);

    let suggestions = null;
    if (template.is_internal) {
      setShowAssessmentGeneratorLoadingModal(true);
      setCreateAssessmentModalVisibility(false);
      setAssessmentGeneratorLoadingText('Analyzing role description');
      setTimeout(
        () => setAssessmentGeneratorLoadingText('Extracting skills'),
        5000
      );

      setTimeout(
        () =>
          setAssessmentGeneratorLoadingText(
            'Generating optimal test configuration'
          ),
        10000
      );

      setTimeout(
        () =>
          setAssessmentGeneratorLoadingText(' Selecting relevant questions'),
        15000
      );

      setTimeout(
        () => setAssessmentGeneratorLoadingText('Finalizing assessment'),
        20000
      );
      const { role_summary } = customizedFields;
      const suggestionsResponse = await getAssessmentSuggestions({
        ...customizedFields,
        role_summary,
      });
      suggestions = suggestionsResponse.data;
      if (!suggestions) {
        addToast({
          type: 'error',
          msg:
            'Cannot generate an assessment for this role. Try adding a shorter summary for the role',
        });
        setShowAssessmentGeneratorLoadingModal(false);
        setCreatingFromTemplateId(null);
        return;
      }
      customizedFields.tests = suggestions.tests;
    }
    createAssessmentFromTemplate({
      ...customizedFields,
      template_id: template.id,
      purpose: assessmentPurpose,
    })
      .then((res) => {
        dispatch(updateCurrentAssessment({ assessment: {} }));
        addToast({
          type: 'success',
          msg: `Assessment "${res.data.recruiter_test_name}" was created successfully.`,
        });
        history.push(
          `/assessment/${res.data?.id}-${getFormattedAssessmentName(
            res.data?.recruiter_test_name
          )}/overview`
        );
      })
      .catch((e) => {
        addToast({
          type: 'error',
          msg: e.response?.data?.message
            ? e.response?.data?.message
            : `There was an error creating an assessment from the template ${template.name}`,
        });
      })
      .finally(() => {
        setCreatingFromTemplateId(null);
        setShowAssessmentGeneratorLoadingModal(false);
      });
  };

  const handleCreateCustomTemplateClick = (e): void => {
    if (!isPaid) {
      e.preventDefault();
      window.open(
        `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/request-demo`,
        'noopener'
      );
      return;
    }
    goToLegacyApp(
      e,
      `create-assessment-customized-content?purpose=${assessmentPurpose}&is_template=true`
    );
  };

  const deleteModalBody = (
    <>
      <p>
        {`Are you sure you want to delete the assessment template called "${selectedTemplate?.name}"?`}
      </p>
      <p>This action is not reversible.</p>
    </>
  );

  const deleteModalProps = {
    loadingTxt: 'Deleting Template...',
    loading: deleting,
    isShown: showDeleteModal,
    actionText: 'Delete Template',
    handleButtonAction: handleDelete,
    setModalVisibility: setShowDeleteModal,
    title: 'Delete Assessment Template',
    body: deleteModalBody,
    showCancel: true,
  };

  return (
    <div>
      <SubHeader>
        <div className="page-title">Create {type}</div>
      </SubHeader>
      <div className="full-width-container">
        <div className="main-container template-intro">
          <div className="template-hero-image">
            <img src={HeroImage} alt="templates" />
          </div>
          <div className="template-intro-text">
            Choose from one of our {type.toLowerCase()} templates below and get
            started in seconds or create your own customized{' '}
            {type.toLowerCase()}.
            <Button
              onClick={(e) => {
                goToLegacyApp(
                  e,
                  `create-assessment-customized-content?purpose=${assessmentPurpose}`
                );
              }}
              variant="primary lg"
              text={`Create Custom ${type}`}
            />
            {internalTemplate && assessmentPurpose !== 'ld' && (
              <Button
                badgeText="Beta"
                onClick={(e) => {
                  showCreateModal(e, internalTemplate);
                }}
                id="generate-assessment"
                data-testid="generate-assessment"
                variant="sub-primary lg"
                text={`Generate ${type}`}
              />
            )}
          </div>
        </div>
        {header && <h3 className="mb4">{header}</h3>}
        {description && <p>{description}</p>}
        {!loading && Array.isArray(templates) ? (
          <>
            <div className="template-filters">
              <TextFilter
                key="search"
                onChange={debounce(onFilterChange, 100)}
                label="Assessment Template Name"
                name="textFilter"
                canHide={false}
                hide={false}
                visible
              />
              <MultiSelectFilter
                key="job-title-filter"
                onChange={onFilterChange}
                label="Job Title"
                name="jobTitleFilter"
                multiple
                options={jobTitleOptions}
                defaultValues={[]}
                optionKey="id"
                optionLabel="name"
                defaultToNull
                canHide={false}
                hide={false}
                visible
                selectClassName="job-title-filter"
              />
            </div>
            {filteredTemplates.length === 0 && (
              <h4 className="empty-template-filter-results">
                There are no assessment templates matching the current filter
              </h4>
            )}
            <div className="templates">
              {filteredTemplates.map((template) => {
                return (
                  <TemplateCard
                    showDeleteModal={handleDeleteModal}
                    key={`template-${template.id}`}
                    type={type}
                    template={template}
                    creatingAssessment={creatingFromTemplateId === template.id}
                    createAction={showCreateModal}
                    isPaid={isPaid}
                    viewDetailsAction={() => {
                      setSelectedTemplateId(template.id);
                      setDetailsModalVisibility(true);
                    }}
                  />
                );
              })}
            </div>
            <div className="create-template">
              <Button
                onClick={handleCreateCustomTemplateClick}
                variant="sub-primary lg"
                text={
                  isPaid
                    ? 'Create Your Own Template'
                    : 'Upgrade To Create Your Own Templates'
                }
              />
            </div>
          </>
        ) : (
          <TemplatesLoader />
        )}
        <TemplateDetailsModal
          isShown={detailsModalVisibility}
          setModalVisibility={setDetailsModalVisibility}
          template={selectedTemplate}
          createAction={showCreateModal}
        />
        <Modal
          isShown={showAssessmentGeneratorLoadingModal}
          showCancel={false}
          containerClass="generating-assessment-modal"
        >
          <div className="generating-assessment-modal-content">
            <h2>{assessmentGeneratorLoadingText}</h2>
            <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            <p>Please do not close this tab.</p>
          </div>
        </Modal>
        <CreateTemplateModal
          isShown={createAssessmentModalVisibility}
          forAssessmentGenerator={selectedTemplate?.is_internal}
          setModalVisibility={setCreateAssessmentModalVisibility}
          template={selectedTemplate}
          createAction={handleCreateFromTemplateClick}
          purpose={assessmentPurpose}
          loading={
            creatingFromTemplateId !== null &&
            creatingFromTemplateId === selectedTemplate?.id
          }
          disabled={
            creatingFromTemplateId !== null &&
            creatingFromTemplateId === selectedTemplate?.id
          }
          loadingTxt="Creating Assessment..."
          groups={groups}
        />
        <DeleteModal {...deleteModalProps} />
        <PlanUpgradeModal
          isShown={showUpgradeModal}
          setModalVisibility={setShowUpgradeModal}
          title={`Upgrade your account to create an ${type.toLowerCase()} from the
              template "${selectedTemplate?.name}"`}
        >
          <div>
            <p>
              This template includes {tests} that are not included on your
              current subscription plan. <br />
              Upgrade your subscription to access this template and many other
              amazing features Alooba has to offer.
            </p>
          </div>
        </PlanUpgradeModal>
      </div>
    </div>
  );
};

export default TemplateList;
