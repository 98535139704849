import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import { useHistory } from 'react-router-dom';
import StickyTableCellLeft from './StickyTableCellLeft';
import StickyTableCellRight from './StickyTableCellRight';

const DynamicTableCell = ({
  row,
  field,
  rowIndex,
  cellIndex,
  hasSticky,
  getPath,
  fieldScroll,
  processCell,
  openInNewTab,
  onClickRow = null,
}): any => {
  const history = useHistory();
  const clickable = onClickRow != null || getPath != null;
  const handleClick = (e): void => {
    if (!clickable) return;
    if (!openInNewTab) {
      e.preventDefault();
      e.stopPropagation();
      history.push(getPath(row));
    }
    if (onClickRow) {
      onClickRow(row);
    }
  };

  if (field.hidden) {
    return <></>;
  }
  if (field.sticky && field.sticky === 'left') {
    hasSticky.current = true;
    return (
      <StickyTableCellLeft
        key={`${field.label}-${rowIndex}-${cellIndex}`}
        className={`${
          field.label
        } dynamic-table-cell sticky-left sticky ${fieldScroll(field)}`}
      >
        <a
          href={getPath ? getPath(row) : null}
          target={openInNewTab ? '_blank' : '_self'}
          onClick={(e) => handleClick(e)}
          rel="noreferrer"
        >
          {processCell(row, field)}
        </a>
      </StickyTableCellLeft>
    );
  }
  if (field.sticky && field.sticky === 'right') {
    hasSticky.current = true;
    return (
      <StickyTableCellRight
        key={`${field.label}-${rowIndex}-${cellIndex}`}
        className={`${
          field.label
        } dynamic-table-cell sticky-right sticky ${fieldScroll(field)}`}
      >
        <a
          href={getPath ? getPath(row) : null}
          target={openInNewTab ? '_blank' : '_self'}
          onClick={(e) => handleClick(e)}
          rel="noreferrer"
        >
          {processCell(row, field)}
        </a>
      </StickyTableCellRight>
    );
  }
  return (
    <TableCell
      className={`${field.label} dynamic-table-cell`}
      key={`${field.label}-${rowIndex}-${cellIndex}`}
    >
      <a
        href={getPath ? getPath(row) : null}
        target={openInNewTab ? '_blank' : '_self'}
        onClick={(e) => handleClick(e)}
        rel="noreferrer"
      >
        {processCell(row, field)}
      </a>
    </TableCell>
  );
};
export default React.memo(DynamicTableCell);
