import AssessmentStatusIcon from 'components/Shared/AssessmentStatusIcon';
import CardContainer from 'components/Shared/MainContainer/CardContainer';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import ScoreIcon from 'images/icons/score.svg';
import WarningIcon from 'images/warning.svg';
import moment from 'moment';
import { selectCandidate } from 'helpers/store';
import { useEffect, useState } from 'react';
import { selectAssessment } from 'store/reducers/assessment';

export interface OtherAssessment {
  reference: string;
  assessment_name: string;
  recruiter_test: any;
  invited_at: string;
  submitted_at: string;
  status: string;
  total_score?: number;
  partial_score?: number;
  verification_code?: string;
}
const OtherAssessments: React.FC<{
  otherAssessments: OtherAssessment[];
  isVerificationCode?: boolean;
}> = ({ otherAssessments, isVerificationCode = false }) => {
  const dispatch = useDispatch();
  const onAssessmentClick = (candidate: OtherAssessment): void => {
    selectCandidate(
      {
        id: 0,
        full_name: '',
        invited_at: '',
        first_name: '',
        last_name: '',
        comments: [],
        reference: candidate.reference,
        verification_code: candidate.verification_code,
        email_address: '',
        status: candidate.status,
        submitted_at: '',
        phone: '',
        hiring_status: '',
        information_fields: [],
        related_assessments: [],
        suspicious_activities: [],
        score: 0,
        score_percent: 0,
      },
      isVerificationCode
    );
    dispatch(selectAssessment({ assessment: candidate.recruiter_test }));
  };

  const [showAll, setShowAll] = useState(false);
  const [displayableAssessments, setDisplayableAssessments] = useState<
    OtherAssessment[]
  >([]);

  const assessmentCloakedCandidates = useSelector(
    (state: RootState) => state.assessment.currentAssessment?.cloak_candidates
  );
  const currentAssessmentPurpose = useSelector(
    (state: RootState) => state.assessment.currentAssessment?.purpose
  );

  useEffect(() => {
    if (showAll) {
      setDisplayableAssessments(otherAssessments);
    } else {
      setDisplayableAssessments(otherAssessments.slice(0, 6));
    }
  }, [otherAssessments, showAll]);

  return (
    <>
      <h2 className="mt6">
        {currentAssessmentPurpose === 'ld'
          ? "Employee's Other Exercises"
          : "Candidate's Other Assessments"}
      </h2>
      <div className="other-assessment-cards-container mt3">
        <div className="other-assessment-cards">
          {displayableAssessments.map((assessment) => {
            return (
              <CardContainer key={assessment.reference}>
                {assessment.status === 'In Progress' ? (
                  <img
                    className="personal-info-icon warning-icon"
                    src={WarningIcon}
                    alt="warning icon"
                  />
                ) : (
                  ''
                )}
                <div className="main-card-content">
                  {assessmentCloakedCandidates ? (
                    <span className="other-assessment-title">
                      {assessment.assessment_name}
                    </span>
                  ) : (
                    <Link
                      to={
                        isVerificationCode
                          ? `/test-completed/${assessment.verification_code}/overview`
                          : `/candidate-details/${assessment.reference}/overview`
                      }
                      onClick={() => {
                        return onAssessmentClick(assessment);
                      }}
                      className="other-assessment-title"
                    >
                      {assessment.assessment_name}
                    </Link>
                  )}
                  <div className="grid cols-md-2 cols-sm-1 mt2">
                    {assessment.invited_at ? (
                      <div>
                        <div className="text-slate">Invited On</div>
                        <div className="invited-date">
                          {moment(assessment.invited_at).format(
                            'MMMM Do YYYY h:mm A'
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {assessment.submitted_at ? (
                      <div>
                        <div className="text-slate">
                          Assessment Submitted On
                        </div>
                        <div className="submitted-date">
                          {moment(assessment.submitted_at).format(
                            'MMMM Do YYYY h:mm A'
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="card-footer grid cols-md-2 cols-sm-2">
                  <div>
                    <div className="text-slate">Status</div>
                    <div>
                      <AssessmentStatusIcon status={assessment.status} />
                      {assessment.status}
                    </div>
                  </div>
                  <div>
                    {assessment.total_score ||
                    assessment.total_score === 0.0 ? (
                      <>
                        <div className="text-slate">Total Score</div>
                        <div className="total-score">
                          <img
                            className="personal-info-icon"
                            src={ScoreIcon}
                            alt="score icon"
                          />
                          &nbsp;
                          {Math.round(assessment.total_score)}%
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    {assessment.partial_score ||
                    assessment.partial_score === 0.0 ? (
                      <>
                        <div className="text-slate">Partial Score</div>
                        <div className="partial-score">
                          <img
                            className="personal-info-icon"
                            src={ScoreIcon}
                            alt="score icon"
                          />
                          &nbsp; {assessment.partial_score}%
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </CardContainer>
            );
          })}
        </div>
        {!showAll && otherAssessments.length > 6 ? (
          <div className="paginator mt5">
            <button type="button" onClick={() => setShowAll(true)}>
              Load More
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default OtherAssessments;
