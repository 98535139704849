import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import './button.scss';

interface Props {
  text?: string;
  onClick?: any;
  variant?: string;
  placement?: 'before' | 'after';
  icon?: string;
  className?: string;
  loadingPlaceholder?: boolean;
  loading?: boolean;
  loadingTxt?: string;
}

const TextButton = ({
  text,
  onClick,
  icon,
  variant,
  placement = 'after',
  className,
  loadingPlaceholder,
  loading,
  loadingTxt,
}: Props): JSX.Element => {
  const internal = (): React.ReactChild => {
    if (loadingPlaceholder) {
      return <Skeleton width={100} height={34} />;
    }
    if (loading) {
      return (
        <span>
          <span className="pr5">{loadingTxt || 'Loading...'}</span>
          <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
        </span>
      );
    }
    return <span className={`text-button-internal ${variant}`}>{text}</span>;
  };
  const handleClick = (e): void => {
    if (!loading) {
      if (onClick) onClick(e);
    }
  };
  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={handleClick}
      className={`text-button ${className} ${loading ? 'loading' : ''}`}
    >
      {placement === 'before' && icon && <img src={icon} alt="text" />}
      {internal()}
      {placement === 'after' && icon && <img src={icon} alt="text" />}
    </div>
  );
};

export default TextButton;
