import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import Button from '../Shared/Button';
import './CreditUsage.scss';

const CreditUsage: React.FC = () => {
  const creditUsage = useSelector(
    (state: RootState) => state.subscription?.creditUsage
  );

  const creditUsagePercentage = (): number => {
    if (creditUsage) {
      return (
        (creditUsage.subscription_credits_used /
          creditUsage.subscription_credits) *
        100
      );
    }
    return 0;
  };

  return (
    <div>
      {creditUsage && (
        <div className="credit-usage subscription main-container">
          <div className="details">
            <h3>Credits</h3>
            <div className="credits-used-section">
              <p className="credits-used-title">Subscription Credits Used</p>
              <p>
                <span
                  className={`${
                    creditUsage.subscription_credits_used >=
                    creditUsage.subscription_credits
                      ? 'danger-text'
                      : ''
                  } credit-number`}
                >
                  {creditUsage.subscription_credits_used.toLocaleString()}
                </span>{' '}
                of{' '}
                <span className="credit-number">
                  {creditUsage.subscription_credits.toLocaleString()}
                </span>
              </p>
            </div>
            <div className="progress-bar">
              <span
                className="progress-fill"
                style={{ width: `${creditUsagePercentage()}%` }}
              />
            </div>
            <p className="remaining-credits">
              {(
                creditUsage.subscription_credits -
                creditUsage.subscription_credits_used
              ).toLocaleString()}{' '}
              subscription credits remaining for the current period.
            </p>
            <p className="additional-credits-available">
              {creditUsage.remaining_additional_credits >= 0 ? (
                <div>
                  {creditUsage.remaining_additional_credits.toLocaleString()}{' '}
                  additional credits available.
                </div>
              ) : (
                <div className="danger-text">
                  You have exceeded your available credits by{' '}
                  {(-creditUsage.remaining_additional_credits).toLocaleString()}
                  .
                </div>
              )}
            </p>
          </div>
          <div className="action">
            <Button
              onClick={() => window.open(`https://www.alooba.com/credits/`)}
              text="Learn More About Credits"
              variant="primary md"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditUsage;
