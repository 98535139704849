import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationDetails } from '../../api/profile.api';
import { SamlConfiguration, SamlDomain } from '../../api/organization.api';
import { ValidationErrors } from '../../api';

export interface SamlState {
  samlConfig: SamlConfiguration;
  samlConfigLoading: boolean;
  error: string;
  samlValidationErrors: ValidationErrors;
  domains: SamlDomain[];
  domainsLoading: boolean;
  domainValidationErrors: ValidationErrors;
}

interface OrganizationState {
  organizationDetails: OrganizationDetails;
  groups: any;
  loading: boolean;
  groupsLoading: boolean;
  colorBrandUpdating: boolean;
  error: string | null;
  sso: SamlState;
}

const initialState: OrganizationState = {
  organizationDetails: {
    id: 0,
    name: '',
    website: '',
    industry: {},
    size_employees: '',
    is_recruitment_company: 0,
    cloak_candidates: null,
    organisation_logo_url: null,
    ai_candidate_feedback: false,
  },
  groups: null,
  loading: false,
  groupsLoading: true,
  colorBrandUpdating: false,
  error: null,
  sso: {
    samlConfig: null,
    samlConfigLoading: false,
    error: null,
    samlValidationErrors: {},
    domains: [],
    domainsLoading: false,
    domainValidationErrors: {},
  },
};

const organizationReducer = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    getOrgStart: (state) => {
      if (state.organizationDetails?.name === '') state.loading = true;
      state.error = null;
    },
    getOrgSuccess: (state, action: PayloadAction<OrganizationDetails>) => {
      state.organizationDetails = action.payload;
      state.loading = false;
      state.colorBrandUpdating = false;
    },
    getOrgFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.colorBrandUpdating = false;
    },
    getGroupsStart: (state) => {
      state.groupsLoading = true;
    },
    getGroupsSuccess: (state, action: PayloadAction<{ data: any[] }>) => {
      state.groups = action.payload.data.reduce(
        (obj, item: { id: number; name: string }) => {
          return {
            ...obj,
            [item.id]: item.name,
          };
        },
        {}
      );
      state.groupsLoading = false;
    },
    getGroupsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.groupsLoading = false;
    },
    changeCloakingSuccess(state, action: PayloadAction<{ checked: boolean }>) {
      state.organizationDetails.cloak_candidates = action.payload.checked;
    },
    changeAiCandidateFeedbackSuccess(
      state,
      action: PayloadAction<{ checked: boolean }>
    ) {
      state.organizationDetails.ai_candidate_feedback = action.payload.checked;
    },
    setOrgColorUpdating(state, action: PayloadAction<boolean>) {
      state.colorBrandUpdating = action.payload;
    },
    getOrgSSOStart(state) {
      state.sso.samlConfigLoading = true;
      state.sso.error = null;
      state.sso.samlValidationErrors = {};
    },
    getOrgSSOSuccess(state, action: PayloadAction<SamlConfiguration>) {
      state.sso.samlConfig = action.payload;
      state.sso.samlConfigLoading = false;
      state.sso.samlValidationErrors = {};
    },
    getOrgSSOFailure(state, action: PayloadAction<string>) {
      state.sso.samlConfigLoading = false;
      state.sso.error = action.payload;
    },
    updateSamlFailure(
      state,
      action: PayloadAction<{ message: string; errors: ValidationErrors }>
    ) {
      state.sso.samlConfigLoading = false;
      state.sso.error = action.payload.message;
      state.sso.samlValidationErrors = action.payload.errors;
    },
    clearSSOError(state) {
      state.sso.error = null;
    },
    ssoDomainsStart(state) {
      state.sso.domainsLoading = true;
      state.sso.domainValidationErrors = {};
    },
    ssoDomainsEnd(state, action: PayloadAction<SamlDomain[]>) {
      state.sso.domainsLoading = false;
      state.sso.domains = action.payload;
      state.sso.domainValidationErrors = {};
    },
    ssoDomainsFailure(state) {
      state.sso.domainsLoading = false;
    },
    addDomainEnd(state, action: PayloadAction<SamlDomain>) {
      state.sso.domainsLoading = false;
      state.sso.domains.push(action.payload);
    },
    addDomainFailure(state, action: PayloadAction<ValidationErrors>) {
      state.sso.domainsLoading = false;
      state.sso.domainValidationErrors = action.payload;
    },
    editDomainStart(state) {
      state.sso.domainsLoading = true;
      state.sso.domainValidationErrors = {};
    },
    editDomainEnd(state, action: PayloadAction<SamlDomain>) {
      state.sso.domainsLoading = false;
      const { domains } = state.sso;
      // find the index of the domain to be replaced
      const i = domains.findIndex((d) => d.domain === action.payload.domain);
      domains[i] = action.payload;
      state.sso.domains = domains;
    },
    editDomainFailure(state, action: PayloadAction<ValidationErrors>) {
      state.sso.domainsLoading = false;
      state.sso.domainValidationErrors = action.payload;
    },
    deleteDomainStart(state) {
      state.sso.domainsLoading = true;
      state.sso.domainValidationErrors = {};
    },
    deleteDomainEnd(state, action: PayloadAction<SamlDomain>) {
      state.sso.domainsLoading = false;
      const { domains } = state.sso;
      // find the index of the domain to be replaced
      const i = domains.findIndex((d) => d.domain === action.payload.domain);
      if (i !== -1) domains.splice(i, 1);
      state.sso.domains = domains;
    },
    deleteDomainFailure(state, action: PayloadAction<ValidationErrors>) {
      state.sso.domainsLoading = false;
      state.sso.domainValidationErrors = action.payload;
    },
  },
});

export const {
  getGroupsStart,
  getGroupsSuccess,
  getGroupsFailure,
  getOrgFailure,
  getOrgSuccess,
  getOrgStart,
  setOrgColorUpdating,
  changeCloakingSuccess,
  changeAiCandidateFeedbackSuccess,
  getOrgSSOStart,
  getOrgSSOSuccess,
  getOrgSSOFailure,
  updateSamlFailure,
  clearSSOError,
  ssoDomainsStart,
  ssoDomainsEnd,
  ssoDomainsFailure,
  addDomainEnd,
  addDomainFailure,
  editDomainStart,
  editDomainEnd,
  editDomainFailure,
  deleteDomainStart,
  deleteDomainEnd,
  deleteDomainFailure,
} = organizationReducer.actions;
export default organizationReducer.reducer;
