import crypto from 'crypto';
import { AssessmentPurpose } from 'components/Assessment';
import { handleError } from 'handleError';
import { AppThunk } from '../store';
import {
  getAssessments,
  AssessmentPayload,
  getAssessmentHiringStatuses,
} from '../../api/assessment.api';
import {
  getAssessmentsStart,
  getAssessmentsSuccess,
  getAssessmentsFailure,
  appendAssessmentsStart,
  appendAssessmentsSuccess,
  appendAssessmentsFailure,
  getHiringStatusesSuccess,
  selectAssessment,
} from '../reducers/assessment';

import {
  updateParticipantsQueryParams,
  setParticipantsPaginator,
} from '../reducers/assessmentCandidate';

export const getOrgAssessments = (
  payload: AssessmentPayload,
  purpose: AssessmentPurpose
): AppThunk => async (dispatch) => {
  const isFiltering = payload.groups?.length || payload.searchTerm != null;
  dispatch(getAssessmentsStart({ purpose, isFiltering }));
  getAssessments(payload, purpose)
    .then((data) => {
      const firstPage = data.data.data.data;
      const firstPageHash = crypto
        .createHash('sha256')
        .update(JSON.stringify(firstPage))
        .digest('hex');
      dispatch(
        getAssessmentsSuccess({
          response: data.data.data,
          purpose,
          firstPageHash,
        })
      );
    })
    .catch((err) => {
      dispatch(getAssessmentsFailure({ message: err.message, purpose }));
      handleError(Object.assign(err, { stack: new Error().stack }));
    });
};

export const appendOrgAssessments = (
  payload: AssessmentPayload,
  purpose: AssessmentPurpose
): AppThunk => async (dispatch) => {
  dispatch(appendAssessmentsStart({ purpose }));
  getAssessments(payload, purpose)
    .then((data) => {
      dispatch(appendAssessmentsSuccess({ response: data.data.data, purpose }));
    })
    .catch((err) => {
      dispatch(appendAssessmentsFailure({ message: err.message, purpose }));
      handleError(Object.assign(err, { stack: new Error().stack }));
    });
};

export const getHiringStatuses = (): AppThunk => async (dispatch) => {
  return getAssessmentHiringStatuses().then((response) => {
    dispatch(getHiringStatusesSuccess(response.data.data));
  });
};

export const setCurrentAssessment = (assessment): AppThunk => async (
  dispatch
) => {
  dispatch(selectAssessment({ assessment }));
  dispatch(updateParticipantsQueryParams({ assessmentId: assessment.id }));
  dispatch(setParticipantsPaginator({ data: [] }));
};
