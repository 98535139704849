import React, { useState, useEffect } from 'react';
import './index.scss';
import SelfAwarenessChart from './SelfAwarenessChart';
import SelfAwarenessTable from './SelfAwarenessTable';

export interface SkillPercentiles {
  shortLabel: string;
  label: string;
  selfRating: number;
  selfAwareness: string;
  scorePercentile: number;
}

export interface SelfAwarenessData extends SkillPercentiles {
  isHighlighted: boolean;
  accuracy: Accuracy;
}

// eslint bug workaround
// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
export enum Accuracy {
  Overestimate = 'overestimate',
  Underestimate = 'underestimate',
  Accurate = 'accurate',
}

const SelfAwareness: React.FC<{
  skills: SkillPercentiles[];
  loading: boolean;
}> = ({ skills, loading = false }) => {
  const [activeSkill, setActiveSkill] = useState<SelfAwarenessData>(null);
  const [skillsList, setSkillsList] = useState<SelfAwarenessData[]>([]);

  useEffect(() => {
    setSkillsList(
      skills.map((skill: SelfAwarenessData) => {
        let isHighlighted = false;

        if (activeSkill) {
          isHighlighted =
            activeSkill.label === skill.label &&
            activeSkill.shortLabel === skill.shortLabel;
        }

        const selfRating: number = Math.round(skill.selfRating * 100);
        const scorePercentile: number = Math.round(skill.scorePercentile * 100);
        let accuracy = Accuracy.Accurate;
        if (selfRating - scorePercentile > 10) {
          accuracy = Accuracy.Overestimate;
        }

        if (scorePercentile - selfRating > 10) {
          accuracy = Accuracy.Underestimate;
        }
        return {
          ...skill,
          isHighlighted,
          selfRating,
          scorePercentile,
          accuracy,
        };
      })
    );
  }, [activeSkill, skills]);

  return (
    <div className="self-awareness-container">
      <div className="chart-container">
        <SelfAwarenessChart
          skills={skillsList}
          onSkillActivate={setActiveSkill}
        />
      </div>
      <div className="table-container">
        <SelfAwarenessTable
          skills={skillsList}
          loading={loading}
          onSkillActivate={setActiveSkill}
        />
      </div>
    </div>
  );
};

export default SelfAwareness;
