import React, { useCallback, useEffect, useRef, useState } from 'react';
import './LiveVideoPlayer.scss';

const LiveVideoPlayer: React.FC<{
  playbackUrl: string;
  CustomPlayer?: any;
  autoPlay?: boolean;
}> = ({ playbackUrl, CustomPlayer, autoPlay = false }) => {
  let player = CustomPlayer;
  if (!player) {
    // @ts-ignore
    const { IVSPlayer } = window;
    player = IVSPlayer;
  }
  const videoElementRef = useRef(null);
  const playerRef = useRef(null);
  const [error, setError] = useState(null);

  const handlePlayerError = useCallback((event): void => {
    let msg = event.message;
    if (['ErrorNotAvailable', 'ErrorNetworkIO'].includes(event.type)) {
      msg = 'No Video Stream Detected.';
    }
    setError({ type: event.type, message: msg });
  }, []);

  const loadVideo = useCallback(() => {
    if (!player?.isPlayerSupported) {
      setError({
        message: 'The current browser does not support streaming videos.',
      });
    }
    // Initialize the player
    if (!playerRef.current && player?.isPlayerSupported) {
      playerRef.current = player?.create();
      playerRef.current?.attachHTMLVideoElement(videoElementRef.current);
      // Attach event listeners
      playerRef.current?.addEventListener(
        player?.PlayerEventType.ERROR,
        handlePlayerError
      );
      // Load the stream
      playerRef.current.load(playbackUrl);
      if (autoPlay) {
        playerRef.current?.play();
      }
    }
  }, [autoPlay, handlePlayerError, playbackUrl, player]);

  useEffect(() => {
    loadVideo();
  }, [loadVideo]);

  return (
    <>
      {error ? (
        <p>{error.message}</p>
      ) : (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          controls
          playsInline
          ref={videoElementRef}
          className="ivs-player"
        />
      )}
    </>
  );
};
export default LiveVideoPlayer;
