import React, { useRef, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import Button from '../Button/index';
import './copylink.scss';

const CopyLink: React.FC<{ value?: string }> = ({ value }) => {
  const [tooltip, setTooltip] = useState<boolean>(false);
  const ref: React.Ref<HTMLInputElement> = useRef(null);

  const copyText = (): void => {
    ref.current.select();
    document.execCommand('copy');
    setTooltip(true);
    setTimeout(() => {
      setTooltip(false);
    }, 2000);
  };
  const selectText = (e): void => {
    e.target.select();
  };

  return (
    <div className="copylink-container">
      <Tooltip
        arrow
        placement="top"
        title="Public link copied to your clipboard"
        open={tooltip}
        disableHoverListener
        disableFocusListener
        disableTouchListener
      >
        <input ref={ref} value={value} onClick={selectText} readOnly />
      </Tooltip>
      <Button text="Copy" variant="sub-primary lg" onClick={copyText} />
    </div>
  );
};

export default CopyLink;
