import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { useToasts } from 'react-toast-notifications';
import { RootState } from '../../store/rootReducer';
import Input from '../Shared/Input';
import {
  getCurrentTokens,
  sendResetPasswordEmail,
} from '../../store/actions/auth.actions';
import Wrapper from './Wrapper';

const PasswordReset: React.FC = () => {
  const history = useHistory();
  const loginActionText = 'Reset Password';
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loading } = useSelector((state: RootState) => state.auth);
  const { accessToken } = getCurrentTokens();
  if (accessToken && !loading) {
    history.push('/');
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Company email field is required'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      return sendResetPasswordEmail(values.email)
        .then((res) => {
          if (res.data.success) {
            addToast({
              type: 'success',
              msg:
                'We have just sent you an email. Please click the link in it to reset your password. If you can’t see the email, please wait a few minutes and check your junk mail.',
            });
          } else {
            addToast({
              type: 'error',
              msg: res.data.message || 'Something went wrong.',
            });
          }
        })
        .catch(() => {
          addToast({
            type: 'error',
            msg: `Something went wrong.`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
  return (
    <LoadingOverlay active={isLoading} spinner={<BounceLoader color="gray" />}>
      <Wrapper
        heading="Reset Password"
        text=""
        action=""
        actionLink="get-started"
        buttonText={loginActionText}
        onFormSubmit={formik.handleSubmit}
      >
        <div id="login_page">
          <Input
            name="email"
            type="email"
            autoFocus
            value={formik.values.email}
            placeholder="Email address"
            label="Email"
            onChange={formik.handleChange}
            errorTxt={formik.touched.email ? formik.errors.email : ''}
          />
          <p className="mt2">
            If you’re experiencing issues resetting your password please{' '}
            <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}/contact-us`}>
              contact support
            </a>
            .
          </p>
        </div>
      </Wrapper>
    </LoadingOverlay>
  );
};
export default PasswordReset;
