import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SKILLS = [
  {
    height: 22,
    width: '40%',
  },
  {
    height: 22,
    width: '50%',
  },
  {
    height: 22,
    width: '40%',
  },
  {
    height: 22,
    width: '50%',
  },
  {
    height: 22,
    width: '25%',
  },
  {
    height: 22,
    width: '40%',
  },
  {
    height: 22,
    width: '40%',
  },
  {
    height: 22,
    width: '40%',
  },
];
const TemplatesLoader = (): JSX.Element => {
  return (
    <div>
      <div className="template-filters skeleton">
        <Skeleton height={50} width={400} />
      </div>
      <div className="templates skeleton">
        {[1, 2, 3, 4, 5].map((item) => (
          <div className="template" key={item}>
            <div className="main-card-content">
              <div className="mb2">
                <Skeleton height={40} width="70%" />
              </div>
              <div className="parts">
                <div className="part">
                  <Skeleton height={50} width="100%" />
                  <div className="skills">
                    <div className="skill">
                      {SKILLS.map((skill, index) => (
                        <Skeleton
                          key={index.toString()}
                          height={skill.height}
                          width={skill.width}
                          style={{ borderRadius: '20px' }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="duration mt1 mb1">
                <Skeleton height={40} width="40%" />
              </div>
            </div>
            <div style={{ justifyContent: 'space-between', display: 'flex' }}>
              <Skeleton height={37} width={120} />
              <Skeleton height={37} width={120} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplatesLoader;
