import React from 'react';
import './index.scss';
import { Subjects } from 'store/reducers/candidate';
import CircleProgressBar from '../../../Shared/CircleProgressBar';

interface Props {
  subject: Subjects;
  index: number;
  onClick: (index: number) => void;
  active: boolean;
}

const SubjectScore: React.FC<Props> = ({ subject, index, onClick, active }) => {
  return (
    <button
      type="button"
      key={subject.id}
      onClick={() => onClick(index)}
      className={`category-section subject-score ${active ? 'active' : ''}`}
    >
      <span>{subject.subject}</span>
      <span className="line" />
      {subject.id !== 0 && (
        <CircleProgressBar
          strokeWidth={3}
          size={48}
          percent={
            subject.requires_evaluation
              ? 0
              : (subject.score / subject.max_score) * 100
          }
        >
          <div className="scored-percent">
            {subject.requires_evaluation ? '?' : subject.score} /{' '}
            {subject.max_score}
          </div>
        </CircleProgressBar>
      )}
    </button>
  );
};
export default SubjectScore;
