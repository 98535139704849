import React, { useState } from 'react';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { useToasts } from 'react-toast-notifications';
import { RootState } from '../../store/rootReducer';
import Input from '../Shared/Input';
import { activate, getCurrentTokens } from '../../store/actions/auth.actions';
import Wrapper from './Wrapper';

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}
const RecruitersInvitation: React.FC = () => {
  const history = useHistory();
  const { activationCode }: { activationCode: string } = useParams();
  const loginActionText = 'Save New Password';
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const query = useQuery();
  const isForgetPassword = query.get('forget');
  const email = query.get('email');

  const { loading } = useSelector((state: RootState) => state.auth);
  const { accessToken } = getCurrentTokens();

  if (accessToken && !loading) {
    history.push('/');
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('The password field is required').min(8),
      password_confirmation: Yup.string()
        .required('The password confirmation field is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const formattedEmail = email?.replace(/\s/g, '+');
      return activate(activationCode, values.password, formattedEmail)
        .then((res) => {
          if (res.data.alias) {
            history.replace(
              `/sign-up?alias=${res.data.alias}&redirect=alooba-assess`
            );
          } else {
            addToast({
              type: 'error',
              msg: res.data.message || 'Something went wrong.',
            });
          }
        })
        .catch(() => {
          addToast({
            type: 'error',
            msg: `Something went wrong.`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <LoadingOverlay active={isLoading} spinner={<BounceLoader color="gray" />}>
      <Wrapper
        heading={
          isForgetPassword ? 'Reset Password' : 'Please Create A Password'
        }
        text=""
        action=""
        actionLink="get-started"
        buttonText={loginActionText}
        onFormSubmit={formik.handleSubmit}
      >
        <div id="login_page">
          <Input
            id="password"
            name="password"
            autoFocus
            type="password"
            value={formik.values.password}
            placeholder="Enter Password"
            label="Password"
            onChange={formik.handleChange}
            errorTxt={formik.touched.password ? formik.errors.password : ''}
            autoComplete="off"
          />
          <Input
            id="password_confirmation"
            name="password_confirmation"
            type="password"
            value={formik.values.password_confirmation}
            placeholder="Enter Password Confirmation"
            label="Password Confirmation"
            onChange={formik.handleChange}
            errorTxt={
              formik.touched.password_confirmation
                ? formik.errors.password_confirmation
                : ''
            }
            autoComplete="off"
          />
        </div>
      </Wrapper>
    </LoadingOverlay>
  );
};

export default RecruitersInvitation;
