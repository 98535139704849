import { useState, useEffect, Dispatch } from 'react';

const useHover = (): [Dispatch<any>, boolean] => {
  const [value, setValue] = useState(false);
  const [node, setNode] = useState(null);
  const handleMouseEnter = (): void => setValue(true);
  const handleMouseLeave = (): void => setValue(false);

  useEffect(() => {
    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
    return null;
  }, [node]);

  return [setNode, value];
};

export default useHover;
