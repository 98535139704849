import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './organizationDetails.scss';
import Skeleton from 'react-loading-skeleton';
import { RootState } from 'store/rootReducer';
import { useToasts } from 'react-toast-notifications';
import ColorPicker from 'components/Shared/ColorPicker';
import { Tooltip } from '@material-ui/core';
import DeleteModal from 'components/Shared/DeleteModal';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import {
  getOrgDetails,
  updateOrgColor,
  updateOrgDetails,
} from '../../store/actions/organization.actions';
import ATS from './ats';
import CandidateCloakingSettings from './CandidateCloakingSettings';
import CandidateFeedbackSettings from './CandidateFeedbackSettings';
import JoinCommunityTalentHubSettings from './JoinCommunityTalentHubSettings';
import Input from '../Shared/Input';
import RadioInput from '../Shared/Input/radio';
import FormContainer from '../Shared/MainContainer/FormContainer';
import { User, getUserFromToken } from '../../helpers/user';
import authorize, { hasOrgFeature } from '../../Authorization/Check';
import FileUpload from '../Shared/Files';
import Button from '../Shared/Button';
import { uploadLogo } from '../../api/profile.api';
import { handleError } from '../../handleError';
import SSO from './sso';

interface FileWithPreview extends File {
  preview?: string;
}

const OrgDetails: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userDetails } = useSelector((state: RootState) => state.profile);
  // is array of file but must not have length > 1
  const [logoFile, setLogoFile] = useState<File[]>();
  const [showLogoDeleteModal, setShowLogoDeleteModal] = useState(false);
  const [fileUploadHelperText, setFileUploadHelperText] = useState(null);
  const [showSaveLogoButton, setShowSaveLogoButton] = useState(false);
  const [sentBrandingUpdate, setSentBrandingUpdate] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const hasBrandingFeature = hasOrgFeature('branding');

  const {
    organizationDetails: organization,
    loading,
    error,
    colorBrandUpdating,
  } = useSelector((state: RootState) => state.organization);
  const { addToast } = useToasts();

  useEffect(() => {
    const orgId =
      userDetails &&
      userDetails.recruiter_detail &&
      userDetails.recruiter_detail.organisation_id;
    if (orgId) {
      dispatch(getOrgDetails(orgId));
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (sentBrandingUpdate) {
      if (!colorBrandUpdating && error) {
        addToast({
          type: 'error',
          msg: error,
        });
        setSentBrandingUpdate(false);
      }
      if (!colorBrandUpdating && !error) {
        addToast({
          type: 'success',
          msg: 'Brand color updated',
        });
        setSentBrandingUpdate(false);
      }
    }
  }, [colorBrandUpdating, error, addToast, sentBrandingUpdate]);

  const getFileFromURL = async (url): Promise<FileWithPreview> => {
    const file: FileWithPreview = new File([], 'logo.jpg');
    file.preview = url;
    return file;
  };
  useEffect(() => {
    setFileUploadHelperText('Supported file format: .jpg, .png, .svg');
    if (organization.organisation_logo_url) {
      getFileFromURL(organization.organisation_logo_url).then((file) => {
        setLogoFile([file]);
        setFileUploadHelperText(
          'Drag and drop an image with the file format: .jpg, .png, .svg to replace logo'
        );
      });
    }
  }, [organization]);

  const user: User = getUserFromToken();

  useEffect(() => {
    if (!authorize('company-settings', 'edit')) {
      history.replace('/forbidden');
    }
  }, [user, history]);

  const onColorChanged = (hex: string): void => {
    setSentBrandingUpdate(true);
    dispatch(updateOrgColor(organization, hex));
    setShowUpgradeModal(!hasBrandingFeature);
  };

  const onAddFile = (addedFile): void => {
    setLogoFile([addedFile]);
    setShowSaveLogoButton(true);
  };

  const handleDeleteLogo = async (): Promise<void> => {
    const originalLogo = logoFile[0];
    setLogoFile([]);
    new Promise((resolve, reject) => {
      try {
        resolve(
          dispatch(
            updateOrgDetails({ ...organization, organisation_logo_url: null })
          )
        );
      } catch (e) {
        reject(e);
      }
    })
      .then(() => {
        addToast({
          type: 'success',
          msg: 'Logo successfully deleted.',
        });
      })
      .catch((e) => {
        addToast({
          type: 'error',
          msg: 'Failed to delete the logo. Try again or refresh the page.',
        });
        setLogoFile([originalLogo]);
        handleError(e);
      });
  };

  const handleSaveLogo = async (): Promise<any> => {
    return uploadLogo(organization, logoFile[0])
      .then(() => {
        addToast({
          type: 'success',
          msg: 'Logo successfully uploaded',
        });
        setShowSaveLogoButton(false);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          msg: 'Logo failed to upload',
        });
        handleError(e);
      })
      .finally(() => setShowUpgradeModal(!hasBrandingFeature));
  };

  return (
    <div className="organization-details">
      <h2 className="mb4">Organization Details</h2>
      <FormContainer>
        <div className="container">
          <h3 className="mb2">Here you can view your organization details.</h3>
          <p className="mb3 small-text">
            To update your organization details please contact your account
            manager.
          </p>
          <div className="row">
            <div className="name-and-logo">
              <h3>Brand Details</h3>
              <div>
                <Input
                  type="text"
                  loading={loading}
                  value={organization.name}
                  label="Organization Name:"
                  disabled
                />
                <div className="color-selection">
                  {loading ? (
                    <Skeleton height={12} width={100} />
                  ) : (
                    <>
                      <Tooltip title="Set the color to apply to the assessments">
                        <span className="color-picker-label">Brand Color:</span>
                      </Tooltip>
                      <ColorPicker
                        value={organization.brand_color}
                        onConfirm={onColorChanged}
                        loading={colorBrandUpdating}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="org-logo-container">
                {!loading ? (
                  <>
                    <FileUpload
                      files={logoFile}
                      label="Drag and drop your logo here"
                      accept="image/*"
                      helperText={fileUploadHelperText}
                      addFile={onAddFile}
                    />
                    <PlanUpgradeModal
                      setModalVisibility={setShowUpgradeModal}
                      isShown={showUpgradeModal}
                      title="Upgrade your account to use your branding"
                    >
                      <p>
                        Custom branding isn’t supported on your current
                        subscription plan. Upgrade your subscription to include
                        your logo and use your brand color in Alooba assessments
                        as well as gaining access to many other amazing features
                        Alooba has to offer.
                      </p>
                    </PlanUpgradeModal>
                    {logoFile && logoFile.length > 0 && !showSaveLogoButton && (
                      <Button
                        id="remove-logo-button"
                        text="Remove Logo"
                        variant="primary md"
                        onClick={() => setShowLogoDeleteModal(true)}
                      />
                    )}
                    <DeleteModal
                      data-testid="delete-logo-modal"
                      title="Delete your organization logo?"
                      body="Candidates will no longer see your logo in emails and public assessment links. This action cannot be undone but you can upload a new logo anytime."
                      isShown={showLogoDeleteModal}
                      handleButtonAction={(): void => {
                        setShowLogoDeleteModal(false);
                        handleDeleteLogo();
                      }}
                      setModalVisibility={(visibility) =>
                        setShowLogoDeleteModal(visibility)
                      }
                    />
                  </>
                ) : (
                  <Skeleton height={100} width={250} />
                )}
                <Button
                  loading={loading}
                  text="Save Logo"
                  variant={`primary md ${!showSaveLogoButton ? 'hidden' : ''}`}
                  onClick={handleSaveLogo}
                />
              </div>
            </div>
            <Input
              type="text"
              loading={loading}
              value={organization.website}
              label="Website URL:"
              disabled
            />
          </div>
          <div className="row">
            <Input
              type="text"
              loading={loading}
              value={organization.industry?.industry}
              label="Industry:"
              disabled
            />
            <Input
              type="text"
              loading={loading}
              value={organization.size_employees}
              label="Size (employees):"
              disabled
            />
          </div>
          <div className="mt5 recruitment-company">
            <p>Are you a recruitment company?</p>
            <div className="radio-buttons">
              <RadioInput
                label="Yes"
                name="yes"
                loading={loading}
                disabled
                checked={organization.is_recruitment_company === 1}
              />
              <RadioInput
                label="No"
                name="no"
                loading={loading}
                disabled
                checked={organization.is_recruitment_company === 0}
              />
            </div>
          </div>
        </div>
      </FormContainer>
      {user.paid && authorize('sso-settings', 'view') && <SSO />}
      {user.paid && authorize('integration', 'add') && <ATS />}
      {user.paid && authorize('company-settings', 'edit') && (
        <>
          <h2 className="mb4 mt8">Organization Settings</h2>
          <div className="main-container container">
            <CandidateCloakingSettings
              loading={loading}
              organization={organization}
            />
            <br />
            <CandidateFeedbackSettings
              loading={loading}
              organization={organization}
            />
            <br />
            <JoinCommunityTalentHubSettings
              loading={loading}
              organization={organization}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OrgDetails;
