import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import styles from './Insights.module.scss';
import IllustrationNoData from '../../images/illustration_no_data.svg';
import { RootState } from '../../store/rootReducer';
import { getOrganizationInsights } from '../../api/profile.api';

export const NotFound: React.FC = () => {
  return (
    <div className={styles.insightsContainer}>
      <h3>Alooba Growth Insights are not yet activated for this account.</h3>
      <p className={styles.description}>
        Contact your account manager to find out how to activate personalized
        Alooba Growth insights.
      </p>
      <img src={IllustrationNoData} alt="No data" />
    </div>
  );
};
const Insights: React.FC = () => {
  const history = useHistory();

  const handleClick = (e): void => {
    e.preventDefault();
    history.push('/alooba-growth');
  };
  const [isLoading, setIsLoading] = useState(true);
  const [insights, setInsights] = useState([]);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const orgId =
    userDetails &&
    userDetails.recruiter_detail &&
    userDetails.recruiter_detail.organisation_id;

  const getInsights = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const response = await getOrganizationInsights(orgId, 'ld');
    if (response.status === 200) {
      const data = response.data ? response.data.data : [];
      setInsights(data);
    }
    setIsLoading(false);
  }, [orgId]);
  useEffect(() => {
    if (orgId) {
      getInsights();
    }
  }, [getInsights, orgId]);
  const renderInsights = (): JSX.Element => {
    if (insights && insights.length > 0) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: insights[0].code }} />;
    }
    return <NotFound />;
  };
  return (
    <>
      <div className="heading">
        <h2 className="mb4">
          <div>
            <div className={styles.headerWrapper}>
              <a href="alooba-growth" onClick={handleClick}>
                Alooba Growth
              </a>
              <span className={styles.breadcrumb}>/</span>
              <span className={styles.pageName}>Insights</span>
            </div>
            <div>
              <span className="assessment-description">
                Get insights about the performance of your employees.
              </span>
            </div>
          </div>
        </h2>
      </div>
      {isLoading ? <Skeleton height={100} /> : renderInsights()}
    </>
  );
};

export default Insights;
