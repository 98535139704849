import React, { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getCorrectness } from '../testUtils';
import './QuestionMarks.scss';

const QuestionMarks: React.FC<{
  value: string;
  maxScore: number;
  onScoreChange?: (number) => void;
  disabled: boolean;
  evaluate: boolean;
  placeholder?: string;
  testType?: string;
  unanswered?: boolean;
}> = ({
  value,
  maxScore,
  onScoreChange,
  disabled,
  evaluate,
  placeholder = '#',
  testType = null,
  unanswered = false,
}) => {
  const [score, setScore] = useState(value);
  const inputRef = useRef(null);
  useEffect(() => {
    setScore(value);
  }, [value]);
  const { addToast } = useToasts();

  const scoreInputHandler = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setScore(evt.target.value);
  };
  const submitScore = (evt: React.FocusEvent<HTMLInputElement>): void => {
    // user leaves the field empty, rollback to the original value
    if (evt.target.value.length < 1) {
      setScore(value);
      return;
    }
    const intValue = parseInt(evt.target.value, 10);

    const isInputValid = intValue >= 0 && intValue <= maxScore;
    // also rollback the value if invalid
    if (!isInputValid) {
      addToast({
        msg: `The score must be between 0 and ${maxScore}`,
        type: 'warning',
      });
      setScore(value);
      return;
    }
    if (onScoreChange && intValue !== parseInt(value, 10)) {
      setScore(evt.target.value);
      onScoreChange(intValue);
    } else {
      setScore(value);
    }
  };

  const onClick = (evt: React.MouseEvent<HTMLInputElement>): void => {
    evt.preventDefault();
    inputRef.current.focus();
  };

  let showHints;
  let label;

  switch (testType) {
    case 'SQL':
      label = 'Query';
      showHints = true;
      break;
    case 'Analytics Coding':
      label = 'Solution';
      showHints = true;
      break;
    case 'Free Response':
      label = 'Response';
      showHints = true;
      break;
    default:
      label = null;
      showHints = false;
  }
  const correctness = getCorrectness(
    score !== null ? parseInt(score, 10) : null,
    maxScore,
    unanswered,
    label
  );

  return (
    <div className="score-input-wrapper">
      <div
        className={`score-input-field ${disabled ? 'disabled' : ''} ${
          evaluate ? 'requires-evaluation' : ''
        }`}
      >
        <div>
          <input
            ref={inputRef}
            data-testid="score-input"
            disabled={disabled}
            type="number"
            step="1"
            inputMode="numeric"
            min="0"
            max={maxScore}
            value={score}
            placeholder={placeholder}
            onClick={onClick}
            onChange={scoreInputHandler}
            onBlur={submitScore}
          />
        </div>
        <div className="middle-bar">/</div>
        <div className="max-score">{maxScore}</div>
      </div>
      {showHints && (
        <p className={`hint ${correctness.key}`}>{correctness.label}</p>
      )}
    </div>
  );
};
export default QuestionMarks;
