import React from 'react';
import { FormControl, InputLabel, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import LightTooltip from '../Tooltip/LightTooltip';

const CustomInputLabel = styled(InputLabel)({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '13px',
  lineHeight: '17px',
  letterSpacing: '0.02em',
  color: '#4A4A68',
  fontFamily: 'Nunito Sans',
});
export type fieldContainerProps = {
  label: string;
  description?: string;
  canHide: boolean;
  visible: boolean;
  children: any;
  hide: any;
};
const FieldContainer = ({
  label,
  description,
  canHide,
  visible,
  children,
  hide = null,
}: fieldContainerProps): JSX.Element => {
  if (!visible) return null;
  return (
    <Grid item>
      {description ? (
        <LightTooltip title={description} placement="top" arrow>
          <CustomInputLabel focused={false}>{label}</CustomInputLabel>
        </LightTooltip>
      ) : (
        <CustomInputLabel focused={false}>{label}</CustomInputLabel>
      )}
      <FormControl style={{ position: 'unset', minWidth: '100%' }}>
        <Grid container alignItems="flex-end" justifyContent="center">
          {children}
          {canHide && (
            <FontAwesomeIcon
              onClick={hide}
              style={{
                marginLeft: '10px',
                fontSize: '12px',
                cursor: 'pointer',
                fontWeight: 100,
                marginBottom: '14px',
              }}
              icon={faTimes}
            />
          )}
        </Grid>
      </FormControl>
    </Grid>
  );
};
export default FieldContainer;
