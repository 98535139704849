import React from 'react';

function ThankYou(): JSX.Element {
  return (
    <div>
      <h2>Thank you so much for providing feedback!</h2>
      <h2>
        Your feedback is crucial for us to make our user experience as strong as
        possible.
      </h2>
    </div>
  );
}

export default ThankYou;
