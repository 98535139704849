import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import './index.scss';
import { getFormattedAssessmentName } from 'helpers';
import { handleError } from 'handleError';
import {
  getAssessment,
  getAssessmentCandidates,
  getAssessmentInformationFields,
  getAssessmentSubjects,
} from 'api/assessment.api';
import { setCurrentAssessment } from 'store/actions/assessment.actions';
import { GetAssessmentCandidatesQueryParams } from 'api/contract';
import { debounce } from 'helpers/events';
import Skeleton from 'react-loading-skeleton';
import ComparisonResult from './ComparisonResult';

interface UrlParams {
  reference: string;
  assessmentId: string;
  secondReference?: string;
}

const CompareCandidates = (): any => {
  const dispatch = useDispatch();
  const { reference, secondReference, assessmentId }: UrlParams = useParams();
  const [error, setError] = useState(null);
  const [firstListLoading, setFirstListLoading] = useState(false);
  const [secondListLoading, setSecondListLoading] = useState(false);
  const [candidateSearchResult, setCandidateSearchResult] = useState([]);
  const [selectedFirstCandidate, setSelectedFirstCandidate] = useState(null);
  const [selectedSecondCandidate, setSelectedSecondCandidate] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [informationFields, setInformationFields] = useState(null);
  const [
    candidateResultsParams,
    setCandidateResultsParams,
  ] = useState<GetAssessmentCandidatesQueryParams>({
    assessmentId: parseInt(assessmentId, 10),
    fields: [
      'Reference',
      'Name',
      'Email',
      'Status',
      'Total Score',
      'Submitted On',
    ],
    orderBy: 'Total Score',
    orderDirection: 'desc',
    status: ['Completed', 'Requires Evaluation'],
    withTestScores: true,
    skills: null,
    candidateInformationFields: null,
    searchTerm: reference,
    perPage: 25,
  });
  const candidatesCloaked = useSelector((state: RootState) => {
    return (
      state.profile.userDetails?.recruiter_detail?.cloak_candidates ||
      state.organization.organizationDetails?.cloak_candidates ||
      state.assessment.currentAssessment?.cloak_candidates
    );
  });

  const [searchTerm, setSearchTerm] = useState(null);
  const currentAssessment = useSelector(
    (state: RootState) => state.assessment?.currentAssessment
  );
  useEffect(() => {
    if (!currentAssessment?.id) {
      getAssessment({ id: assessmentId })
        .then((response) => {
          dispatch(setCurrentAssessment(response?.data?.data));
        })
        .catch((error) => {
          handleError(error);
          setError(error);
        });
    }
  }, [assessmentId, currentAssessment?.id, dispatch]);

  useEffect(() => {
    if (!subjects || !informationFields) {
      return;
    }
    if (!selectedFirstCandidate && !firstListLoading && reference) {
      setFirstListLoading(true);
      getAssessmentCandidates({
        ...candidateResultsParams,
        skills: subjects,
        candidateInformationFields: informationFields,
        searchTerm: reference,
      })
        .then((response) => {
          setSelectedFirstCandidate(response?.data?.data?.data[0]);
        })
        .catch((error) => {
          handleError(error);
          setError(error);
        })
        .finally(() => {
          setFirstListLoading(false);
        });
    }
    if (!selectedSecondCandidate && secondReference && !secondListLoading) {
      setSecondListLoading(true);
      getAssessmentCandidates({
        ...candidateResultsParams,
        skills: subjects,
        candidateInformationFields: informationFields,
        searchTerm: secondReference,
      })
        .then((response) => {
          setSelectedSecondCandidate(response?.data?.data?.data[0]);
        })
        .catch((error) => {
          handleError(error);
          setError(error);
        })
        .finally(() => {
          setSecondListLoading(false);
        });
    }
  }, [
    candidateResultsParams,
    firstListLoading,
    informationFields,
    reference,
    secondListLoading,
    secondReference,
    selectedFirstCandidate,
    selectedSecondCandidate,
    subjects,
  ]);

  useEffect(() => {
    if (subjects && informationFields) {
      setCandidateResultsParams((previousParams) => ({
        ...previousParams,
        skills: subjects.map((subject) => subject),
        candidateInformationFields: informationFields,
      }));
    }
  }, [subjects, informationFields]);

  useEffect(() => {
    const getRequestData = async (): Promise<{
      informationFields: any;
      subjects: any;
    }> => {
      const [informationFields, subjects] = await Promise.all([
        getAssessmentInformationFields(parseInt(assessmentId, 10)),
        getAssessmentSubjects(parseInt(assessmentId, 10)),
      ]);
      return {
        informationFields: informationFields?.data?.data,
        subjects: subjects?.data?.data,
      };
    };
    if (assessmentId) {
      getRequestData().then(({ informationFields, subjects }) => {
        setSubjects(subjects.map((value) => value.subject).sort());
        setInformationFields(
          informationFields
            .filter((value) => !value.is_anonymised)
            .map((field) => field.label)
        );
      });
    }
  }, [assessmentId]);

  useEffect(() => {
    if (selectedFirstCandidate && currentAssessment?.id) {
      window.history.replaceState(
        null,
        '',
        `/compare-candidates/${
          currentAssessment?.id
        }-${getFormattedAssessmentName(
          currentAssessment?.recruiter_test_name
        )}/${selectedFirstCandidate.Reference}/${
          selectedSecondCandidate?.Reference ?? ''
        }`
      );
    }
  }, [
    currentAssessment?.id,
    currentAssessment?.recruiter_test_name,
    selectedFirstCandidate,
    selectedSecondCandidate,
  ]);

  const onFirstCandidateChange = (selectedCandidate: {
    Reference: string;
  }): void => {
    setSelectedFirstCandidate(
      candidateSearchResult?.find(
        (candidate) => candidate.Reference === selectedCandidate.Reference
      )
    );
  };
  const onSecondCandidateChange = (selectedCandidate: {
    Reference: string;
  }): void => {
    setSelectedSecondCandidate(
      candidateSearchResult?.find(
        (candidate) => candidate.Reference === selectedCandidate.Reference
      )
    );
  };

  const getCandidateLabel = (candidate): string => {
    let label = candidatesCloaked ? candidate.Reference : candidate.Name;
    if (candidate['Total Score'] !== null) {
      label = `${label} (${candidate['Total Score']}%)`;
    }
    return label;
  };

  const getCandidateList = (
    value,
    changedSearchInput: 'first' | 'second'
  ): void => {
    if (subjects === null || informationFields === null) {
      return;
    }
    if (changedSearchInput === 'first') {
      setFirstListLoading(true);
    } else {
      setSecondListLoading(true);
    }
    getAssessmentCandidates({
      ...candidateResultsParams,
      searchTerm: value,
      excludeErased: true,
    })
      .then((response) => {
        setCandidateSearchResult(response?.data?.data?.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        if (changedSearchInput === 'first') {
          setFirstListLoading(false);
        } else {
          setSecondListLoading(false);
        }
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCandidates = useCallback(debounce(getCandidateList, 300), [
    candidateResultsParams,
  ]);

  const stringMatch = (string1, string2): boolean => {
    return string1.toLowerCase().includes(string2.toLowerCase());
  };

  if (error) {
    return <p>{error}</p>;
  }
  return (
    <>
      {currentAssessment?.purpose ? (
        <h2>
          {currentAssessment?.purpose === 'ld'
            ? 'Compare Employees'
            : 'Compare Assessment Candidates'}
        </h2>
      ) : (
        <Skeleton width={200} />
      )}
      <div className="compare-candidates-wrapper main-container">
        <div className="select-candidate-boxes">
          <div className="select-candidate-box">
            <div className="select-box-content">
              <h3>
                First{' '}
                {currentAssessment?.purpose === 'ld' ? 'Employee' : 'Candidate'}
              </h3>
              <div className="select-container">
                <Select
                  isDisabled={subjects === null || informationFields === null}
                  placeholder={
                    currentAssessment?.purpose === 'ld'
                      ? 'Search Employees'
                      : 'Search Candidates'
                  }
                  onFocus={() => {
                    fetchCandidates('', 'first');
                  }}
                  onInputChange={(input) => {
                    if (input || (input === '' && searchTerm !== input)) {
                      fetchCandidates(input, 'first');
                    }
                    setSearchTerm(input);
                  }}
                  value={selectedFirstCandidate}
                  options={candidateSearchResult.filter(
                    (candidate) =>
                      candidate.Reference !== selectedSecondCandidate?.Reference
                  )}
                  className="react-select"
                  classNamePrefix="select"
                  onChange={onFirstCandidateChange}
                  getOptionValue={(option) => option.Reference}
                  getOptionLabel={(option) => getCandidateLabel(option)}
                  filterOption={(option, input) => {
                    return (
                      input === '' ||
                      stringMatch(option.data.Name, input) ||
                      stringMatch(option.data.Email, input) ||
                      stringMatch(option.data.Reference, input)
                    );
                  }}
                  isLoading={firstListLoading}
                />
              </div>
            </div>
          </div>
          <div className="select-candidate-box">
            <div className="select-box-content">
              <h3>
                Second{' '}
                {currentAssessment?.purpose === 'ld' ? 'Employee' : 'Candidate'}
              </h3>
              <div className="select-container">
                <Select
                  isDisabled={subjects === null || informationFields === null}
                  placeholder={
                    currentAssessment?.purpose === 'ld'
                      ? 'Search Employees'
                      : 'Search Candidates'
                  }
                  onFocus={() => {
                    fetchCandidates('', 'second');
                  }}
                  onInputChange={(input) => {
                    if (input || (input === '' && searchTerm !== input)) {
                      fetchCandidates(input, 'second');
                    }
                    setSearchTerm(input);
                  }}
                  value={selectedSecondCandidate}
                  options={candidateSearchResult.filter(
                    (candidate) =>
                      candidate.Reference !== selectedFirstCandidate?.Reference
                  )}
                  className="react-select"
                  classNamePrefix="select"
                  onChange={onSecondCandidateChange}
                  getOptionValue={(option) => option.Reference}
                  getOptionLabel={(option) => getCandidateLabel(option)}
                  filterOption={(option, input) => {
                    return (
                      input === '' ||
                      stringMatch(option.data.Name, input) ||
                      stringMatch(option.data.Email, input) ||
                      stringMatch(option.data.Reference, input)
                    );
                  }}
                  isLoading={secondListLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <ComparisonResult
          firstCandidate={selectedFirstCandidate}
          secondCandidate={selectedSecondCandidate}
          params={candidateResultsParams}
          cloaked={candidatesCloaked}
        />
      </div>
    </>
  );
};

export default CompareCandidates;
