import React from 'react';
import PageSuccessIcon from 'images/success.svg';
import moment from 'moment-timezone';
import { fullDateTime } from 'helpers/datetime';
import LightTooltip from '../../../Tooltip/LightTooltip';

interface InviteCandidateSuccessProps {
  values: any;
  defaultDaysToComplete: number;
  assessmentOrExercise: string;
  softExpiration: boolean;
}

const MIN_DAYS_TO_SEND_FINAL_REMINDER = 3;
const DAYS_BEFORE_EXPIRATION_FOR_FINAL_REMINDER = 1;
const REMINDER_INTERVAL = 3;
const MIN_DAYS_TO_SEND_NORMAL_REMINDER = 2;

const hasFinalReminder = (daysToComplete: number): boolean => {
  return daysToComplete >= MIN_DAYS_TO_SEND_FINAL_REMINDER;
};

const getFinalReminderDate = (expiresOn, tzCodeExpiration): string => {
  return moment(expiresOn)
    .tz(tzCodeExpiration)
    .add(-DAYS_BEFORE_EXPIRATION_FOR_FINAL_REMINDER, 'days')
    .format('lll');
};

// the first reminder will be sent 1 day after the invitation date
// all the other reminders will be sent every 3 days
const getReminderDates = (invitedOn, totalReminders): string[] => {
  const dates = [];
  dates.push(moment(invitedOn).add(1, 'days').format('lll'));
  for (let i = 1; i < totalReminders; i += 1) {
    dates.push(
      moment(invitedOn)
        .add(i * REMINDER_INTERVAL + 1, 'days')
        .format('lll')
    );
  }
  return dates;
};

const getTotalNormalReminders = (daysToComplete): number => {
  if (daysToComplete < MIN_DAYS_TO_SEND_NORMAL_REMINDER) {
    return 0;
  }
  if (daysToComplete === MIN_DAYS_TO_SEND_NORMAL_REMINDER) {
    return 1;
  }
  return Math.ceil((daysToComplete - 2) / REMINDER_INTERVAL);
};

const getDaysToComplete = (
  invitedOn,
  expiresOn,
  tzCode,
  tzCodeExpiration
): any => {
  return moment(fullDateTime(expiresOn, tzCodeExpiration)).diff(
    moment(fullDateTime(invitedOn, tzCode)),
    'days'
  );
};

const InviteCandidateSuccess = (
  props: InviteCandidateSuccessProps
): JSX.Element => {
  const {
    values,
    defaultDaysToComplete,
    assessmentOrExercise,
    softExpiration,
  } = props;
  if (!values.invitation_date) {
    values.invitation_date = new Date();
  }
  if (!values.expiration_date) {
    values.expiration_date = moment(values.invitation_date)
      .add(defaultDaysToComplete, 'days')
      .tz(values.tz_code_expiration);
  }
  const daysToComplete = getDaysToComplete(
    values.invitation_date,
    values.expiration_date,
    values.tz_code,
    values.tz_code_expiration
  );
  const totalReminders = getTotalNormalReminders(daysToComplete);
  const hasReminder = totalReminders >= 1;
  const finalReminderDate = hasFinalReminder(daysToComplete)
    ? getFinalReminderDate(values.expiration_date, values.tz_code_expiration)
    : 'N/A';
  const reminderDates = getReminderDates(
    values.invitation_date,
    totalReminders
  );

  const messages = {
    invitation: `An invitation with a link to take the ${assessmentOrExercise} will be emailed.`,
    reminder: `If they haven't started the ${assessmentOrExercise}, a reminder will be emailed to them to take the assessment.`,
    finalReminder: `If they still haven't taken the ${assessmentOrExercise}, a final reminder will be emailed informing them they only have 1 day left.`,
    finalReminderWithPhone: `If they still haven't taken the ${assessmentOrExercise}, a final reminder will be emailed informing them they only have 1 day left and a text message will be sent to their phone.`,
    softExpiry: `This is when we have told the ${
      assessmentOrExercise === 'exercise' ? 'employee' : 'candidate'
    } they must complete the ${assessmentOrExercise} by.`,
    expiry: `They will no longer be able to take the ${assessmentOrExercise}. However you can still grant an extension.`,
  };

  return (
    <div
      className={`invite-candidate-success${
        reminderDates.length > 1 ? ' vertical' : ''
      }`}
    >
      <img src={PageSuccessIcon} alt="page success" width="146" />
      <p>
        {values.first_name} {values.last_name} &lt;
        {values.email_address}&gt; has been added to the {assessmentOrExercise}.{' '}
        {!values.has_invitation_date ? (
          <>They should receive the invitation shortly.</>
        ) : (
          <>The invitation is scheduled to be sent out.</>
        )}
      </p>
      <h3>What Happens Now?</h3>
      <div className="timeline" id="timeline">
        <LightTooltip title={messages.invitation} arrow>
          <div className="list-item complete">
            <div className="timestamp first">
              <span className="date">
                {moment(values.invitation_date).format('lll')}
              </span>
              <span className="time">(GMT {values.tz_offset})</span>
            </div>
            <div className="status">Invitation</div>
          </div>
        </LightTooltip>
        {hasReminder && (
          <div>
            {reminderDates.map((reminderDate) => (
              <LightTooltip title={messages.reminder} arrow>
                <div className="list-item">
                  <div className="timestamp">
                    <span className="date">
                      {moment(reminderDate).format('lll')}
                    </span>
                    <span className="time">(GMT {values.tz_offset})</span>
                  </div>
                  <div className="status">Reminder</div>
                </div>
              </LightTooltip>
            ))}
          </div>
        )}
        {hasFinalReminder(daysToComplete) && (
          <LightTooltip
            title={`${
              values.phone_number
                ? messages.finalReminderWithPhone
                : messages.finalReminder
            }`}
            arrow
          >
            <div className="list-item">
              <div className="timestamp">
                <span className="date">
                  {moment(finalReminderDate).format('lll')}
                </span>
                <span className="time">
                  (GMT {values.tz_offset_expiration})
                </span>
              </div>
              <div className="status">Final Reminder</div>
            </div>
          </LightTooltip>
        )}
        {softExpiration && (
          <LightTooltip title={messages.softExpiry} arrow>
            <div className="list-item">
              <div className="timestamp">
                <span className="date">
                  {moment(values.expiration_date).format('lll')}
                </span>
                <span className="time">
                  (GMT {values.tz_offset_expiration})
                </span>
              </div>
              <div className="status">Soft Expiry</div>
            </div>
          </LightTooltip>
        )}
        <LightTooltip title={messages.expiry} arrow>
          <div className="list-item">
            <div className="timestamp last">
              <span className="date">
                {moment(values.expiration_date)
                  .add(softExpiration ? 30 : 0, 'days')
                  .format('lll')}
              </span>
              <span className="time">(GMT {values.tz_offset_expiration})</span>
            </div>
            <div className="status last">Expiry</div>
          </div>
        </LightTooltip>
      </div>
    </div>
  );
};

export default InviteCandidateSuccess;
