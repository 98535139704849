import React, { useMemo } from 'react';
import SuspiciousActivityIcon from 'images/icons/suspicious-activity.svg';
import ImageWithTooltip from '../../Shared/Tooltip';

const SuspiciousSnapshot = ({ snapshot }): JSX.Element => {
  const SuspiciousText = useMemo(() => {
    if (snapshot.face_number > 1) {
      return 'Multiple faces were detected in this snapshot.';
    }
    if (!snapshot.face_match && snapshot.face_match !== null) {
      if (snapshot.face_number === 1) {
        return 'The person in this snapshot is different from the candidate.';
      }
    }
    if (snapshot.face_number === 0) {
      return 'No face was detected.';
    }
    if (snapshot.cheating_labels && snapshot.cheating_labels.length > 0) {
      return `The following item${
        snapshot.cheating_labels.length > 1 ? 's were' : ' was'
      } detected in the snapshot: ${snapshot.cheating_labels.join(', ')}.`;
    }
    return null;
  }, [snapshot]);
  if (!SuspiciousText) {
    return null;
  }
  return (
    <div className="snapshot-icon">
      <ImageWithTooltip
        pointer={false}
        icon={SuspiciousActivityIcon}
        tooltip={SuspiciousText}
        alt={SuspiciousText}
        noHover
      />
    </div>
  );
};
export default SuspiciousSnapshot;
