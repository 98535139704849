import Axios from 'axios';
import axiosRetry from 'axios-retry';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import { retryAfterDelay } from '../helpers';
import { waitForAccessToken } from '../store/actions/auth.actions';

const api = Axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosBetterStacktrace(api);

/*
 * This makes 3 retries with an increasing delay on all idempotent requests that
 * fail due to a network or 5xx error.
 */
axiosRetry(api, {
  retries: 3,
  retryDelay: retryAfterDelay,
});

api.interceptors.request.use(
  async (config) => {
    if (!config.is_public) {
      const accessToken = await waitForAccessToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export type ValidationErrors = Record<string, string[]>;

export default api;
