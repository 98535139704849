/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import Axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import store from '../store/store';

export interface Decoded {
  ufid: number;
  exp: any;
}

const authAxios = Axios.create({
  baseURL: process.env.REACT_APP_AUTH_SERVER_URL,
  headers: {
    Accept: 'application/json',
  },
});

axiosBetterStacktrace(authAxios);

authAxios.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().auth;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default authAxios;
