import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import IntegrationAuth from 'components/Integration/IntegrationAuth';
import { useDispatch } from 'react-redux';
import { deactivatePageHeader } from '../store/reducers/layout';

const IntegrationAuthPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  const { partner } = useParams<{
    partner: string;
  }>();
  const pageParams = new URLSearchParams(useLocation().search);
  const code = pageParams.get('code');
  const state = pageParams.get('state');

  return (
    <div className="container">
      <Helmet>
        <title>Integration | alooba.com</title>
      </Helmet>
      <IntegrationAuth partner={partner} code={code} state={state} />
    </div>
  );
};

export default IntegrationAuthPage;
