import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
} from 'react';
import jwt_decode from 'jwt-decode';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import '../../styles/spacing.styles.scss';
import './index.scss';
import CompletedBlank from 'components/CandidateResultsList/CompletedBlank';
import ErasedCandidateInfo from 'components/Shared/Erased/ErasedCandidateInfo';
import { LoadingIcon } from 'components/Shared/Icon/Loading';
import AloobaTooltip from 'components/Shared/Tooltip/AloobaTooltip';
import { inviteCandidateManualConfirm } from 'api/candidate.api';
import { DynamicTablePropsContext } from '../Shared/DynamicTable/context/DynamicTablePropsContext';
import { searchCandidates } from '../../api/assessment.api';
import { emptyJwt } from '../../helpers';
import {} from '../../helpers/text';
import { LoaderContext } from '../../context/loader';
import { RefreshContext } from '../Participants/context/refresh';
import useDynamicTable from '../../hooks/useDynamicTable';
import CompletedTable from '../CandidateResultsList/CompletedTable';
import TableBlank from '../Participants/TableBlank';
import { TableField } from '../CandidateResultsList/completedTableFields';
import { RootState } from '../../store/rootReducer';
import ActionMenuContainer from '../Participants/ActionMenuContainer';
import styles from '../Assessment/Assessment.module.scss';

import {
  updateQueryParams,
  setPaginator,
  setCurrentPage,
} from '../../store/reducers/suggestedCandidates';
import { getOrgDetails } from '../../store/actions/organization.actions';

const getDataCallback = {
  callback: (data: any): any => {
    return data;
  },
};

const tableFields: TableField[] = [
  {
    label: 'Name',
    position: 4,
  },
  {
    label: 'Email',
    position: 5,
  },
  {
    label: 'Relevance',
    position: 6,
    type: 'percentage',
    hint:
      "This percentage reflects the extent to which the candidate's tested skills align with the skills assessed in this assessment.",
  },
  {
    label: 'AVG Total Score',
    position: 50,
    type: 'percentage',
    hint:
      'This is the average score of all the candidate’s attempts across the relevant skills.',
  },
  {
    label: 'Match',
    position: 100,
    type: 'percentage',
    hint:
      "This assesses how well the candidate's qualifications match the role defined by the current assessment. A higher score indicates a stronger fit.",
  },
];
const piiFields = ['Name', 'Email'];

const products: string[] = ['hiring', 'junior'];
const noDataMessage = 'No matching candidate suggestions found';

interface Props {
  onParticipantsInvited?: () => void;
}

const SuggestedCandidates = ({ onParticipantsInvited }: Props): JSX.Element => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { organizationDetails: organization } = useSelector(
    (state: RootState) => state.organization
  );
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [loadingInvitaions, setLoadingInvitations] = useState<Set<any>>(
    new Set<any>()
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [dateFilter] = useState<any>({
    'date[from]': new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .split('T')[0],
    'date[to]': new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .split('T')[0],
  });
  const [, setSearch] = useState<string>('');
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const [showRestrictedWarning, setShowRestrictedWarning] = useState<boolean>(
    false
  );
  const [is_cloaked, setIsCloaked] = useState<boolean>(false);

  const organizationCloakedCandidates = useSelector(
    (state: RootState) =>
      state.organization.organizationDetails?.cloak_candidates
  );
  const recruiterCloakedCandidates = useSelector(
    (state: RootState) =>
      state.profile.userDetails.recruiter_detail?.cloak_candidates
  );

  useEffect(() => {
    if (organizationCloakedCandidates || recruiterCloakedCandidates) {
      setIsCloaked(true);
    }
  }, [organizationCloakedCandidates, recruiterCloakedCandidates]);

  const { paginator, queryParams, currentPage }: any = useSelector(
    (state: RootState) => state.suggestedCandidates
  );
  useEffect(() => {
    const decoded: { pl: any[] } = jwt_decode(accessToken || emptyJwt);
    if (decoded.pl?.includes('Restricted')) {
      setShowRestrictedWarning(true);
    }
  }, [accessToken]);

  const {
    isLast,
    nextPage,
    changePerPage,
    filterSearch,
    sortingAction,
    tablePaginatorProps,
  } = useDynamicTable(getDataCallback, paginator, currentPage, setSearch);

  const shouldCloakPII = useMemo(() => {
    return (
      userDetails?.recruiter_detail?.cloak_candidates ||
      organization.cloak_candidates
    );
  }, [userDetails, organization]);

  useEffect(() => {
    const orgId = userDetails?.recruiter_detail?.organisation_id;
    if (orgId && shouldCloakPII === null) {
      dispatch(getOrgDetails(orgId));
    }
  }, [organization, userDetails, dispatch, shouldCloakPII]);

  const [completedTableProps, setCompletedTableProps] = useState<any>({
    loading: isTableLoading,
    fields: tableFields,
    data: [],
    paginator,
    filters: [],
    configurableRows: true,
    noDataMessage,
    noMinHeight: true,
  });
  const [blank, setBlank] = useState<boolean>(true);

  const defaultQueryParams = useMemo(() => {
    return {
      matchForAssessment: Number(assessmentId),
      minimumSubjectsMatch: 50,
      minimumAvgTotalScore: 50,
      products,
      subjects: [],
      fromDate: dateFilter['date[from]'],
      toDate: dateFilter['date[to]'],
      perPage,
    };
  }, [assessmentId, dateFilter, perPage]);

  useEffect(() => {
    getDataCallback.callback({ ...defaultQueryParams });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  const getData = async (data): Promise<any> => {
    setIsError(false);
    setTableLoading(true);
    let state = defaultQueryParams;
    if (!data.reset) {
      state = {
        ...queryParams,
        ...data,
      };
    }
    dispatch(updateQueryParams(state));
    const res = await searchCandidates(state);
    if (!res) {
      setIsError(true);
    } else {
      dispatch(setPaginator(res.data.data));
      setBlank(firstLoad ? res.data.data.total === 0 : false);
      setPerPage(res.data.data.per_page);
      if (data.page) {
        dispatch(setCurrentPage(data.page));
      }
      setFirstLoad(false);
    }
    setTableLoading(false);
    setLoaded(true);
  };
  getDataCallback.callback = useCallback(getData, [
    defaultQueryParams,
    firstLoad,
    queryParams,
    dispatch,
  ]);

  const processCell = useCallback((row: any, field: any): any => {
    if (!row[field.label]) {
      return 'N/A';
    }

    const value = row[field.label];

    const isErased =
      row.data_erasure_request_id !== null &&
      row.data_erasure_request_id !== undefined;
    if (isErased && field.label === 'Name') {
      return (
        <ErasedCandidateInfo placement="top" participantType="candidate" />
      );
    }

    if (piiFields.includes(field.label) && row['Cloak Candidates']) {
      return <div className="">-</div>;
    }

    if (field.label === 'Name') {
      return <div className="name bold">{value}</div>;
    }

    if (field.type === 'percentage') {
      if (field.label === 'Match') {
        return (
          <div className="total-score bold text-center skill-score">{`${value}%`}</div>
        );
      }
      return <div className="text-center skill-score">{`${value}%`}</div>;
    }

    return value;
  }, []);

  const onSendInvitation = async (row: any): Promise<void> => {
    setLoadingInvitations((prev) => new Set(prev.add(row.candidate_id)));

    return inviteCandidateManualConfirm({
      recruiter_test_id: Number(assessmentId),
      email_address: row.Email,
      first_name: row.first_name,
      last_name: row.last_name,
      phone_number: row.phone_number ?? '',
      has_invitation_date: 0,
      has_expiration_date: 0,
      invitation_date: null,
      expiration_date: null,
      tz_code: null,
      tz_offset: null,
      tz_code_expiration: null,
      tz_offset_expiration: null,
    })
      .then((res) => {
        if (!res?.data?.success) {
          throw new Error('Unable to send invitation');
        }

        const candidateIdentifier = shouldCloakPII
          ? 'the candidate'
          : row.Email;
        addToast({
          type: 'success',
          msg: `The invitation has been sent to ${candidateIdentifier}`,
        });

        onParticipantsInvited?.call(null);

        getData({
          page: currentPage,
        });
      })
      .catch((err) => {
        addToast({
          type: 'error',
          msg: err.response?.data?.error ?? 'Unable to send invitation',
        });
      })
      .finally(() => {
        setLoadingInvitations((prev) => {
          const next = new Set(prev);
          next.delete(row.candidate_id);
          return next;
        });
      });
  };

  const actionMenu = useCallback(
    (row: any): ReactElement => {
      if (loadingInvitaions.has(row.candidate_id)) {
        return (
          <AloobaTooltip placement="top" title="Sending invitation...">
            <LoadingIcon />
          </AloobaTooltip>
        );
      }

      return (
        <ActionMenuContainer
          {...{
            row: { ...row, 'Send Invitation': true },
            userDetails,
            assessment: row,
            showTestDuration: false,
            shouldCloakPII: is_cloaked || row['Cloak Candidates'],
            onSendInvitation,
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [is_cloaked, userDetails]
  );

  const retry = (): void => {
    getDataCallback.callback({});
  };

  useEffect(() => {
    if (!loaded || blank) {
      return;
    }
    const extraProps = {
      getPath: undefined,
      actionMenu: undefined,
      onClickRow: undefined,
    };
    extraProps.actionMenu = actionMenu;

    const currentQueryParams = queryParams || defaultQueryParams;

    const tableSearchProps = {
      filterSearch,
      searchTerm: currentQueryParams.searchTerm,
    };
    const sortingProps = {
      sortingAction,
      orderBy: currentQueryParams.orderBy,
      orderDirection: currentQueryParams.orderDirection,
    };
    setCompletedTableProps({
      loading: false,
      data: paginator.data,
      fields: tableFields.filter((field) => {
        if (shouldCloakPII) {
          return !piiFields.includes(field.label);
        }
        return true;
      }),
      fromDate: dateFilter['date[from]'],
      toDate: dateFilter['date[to]'],
      paginator,
      tableSearchProps,
      sortingProps,
      actionMenu,
      filters: [],
      processCell,
      configurableRows: true,
      tablePaginatorProps,
      noDataMessage,
      noMinHeight: true,
      ...extraProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loaded,
    currentPage,
    paginator,
    queryParams,
    blank,
    userDetails.user_timezone,
    userDetails,
    nextPage,
    changePerPage,
    isLast,
    isTableLoading,
    processCell,
    tableFields,
    dateFilter,
    shouldCloakPII,
  ]);

  return (
    <>
      {firstLoad && isTableLoading && <Skeleton height="200px" />}
      {!firstLoad && (
        <div className="suggested-candidates">
          <RefreshContext.Provider value={[firstLoad, setFirstLoad]}>
            <p className="top-caption">
              To give you a head start, here are some candidate suggestions that
              scored well in similar assessments during the last month.
            </p>
            {showRestrictedWarning && (
              <div className={`${styles.warningBar} mt4`}>
                <div className={styles.textContent}>
                  <p>
                    Your account only has access to the results of the
                    candidates you added. Results of candidates you didn&apos;t
                    add will not show here.
                  </p>
                </div>
              </div>
            )}
            <LoaderContext.Provider value={[isTableLoading, setTableLoading]}>
              {isError && (
                <TableBlank
                  message="Unable to fetch candidates."
                  onRetry={retry}
                />
              )}

              {!isError && (
                <>
                  {isTableLoading && <CompletedBlank {...{ loading: true }} />}
                  {!isTableLoading && (
                    <DynamicTablePropsContext.Provider
                      value={[completedTableProps, setCompletedTableProps]}
                    >
                      <CompletedTable {...{ loading: false }} />
                    </DynamicTablePropsContext.Provider>
                  )}
                </>
              )}
            </LoaderContext.Provider>
          </RefreshContext.Provider>
        </div>
      )}
    </>
  );
};

export default SuggestedCandidates;
