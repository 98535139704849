import { handleError } from 'handleError';
import store, { AppThunk } from '../store';
import { getBillingDetails as getBillingDetailsApi } from '../../api/payment.api';
import {
  getBillingDetailsStart,
  getBillingDetailsSuccess,
  getBillingDetailsFailure,
} from '../reducers/billing';

export const getBillingDetails = (orgId: number): AppThunk => async (
  dispatch
) => {
  const { billing } = store.getState();
  if (billing?.loading) {
    return;
  }
  dispatch(getBillingDetailsStart());
  getBillingDetailsApi(orgId)
    .then((data) => {
      dispatch(getBillingDetailsSuccess(data.data));
    })
    .catch((err) => {
      dispatch(getBillingDetailsFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};
