import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useErrorHandler } from 'react-error-boundary';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { retakeTest } from 'api/candidate.api';
import moment from 'moment-timezone';
import { fullDateTime } from 'helpers/datetime';
import FormContainer from 'components/Shared/MainContainer/FormContainer';
import DateTimePicker from 'components/Shared/DateTimePicker';
import MemoizedTimezoneDropDown from 'components/Shared/Dropdown/MemoizedTimezoneDropDown';
import Input from 'components/Shared/Input';
import Switcher from 'components/Shared/Switcher';
import { Modal } from 'components/Shared/Modal';
import { stopEvent } from 'helpers/events';
import { fetchTimezones } from 'store/actions/utils.actions';
import {
  setTestProperty,
  changeCandidateTestStatus,
  changeTestsCompletedStatus,
  clearTestScores,
} from 'store/reducers/candidate';

import './index.scss';
import Checkbox from 'components/Shared/Input/checkbox';

interface Assessment {
  purpose: string;
}

interface RetakeAssessmentModalProps {
  assessment: Assessment;
  candidate: any;
  userDetails: any;
  setModalVisibility: any;
  isShown: boolean;
  test_ids?: number[];
  testParts?: any[];
}
const RetakeAssessmentModal = (
  props: RetakeAssessmentModalProps
): JSX.Element => {
  const dispatch = useDispatch();
  const {
    assessment,
    userDetails,
    candidate,
    setModalVisibility,
    test_ids,
    testParts,
  } = props;
  const parts = testParts ?? candidate.tests;
  const handleError = useErrorHandler();
  const [confirming, setConfirming] = useState<boolean>(false);
  const [mustValidate, setMustValidate] = useState<boolean>(false);
  const [partsToDelete, setPartsToDelete] = useState<any[]>([]);
  const [showExtend, setShowExtend] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const [errorText, setErrorText] = useState<string>('');
  const { addToast } = useToasts();
  const { timezones } = useSelector((state: RootState) => state.utils);
  const userTz = useMemo(() => {
    if (!userDetails?.user_timezone) return 'UTC';
    return userDetails.user_timezone;
  }, [userDetails]);

  const modalFor = useMemo(() => {
    if (assessment.purpose === 'ld') {
      return 'Employee';
    } else {
      return 'Candidate';
    }
  }, [assessment]);

  const [values, setValues] = useState<any>({
    tz_code: userTz,
    candidate_test_id: candidate.id,
    test_ids,
    must_extend: false,
    new_expiry_date: '',
    new_expiry_date_verbose: '',
    tz_offset: '',
    no_send_expiry_email: 'true',
  });

  useEffect(() => {
    if (!test_ids || !test_ids.length) {
      setPartsToDelete(
        parts.filter(
          (part) =>
            part.test_type !== null &&
            part.test_type !== 'Interview' &&
            part.is_completed
        )
      );
    } else {
      const deleteParts = [];
      for (let i = 0; i < parts.length; i += 1) {
        const part = parts[i];
        for (let j = 0; j < test_ids.length; j += 1) {
          if (part.id === test_ids[j] && part.is_completed) {
            deleteParts.push(part);
            break;
          }
        }
      }
      setPartsToDelete(deleteParts);
      setValues((previousValues) => ({
        ...previousValues,
        test_ids: deleteParts.map((p) => p.id),
      }));
    }
  }, [parts, test_ids]);

  useEffect(() => {
    if (candidate.expires_on < moment().format('YYYY-MM-DD HH:mm:ss')) {
      setExpired(true);
      setShowExtend(true);
      setMustValidate(true);
    }
  }, [candidate.expires_on]);

  useEffect(() => {
    setValues((prevValues: any) => ({
      ...prevValues,
      must_extend: showExtend,
    }));
  }, [showExtend]);

  const currentExpiry = moment(candidate.expires_on);
  const validator = useCallback((): boolean => {
    if (!showExtend) {
      return true;
    }
    if (values.new_expiry_date === '') {
      setErrorText('You must provide a new expiry date.');
      return false;
    }
    const nowLiteral = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    const now = moment(nowLiteral);
    const expiryUtc = moment(values.new_expiry_date)
      .tz(values.tz_code)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const newExpiry = moment(expiryUtc);
    if (newExpiry.diff(currentExpiry, 'minutes') <= 1441) {
      setErrorText(
        'New expiry date must be at least 24 hours after current expiry date.'
      );
      return false;
    }
    if (newExpiry.diff(now, 'minutes') <= 1441) {
      setErrorText(
        'New expiry date must be at least 24 hours after current expiry date.'
      );
      return false;
    }
    setErrorText('');
    return true;
  }, [values.new_expiry_date, values.tz_code, showExtend, currentExpiry]);

  useEffect(() => {
    if (mustValidate) {
      validator();
      setMustValidate(false);
    }
  }, [mustValidate, validator]);

  // On first load, fetch timezones from redux state or localstorage or,
  // in the last case, from the server
  useEffect(() => {
    dispatch(fetchTimezones());
  }, [dispatch]);

  useEffect(() => {
    if (
      candidate.status === 'Expired' ||
      candidate.status === 'Incomplete Expired'
    ) {
      setExpired(true);
      setShowExtend(true);
    }
  }, [candidate]);

  const handleButtonAction = async (
    e: React.SyntheticEvent<EventTarget>
  ): Promise<any> => {
    stopEvent(e);
    if (!validator()) {
      return;
    }
    if (confirming) {
      return;
    }
    setConfirming(true);
    try {
      const response = await retakeTest(formik.values);
      if (response.data.error) {
        addToast({
          type: 'error',
          msg: response.data.message,
        });
      } else {
        if (response.data.full_retake) {
          dispatch(changeCandidateTestStatus({ status: 'Opened Assessment' }));
          dispatch(clearTestScores());
        } else {
          dispatch(changeCandidateTestStatus({ status: 'Incomplete' }));
        }
        dispatch(
          changeTestsCompletedStatus({
            testIds: values.test_ids,
            is_submitted: false,
          })
        );
        values.test_ids?.forEach((testId) => {
          dispatch(
            setTestProperty({
              testId,
              propertyName: 'status',
              value: 'Not Started',
            })
          );
        });
        addToast({
          type: 'success',
          msg: `The ${modalFor.toLowerCase()} has been requested to retake the test`,
        });
      }
    } catch (se) {
      addToast({
        type: 'error',
        msg: 'Unable to request retake of the test',
      });
      handleError(se);
    }
    setConfirming(false);
    handleClose();
  };

  const handleClose = (): void => {
    setModalVisibility(false);
    setFormDisabled(true);
    setConfirming(false);
    setPartsToDelete([]);
    setValues((prevValues: any) => ({
      ...prevValues,
      test_ids: [],
    }));
  };

  const customProps = {
    loading: confirming,
    actionText: 'Confirm',
    showCancel: true,
    cancelVariant: 'sub-primary',
    handleButtonAction,
    handleClose,
    containerClass: 'participants-modal retake-assessment-modal',
    isSubmit: true,
  };
  const handleTimezoneChange = (option): void => {
    const changeValues = {
      tz_code: option.code,
      tz_offset: option.offset,
      new_expiry_date: '',
    };
    setValues({
      ...values,
      ...changeValues,
    });
  };
  const handleDateTimeChange = (value: string): void => {
    setValues({
      ...values,
      ...{
        new_expiry_date: value,
        new_expiry_date_verbose: fullDateTime(value, values.tz_code),
      },
    });
    setMustValidate(true);
  };
  const handleToggleExtend = (): void => {
    setValues({
      ...values,
      ...{
        must_extend: !showExtend,
      },
    });
    setShowExtend(!showExtend);
    setMustValidate(true);
  };
  const togglePart = (part: any): void => {
    const partExists = partsToDelete.filter((p) => p.id === part.id);
    let updatedParts = [];
    if (partExists.length) {
      updatedParts = partsToDelete.filter((p) => p.id !== part.id);
    } else {
      updatedParts = [...partsToDelete, part];
    }
    setPartsToDelete(updatedParts);
    setValues({ ...values, test_ids: updatedParts.map((p) => p.id) });
    if (updatedParts.length) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  };
  const customInput = (
    <Input
      type="text"
      label="New Expiry Date"
      autoComplete="off"
      placeholder="Select a new expiry"
      errorTxt={errorText}
      onChange={(e) => handleDateTimeChange(e.target.value)}
    />
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    onSubmit: (): void => {
      validator();
    },
  });
  const DateTimePickerProps = {
    customInput,
    utc: values.new_expiry_date,
    handleChange: handleDateTimeChange,
    props: {
      minDate: moment(fullDateTime(candidate.expires_on, userTz)).toDate(),
    },
  };

  return (
    <Modal {...{ ...props, ...customProps, disabled: formDisabled }}>
      <>
        <h2>Request {modalFor} To Retake The Assessment</h2>
        <br />
        <p>
          Are you sure you want to remove the {modalFor.toLowerCase()}&apos;s
          results for this assessment? They will be sent an email requesting
          them to retake the selected tests.
        </p>
        <br />
        <p>
          <b>Remove their existing results and request them to retake:</b>
        </p>
        <ul>
          {parts
            .filter(
              (part) =>
                part.test_type !== null && part.test_type !== 'Interview'
            )
            .map((part) => {
              const testType =
                part.test_type === 'MCQ'
                  ? 'Concepts & Knowledge'
                  : part.test_type;
              return (
                <div className="retake-checkbox-wrapper">
                  <Checkbox
                    disabled={!part?.is_submitted}
                    key={part.id}
                    label={testType}
                    handleChange={() => {
                      if (part?.is_submitted) {
                        togglePart(part);
                      }
                    }}
                    isChecked={
                      partsToDelete.includes(part) && part?.is_submitted
                    }
                  />
                </div>
              );
            })}
        </ul>
        {!expired ? (
          <>
            <b>The current expiry date is:</b>{' '}
            {`${fullDateTime(
              candidate.expires_on,
              userTz,
              'lll [(GMT]Z[)]'
            )} ${userTz}`}
            <div className="extend-switcher">
              <p>Would you like to extend it?</p>
              <Switcher checked={showExtend} onChange={handleToggleExtend} />
            </div>
          </>
        ) : (
          <>
            <b>The invitation expired on:</b>{' '}
            {`${fullDateTime(
              candidate.expires_on,
              userTz,
              'lll [(GMT]Z[)]'
            )} ${userTz}`}
            <div className="extend-switcher">
              <p>You must extend the invitation.</p>
            </div>
          </>
        )}
        {showExtend && userDetails && userDetails.user_timezone && (
          <div className="extend-expiry">
            <FormContainer>
              <div className="container">
                <div className="row">
                  <MemoizedTimezoneDropDown
                    label="Timezone"
                    placeholder="Search Timezones..."
                    value={formik.values.tz_code}
                    handleChange={handleTimezoneChange}
                    menuPlacement="top"
                    defaultTimezones={timezones}
                  />
                  <DateTimePicker {...DateTimePickerProps} />
                </div>
              </div>
            </FormContainer>
          </div>
        )}
        <hr />
      </>
    </Modal>
  );
};
export default RetakeAssessmentModal;
