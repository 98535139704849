import React from 'react';
import { useDispatch } from 'react-redux';
import CreateTestIntro from 'components/CreateTest/CreateTestIntro';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createInterview } from 'api/interview.api';
import { appendAssessmentInterview } from 'store/reducers/interview';
import HeroImage from '../images/interview_hero_image.svg';

const CreateInterviewIntroPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const action = async (assessmentId: number): Promise<void> => {
    const response = await createInterview(assessmentId);
    const interview = response?.data?.data?.interview;
    if (interview) {
      dispatch(appendAssessmentInterview({ assessmentId, interview }));
      history.push(`/interview/${interview.id}/edit`);
    }
  };
  return (
    <div className="container">
      <Helmet>
        <title>Create Interview | alooba.com</title>
      </Helmet>
      <CreateTestIntro
        type="Interview"
        header="Alooba Interview"
        image={HeroImage}
        description="Create your structured interview here. Structured
        interviews help to reduce bias and subjectivity by ensuring every
        candidate is asked the same questions and measured against the same
        scoring rubric. This gives you an apples-for-apples comparison of
        interview performance."
        action={action}
      />
    </div>
  );
};

export default CreateInterviewIntroPage;
