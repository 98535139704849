import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorHandler } from 'react-error-boundary';
import { useToasts } from 'react-toast-notifications';
import { getOrgDetails } from 'store/actions/organization.actions';
import Input from '../Shared/Input';
import { getTimezones } from '../../api/profile-api';
import { RootState } from '../../store/rootReducer';
import Dropdown from '../Shared/Dropdown';
import api from '../../api';
import './PersonalDetails.scss';
import FormContainer from '../Shared/MainContainer/FormContainer';
import CloakingCandidateSettings from './CloakingCandidateSettings';

const PersonalDetails: React.FC = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState(true);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { organizationDetails: organization } = useSelector(
    (state: RootState) => state.organization
  );
  const [timezones, setTimezones] = useState([]);
  const [user_timezone, setUserTimezone] = useState('');
  const { addToast } = useToasts();

  const handleChange = (option): void => {
    setUserTimezone(option.code);
    formik.handleSubmit();
  };

  useEffect(() => {
    getTimezones()
      .then((res) => {
        const formattedTimezones =
          res &&
          res.data &&
          res.data.data.map((zone) => {
            return {
              label: `(GMT${zone.offset}) ${zone.code}`,
              code: zone.code,
              value: zone.code,
              offset: zone.offset,
            };
          });
        setTimezones(formattedTimezones);
        setLoading(false);
      })
      .catch((err) => handleError(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const orgId =
      userDetails &&
      userDetails.recruiter_detail &&
      userDetails.recruiter_detail.organisation_id;
    if (orgId) {
      dispatch(getOrgDetails(orgId));
    }
  }, [userDetails, dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userDetails.first_name,
      lastName: userDetails.last_name,
      emailAddress: userDetails.email,
      title:
        (userDetails.recruiter_detail && userDetails.recruiter_detail.title) ||
        '',
      role:
        userDetails.recruiter_detail &&
        userDetails.recruiter_detail.hiring_role_csv,
      user_timezone: userDetails.user_timezone,
    },
    onSubmit: () => {
      if (user_timezone) {
        api
          .patch(`/users/${userDetails.id}`, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            user_timezone,
          })
          .then((response) => {
            if (response.status === 200) {
              addToast({
                type: 'success',
                msg: 'You have successfully updated your timezone.',
              });
            } else {
              addToast({
                type: 'error',
                msg:
                  'An error occured while saving timezone. Please try again later!!',
              });
            }
          });
      }
    },
  });
  const formProps = {
    handleSubmit: formik.handleSubmit,
  };

  const switchProps = {
    organization,
    userDetails,
    loading,
  };

  return (
    <>
      <div className="personal-details">
        <h2 className="mb4">Personal Details</h2>
        <FormContainer {...formProps}>
          <div className="container">
            <div className="row">
              <Input
                id="firstName"
                type="text"
                value={formik.values.firstName}
                label="First Name"
                loading={loading}
                disabled
                autoComplete="off"
              />
              <Input
                id="lastName"
                type="text"
                loading={loading}
                value={formik.values.lastName}
                label="Last Name"
                disabled
                autoComplete="off"
              />
            </div>
            <div className="row">
              <Input
                id="emailAddress"
                loading={loading}
                type="text"
                value={formik.values.emailAddress}
                label="Email Address"
                disabled
                autoComplete="off"
              />
              <Input
                id="title"
                type="text"
                loading={loading}
                value={formik.values.title}
                label="Your Title"
                disabled
                autoComplete="off"
              />
            </div>
            <div className="row">
              <Input
                id="role"
                type="text"
                loading={loading}
                value={formik.values.role}
                label="Your Role in Hiring"
                disabled
                autoComplete="off"
              />
              <Dropdown
                id="timezone"
                dropdownOptions={timezones}
                label="Your Timezone"
                loading={loading}
                placeholder="Search Timezones..."
                value={user_timezone || userDetails.user_timezone}
                handleChange={handleChange}
              />
            </div>
          </div>
        </FormContainer>
        <CloakingCandidateSettings {...switchProps} />
      </div>
    </>
  );
};

export default PersonalDetails;
