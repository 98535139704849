import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal } from 'components/Shared/Modal';
import { Slider } from '@material-ui/core';
import { editInterview } from 'api/interview.api';
import { updateAssessmentInterview } from 'store/reducers/interview';
import './index.scss';

interface UpdateInterviewDurationModalProps {
  isShown: boolean;
  setModalVisibility: (isShown: boolean) => void;
  interview: any;
  setInterview: any;
  assessment: any;
}
const UpdateInterviewDurationModal = (
  props: UpdateInterviewDurationModalProps
): JSX.Element => {
  const dispatch = useDispatch();
  const { setModalVisibility, interview, setInterview, assessment } = props;

  const [loading, setLoading] = useState(false);
  const [newTimeLength, setNewTimeLength] = useState(interview?.duration);
  const { addToast } = useToasts();

  const handleClose = useCallback((): void => {
    // Reset modal state
    setLoading(false);

    // Hide modal
    setModalVisibility(false);
  }, [setLoading, setModalVisibility]);

  const handleChange = (e, value): void => {
    if (newTimeLength !== value * 60) {
      setNewTimeLength(value * 60);
    }
  };

  const isStepActive = (step: number): boolean => {
    return newTimeLength === step * 60;
  };

  const handleButtonAction = (): void => {
    setLoading(true);
    editInterview(assessment.id, interview.id, {
      time_length: newTimeLength,
    })
      .then(() => {
        setInterview({
          ...interview,
          duration: newTimeLength,
        });
        dispatch(
          updateAssessmentInterview({
            assessmentId: assessment.id,
            interviewId: interview.id,
            values: {
              duration: newTimeLength,
            },
          })
        );
        addToast({
          msg: 'The interview duration was updated.',
          type: 'success',
        });
      })
      .catch(() => {
        addToast({
          msg: 'There was a problem updating the interview duration.',
          type: 'error',
        });
      })
      .finally(() => {
        handleClose();
      });
  };

  const customProps = {
    loading,
    setModalVisibility,
    containerClass: 'participants-modal update-interview-duration-modal',
    actionText: 'Confirm',
    handleButtonAction,
    showCancel: true,
    cancelButtonText: 'Cancel',
    cancelVariant: 'sub-primary',
    handleClose: () => handleClose(),
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        <div className="duration-modal-header">
          <h2>Custom Interview Duration</h2>
          <small>
            How much time should be allocated to this interview? This includes
            all introductions and interview wrap-up time.
          </small>
        </div>
        <div className="slider">
          <div className="slider-container">
            <Slider
              aria-label="Time"
              defaultValue={Math.round(interview.duration / 60)}
              getAriaValueText={() => 'test'}
              onChangeCommitted={handleChange}
              valueLabelDisplay="off"
              step={15}
              marks
              min={15}
              max={120}
            />
          </div>
          <div className="time-increments">
            <p className={`${isStepActive(15) ? 'selected' : ''}`}>15 min</p>
            <p
              className={`intermediary-increment ${
                isStepActive(30) ? 'selected' : ''
              }`}
            >
              30 min
            </p>
            <p
              className={`forty-five-min ${isStepActive(45) ? 'selected' : ''}`}
            >
              45 min
            </p>
            <p
              className={`intermediary-increment ${
                isStepActive(60) ? 'selected' : ''
              }`}
            >
              1 hour
            </p>
            <p
              className={`intermediary-increment ${
                isStepActive(75) ? 'selected' : ''
              }`}
            >
              1:15 m
            </p>
            <p className={`ninety-min ${isStepActive(90) ? 'selected' : ''}`}>
              1:30 m
            </p>
            <p
              className={`intermediary-increment ${
                isStepActive(105) ? 'selected' : ''
              }`}
            >
              1:45 m
            </p>
            <p className={`${isStepActive(120) ? 'selected' : ''}`}>2 hours</p>
          </div>
        </div>
      </>
    </Modal>
  );
};
export default UpdateInterviewDurationModal;
