import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Modal } from '../Shared/Modal';
import { Button } from '../Shared';

interface RemoteIntegrationProps {
  name: string;
  logo?: string;
  loading?: boolean;
  enabled?: boolean;
  help?: React.ReactNode;
}

export const RemoteIntegrationPartner: React.FC<RemoteIntegrationProps> = ({
  name,
  logo,
  loading,
  enabled = false,
  help,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="integration-partner">
      <Button
        loading={loading}
        loadingPlaceholder={loading}
        variant="sub-primary md"
        text={enabled ? 'Connected' : 'Connect'}
        onClick={() => setShowModal(true)}
        disabled={enabled}
      />
      {loading && <Skeleton height={32} width={160} />}
      {!loading && (logo ? <img alt={name} src={logo} /> : <h4>{name}</h4>)}
      <Modal
        isShown={showModal}
        setModalVisibility={setShowModal}
        showCancel
        cancelButtonText="Close"
        cancelVariant="sub-primary"
      >
        <div>
          <h4 className="mb4">{`Connect to ${name}`}</h4>
          {help}
        </div>
      </Modal>
    </div>
  );
};

export default RemoteIntegrationPartner;
