import React from 'react';
import TimeIcon from 'images/icons/estimated-duration.svg';
import StatIcon from 'components/Shared/StatIcon';

interface TimeStatProps {
  hours?: number;
  mins?: number;
}
const TimeStat = ({ hours, mins }: TimeStatProps): JSX.Element => {
  return (
    <StatIcon
      icon={TimeIcon}
      alt="assessment time"
      tooltip="Total duration"
      noHover
    >
      <>
        {hours > 0 && (
          <>
            <h5>{hours}</h5>
            <p>Hr</p>
            <p>&nbsp;</p>
          </>
        )}
        <h5>{mins}</h5>
        <p>Min</p>
      </>
    </StatIcon>
  );
};

export default TimeStat;
