import React from 'react';
import { LoadingIcon } from 'components/Shared/Icon/Loading';
import { GridIcon } from 'components/Shared/Icon/Grid';
import './index.scss';

interface Props {
  loading?: boolean;
}
const CompletedBlank = ({ loading = true }: Props): JSX.Element => {
  return (
    <div className="participants-container blank main_white_bg">
      {loading ? (
        <LoadingIcon />
      ) : (
        <GridIcon text="No-one has completed the assessment yet" />
      )}
    </div>
  );
};
export default CompletedBlank;
