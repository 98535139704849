import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/Shared';
import { getInterview } from 'api/interview.api';
import { handleError } from 'handleError';
import SubHeader from '../Shared/SubHeader';
import InterviewHeader from './InterviewHeader';
import ScheduledDuration from '../CreateTest/ScheduledDuration';
import QuestionList from './InterviewQuestionList/index';
import InterviewQuestionDetails from './InterviewQuestionDetails';
import InterviewDuration from './InterviewDuration';
import './index.scss';
import AddSectionModal from './Modals/AddInterviewTopicModal';
import UpdateInterviewDurationModal from './Modals/UpdateInterviewDurationModal';
import { RootState } from '../../store/rootReducer';
import { setQuestionContext } from '../../store/reducers/interview';
import AddQuestionModal from './Modals/AddQuestionModal';

interface Props {
  interviewId: number;
}

const EditInterview: React.FC<Props> = ({ interviewId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [interview, setInterview] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedQuestionSkill, setSelectedQuestionSkill] = useState(null);
  const [skillQuestions, setSkillQuestions] = useState({});
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [skillMap, setSkillMap] = useState({});
  const [additionalCriteriaSkillMap, setAdditionalCriteriaSkillMap] = useState(
    {}
  );
  const [skillLength, setSkillLength] = useState(0);
  const [selectedSkillIndex, setSelectedSkillIndex] = useState(null);
  const [
    additionalCriteriaSkillLength,
    setAdditionalCriteriaSkillLength,
  ] = useState(0);
  const [questionMap, setQuestionMap] = useState({});
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState<boolean>(
    false
  );
  const [addedQuestionId, setAddedQuestionId] = useState<number | null>(null);
  const [showUpdateDurationModal, setShowUpdateDurationModal] = useState(false);
  const [newTopicPosition, setNewTopicPosition] = useState(0);
  const [
    newAdditionalCriteriaPosition,
    setNewAdditionalCriteriaPosition,
  ] = useState(0);
  const [estimatedDuration, setEstimatedDuration] = useState(0);
  const [newQuestionPosition, setNewQuestionPosition] = useState(0);
  const [forceRefetch, setForceRefetch] = useState(0);
  const { questionContext }: any = useSelector(
    (state: RootState) => state.interview
  );

  const handleForceRefetch = (): void => {
    setForceRefetch(forceRefetch + 1);
  };

  const showPreSelectedSkillModal = (skill, position): void => {
    setSelectedSkill({ ...skill });
    setNewQuestionPosition(position);
    setShowAddSectionModal(true);
  };

  const showAddInterviewTopicModal = (): void => {
    dispatch(setQuestionContext('interview-content'));
    setShowAddSectionModal(true);
  };

  const onSelectedQuestionDetailsChanged = (newValues: any): void => {
    const index = interview.questions.findIndex(
      (question) => question.id === selectedQuestion.id
    );
    const questions = [...interview.questions];
    questions[index] = { ...questions[index], ...newValues };
    setInterview({
      ...interview,
      questions,
    });
    setSelectedQuestion(questions[index]);
  };

  useEffect(() => {
    async function fetchInterview(): Promise<any> {
      setLoading(true);
      try {
        const response = await getInterview(interviewId);
        setInterview(response.data.data.interview);
        setAssessment(response.data.data.assessment);
        if (
          response.data.data.interview.questions &&
          response.data.data.interview.questions.length > 0
        ) {
          const firstQuestion = response.data.data.interview.questions[0];
          if (selectedQuestion === null) {
            setSelectedQuestion(firstQuestion);
          }

          if (selectedQuestionSkill === null) {
            let firstSkill = {
              ...(firstQuestion.primary_skill || firstQuestion.skills[0]),
              questions_type: firstQuestion.question_type.question_type,
            };
            firstSkill = {
              ...firstSkill,
              name: firstSkill.name || firstSkill.subject,
              collapsed: false,
            };
            setSelectedQuestionSkill(firstSkill);
          }
        }
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    }
    fetchInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId, forceRefetch]);

  useEffect(() => {
    if (interview) {
      let tmpEstimatedDuration = 0;
      interview.questions.forEach((question) => {
        const questionType = question.question_type.question_type;
        if (
          questionType !== 'Additional Criteria' &&
          questionType !== 'MCQ Rating'
        ) {
          tmpEstimatedDuration += question.duration;
        }
      });
      setEstimatedDuration(tmpEstimatedDuration);
    }
  }, [interview, setEstimatedDuration]);

  const questionPosition = useMemo(() => {
    if (
      newAdditionalCriteriaPosition &&
      questionContext === 'additional-criteria'
    ) {
      return newAdditionalCriteriaPosition;
    }
    if (newQuestionPosition) return newQuestionPosition;
    return newTopicPosition;
  }, [
    newAdditionalCriteriaPosition,
    questionContext,
    newQuestionPosition,
    newTopicPosition,
  ]);

  return (
    <div>
      <SubHeader>
        <InterviewHeader
          loading={loading}
          assessment={assessment}
          interview={interview}
        />
      </SubHeader>
      <div className="full-width-container">
        <h3 className="mb4">Interview Configuration</h3>

        <div className="interview-configuration main-container">
          <div className="top-section">
            <div className="add-section">
              {skillLength !== 0 && (
                <Button
                  variant="sub-primary md"
                  text="Add Interview Topic"
                  onClick={() => showAddInterviewTopicModal()}
                  addButton
                />
              )}
            </div>
            <ScheduledDuration
              loading={loading}
              duration={interview ? interview.duration : null}
              handleEdit={() => setShowUpdateDurationModal(true)}
              isEditable
            />
          </div>
          <div className="content-wrapper">
            <QuestionList
              assessmentId={assessment?.id}
              interview={interview}
              setInterview={setInterview}
              loading={loading}
              questions={interview ? interview.questions : null}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              selectedQuestionSkill={selectedQuestionSkill}
              setSelectedQuestionSkill={setSelectedQuestionSkill}
              skillQuestions={skillQuestions}
              setSkillQuestions={setSkillQuestions}
              setQuestionMap={setQuestionMap}
              setSkillMap={setSkillMap}
              setAdditionalCriteriaSkillMap={setAdditionalCriteriaSkillMap}
              setShowAddSectionModal={setShowAddSectionModal}
              setNewTopicPosition={setNewTopicPosition}
              setNewAdditionalCriteriaPosition={
                setNewAdditionalCriteriaPosition
              }
              showPreSelectedSkillModal={showPreSelectedSkillModal}
              additionalCriteriaSkillLength={additionalCriteriaSkillLength}
            />
            <div className="question-section question-details">
              <InterviewQuestionDetails
                interviewId={interviewId}
                onQuestionUpdate={onSelectedQuestionDetailsChanged}
                question={selectedQuestion}
                isEditable
              />
            </div>
          </div>
          <InterviewDuration
            loading={loading}
            estimatedDuration={estimatedDuration}
            interview={interview}
          />
        </div>
      </div>
      {!loading && (
        <>
          <AddSectionModal
            isShown={showAddSectionModal}
            setModalVisibility={setShowAddSectionModal}
            interview={interview}
            skillMap={skillMap}
            additionalCriteriaSkillMap={additionalCriteriaSkillMap}
            assessment={assessment}
            position={questionPosition}
            setNewTopicPosition={setNewTopicPosition}
            setNewQuestionPosition={setNewQuestionPosition}
            preSelectedSkill={selectedSkill}
            setPreSelectedSkill={setSelectedSkill}
            questionMap={questionMap}
            skillQuestions={skillQuestions}
            forceRefetch={handleForceRefetch}
            setSkillLength={setSkillLength}
            setAdditionalCriteriaSkillLength={setAdditionalCriteriaSkillLength}
            setShowAddQuestionModal={setShowAddQuestionModal}
            selectedSkill={selectedSkillIndex}
            setSelectedSkill={setSelectedSkillIndex}
            addedQuestionId={addedQuestionId}
            setAddedQuestionId={setAddedQuestionId}
          />
          <UpdateInterviewDurationModal
            isShown={showUpdateDurationModal}
            setModalVisibility={setShowUpdateDurationModal}
            interview={interview}
            setInterview={setInterview}
            assessment={assessment}
          />
          <AddQuestionModal
            isShown={showAddQuestionModal}
            setModalVisibility={setShowAddQuestionModal}
            setTopicModalVisibility={setShowAddSectionModal}
            selectedSkill={selectedSkill}
            selectedSkillIndex={selectedSkillIndex}
            setAddedQuestionId={setAddedQuestionId}
          />
        </>
      )}
    </div>
  );
};

export default EditInterview;
