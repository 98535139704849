import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    color: '#004385',
  },
  indicator: {
    backgroundColor: '#0E0E2C',
    zIndex: 2,
  },
  tabSelected: {
    color: '#0E0E2C',
    fontWeight: 'bolder',
  },
  tabs: {
    '& .MuiTab-wrapper': {
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      lineHeight: '22px',
      /* identical to box height */
      letterSpacing: '0.02em',
      textAlign: 'left',
      textTransform: 'none',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 0,
      marginRight: '16px',
    },
  },
  tab: {
    '& .MuiBox-root': {
      paddingTop: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
    },
  },
  iconLabelWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

export const dropdownStyles = {
  placeholder: (styles: CSSStyleDeclaration): any => {
    return {
      ...styles,
      color: '#0e0e2c',
      '@media only screen and (max-width: 400px)': {
        fontSize: '16px',
      },
    };
  },
  option: (
    styles: CSSStyleDeclaration,
    { isFocused }: { isFocused: boolean }
  ): any => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f5f5f5 !important' : null,
      color: '#0e0e2c',
    };
  },
  control: (styles: CSSStyleDeclaration): any => {
    return {
      ...styles,
      position: 'fixed',
      top: '135px',
      zIndex: '19',
      width: 'calc(100% - 190px)',
      maxWidth: '350px',
      padding: '3px 0',
      border: '1px solid #ECF1F4',
      boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
    };
  },
  valueContainer: (styles: CSSStyleDeclaration): any => {
    return {
      ...styles,
      height: '36px',
    };
  },
  menu: (styles: CSSStyleDeclaration): any => {
    return {
      ...styles,
      position: 'fixed',
      top: '128px',
      zIndex: '20',
      width: 'calc(100% - 170px)',
      maxWidth: '350px',
    };
  },
  indicatorSeparator: (): any => {
    return { display: 'none' };
  },
  dropdownIndicator: (styles: CSSStyleDeclaration): any => {
    return {
      ...styles,
      color: '#004385',
      '&:hover': {
        color: '#004385',
      },
    };
  },
};

export const dropdownWithTrialBannerStyles = {
  ...dropdownStyles,
  control: (styles: CSSStyleDeclaration): any => {
    return {
      ...styles,
      position: 'fixed',
      top: '215px',
      zIndex: '19',
      width: 'calc(100% - 190px)',
      maxWidth: '350px',
      padding: '3px 0',
      border: '1px solid #ECF1F4',
      boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
    };
  },
};
