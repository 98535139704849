import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Button, NoPageContent } from 'components/Shared';
import NoAccess from 'components/Assessment/NoAccess';
import { fetchInterviews } from 'store/actions/interview.actions';
import InterviewCard from './InterviewCard';
import InterviewCandidates from '../Interview/InterviewCandidates';
import { assessmentContext } from '../Assessment';
import authorize from '../../Authorization/Check';
import { User, getUserFromToken } from '../../helpers/user';
import './index.scss';

const AssessmentInterviews: React.FC = () => {
  const { assessment } = useContext(assessmentContext);
  const { interviews } = useSelector((state: RootState) => state.interview);
  const [loading, setLoading] = useState<boolean>(true);
  const [assessmentInterviews, setAssessmentInterviews] = useState<any>([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const user: User = getUserFromToken();
  const hasAccess = user.access.interview;

  const handleCreateInterviewClick = (): void => {
    const createInterviewURL = `/interview/create?assessment=${assessment.id}`;
    history.push(createInterviewURL);
  };

  // On load, fetch assessment interviews
  useEffect(() => {
    dispatch(fetchInterviews(assessment.id));
  }, [dispatch, assessment]);

  // Interviews are loaded
  useEffect(() => {
    if (loading && interviews[assessment.id]?.getting === false) {
      setLoading(false);
      setAssessmentInterviews(interviews[assessment.id].interviews);
    }
  }, [loading, assessment, interviews]);

  return !hasAccess ? (
    <NoAccess feature="interview" />
  ) : (
    <>
      <div className="interviews-top-bar">
        <h2 className="section-title">
          {loading && <Skeleton height={20} width={200} />}
          {!loading && 'Interviews'}
          {authorize('interview', 'add') ? (
            <Button
              addButton
              onClick={handleCreateInterviewClick}
              variant="primary md right"
              text="Create Interview"
            />
          ) : (
            ''
          )}
        </h2>
      </div>
      {loading ? (
        <>{/* TODO: Add loading state here */}</>
      ) : (
        <>
          {assessmentInterviews?.length === 0 ? (
            <NoPageContent
              btnTxt="Create Interview"
              title={`There Are No ${assessment.recruiter_test_name} Interviews Yet`}
              desc="No interviews have been created for this assessment yet. Click on 'Create Interview' to start creating an interview for this assessment"
              handleClick={handleCreateInterviewClick}
            />
          ) : (
            <>
              <div className="interviews">
                {assessmentInterviews?.map((interview) => (
                  <InterviewCard
                    interview={interview}
                    key={`interview-${interview.id}`}
                  />
                ))}
              </div>
              <InterviewCandidates assessment={assessment} />
            </>
          )}
        </>
      )}
    </>
  );
};
export default AssessmentInterviews;
