import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssessmentPurpose } from 'components/Assessment';

interface HiringStatus {
  id: number;
  position: number;
  status: string;
}

const defaultHiringStatus = { id: 0, position: 0, status: 'Not yet Evaluated' };

interface AssessmentState {
  assessments: any[] | null;
  firstPageHash: string | null;
  totalAssessments: number;
  loading: boolean;
  filtering: boolean;
  loadingMore: boolean;
  hasFiltered: boolean;
  hasActiveSearch: boolean;
  hasSelectedGroups: boolean;
  lastError: string | null;
}

type AssessmentStateByPurpose = {
  [purpose in AssessmentPurpose]: AssessmentState;
};

interface ExtraAssessmentState {
  currentAssessment: any;
  currentTab: string;
  participantsView: 'table' | 'kanban';
  hiringStatuses: any[];
}

const initialSingleState: AssessmentState = {
  assessments: null,
  firstPageHash: null,
  totalAssessments: 0,
  loading: true,
  filtering: false,
  loadingMore: false,
  hasFiltered: false,
  hasActiveSearch: false,
  hasSelectedGroups: false,
  lastError: null,
};

const initialState: AssessmentStateByPurpose & ExtraAssessmentState = {
  hiring: initialSingleState,
  junior: initialSingleState,
  ld: initialSingleState,
  currentAssessment: {},
  currentTab: 'overview',
  participantsView: 'table',
  hiringStatuses: [defaultHiringStatus],
};

const AssessmentReducer = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    getAssessmentsStart: (state, action: PayloadAction<any>) => {
      const { purpose, isFiltering } = action.payload;
      if (!state[purpose].assessments) {
        state[purpose].loading = true;
      } else if (state[purpose].hasFiltered) {
        /**
         * This condition prevents the "Applying filters..." message
         * from showing on the regular loading states
         */
        state[purpose].filtering = true;
      }
      if (isFiltering) {
        state[purpose].filtering = true;
      }
    },
    getAssessmentsSuccess: (state, action: PayloadAction<any>) => {
      const { purpose } = action.payload;
      if (action.payload.firstPageHash !== state[purpose].firstPageHash) {
        state[purpose].assessments = action.payload.response.data;
        state[purpose].totalAssessments = action.payload.response.total;
        state[purpose].firstPageHash = action.payload.firstPageHash;
        state[purpose].loading = false;
      }
      state[purpose].filtering = false;
    },
    getAssessmentsFailure: (state, action: PayloadAction<any>) => {
      const { purpose } = action.payload;
      state[purpose].lastError = action.payload.message;
      state[purpose].loading = false;
      state[purpose].filtering = false;
    },
    appendAssessmentsStart: (state, action: PayloadAction<any>) => {
      const { purpose } = action.payload;
      state[purpose].loadingMore = true;
    },
    appendAssessmentsSuccess: (state, action: PayloadAction<any>) => {
      const { purpose } = action.payload;
      if (state[purpose].assessments) {
        state[purpose].assessments = [
          ...state[purpose].assessments,
          ...action.payload.response.data,
        ];
        state[purpose].totalAssessments = action.payload.response.total;
        state[purpose].loadingMore = false;
      }
    },
    appendAssessmentsFailure: (state, action: PayloadAction<any>) => {
      const { purpose } = action.payload;
      state[purpose].lastError = action.payload.message;
      state[purpose].loadingMore = false;
    },
    incrementTotal: (state, action: PayloadAction<any>) => {
      const { purpose } = action.payload;
      state[purpose].totalAssessments += 1;
    },
    decrementTotal: (state, action: PayloadAction<any>) => {
      const { purpose } = action.payload;
      state[purpose].totalAssessments -= 1;
    },
    setHasFiltered: (state, action: PayloadAction<any>) => {
      const { purpose, value } = action.payload;
      state[purpose].hasFiltered = value;
    },
    setHasActiveSearch: (state, action: PayloadAction<any>) => {
      const { purpose, value } = action.payload;
      state[purpose].hasActiveSearch = value;
    },
    setHasSelectedGroups: (state, action: PayloadAction<any>) => {
      const { purpose, value } = action.payload;
      state[purpose].hasSelectedGroups = value;
    },
    selectAssessment: (state, action: PayloadAction<any>) => {
      const { assessment } = action.payload;
      state.currentAssessment = assessment;
    },
    incrementCandidateCount: (
      state,
      action: PayloadAction<{
        id: number;
        purpose: string;
      }>
    ) => {
      const { id, purpose } = action.payload;
      if (state[purpose].assessments) {
        for (let i = 0; i < state[purpose].assessments.length; i += 1) {
          if (state[purpose].assessments[i].id === id) {
            const currentCount = state[purpose].assessments[i].candidates_count;
            state[purpose].assessments[i].candidates_count = currentCount + 1;
            break;
          }
        }
      }
    },
    updateAssessmentCloaking: (state, action: PayloadAction<any>) => {
      const { id, cloak_candidates, purpose } = action.payload;
      for (let i = 0; i < state[purpose].assessments.length; i += 1) {
        if (state[purpose].assessments[i].id === id) {
          if (cloak_candidates !== undefined) {
            state[purpose].assessments[i].cloak_candidates = cloak_candidates;
            break;
          }
        }
      }
    },
    updateAssessmentInvitedStatus: (state, action: PayloadAction<any>) => {
      const { id, invited_status, purpose } = action.payload;
      for (let i = 0; i < state[purpose].assessments.length; i += 1) {
        if (state[purpose].assessments[i].id === id) {
          if (invited_status !== undefined) {
            state[purpose].assessments[i].has_invited_yourself = invited_status;
            break;
          }
        }
      }
    },
    updateCurrentAssessment: (state, action: PayloadAction<any>) => {
      const { assessment } = action.payload;
      state.currentAssessment = assessment;
    },
    getHiringStatusesSuccess: (
      state,
      action: PayloadAction<HiringStatus[]>
    ) => {
      state.hiringStatuses = action.payload;
    },
    selectTab: (state, action: PayloadAction<string>) => {
      const tab = action.payload;
      state.currentTab = tab;
    },
    toggleParticipantsView: (state) => {
      state.participantsView =
        state.participantsView === 'kanban' ? 'table' : 'kanban';
    },
  },
});

export const {
  getAssessmentsFailure,
  getAssessmentsSuccess,
  getAssessmentsStart,
  getHiringStatusesSuccess,
  appendAssessmentsFailure,
  appendAssessmentsSuccess,
  appendAssessmentsStart,
  incrementTotal,
  decrementTotal,
  setHasFiltered,
  setHasActiveSearch,
  setHasSelectedGroups,
  selectAssessment,
  incrementCandidateCount,
  updateAssessmentCloaking,
  updateAssessmentInvitedStatus,
  updateCurrentAssessment,
  selectTab,
  toggleParticipantsView,
} = AssessmentReducer.actions;
export default AssessmentReducer.reducer;
