import Assessment from 'components/Assessment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { activatePageHeader } from '../store/reducers/layout';

interface UrlParams {
  formattedAssessmentName?: string;
}

const AssessmentPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(activatePageHeader());
  const { formattedAssessmentName }: UrlParams = useParams();
  return (
    <div className="container">
      <Helmet>
        <title>
          {`Hiring Assessment ${decodeURIComponent(
            formattedAssessmentName.replace(/-/g, ' ')
          )} | alooba.com`}
        </title>
      </Helmet>
      <Assessment />
    </div>
  );
};

export default AssessmentPage;
