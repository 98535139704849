import React from 'react';
import PieChart from '../Shared/PieChart';

interface Frequency {
  label: string;
  count: number;
}

interface LocationStats {
  total: number;
  summary: Frequency[];
}
const LocationPieChart: React.FC<{
  locationStats: LocationStats;
  height: number;
}> = ({ locationStats, height }): JSX.Element => {
  const locationDistribution = locationStats.summary.map(({ label, count }) => {
    return { label, value: count };
  });
  return <PieChart height={height} data={locationDistribution} />;
};

export default LocationPieChart;
