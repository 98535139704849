import { AxiosResponse } from 'axios';
import api from './index';

export async function getUser(id?: string | null): Promise<AxiosResponse> {
  return api.get(`${process.env.REACT_APP_SERVER_URL}/users/${id}`, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
    },
  });
}

export async function getTimezones(): Promise<AxiosResponse> {
  return api.get(`${process.env.REACT_APP_SERVER_URL}/users/timezones`, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
    },
  });
}
