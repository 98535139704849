import React from 'react';
import './QuestionDuration.scss';
import ClockIcon from '../../images/icons/clock.svg';

interface Props {
  duration: number;
}

const QuestionDuration: React.FC<Props> = ({ duration }) => {
  const minutes = Math.ceil(duration / 60);

  return (
    <div className="question-duration">
      <img className="icon" src={ClockIcon} alt="clock icon" />
      <div className="value">{minutes}</div>
      <div className="suffix">min</div>
    </div>
  );
};

export default QuestionDuration;
