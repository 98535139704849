import Groups from 'components/Groups';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { deactivatePageHeader } from '../store/reducers/layout';

const GroupsPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <div className="container">
      <Helmet>
        <title>Groups | alooba.com</title>
      </Helmet>
      <Groups />
    </div>
  );
};

export default GroupsPage;
