import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { hasAdminPrivileges, hasCompletedSignup, hasPaid } from 'helpers/user';

const COMPLETE_ACCOUNT_SETUP_PATH = '/complete-account-setup';
const CHOOSE_SUBSCRIPTION_PLAN_PATH = '/choose-subscription-plan';
const NO_ACTIVE_SUBSCRIPTION_PATH = '/no-active-subscription';
const MAIN_APP_PATH = '/alooba-assess';
const PUBLIC_ROUTES = [
  'test-completed/*',
  'feedback/nps/*',
  'forbidden',
  '/recruiter-invitation/*',
  'password-reset',
  'login',
];

const SPECIAL_ALLOWED_ROUTES = ['manage-users'];

const useAuthRedirect = (access_token): void => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = location.search.includes('trial_code')
      ? location.search
      : '';

    const isPublicRoute = PUBLIC_ROUTES.some((route) =>
      new RegExp(route).test(location.pathname)
    );
    if (isPublicRoute) return;

    if (
      SPECIAL_ALLOWED_ROUTES.some((route) =>
        new RegExp(route).test(location.pathname)
      )
    ) {
      return;
    }

    if (
      !hasCompletedSignup(access_token) &&
      location.pathname !== COMPLETE_ACCOUNT_SETUP_PATH
    ) {
      history.push(`${COMPLETE_ACCOUNT_SETUP_PATH}${queryParams}`);
    } else if (hasCompletedSignup(access_token) && !hasPaid()) {
      if (
        hasAdminPrivileges() &&
        location.pathname !== CHOOSE_SUBSCRIPTION_PLAN_PATH
      ) {
        history.push(`${CHOOSE_SUBSCRIPTION_PLAN_PATH}${queryParams}`);
      } else if (
        !hasAdminPrivileges() &&
        NO_ACTIVE_SUBSCRIPTION_PATH !== location.pathname
      ) {
        history.push(`${NO_ACTIVE_SUBSCRIPTION_PATH}${queryParams}`);
      }
    } else if (
      hasCompletedSignup(access_token) &&
      hasPaid() &&
      [
        COMPLETE_ACCOUNT_SETUP_PATH,
        CHOOSE_SUBSCRIPTION_PLAN_PATH,
        NO_ACTIVE_SUBSCRIPTION_PATH,
      ].includes(location.pathname)
    ) {
      history.push(MAIN_APP_PATH);
    }
  }, [access_token, history, location.pathname, location.search]);
};

export default useAuthRedirect;
