import { TableField } from 'components/CandidateResultsList/completedTableFields';

export type HiringTeamUser = {
  id: number;
  first_name: string;
  last_name: string;
  organisation_id: number;
  Name: string;
  Email: string;
  'Last Login': string | null;
  'Added On': string | null;
  Permissions: string;
  'Phone Number': string | null;
  'MFA Enabled': boolean;
  groups?: any[];
  Status: string;
};

export const selectFields = [
  'Name',
  'Email',
  'Last Login',
  'Added On',
  'Permissions',
  'Phone Number',
  'MFA Enabled',
  'Status',
];

export const tableFields: TableField[] = [
  {
    label: 'Name',
    sticky: 'left',
    scroll: 'end',
  },
  {
    label: 'Email',
  },
  {
    label: 'Last Login',
    type: 'datetime',
  },
  {
    label: 'Added On',
    type: 'datetime',
  },
  {
    label: 'Permissions',
  },
  {
    label: 'Phone Number',
    nonSortable: true,
  },
  {
    label: 'MFA Enabled',
    nonSortable: true,
  },
  {
    label: 'Status',
    nonSortable: true,
  },
];
