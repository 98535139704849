import { useEffect } from 'react';

let updateSizesTimeout;
let resizeListeners = [];

const currentWindowSize = {
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
};

const useWindowSize = (
  onResize?: () => void
): {
  windowWidth: number;
  windowHeight: number;
} => {
  const handleResize = (): void => {
    clearTimeout(updateSizesTimeout);
    updateSizesTimeout = setTimeout(() => {
      currentWindowSize.windowWidth = window.innerWidth;
      currentWindowSize.windowHeight = window.innerHeight;
      resizeListeners.forEach((listener) => listener());
    }, 200);
  };

  useEffect(() => {
    if (onResize) {
      resizeListeners.push(onResize);
      onResize();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      resizeListeners = resizeListeners.filter((val) => val !== onResize);
    };
  }, [onResize]);

  return currentWindowSize;
};

export default useWindowSize;
