import React, { useState, useEffect, useRef, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import StatIcon from '../StatIcon';
import ViewMoreIcon from '../../../images/icons/view-more-blue.svg';

export enum MenuPositions {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

const ActionMenuItem = React.forwardRef<HTMLDivElement, any>((props, ref) => (
  <div ref={ref}>
    <StatIcon {...props} />
  </div>
));

interface MenuPosition {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

interface ActionMenuProps {
  id: string;
  items: ReactNode[];
  customStyles?: React.CSSProperties;
  position?: MenuPositions;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
  id,
  items,
  customStyles,
  position = MenuPositions.BottomLeft,
}) => {
  const [showDropdown, setDropdown] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>({});
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showDropdown && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      let newMenuPosition: MenuPosition = {};
      switch (position) {
        case MenuPositions.TopLeft:
          newMenuPosition = {
            bottom: window.innerHeight - rect.top,
            right: window.innerWidth - rect.left,
          };
          break;
        case MenuPositions.TopRight:
          newMenuPosition = {
            bottom: window.innerHeight - rect.top,
            left: rect.right,
          };
          break;
        case MenuPositions.BottomRight:
          newMenuPosition = {
            top: rect.bottom - 20,
            left: rect.left - 20,
          };
          break;
        default:
          // MenuPositions.BottomLeft
          newMenuPosition = {
            top: rect.bottom - 20,
            right: window.innerWidth - rect.right - 20,
          };
          break;
      }
      setMenuPosition(newMenuPosition);
    }
  }, [showDropdown, position]);

  const handleDropdown = (e: React.SyntheticEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    setDropdown(!showDropdown);
  };

  const handleMouseLeave = (e: React.SyntheticEvent): void => {
    e.stopPropagation();
    setDropdown(false);
  };

  const dropdown = showDropdown && (
    <div
      className="action-menu-portal"
      style={{
        position: 'fixed',
        ...menuPosition,
        ...customStyles,
      }}
      onMouseLeave={handleMouseLeave}
    >
      <div className="hitbox">&ensp;</div>
      <div className="action-menu">
        {items.map((item) => {
          return item;
        })}
      </div>
    </div>
  );

  return (
    <div
      className="action-menu-wrapper"
      data-testid={`wrapper-${id}`}
      onMouseLeave={handleMouseLeave}
    >
      <ActionMenuItem
        id={`stat-icon-${id}`}
        handleImageClick={handleDropdown}
        icon={ViewMoreIcon}
        alt="view-more"
        highlighted={showDropdown}
        ref={buttonRef}
      />
      {dropdown && ReactDOM.createPortal(dropdown, document.body)}
    </div>
  );
};

export default ActionMenu;
