import React, { useState, useRef, useContext } from 'react';
import { faTrashAlt, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stopEvent } from 'helpers/events';
import { useToasts } from 'react-toast-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  setCandidateAsBenchmark,
  updateAssessmentData,
} from 'api/assessment.api';
import { handleError } from 'handleError';
import { User, getUserFromToken } from '../../helpers/user';
import authorize from '../../Authorization/Check';
import InterviewIcon from '../../images/icons/interview-blue.svg';
import ActionMenu from '../Shared/Dropdown/ActionMenu';
import DeleteCandidateAssessmentModal from './DeleteCandidateAssessmentModal';
import InterviewInviteModal from './InterviewInviteModal';
import { assessmentContext } from '../Assessment';
import { RefreshContext } from '../Participants/context/refresh';

interface ActionMenuProps {
  row: any;
}
const ActionMenuContainer: React.FC<any> = ({
  row,
}: ActionMenuProps): JSX.Element => {
  const { assessment, setAssessment } = useContext(assessmentContext);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [
    showInterviewInviteModal,
    setShowInterviewInviteModal,
  ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useContext(RefreshContext);
  const { addToast } = useToasts();
  const user: User = getUserFromToken();

  const handleSetBenchmark = async (
    e: React.SyntheticEvent<EventTarget>
  ): Promise<any> => {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    updateAssessmentData({
      id: assessment.id,
      passing_score:
        row.Benchmark === row.candidate_test_id
          ? null
          : Math.round(row['Total Score']),
    })
      .then(() => {
        return setCandidateAsBenchmark(assessment.id, {
          candidate_test_id: row.candidate_test_id,
        });
      })
      .then((response) => {
        if (response.data) {
          if (row.Benchmark === row.candidate_test_id) {
            addToast({
              type: 'success',
              msg: `${row.Name} has been removed as Benchmark.`,
            });
            setAssessment({
              ...assessment,
              benchmark_candidate_test: null,
              passing_score: null,
            });
          } else {
            if (row['Total Score'] >= 10 && row['Total Score'] <= 95) {
              setAssessment({
                ...assessment,
                benchmark_candidate_test: {
                  ...assessment.benchmark_candidate_test,
                  total_score: {
                    scored_percent: Math.round(row['Total Score']),
                  },
                },
                passing_score: Math.round(row['Total Score']),
              });
            }
            addToast({
              type: 'success',
              msg: `${row.Name} has been set as Benchmark.`,
            });
          }
          setFirstLoad(!firstLoad);
        } else {
          addToast({
            type: 'error',
            msg: response.data.message,
          });
        }
      })
      .catch((err) => {
        let msg;
        if (err.response.status === 422) {
          [msg] = Object.values(err.response.data).flat(2);
        } else {
          msg = 'Unable to set Participant as Benchmark';
        }
        addToast({
          type: 'error',
          msg,
        });
        handleError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteModalProps = useRef(null);
  deleteModalProps.current = {
    setModalVisibility: setShowDeleteModal,
    isShown: showDeleteModal,
    row,
  };
  const interviewInviteModalProps = useRef(null);
  interviewInviteModalProps.current = {
    setModalVisibility: setShowInterviewInviteModal,
    isShown: showInterviewInviteModal,
    candidate: row,
  };

  const toggleDeleteModal = (e: React.SyntheticEvent<EventTarget>): void => {
    stopEvent(e);
    setShowDeleteModal(!showDeleteModal);
    // TO-DO update modal content
  };

  const handleInviteToInterviewClick = (
    e: React.SyntheticEvent<EventTarget>
  ): void => {
    stopEvent(e);
    setShowInterviewInviteModal(true);
  };

  const setBenchmarkRow = (): any => {
    if (row.Benchmark === row.candidate_test_id) {
      return (
        <p
          onClick={handleSetBenchmark}
          onKeyPress={handleSetBenchmark}
          role="presentation"
          tabIndex={-1}
        >
          <FontAwesomeIcon className="icon" icon={faFlag} />
          <span style={{ marginRight: '5px' }}>Remove as benchmark</span>
          {isLoading && <CircularProgress size={14} />}
        </p>
      );
    }
    if (row.Status === 'Requires Evaluation') {
      return null;
    }
    return (
      <p
        onClick={handleSetBenchmark}
        onKeyPress={handleSetBenchmark}
        role="presentation"
        tabIndex={-1}
      >
        <FontAwesomeIcon className="icon" icon={faFlag} />
        <span style={{ marginRight: '5px' }}>Set as benchmark</span>
        {isLoading && <CircularProgress size={14} />}
      </p>
    );
  };
  const inviteToInterviewRow = (): any => {
    // Only show the invite to interview if the assessment isn't for
    // Alooba Growth, the user's organization has the interview product
    // enabled, and the user is authorized to invite candidates to interviews
    if (
      assessment.purpose !== 'ld' &&
      !assessment.is_archived &&
      user.access.interview &&
      authorize('interview-candidate', 'add')
    ) {
      return (
        <p
          onClick={handleInviteToInterviewClick}
          onKeyPress={handleInviteToInterviewClick}
          role="presentation"
          tabIndex={-1}
        >
          <img className="icon" src={InterviewIcon} alt="Interview icon" />
          Invite to Interview
        </p>
      );
    }
    return <></>;
  };
  const deleteRow = (): any => {
    if (row['Delete Assessment']) {
      return (
        <p
          data-testid="delete-candidate-assessment-button"
          onClick={toggleDeleteModal}
          onKeyPress={toggleDeleteModal}
          role="presentation"
          tabIndex={-1}
        >
          <FontAwesomeIcon className="icon" icon={faTrashAlt} />
          <span>Delete Assessment</span>
        </p>
      );
    }
    return <></>;
  };
  const isErased =
    row.data_erasure_request_id !== null &&
    row.data_erasure_request_id !== undefined;
  const actionMenuItems = [
    setBenchmarkRow(),
    ...(isErased ? [] : [inviteToInterviewRow()]),
    deleteRow(),
  ];
  return (
    <>
      <ActionMenu
        id={`candidate-actions-menu-${row.candidate_test_id}`}
        items={actionMenuItems}
      />
      <DeleteCandidateAssessmentModal {...deleteModalProps.current} />
      {interviewInviteModalProps.current.isShown ? (
        <InterviewInviteModal {...interviewInviteModalProps.current} />
      ) : (
        ''
      )}
    </>
  );
};
export default ActionMenuContainer;
