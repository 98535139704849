import React, { useEffect, useState } from 'react';
import Markdown from 'components/Shared/Markdown/Markdown';
import LightTooltip from '../../Tooltip/LightTooltip';
import CheckIcon from '../../../../images/icons/check-color.svg';
import CrossIcon from '../../../../images/icons/cross-color.svg';
import './AnswerDetails.scss';

export interface Props {
  answer: any;
  isCorrect: boolean;
  isCandidateAnswer?: boolean;
  subjects?: string[];
}

const AnswerOption: React.FC<Props> = ({
  answer,
  isCorrect,
  isCandidateAnswer,
  subjects,
}) => {
  // Determine answer color and icon
  const iconSrc = isCorrect ? CheckIcon : CrossIcon;
  const questionAnswerClass = isCorrect ? 'correct' : 'wrong';
  const [tooltipText, setTooltipText] = useState('');

  const positionMap = {
    1: 'a',
    2: 'b',
    3: 'c',
    4: 'd',
    5: 'e',
  };

  useEffect(() => {
    let text = '';
    if (isCorrect) {
      text = 'Expected answer';
    }
    if (isCandidateAnswer) {
      text = "Candidate's answer";
    }
    setTooltipText(text);
  }, [isCorrect, isCandidateAnswer]);

  return (
    <LightTooltip title={tooltipText} placement="top" arrow>
      <div className={`question-answer ${questionAnswerClass}`}>
        {answer && (
          <div className={`left-info ${questionAnswerClass}`}>
            <p>
              <b>{positionMap[answer.position]}</b>
            </p>
          </div>
        )}
        <div className="question-answer-text">
          <Markdown
            className="question-background-collapsable long-str-wrap"
            content={answer?.answer}
            subjects={subjects}
          />
        </div>
        <div className={`right-icon ${questionAnswerClass}`}>
          <img src={iconSrc} alt={`Answer icon ${isCorrect}`} />
        </div>
      </div>
    </LightTooltip>
  );
};
export default AnswerOption;
