import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

axiosBetterStacktrace(axios);

export async function getUserLocationData(): Promise<any> {
  const url = `https://ipinfo.io`;
  const response = await axios.get(url);
  return response;
}
