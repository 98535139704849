import React from 'react';
import { Helmet } from 'react-helmet';
import ForbiddenSvg from '../../images/403.svg';
import MinimalLayout from '../Shared/Layout/MinimalLayout';
import './Forbidden.scss';

const Forbidden: React.FC = () => (
  <MinimalLayout>
    <div className="error-page-container">
      <Helmet>
        <title>Access Denied | alooba.com</title>
      </Helmet>
      <img
        src={ForbiddenSvg}
        alt="403 - Access Denied"
        style={{
          width: '40%',
          minWidth: '200px',
          maxWidth: '500px',
          marginBottom: '20px',
        }}
      />
      <h2>Access Denied</h2>
      <br />
      <p>You don&apos;t have permission to access the page you requested.</p>
    </div>
  </MinimalLayout>
);

export default Forbidden;
