import React from 'react';
import { useHistory } from 'react-router-dom';
import AddCandidateIcon from 'images/add_candidates.svg';
import ImportCandidateIcon from 'images/import_candidates.svg';
import ConnectATSIcon from 'images/connect_to_ats.svg';
import { capitalizeFirstLetter } from 'helpers/text';
import Button from '../../../Button';

interface InviteCandidateSuccessProps {
  assessment: any;
  candidateOrEmployee: string;
  setCurrentStep: any;
}

const InviteCandidateSuccess = (
  props: InviteCandidateSuccessProps
): JSX.Element => {
  const history = useHistory();
  const goToATS = (): void => {
    history.push(`/organization-settings#ats-integrations`);
  };
  const { assessment, candidateOrEmployee, setCurrentStep } = props;
  return (
    <>
      <h2>
        Invite {candidateOrEmployee}s to:{' '}
        <b>{assessment.recruiter_test_name}</b>
      </h2>
      <br />
      <div
        className={`select-invite-type ${
          assessment.purpose === 'ld' ? 'small' : ''
        }`}
      >
        <div className="card main-container">
          <img src={AddCandidateIcon} alt="Invite candidate" />
          <h2>Invite {capitalizeFirstLetter(candidateOrEmployee)}</h2>
          <div className="invite-candidate-text">
            You can manually invite one {candidateOrEmployee} at a time.
          </div>
          <Button
            type="button"
            onClick={() => setCurrentStep('invite-candidate')}
            variant="primary md"
            text={`Invite ${capitalizeFirstLetter(candidateOrEmployee)}`}
            id="invite-manual-button"
          />
        </div>

        <div className="card main-container">
          <img src={ImportCandidateIcon} alt="Import candidate" />
          <h2>Import {capitalizeFirstLetter(candidateOrEmployee)}s</h2>
          <div className="invite-candidate-text">
            You can import a file of {candidateOrEmployee}s.
          </div>
          <Button
            type="button"
            onClick={() =>
              window
                .open(
                  `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/add-candidate-import?purpose=${assessment.purpose}&test_id=${assessment.id}`,
                  '_self'
                )
                .focus()
            }
            variant="primary md"
            text={`Import ${capitalizeFirstLetter(candidateOrEmployee)}s`}
          />
        </div>

        {assessment.purpose !== 'ld' && (
          <div className="card main-container">
            <img src={ConnectATSIcon} alt="Connect to ATS" />
            <h2>Connect to ATS</h2>
            <div className="invite-candidate-text">
              You can connect directly to your Applicant Tracking System.
            </div>
            <Button
              type="button"
              onClick={goToATS}
              variant="primary md"
              text="Connect to ATS"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InviteCandidateSuccess;
