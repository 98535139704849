import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import Divider from 'components/Shared/Divider';
import avatarIcon from 'assets/avatar-icon.png';
import MicrosoftLogo from 'assets/partner/microsoft.svg';
import { RootState } from '../../store/rootReducer';
import Input from '../Shared/Input';
import {
  getCurrentTokens,
  getSamlConfig,
  loginWithCredentials,
} from '../../store/actions/auth.actions';
import Wrapper from './Wrapper';
import { redirectAfterLogin } from './MFA';

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const redirectUrl = query.get('redirect_url');
  const errorMessage = query.get('error');
  const [ssoUrl, setSsoUrl] = useState<string>(null);
  const [forceSSO, setForceSSO] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [loginActionText, setLoginActionText] = useState<string>('Next');

  const { loading, error } = useSelector((state: RootState) => state.auth);

  /*
    The user can be logged out in the old app and still have a valid token in the new one.
    We need to allow the user to log in again to get a new session on the old app avoiding endless loop.
    If the user comes without a redirectUrl, then the user should be redirect to the dashboard.
    if accessToken is there and redirectUrl too, redirect the user to /
  */

  const { accessToken } = getCurrentTokens();

  if (accessToken && !loading) {
    if (!redirectUrl) {
      history.push('/');
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Company email field is required'),
    }),
    onSubmit: async (values) => {
      if (ssoUrl && loginActionText === 'Cancel') {
        resetLogin();
        return;
      }
      setUsername(values.email);
      if (values.email && !values.password) {
        setLoginActionText('Log in');
      }
      if (values.password !== '') {
        const data = await loginWithCredentials(values);

        if (data) {
          if (data.data?.mfa_id) {
            history.push({
              pathname: `/login-mfa/${data.data.mfa_id}`,
              search: new URLSearchParams({
                redirectUrl,
                message: data.message,
                email: data.data.email,
              }).toString(),
            });
            return;
          }
          const url = redirectAfterLogin(redirectUrl, data);
          if (url) {
            history.push(url);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (username !== '') {
      getSamlConfig(username).then((config) => {
        if (config) {
          setSsoUrl(config.login_url);
          setForceSSO(config.force_sso);
          if (config.login_url && config.force_sso) {
            setLoginActionText('Cancel');
          }
        }
      });
    }
  }, [username, dispatch]);

  const resetLogin = (): void => {
    setUsername('');
    setSsoUrl(null);
    setForceSSO(false);
    setLoginActionText('Next');
    formik.resetForm();
  };

  return (
    <LoadingOverlay active={loading} spinner={<BounceLoader color="gray" />}>
      <Wrapper
        heading="Log in to Alooba"
        text="Don't have an account?"
        action="Sign Up"
        actionLink="get-started"
        buttonText={loginActionText}
        baseText={
          !forceSSO && username !== '' ? 'Forgotten Password?' : <>&nbsp;</>
        }
        baseTextLink={!forceSSO && username !== '' ? `/password-reset` : null}
        onFormSubmit={formik.handleSubmit}
        errorText={errorMessage}
      >
        <div id="login_page">
          <>
            {ssoUrl !== null && (
              <a href={ssoUrl} className="button sso-active-directory">
                <img src={MicrosoftLogo} alt="Microsoft Logo" />
                Log in with Active Directory
              </a>
            )}
            {!forceSSO && <Divider text="OR" />}
            {username === '' && (
              <Input
                name="email"
                type="email"
                autoFocus
                value={formik.values.email}
                placeholder="Email address"
                label="Email"
                onChange={formik.handleChange}
                errorTxt={formik.touched.email ? formik.errors.email : ''}
              />
            )}
            {!forceSSO && username !== '' && (
              <div>
                <div className="username-display">
                  <div className="name">
                    <img src={avatarIcon} alt="Avatar Icon" height="24" />
                    {username}
                  </div>
                  <a
                    id="username-button"
                    href="#reset"
                    role="button"
                    onClick={resetLogin}
                  >
                    Change
                  </a>
                </div>
                <Input
                  id="password"
                  name="password"
                  autoFocus
                  type="password"
                  value={formik.values.password}
                  placeholder="Enter Password"
                  label="Password"
                  onChange={formik.handleChange}
                  errorTxt={
                    formik.touched.password ? formik.errors.password : ''
                  }
                  autoComplete="off"
                />
              </div>
            )}
          </>

          {error && !loading ? <span className="auth-error">{error}</span> : ''}
        </div>
      </Wrapper>
    </LoadingOverlay>
  );
};

export default Login;
