import React from 'react';

import TextButton from 'components/Shared/Button/TextButton';
import { QuestionType } from 'helpers/constants';
import SkillQuestions from './SkillQuestions';
import dndIcon from '../../../images/icons/dnd.svg';
import QuestionIcon from '../../../images/icons/question.svg';
import AddIcon from '../../../images/icons/add.svg';

type Props = {
  skill: any;
  questions: any;
  toggleSkillCollapse: any;
  selectedQuestion: any;
  handleSelectQuestion: any;
  handleAddQuestion: any;
  handleRemoveQuestion: any;
  canAddQuestion: any;
  removingMap: any;
  onQuestionDragEnd: any;
  dndEnabled?: boolean;
  dndDragHandleProps?: any;
};

const Skill: React.FC<Props> = ({
  skill,
  questions,
  toggleSkillCollapse,
  selectedQuestion,
  handleSelectQuestion,
  handleAddQuestion,
  handleRemoveQuestion,
  canAddQuestion,
  removingMap,
  onQuestionDragEnd,
  dndEnabled = false,
  dndDragHandleProps = {},
}) => {
  return (
    <div className="question-group">
      <div
        className="skill-row"
        onClick={() => {
          toggleSkillCollapse(skill);
        }}
        role="button"
        tabIndex={0}
      >
        <img
          height={23}
          width={12}
          src={dndIcon}
          alt="drag-and-drop"
          className={`dnd-icon ${dndEnabled ? '' : 'dnd-icon-disabled'}`}
          {...dndDragHandleProps}
        />
        <div className="skill">{skill.name}</div>
        <div className="right">
          <div className="num-questions">
            <img className="icon" src={QuestionIcon} alt="question icon" />
            <div className="value">{questions.length}</div>
          </div>
          <div className={skill.collapsed ? 'chevron-down' : 'chevron-up'} />
        </div>
      </div>
      <div className={`skill-questions ${skill.collapsed ? 'collapsed' : ''}`}>
        <SkillQuestions
          handleRemoveQuestion={handleRemoveQuestion}
          questions={questions}
          skill={skill}
          onQuestionDragEnd={onQuestionDragEnd}
          removingMap={removingMap}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={(question) => {
            handleSelectQuestion(question, skill);
          }}
        />
        {canAddQuestion && (
          <TextButton
            text={
              skill.questions_type === QuestionType.Informational
                ? 'Add Information'
                : `Add ${skill.name} Question`
            }
            variant="add"
            placement="before"
            className="mt2"
            icon={AddIcon}
            onClick={handleAddQuestion}
          />
        )}
      </div>
    </div>
  );
};

export default Skill;
