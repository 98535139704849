import React, { useState, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import CustomSelect from '../../CustomSelect';
import FieldContainer from '../FieldContainer';
import CustomListItemText from '../../CustomListItemText';
import CustomMenuItem from '../../CustomMenuItem';
import './index.scss';

export type multiSelectProps = {
  name: string;
  options: Array<any>;
  label: string;
  description?: string;
  canHide: boolean;
  visible: boolean;
  onChange: any;
  hide: any;
  value?: any;
  defaultValues?: any[];
  initialValue?: any[];
  multiple?: boolean;
  withCheckbox?: boolean;
  optionKey?: string;
  optionLabel?: string;
  selectClassName?: string;
  menuClassName?: string;
  selectProps?: any;
  allowRemovingItems?: boolean;
  allText?: string;
  defaultToNull?: boolean;
  optionsMap?: any;
};
const MultiSelectFilter = ({
  name,
  options,
  label,
  description,
  canHide,
  visible,
  onChange,
  hide,
  value = null,
  defaultValues = [],
  initialValue,
  multiple = true,
  withCheckbox = true,
  optionKey = null,
  optionLabel = null,
  selectClassName = '',
  menuClassName = '',
  selectProps = {},
  allowRemovingItems = true,
  allText = 'All',
  defaultToNull = false,
  optionsMap = null,
}: multiSelectProps): JSX.Element => {
  const [values, setValues] = useState(initialValue || defaultValues || []);
  useEffect(() => {
    if (value) {
      setValues(value || values);
    }
  }, [value, values]);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    let currentValues: (string | number)[] = event.target.value as string[];
    setValues(currentValues);
    currentValues = currentValues.map
      ? currentValues.map((value) => value || 0)
      : currentValues;
    if (onChange) {
      const newOptions = defaultToNull ? null : options;
      onChange({
        [name]: currentValues.length > 0 ? currentValues : newOptions,
      });
    }
  };
  const resetAndHide = (): void => {
    setValues([]);
    onChange({
      [name]: defaultValues,
    });
    hide();
  };
  const selectRenderValue = (selected: Array<any>): JSX.Element => {
    if (!Array.isArray(selected)) {
      selected = [selected];
    }
    if (optionLabel && optionKey) {
      selected = options
        .filter((item) => selected.includes(item[optionKey]))
        .map((item) => item[optionLabel]);
    }
    if (optionsMap) {
      selected = selected.map((item) => optionsMap[item] ?? item);
    }
    return (
      <div className="render-select-value">
        <div className="render-select-value-text">{selected.join(', ')}</div>
        {selected.length > 1 && (
          <div className="render-select-value-count">
            &ensp;({selected.length})
          </div>
        )}
      </div>
    );
  };
  return (
    <FieldContainer
      hide={resetAndHide}
      label={label}
      description={description}
      canHide={canHide}
      visible={visible}
    >
      <CustomSelect
        data-testid={`${name}-select`}
        autoWidth
        className={selectClassName}
        onChange={handleChange}
        displayEmpty
        MenuProps={{
          className: `custom-select-menu ${menuClassName}`,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          disableScrollLock: true,
        }}
        multiple={multiple}
        renderValue={(selected: Array<any>) =>
          selected.length ? selectRenderValue(selected) : allText
        }
        value={values}
        {...selectProps}
      >
        {options.map((option) => (
          <CustomMenuItem
            key={optionKey ? option[optionKey] : option}
            value={optionKey ? option[optionKey] : option}
            disabled={
              !allowRemovingItems &&
              values.indexOf(optionKey ? option[optionKey] : option) > -1
            }
          >
            <CustomListItemText
              primary={optionLabel ? option[optionLabel] : option}
            />
            {withCheckbox && (
              <Checkbox
                style={{
                  color: '#4A4A68',
                  width: '20px',
                }}
                color="primary"
                checked={
                  values.indexOf(optionKey ? option[optionKey] : option) > -1
                }
              />
            )}
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </FieldContainer>
  );
};
export default MultiSelectFilter;
