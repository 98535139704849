import './index.scss';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Button } from '../Shared';
import { goToLandingPageApp } from '../../hooks';
import { getCustomerPortalLink } from '../../api/subscription.api';

interface Props {
  subscription?: any;
  organisationId?: number;
}

const TrialBanner = ({ subscription, organisationId }: Props): JSX.Element => {
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
  const { addToast } = useToasts();

  const getPaymentLink = async (): Promise<void> => {
    try {
      setLoadingPaymentLink(true);
      const response = await getCustomerPortalLink(organisationId);
      if (response?.data?.data?.url) {
        window.location.assign(`${response.data.data.url}/payment-methods`);
      }
    } catch (e) {
      setLoadingPaymentLink(false);
      addToast({ type: 'error', msg: e.response?.data?.message || e.message });
    }
  };
  let trialDaysLeft = 0;
  if (subscription?.trial_ends_at) {
    trialDaysLeft = Math.ceil(
      (new Date(subscription.trial_ends_at).valueOf() - new Date().valueOf()) /
        (1000 * 60 * 60 * 24)
    );
  }
  let trialMessage =
    'Urgent: This is the last day of your trial! Add your payment method now to retain access.';
  if (trialDaysLeft > 3) {
    trialMessage = `Your trial will end in <strong>${trialDaysLeft} days</strong>! Complete the Alooba onboarding to make the most of your trial.`;
  } else if (trialDaysLeft > 1) {
    trialMessage = `Urgent: Your trial will end in <strong>${trialDaysLeft} days</strong>! Add your payment details now to retain access.`;
  }
  const docUrl =
    process.env.REACT_APP_ALOOBA_SUPPORT_GETTING_STARTED_URL ??
    'https://support.alooba.com/article/74-getting-started-with-alooba';
  const booking =
    process.env.REACT_APP_ALOOBA_ONBOARDING_PAGE ?? 'book-assess-call';
  return (
    <div className={`trial-banner ${trialDaysLeft < 2 ? 'urgent' : ''}`}>
      <div
        className="message"
        dangerouslySetInnerHTML={{ __html: trialMessage }}
      />
      <div className="buttons">
        {trialDaysLeft > 3 ? (
          <>
            <Button
              variant="sub-primary md"
              text="Read Getting Started Docs"
              onClick={() => window.location.assign(docUrl)}
            />
            <Button
              variant="sub-primary md"
              text="Book Onboarding Call"
              onClick={(e) => goToLandingPageApp(e, booking)}
            />
          </>
        ) : (
          <Button
            variant="sub-primary md"
            text="Add Payment Details"
            onClick={getPaymentLink}
            loading={loadingPaymentLink}
            loadingTxt="Redirecting..."
          />
        )}
      </div>
    </div>
  );
};
export default TrialBanner;
