import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getTestDetails, getTestEvents } from 'store/actions/candidate.actions';
import {
  setTestProperty,
  selectTest,
  SkillResource as SkillResourceType,
} from 'store/reducers/candidate';
import { TestType } from 'helpers/constants';
import QuestionSidebar from 'components/Shared/Test/QuestionSidebar';
import TestHeader from 'components/Shared/Test/TestHeader';
import InterviewHeader from 'components/Shared/Test/TestHeader/InterviewHeader';
import QuestionDetails from 'components/Shared/Test/QuestionDetails/QuestionDetails';
import QuestionBackground from 'components/Shared/Test/QuestionDetails/QuestionBackground';
import UploadedFiles from 'components/Shared/Test/UploadedFiles';
import SuspiciousActivities from 'components/Shared/SuspiciousActivityCard';
import QuestionInsights from 'components/Shared/QuestionInsights';
import InfoIcon from '../../../images/icons/info-blue.svg';
import './TestLayout.scss';
import SubjectScoresSummary from './SubjectScoresSummary';
import SkillResource from '../../CandidateResults/AreasForReviewTab/SkillResource';
import Timeline from '../../Shared/Timeline';

const testStatusMessages = {
  'Not Started':
    "The {test_type} test hasn't been started yet. Please wait until the test is completed to view the results.",
  'In Progress':
    'The {test_type} test is currently in progress. Please wait until the test is submitted to view the results.',
  Canceled: 'This test is canceled.',
};

export default function TestTabLayout({
  testId,
  testType,
  recruiterTestId,
  candidateTestId,
  toggleShowTestRetakeModal,
  toggleShowAddTimeModal,
  insights,
  dataSets,
  files,
  children,
  hideSensitiveInfo = false,
  skillResources = [],
}: {
  testId: number;
  testType: string;
  recruiterTestId: number;
  candidateTestId: number;
  toggleShowTestRetakeModal: any;
  toggleShowAddTimeModal: any;
  insights?: any;
  dataSets?: any;
  files?: any;
  children?: React.ReactNode;
  hideSensitiveInfo?: boolean;
  skillResources?: SkillResourceType[];
}): any {
  testType = testType === 'MCQ' ? 'Concepts & Knowledge' : testType;
  const dispatch = useDispatch();
  const { candidate, results, loading: loadingState, testEvents } = useSelector(
    (state: RootState) => state.candidate
  );
  const { reference }: { reference: string; partIndex?: string } = useParams();

  const showEventTracking = useMemo(
    () => testType !== TestType.PersonalityProfiling,
    [testType]
  );

  // Set current test as selected as soon as component mounts
  useEffect(() => {
    dispatch(selectTest({ testId }));
  }, [dispatch, testId]);

  // If we haven't fetched the test data from the backend yet, fetch it now.
  useEffect(() => {
    if (!results?.tests[testId] && candidate?.reference) {
      dispatch(getTestDetails(reference, testId, hideSensitiveInfo));
    }
  }, [reference, hideSensitiveInfo, dispatch, candidate, results, testId]);

  useEffect(() => {
    if (showEventTracking && !hideSensitiveInfo && !testEvents[testId]) {
      dispatch(getTestEvents(reference, testId));
    }
  }, [
    reference,
    hideSensitiveInfo,
    dispatch,
    candidate,
    testId,
    testEvents,
    showEventTracking,
  ]);

  // Create a simpler aliased name for the test
  const test = results.tests[testId];

  const overallScore = useMemo(() => {
    if (
      !results ||
      !test ||
      !test?.total_score ||
      test.total_score.scored_percent === null
    ) {
      return null;
    }
    if (test.total_score?.score === '' || test.total_score?.max_score === '') {
      return null;
    }
    return Math.round(
      (parseInt(test.total_score?.score, 10) /
        parseInt(test.total_score?.max_score, 10)) *
        100
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, test]);

  const loading = useMemo(() => test === undefined, [test]);
  const isMCQ = useMemo(
    () => testType === 'Concepts & Knowledge' || testType === 'Data Analysis',
    [testType]
  );
  const isInterview = useMemo(() => testType === 'Interview', [testType]);

  return (
    <div className="main-container test-results-content">
      {testStatusMessages[test?.status] ? (
        <div className="test-status-message">
          <img src={InfoIcon} alt="info icon" />
          {testStatusMessages[test?.status].replace('{test_type}', testType)}
        </div>
      ) : (
        <>
          {!isInterview ? (
            <TestHeader
              scoredPercent={overallScore}
              testDetails={test}
              loading={loading}
              toggleShowTestRetakeModal={toggleShowTestRetakeModal}
              toggleShowAddTimeModal={toggleShowAddTimeModal}
              enableRetake={
                !hideSensitiveInfo && !isInterview && !candidate?.is_erased
              }
            />
          ) : (
            <InterviewHeader
              scoredPercent={overallScore}
              testDetails={test}
              loading={loading}
            />
          )}
          {test?.questions.length > 0 ? (
            <div className="side-by-side">
              <QuestionSidebar
                testId={testId}
                questionLength={test?.question_length}
                onQuestionSelected={(question) => {
                  dispatch(
                    setTestProperty({
                      testId,
                      propertyName: 'selectedQuestionIndex',
                      value: question.position - 1,
                    })
                  );
                }}
                selectedIndex={test?.selectedQuestionIndex}
                subjectsWithQuestions={test?.subjects_with_questions}
                questions={test?.questions}
                isInterview={isInterview}
                loading={loading}
              />
              <div className="question-info">
                {test?.test_type === 'Data Analysis' && !loading && (
                  <div className="test-question-details business-information">
                    {test?.questions[test?.selectedQuestionIndex]
                      ?.background && (
                      <>
                        <h4>BUSINESS INFORMATION</h4>
                        <QuestionBackground
                          background={
                            test?.questions[test?.selectedQuestionIndex]
                              ?.background
                          }
                          dataSets={dataSets}
                          recruiterTestId={recruiterTestId}
                          questionId={
                            test?.questions[test?.selectedQuestionIndex]
                              ?.question_id
                          }
                        />
                      </>
                    )}
                    <UploadedFiles
                      candidate={candidate}
                      files={files}
                      testEnd={test?.end}
                    />
                  </div>
                )}
                <div className="test-question-details-wrapper">
                  <QuestionDetails
                    question={test?.questions[test?.selectedQuestionIndex]}
                    loading={loading}
                    testId={testId}
                    testType={testType}
                    recruiterTestId={recruiterTestId}
                    candidateTestId={candidateTestId}
                    disableScoring={hideSensitiveInfo}
                    selectedIndex={test?.selectedQuestionIndex}
                    isMCQ={isMCQ}
                  >
                    <div className="question-details-children">
                      {!loading && children}
                      {skillResources.length > 0 && (
                        <div className="skill-resource-container subject-resource">
                          <h3>LEARNING RESOURCES</h3>
                          {skillResources.map((resource) => (
                            <SkillResource
                              key={resource.id}
                              link={resource.link}
                              title={resource.title}
                            />
                          ))}
                        </div>
                      )}
                      {insights && (
                        <div className="mt5 question-insights">
                          <QuestionInsights
                            data={insights.buckets}
                            durationPerBucket={insights.bucket_duration}
                            bucketsLength={insights.buckets_length}
                            highlight={{
                              time_taken:
                                test?.questions[test?.selectedQuestionIndex]
                                  ?.time_taken,
                              correct:
                                test?.questions[test?.selectedQuestionIndex]
                                  ?.score > 0,
                            }}
                            isLoading={loadingState.insights}
                          />
                        </div>
                      )}
                    </div>
                  </QuestionDetails>
                </div>
              </div>
            </div>
          ) : (
            <SubjectScoresSummary testId={testId} />
          )}
          {showEventTracking && testEvents[testId]?.length > 0 && (
            <div className="test-tab-timeline">
              <Timeline
                testEvents={testEvents[testId]}
                test={test}
                isMCQ={isMCQ}
              />
              <hr />
            </div>
          )}
          {!loading && (
            <SuspiciousActivities
              suspiciousActivities={test?.test_suspicious_activities}
            />
          )}
        </>
      )}
    </div>
  );
}
