import CardContainer from 'components/Shared/MainContainer/CardContainer';
import SelfAwareness, {
  SkillPercentiles,
} from 'components/Shared/SelfAwareness';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadSelfAwarenessData } from 'store/actions/candidate.actions';
import { Candidate, CandidateSelfAwareness } from 'store/reducers/candidate';
import { RootState } from 'store/rootReducer';
import HowIsThisCalculatedButton from 'components/Shared/SelfAwareness/HowIsThisCalculatedButton';
import './SelfAwarenessTab.scss';

interface Props {
  candidate: Candidate;
  isVerificationCode?: boolean;
  description?: any;
  title?: any;
}
const SelfAwarenessTab: React.FC<Props> = ({
  candidate,
  isVerificationCode = false,
  description = null,
  title = null,
}) => {
  const dispatch = useDispatch();
  const { candidateSkillAwareness, loading } = useSelector(
    (state: RootState) => state.candidate
  );

  const { reference }: { reference: string } = useParams();
  const [skills, setSkills] = useState<SkillPercentiles[]>([]);

  useEffect(() => {
    if (candidateSkillAwareness.length === 0) {
      dispatch(loadSelfAwarenessData(reference, isVerificationCode));
    }
  }, [
    isVerificationCode,
    reference,
    dispatch,
    candidate,
    candidateSkillAwareness.length,
  ]);

  const getInitials = (subject: string): string => {
    return subject
      .split(' ')
      .reduce((previous: string, current: string): string => {
        // if one word only and is 3 or less characters
        // then just use that word
        if (previous === '' && current.length <= 3) {
          return current;
        }

        if (previous.length >= 2) {
          return previous;
        }

        // ignore special characters
        if (current.match(/[^a-zA-Z0-9]+/)) {
          return previous;
        }
        return previous + current.substring(0, 1);
      }, '');
  };
  useEffect(() => {
    setSkills(
      candidateSkillAwareness.map(
        (skill: CandidateSelfAwareness): SkillPercentiles => {
          return {
            label: skill.subject,
            scorePercentile: skill.score,
            selfRating: skill.self_rating,
            shortLabel: getInitials(skill.subject),
            selfAwareness: skill.accuracy,
          };
        }
      )
    );
  }, [candidateSkillAwareness]);

  return (
    <div>
      <div className="outside-card">
        <div>{title}</div>
        <HowIsThisCalculatedButton isVerificationCode={isVerificationCode} />
      </div>
      <div>{description}</div>
      <CardContainer>
        <SelfAwareness skills={skills} loading={loading.selfAwareness} />
      </CardContainer>
    </div>
  );
};

export default SelfAwarenessTab;
