import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#ECF1F4',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '10px 16px',
    fontSize: '14px',
    fontFamily: 'Nunito, sans-serif',
  },
  arrow: {
    color: '#ECF1F4',
  },
}))(Tooltip);

export default LightTooltip;
