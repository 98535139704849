import { AssessmentPurpose } from 'components/Assessment';
import { NPS_STATUSES } from 'components/NpsLanding';
import { handleError } from 'handleError';
import api from './index';
import { apiLegacy } from '../api-legacy';
import {
  GetAssessmentCandidatesQueryParams,
  SearchCandidatesQueryParams,
} from './contract';

interface AssessmentData {
  id?: any;
  passing_score?: number;
  cloak_candidates?: boolean;
  is_archived?: boolean;
}

export interface AssessmentPayload {
  perPage?: number;
  page?: number;
  searchTerm?: string;
  groups?: any[];
  status?: string;
}
export interface CreateFromTemplatePayload {
  template_id: number;
  purpose: AssessmentPurpose | null;
  role_title: string;
  group_id: string;
  location: any;
}
export interface AssessmentSuggestionsPayload {
  role_summary: string;
  role_title: string;
}
export async function getTemplates(): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/templates`;
  return api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      perPage: 100,
    },
  });
}

declare module 'axios' {
  export interface AxiosRequestConfig {
    is_public?: boolean;
  }
}

/*
 * Get test parts suggestions for a given role
 */
export async function getAssessmentSuggestions(
  data: AssessmentSuggestionsPayload
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/suggest?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, data);
}
export async function createAssessmentFromTemplate(
  data: CreateFromTemplatePayload
): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/create-test-from-template`;
  return apiLegacy.post(url, data);
}
export async function getAssessments(
  data: AssessmentPayload,
  purpose: AssessmentPurpose
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments`;
  return api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      purpose,
      ...data,
    },
  });
}
export async function getSubjects(): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/subjects`;
  return api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
    },
  });
}
export async function resendEmailVerificationLink(): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/resend-activate-email-ajax`;
  return apiLegacy.post(url);
}
export async function deleteAssessment(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.delete(url);
}
export async function getAssessment(data: AssessmentData): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${data.id}/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function getAssessmentCandidates(
  queryParams: GetAssessmentCandidatesQueryParams
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/candidates/`;
  return api
    .get(url, {
      params: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        ...queryParams,
      },
    })
    .catch((error) => {
      handleError(error);
      if (error.response && error.response.status === 403) {
        window.location.replace('/forbidden');
      }
    });
}
export async function exportAssessmentCandidates(
  queryParams: GetAssessmentCandidatesQueryParams
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/export/`;
  return api.get(url, {
    responseType: 'blob',
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      ...queryParams,
    },
  });
}
export async function downloadExportFile(fileName: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${fileName}/download-export/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function searchCandidates(
  queryParams: SearchCandidatesQueryParams
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/search-candidates/`;
  return api
    .get(url, {
      params: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        ...queryParams,
      },
    })
    .catch((error) => {
      handleError(error);
      if (error.response && error.response.status === 403) {
        window.location.replace('/forbidden');
      }
    });
}

export async function getCandidatesByHiringStatuses(
  assessmentId: number
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/candidates-by-hiring-statuses/`;
  return api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      assessmentId,
    },
  });
}

export async function getAssessmentInformationFields(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/information-fields/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function getAssessmentHiringStatuses(): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/hiring-statuses/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function getAssessmentSubjects(
  id: number,
  excludeInterviews?: boolean
): Promise<any> {
  const url = `${
    process.env.REACT_APP_SERVER_URL
  }/assessments/${id}/subjects/?${
    excludeInterviews ? 'exclude-interviews=true&' : ''
  }client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function getAllSubjects(
  testTypeId: number | null = null,
  forInterview = 0,
  isAdditionalCriteria = 0
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/subjects/`;
  const params: any = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    for_interview: forInterview,
    additional_criteria: isAdditionalCriteria,
  };
  if (testTypeId) {
    params.test_type_id = testTypeId;
  }
  return api.get(url, {
    params,
  });
}
export async function getQuestionsForSubject(
  subjectId: number,
  responseType,
  forInterview = 0
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/subjects/${subjectId}/questions`;
  return api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      response_type: responseType,
      for_interview: forInterview,
    },
  });
}
export async function addQuestionsToPart(
  assessmentId: number,
  partId: number,
  position: number,
  questions: any[]
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/parts/${partId}/add-questions?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    assessmentId,
    partId,
    position,
    questions,
  });
}
export async function removeQuestionFromPart(
  assessmentId: number,
  partId: number,
  questionId: number
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/parts/${partId}/remove-question/${questionId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.delete(url);
}
export async function updateQuestionInPart(
  assessmentId: number,
  partId: number,
  questionId: number,
  values: { position?: number; duration?: number; marks?: number }
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/parts/${partId}/questions/${questionId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url, values);
}
export async function reorderQuestionsInPart(
  assessmentId: number,
  partId: number,
  questionIds: number[],
  position: number
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/parts/${partId}/reorder-questions?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url, { question_ids: questionIds, position });
}
export async function getFunnelData(
  id: number,
  pass_threshhold: number
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/funnel/?client_id=${process.env.REACT_APP_CLIENT_ID}&pass_threshhold=${pass_threshhold}`;
  return api.get(url);
}
export async function getHistogramData(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/histogram/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function getFeedbackSummaryData(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/feedback-summary/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function getSubjectAvgs(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/averages/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function duplicateAssessment(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/assessments/duplicate-existing/${id}`;
  return apiLegacy.post(url);
}
export async function convertToTemplate(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/convert-to-template?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url);
}
export async function updateAssessmentData(data: AssessmentData): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${data.id}/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url, data);
}
export async function getParticipantStats(id: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/statistics/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function inviteYourself(bodyFormData: FormData): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/invite-yourself-ajax`;
  return apiLegacy.post(url, bodyFormData);
}
export async function setCandidateAsBenchmark(
  id: number,
  postData: {
    candidate_test_id: number;
  }
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/set-candidate-benchmark/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, postData);
}
export async function deleteParticipant(postData: {
  candidate_test_id: number;
}): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/delete-candidate-result-ajax`;
  return apiLegacy.post(url, postData);
}
export async function extendExpiry(postData: {
  tz_code: string;
  candidate_test_id: string;
  new_expiry_date: string;
  new_expiry_date_verbose: string;
  tz_offset: string;
}): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/extend-expiry-confirm-ajax`;
  return apiLegacy.post(url, postData);
}
export async function getTestsPartList(assessmentId: number): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/get-test-parts-list-ajax/${assessmentId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return apiLegacy.get(url);
}
export async function increaseTestDuration(
  postData: {
    product_parts: { id: number | string; new_time_length: number }[];
    additional_time_length: number;
  },
  assessmentId: number | string
): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/add-additional-time-allowance-ajax/${assessmentId}`;
  return apiLegacy.post(url, postData);
}
export async function getFreeResponseFeed(
  recruiterTestId: number
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/free-response-feed/${recruiterTestId}`;
  return api
    .get(url, {
      params: {
        client_id: process.env.REACT_APP_CLIENT_ID,
      },
    })
    .catch((error) => {
      handleError(error);
      if (error.response && error.response.status === 403) {
        window.location.replace('/forbidden');
      }
    });
}
export async function setQuestionScore({
  candidate_test_id,
  test_question_id,
  score,
  recruiter_test_id,
}): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/set-question-score`;
  return apiLegacy.post(url, {
    candidate_test_id,
    test_question_id,
    score,
    recruiter_test_id,
  });
}
export async function saveQuestionResponse(
  reference: string,
  testId: number | string,
  testQuestionId: number | string,
  response: string
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/tests/${testId}/questions/${testQuestionId}/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url, {
    response,
  });
}
export async function saveNewAnswer(
  reference: string,
  testId: number | string,
  testQuestionId: number | string,
  answerId: number | string
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/tests/${testId}/questions/${testQuestionId}/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url, {
    answer: answerId,
  });
}
export async function getResultReport(
  id: number,
  isDirectLink: boolean
): Promise<any> {
  let url = `${process.env.REACT_APP_SERVER_URL}/assessments/${id}/report/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  if (isDirectLink) {
    url = `${url}&direct_download=true`;
    return api.get(url, { responseType: 'blob' });
  }
  return api.get(url);
}
export async function downloadResultReport(fileName: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${fileName}/download-report/?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function submitNpsFeedback({
  code,
  channel,
  rating,
  reason,
}): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/nps/feedback?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(
    url,
    {
      code,
      channel,
      rating,
      reason,
    },
    { is_public: true }
  );
}
export async function checkNpsRequest(code: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/nps/check/${code}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url, { is_public: true });
}
export async function updateNpsRequestStatus(
  code: string,
  status: NPS_STATUSES
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/nps/update-request-status/${code}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.put(url, { status }, { is_public: true });
}
