import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,600,700,800,900');

 body {
  font-family: 'Nunito Sans', sans-serif;
 }
`;
