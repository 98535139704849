import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TemplatesState {
  loading: boolean;
  templates: any[] | null;
  error: string | null;
}

const initialState: TemplatesState = {
  templates: null,
  loading: false,
  error: null,
};

const templatesReducer = createSlice({
  name: 'template',
  initialState,
  reducers: {
    getTemplatesStart: (state) => {
      if (state.templates === null) {
        state.loading = true;
      }
      state.error = null;
    },
    getTemplatesSuccess: (state, action: PayloadAction<any[]>) => {
      state.templates = action.payload;
      state.loading = false;
    },
    getTemplatesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setTemplates: (state, action: PayloadAction<any[]>) => {
      state.templates = action.payload;
    },
  },
});

export const {
  getTemplatesStart,
  getTemplatesSuccess,
  getTemplatesFailure,
  setTemplates,
} = templatesReducer.actions;
export default templatesReducer.reducer;
