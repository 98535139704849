import ListItemText from '@material-ui/core/ListItemText';
import { styled } from '@material-ui/core/styles';
import { dark } from '../../../styles/colors';

const CustomListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.02em',
    color: dark,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '180px',
  },
});
export default CustomListItemText;
