import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import FreeResponse from 'components/Shared/Test/Response/FreeResponse';
import InterviewResponse from 'components/Shared/Test/Response/InterviewResponse';
import SQLResponse from 'components/Shared/Test/Response/SQLResponse';
import CodingResponse from 'components/Shared/Test/Response/CodingResponse';
import TestTabLayout from './TestTabLayout';
import './TestLayout.scss';

export default function ResponseTest({
  testId,
  testType,
  recruiterTestId,
  candidateTestId,
  candidateReference,
  toggleShowTestRetakeModal,
  toggleShowAddTimeModal,
  hideSensitiveInfo = false,
}: {
  testId: number;
  testType: string;
  recruiterTestId: number;
  candidateTestId: number;
  candidateReference: string;
  toggleShowTestRetakeModal: any;
  toggleShowAddTimeModal: any;
  hideSensitiveInfo?: boolean;
}): any {
  const { results } = useSelector((state: RootState) => state.candidate);

  // Create a simpler aliased name for the test
  const test = useMemo(() => results.tests[testId], [results, testId]);

  const getResponseComponent = (): React.ReactNode => {
    if (hideSensitiveInfo) return null;
    const questionDetails = test?.questions[test?.selectedQuestionIndex];
    let component;
    switch (testType) {
      case 'Free Response':
        component = <FreeResponse questionDetails={questionDetails} />;
        break;
      case 'SQL':
        component = (
          <SQLResponse testId={testId} questionDetails={questionDetails} />
        );
        break;
      case 'Interview':
        component = (
          <InterviewResponse
            candidateReference={candidateReference}
            testId={testId}
            questionDetails={questionDetails}
          />
        );
        break;
      default:
        component = <CodingResponse questionDetails={questionDetails} />;
    }
    return component;
  };

  return (
    <TestTabLayout
      testId={testId}
      testType={testType}
      recruiterTestId={recruiterTestId}
      candidateTestId={candidateTestId}
      toggleShowTestRetakeModal={toggleShowTestRetakeModal}
      toggleShowAddTimeModal={toggleShowAddTimeModal}
      skillResources={
        test?.questions[test?.selectedQuestionIndex]?.skill_resources
      }
      hideSensitiveInfo={hideSensitiveInfo}
    >
      {getResponseComponent()}
    </TestTabLayout>
  );
}
