import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import Billing from '../components/Billing';
import { deactivatePageHeader } from '../store/reducers/layout';

const BillingPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <div className="container">
      <Helmet>
        <title>Billing | alooba.com</title>
      </Helmet>
      <Billing />
    </div>
  );
};

export default BillingPage;
