import CardContainer from 'components/Shared/MainContainer/CardContainer';
import { Modal } from 'components/Shared/Modal';
import React, { useEffect, useState } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import './InternalNotes.scss';
import moment from 'moment';
import ActionMenu, {
  MenuPositions,
} from 'components/Shared/Dropdown/ActionMenu';
import NotesIcon from 'images/icons/comment-blue.svg';
import ErrorIcon from 'images/error.svg';
import AloobaLogo from 'images/alooba-logo-white.png';
import { Button } from 'components/Shared';
import {
  addAssessmentComment,
  destroyComment,
  editComment,
} from 'store/actions/candidate.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { useToasts } from 'react-toast-notifications';
import CommentInput from './CommentInput';

export interface Note {
  id: number;
  name: string;
  comment: string;
  created_at: string;
  user_id: number;
  writable: boolean;
  isBeingEdited?: boolean;
}

interface Props {
  notes: Note[];
  reference: string;
}

const InternalNotes: React.FC<Props> = ({ notes, reference }) => {
  const dispatch = useDispatch();
  const commentError = useSelector(
    (state: RootState) => state.candidate.commentError
  );
  const { addToast } = useToasts();

  const { userDetails } = useSelector((state: RootState) => state.profile);
  const [showAddComment, setShowAddComment] = useState(false);
  const [placeholderInputNote, setPlaceholderInputNote] = useState(null);
  const [deletableComment, setDeletableComment] = useState(null);
  const [editableNote, setEditableNote] = useState(null);
  const [isDeleting, setIsDeleting] = useState(null);
  const [notesList, setNotesList] = useState([]);
  const [user, setUser] = useState('');
  const [showAll, setShowAll] = useState(false);

  const initialCommentsShown = 5;

  const onCommentInputSubmit = (comment: string): void => {
    setPlaceholderInputNote({
      comment,
      created_at: new Date().toISOString(),
      name: `${user}`,
      id: null,
    });
    dispatch(addAssessmentComment(comment, reference));
    setPlaceholderInputNote(null);
    setShowAddComment(false);
  };

  useEffect(() => {
    setUser(`${userDetails.first_name} ${userDetails.last_name}`);
  }, [userDetails]);
  useEffect(() => {
    const displayableNotes = [].concat(notes);
    if (placeholderInputNote) {
      displayableNotes.unshift(placeholderInputNote);
    }
    setNotesList(displayableNotes);
  }, [notes, placeholderInputNote]);

  useEffect(() => {
    if (commentError) {
      addToast({
        type: 'error',
        msg: commentError.message,
        autoDismiss: false,
      });
    }
  }, [addToast, commentError]);

  const avatarColorPool = [
    '#E85252',
    '#F88C42',
    '#F3CA55',
    '#35485A',
    '#004283',
    '#d62828',
    '#0E0E2C',
    '#9EBCFD',
    '#03CEA4',
    '#4A4A68',
  ];

  const onConfirmDelete = (note: Note): void => {
    setIsDeleting(true);
    dispatch(destroyComment(reference, note.id));
    setIsDeleting(false);
    setDeletableComment(null);
  };

  const onClickEdit = (comment: string): void => {
    const note = editableNote;
    dispatch(
      editComment(reference, {
        comment,
        id: note.id,
        name: note.name,
        created_at: note.created_at,
        writable: note.writable,
        user_id: note.user_id,
      })
    );
    setEditableNote(false);
  };

  const editMenuItem = (note: Note): JSX.Element => {
    return (
      <p key="edit" onClick={() => setEditableNote(note)}>
        <span style={{ marginRight: '5px' }}>Edit</span>
      </p>
    );
  };

  const deleteMenuItem = (note: Note): JSX.Element => {
    return (
      <p key="delete" onClick={() => setDeletableComment(note)}>
        <span style={{ marginRight: '5px' }}>Delete</span>
      </p>
    );
  };
  return (
    <div className="internal-notes-container">
      <h2 className="heading">Internal Notes</h2>
      <CardContainer>
        <div className="comment-input-area">
          {showAddComment ? (
            <CommentInput
              onCancel={() => setShowAddComment(false)}
              onSubmit={onCommentInputSubmit}
            />
          ) : (
            <div className="input-toggler">
              <div>
                <img
                  className="personal-info-icon mr4"
                  src={NotesIcon}
                  alt="Notes Icon"
                />
                <strong>{notesList.length}</strong> notes
              </div>
              <div>
                <Button
                  variant="primary md"
                  text="Write a Note"
                  onClick={() => setShowAddComment(true)}
                />
              </div>
            </div>
          )}
        </div>
        <hr />
        <ConfigProvider colors={avatarColorPool}>
          <div>
            {notesList.map((note: Note, i) => {
              const key = `${note.name}-${note.id}`;
              if (!showAll && i >= 5) {
                return <div key={key} />;
              }

              if (editableNote?.id === note.id) {
                return (
                  <div className="individual-note" key={key}>
                    <div className="avatar">
                      <Avatar name={note.name} round size="48" />
                    </div>
                    <div className="note-container">
                      <CommentInput
                        submitButtonText="Edit"
                        initialValue={note.comment}
                        onCancel={() => setEditableNote(null)}
                        onSubmit={(v) => onClickEdit(v)}
                      />
                    </div>
                  </div>
                );
              }
              return (
                <div
                  className={
                    note.id === commentError?.id
                      ? 'error individual-note'
                      : 'individual-note'
                  }
                  key={key}
                >
                  <div className="avatar">
                    {note.user_id !== null ? (
                      <Avatar name={note.name} round size="48" />
                    ) : (
                      <Avatar
                        color="#004385"
                        src={AloobaLogo}
                        name="{note.name}"
                        round
                        size="48"
                      />
                    )}
                  </div>
                  <div className="note-container">
                    <div className="note-heading">
                      <div className="name">
                        {note.id === commentError?.id ? (
                          <img
                            id="comment-error"
                            src={ErrorIcon}
                            alt="error"
                            title={commentError.message}
                          />
                        ) : (
                          ''
                        )}
                        {note.name}
                      </div>
                      <div className="extra">
                        <div className="date">
                          {moment(note.created_at).fromNow()}
                        </div>
                        <div className="actions">
                          {note.writable ? (
                            <>
                              <div className="hitbox">&ensp;</div>
                              <ActionMenu
                                id="actions"
                                items={[
                                  editMenuItem(note),
                                  deleteMenuItem(note),
                                ]}
                                position={MenuPositions.BottomLeft}
                              />
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="comment">
                      {note.comment.split('\n').map((line: string) => {
                        if (line.length < 1) {
                          return <p key="blank-line">&nbsp;</p>;
                        }
                        return <p key={line}>{line}</p>;
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </ConfigProvider>
        {!showAll && notesList.length > initialCommentsShown ? (
          <div className="paginator">
            <button type="button" onClick={() => setShowAll(true)}>
              Load More
            </button>
          </div>
        ) : (
          ''
        )}
      </CardContainer>
      <Modal
        isShown={deletableComment !== null}
        isSubmit
        showCancel
        loading={isDeleting}
        handleButtonAction={() => onConfirmDelete(deletableComment)}
        handleClose={() => setDeletableComment(null)}
        actionText="Delete"
      >
        <>
          <h2>Are you sure you want to delete this comment?</h2>
          <br />
          <p>{deletableComment?.comment}</p>
          <hr />
        </>
      </Modal>
    </div>
  );
};

export default InternalNotes;
