import React from 'react';
import './Input.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface AloobaPhoneInputProps {
  onChange?: any;
  label: string;
  errorTxt?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  defaultCountryCode?: string;
  required?: boolean;
}
const AloobaPhoneInput = ({
  onChange,
  label,
  errorTxt,
  disabled,
  placeholder,
  value,
  defaultCountryCode = 'us',
  required,
}: AloobaPhoneInputProps): JSX.Element => {
  const id = label
    .toLowerCase()
    .replace(/[\s+]/g, '-')
    .replace(/[^a-z-]/g, '');
  return (
    <div className="alooba-input tel">
      <label htmlFor={id}>
        {label} {required && <span className="required-input">*</span>}
      </label>
      <div className={`${errorTxt ? 'errorInput' : ''}`}>
        <PhoneInput
          onChange={(phone, country, formatted, formattedValue) => {
            let finalFormattedValue = formattedValue;
            if (country && 'dialCode' in country) {
              const countryCodeLength = country.dialCode.length;
              if (
                finalFormattedValue.charAt(countryCodeLength + 1) === '0' &&
                finalFormattedValue.length > countryCodeLength + 1
              ) {
                finalFormattedValue =
                  finalFormattedValue.slice(0, countryCodeLength + 1) +
                  finalFormattedValue.slice(countryCodeLength + 2);
              }
            }
            if (finalFormattedValue === '+') {
              finalFormattedValue = '';
            }
            onChange(finalFormattedValue);
          }}
          country={defaultCountryCode}
          autoFormat={false}
          countryCodeEditable
          copyNumbersOnly={false}
          enableLongNumbers
          value={value}
          prefix="+"
          disabled={disabled || false}
          placeholder={placeholder}
          inputProps={{ 'data-testid': id }}
        />
      </div>
      {errorTxt && <p className="errorMsg">{errorTxt}</p>}
    </div>
  );
};

export default AloobaPhoneInput;
