export const shortenName = (name: string, limit: number): string => {
  const size = limit || 30;
  if (name && name.length > size) {
    name = `${name.slice(0, size)}...`;
  }
  return name;
};
export const timeFormat = (totalTime: number): any => {
  const timeInMinuites = totalTime > 0 ? totalTime / 60 : 0;
  let hours = 0;
  let mins = 0;
  if (timeInMinuites > 0) {
    hours = Math.floor(timeInMinuites / 60);
    mins = timeInMinuites % 60;
  }
  return { hours, mins };
};
export const fixPercentage = (percentage?: number): string => {
  return percentage ? `${percentage.toFixed()}%` : 'N/A';
};
export const putOnClipboard = (value: string): void => {
  const ta = document.createElement('textarea');
  ta.innerText = value;
  document.body.appendChild(ta);
  ta.select();
  document.execCommand('copy');
  ta.remove();
};

export const sleep = (ms: number): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getFormattedAssessmentName = (str: string): string => {
  return encodeURIComponent(str.replace(/[\s/\\%]+/g, '-').replace(/-+/g, '-'));
};

export const isObjectEmpty = (object): boolean =>
  object &&
  Object.keys(object).length === 0 &&
  Object.getPrototypeOf(object) === Object.prototype;

export const formatBytes = (bytes, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const hasScroll = (ref): boolean => {
  if (ref.current) {
    return ref.current?.scrollHeight > ref.current?.clientHeight;
  }
  return false;
};

export const checkObjectEquality = (obj1, obj2): boolean => {
  const keys = Object.keys(obj1);
  for (let i = 0; i < keys.length; i += 1) {
    if (obj2[keys[i]] !== obj1[keys[i]]) {
      return false;
    }
  }
  return true;
};

export const getTestTabUrl = (tabTitle): string => {
  return tabTitle.replace(/ /g, '-').replace('&', 'and').toLowerCase();
};

export const retryAfterDelay = (retryCount): number => {
  if (retryCount >= 3) {
    return 9000;
  }
  return retryCount * retryCount * 1000;
};

export const emptyJwt = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.e30.Et9HFtf9R3GEMA0IICOfFMVXY7kkTX1wr4qCyhIf58U`;
export const downloadFile = (blob, fileName): Promise<void> => {
  return new Promise<void>((resolve) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);

    // Wrap the click event in a function
    const clickHandler = (): void => {
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        link.removeEventListener('click', clickHandler);
        resolve();
      }, 100); // Adding a slight delay to ensure the cleanup happens after the click
    };

    link.addEventListener('click', clickHandler);

    // Programmatically trigger the click event
    link.click();
  });
};
