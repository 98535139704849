import CompareCandidates from 'components/CompareCandidates';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { deactivatePageHeader } from '../store/reducers/layout';

const CompareCandidatesPage: React.FC = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('Compare Candidates');
  const currentAssessment = useSelector(
    (state: RootState) => state.assessment?.currentAssessment
  );
  useEffect(() => {
    if (currentAssessment?.purpose === 'ld') {
      setTitle('Compare Employees');
    } else {
      setTitle('Compare Candidates');
    }
  }, [currentAssessment]);

  dispatch(deactivatePageHeader());
  return (
    <div>
      <Helmet>
        <title>{title} | alooba.com</title>
      </Helmet>
      <CompareCandidates />
    </div>
  );
};

export default CompareCandidatesPage;
