import React from 'react';

type IconProps = {
  fill?: string;
  text?: string;
};

const iconStyle = {
  display: 'flex',
  alignItems: 'center',
  color: '#0e0e2c',
};

const Grid: React.FC<IconProps> = ({ fill = '#C4C4C4', text }) => {
  return (
    <div style={{ flexDirection: 'column', ...iconStyle }}>
      <svg
        width="541"
        height="76"
        viewBox="0 0 541 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="236.946"
          y="44.7447"
          width="70.0542"
          height="1.94595"
          fill={fill}
        />
        <rect
          x="236.946"
          y="36.892"
          width="70.0542"
          height="1.94595"
          fill={fill}
        />
        <rect
          x="236.946"
          y="29.5136"
          width="70.0542"
          height="1.94595"
          fill={fill}
        />
        <rect
          x="236.946"
          y="22.1352"
          width="70.0542"
          height="1.94595"
          fill={fill}
        />
        <rect
          x="236.946"
          y="14.7568"
          width="70.0542"
          height="1.94595"
          fill={fill}
        />
        <rect
          x="236.946"
          y="7.37842"
          width="70.0542"
          height="1.94595"
          fill={fill}
        />
        <rect x="236.946" width="70.0542" height="1.94595" fill={fill} />
        <rect x="235" width="1.94595" height="46.7028" fill={fill} />
        <rect x="252.513" width="1.94595" height="46.7028" fill={fill} />
        <rect x="261.27" width="1.94595" height="46.7028" fill={fill} />
        <rect x="270.027" width="1.94595" height="46.7028" fill={fill} />
        <rect x="278.784" width="1.94595" height="46.7028" fill={fill} />
        <rect x="287.54" width="1.94595" height="46.7028" fill={fill} />
        <rect x="296.297" width="1.94595" height="46.7028" fill={fill} />
        <rect x="305.054" width="1.94595" height="46.7028" fill={fill} />
      </svg>
      {text && <span style={{ marginTop: '-15px' }}>{text}</span>}
    </div>
  );
};

export const GridIcon = React.memo(Grid);
