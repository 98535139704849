import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import DynamicTabs from 'components/Shared/DynamicTabs';
import { selectTab } from '../../store/reducers/assessment';
import '../../styles/heading.scss';

interface AssessmentTabsProps {
  tabsData: any;
  trialBannerShow?: boolean;
}

interface UrlParams {
  assessmentId?: string;
  tabName?: string;
  formattedAssessmentName?: string;
}

export default function AssessmentTabs(props: AssessmentTabsProps): any {
  const dispatch = useDispatch();
  const { tabsData, trialBannerShow } = props;
  const history = useHistory();
  const [value, setValue] = useState<any>();
  const [content, setContent] = useState<any>(`this is tab content ${value}`);
  const {
    assessmentId,
    tabName,
    formattedAssessmentName,
  }: UrlParams = useParams();

  useEffect(() => {
    if (value) {
      if (!tabsData[value]) {
        dispatch(selectTab('overview'));
        history.push(
          `/assessment/${assessmentId}-${formattedAssessmentName}/overview`
        );
      } else {
        const linkParts = tabsData[value]?.contentLink.split('/');
        const tabLink = linkParts[linkParts.length - 1];
        dispatch(selectTab(tabLink));
        setContent(tabsData[value]?.component);
      }
    }
  }, [
    assessmentId,
    tabsData,
    formattedAssessmentName,
    value,
    history,
    dispatch,
  ]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ): any => {
    window.history.replaceState(null, null, tabsData[newValue].contentLink);
    setValue(newValue);
    setContent(tabsData[newValue].component);
  };
  if (value === undefined) {
    tabsData.map((tab, k) => {
      if (tab.contentLink.includes(tabName)) {
        setValue(k);
        setContent(tabsData[k].component);
      }
      return true;
    });
  }
  const dynamicTabsProps = {
    tabsData,
    handleChange,
    value,
    content,
    trialBannerShow,
  };
  return <DynamicTabs {...dynamicTabsProps} />;
}
