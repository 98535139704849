import React from 'react';
import './PayInvoiceMessage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const PayInvoiceMessage: React.FC = () => {
  return (
    <div className="restricted-access">
      <div className="card">
        <div className="head">
          <FontAwesomeIcon className="icon" icon={faExclamationTriangle} />
          <h1 className="title">Account Access Restricted</h1>
        </div>

        <div className="alert-box">
          <div className="alert">
            Your access to Alooba has been temporarily restricted due to a past
            due payment.
          </div>

          <div className="message">
            <p>
              To restore full access to your Alooba account, please pay your
              outstanding invoice. Your subscription is still active, and access
              will be immediately restored once payment is processed.
            </p>

            <p className="support">
              Need assistance? Please contact{' '}
              <a href="mailto:support@alooba.com">support@alooba.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayInvoiceMessage;
