import React from 'react';
import './index.scss';

const SkillResource = ({ link, title }): any => {
  return (
    <div className="skill-resource">
      <a href={link} target="_blank" rel="noreferrer">
        <img
          src={`http://www.google.com/s2/favicons?domain=${link}&sz=24`}
          alt={title}
          width="24"
          height="24"
        />
        {title}
      </a>
    </div>
  );
};
export default SkillResource;
