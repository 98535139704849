import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';

import { RootState } from 'store/rootReducer';
import {
  getStripeCheckoutToken,
  getPaymentMethods,
  deletePaymentMethod,
} from 'store/actions/payment.actions';

import { faPlus, faCreditCard } from '@fortawesome/free-solid-svg-icons';

import {
  faCcMastercard,
  faCcVisa,
  faCcJcb,
  faCcDiscover,
  faCcAmex,
  faCcDinersClub,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PaymentMethods.scss';
import { stripePaymentDetailsShown } from 'store/reducers/app';

// https://docs.stripe.com/api/payment_methods/object
const brands = {
  american_express: faCcAmex,
  // cartes_bancaires,
  diners_club: faCcDinersClub,
  discover: faCcDiscover,
  // eftpos_australia,
  // interac,
  jcb: faCcJcb,
  mastercard: faCcMastercard,
  // union_pay,
  visa: faCcVisa,
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

interface PaymentMethodsProps {
  selectedPaymentMethod: any;
  setSelectedPaymentMethod: any;
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  const dispatch = useDispatch();

  const { userDetails } = useSelector((state: RootState) => state.profile);
  const payment = useSelector((state: RootState) => state.payment);
  const orgId = userDetails?.recruiter_detail?.organisation_id;

  const handleComplete = (): void => {
    dispatch(getPaymentMethods(orgId));
  };

  const handleAddPaymentMethod = (): void => {
    dispatch(stripePaymentDetailsShown());
    dispatch(getStripeCheckoutToken(orgId));
  };

  const handleDeletePaymentMethod = (lastDigits: string): void => {
    dispatch(deletePaymentMethod(orgId, lastDigits));
  };

  useEffect(() => {
    if (orgId) {
      dispatch(getPaymentMethods(orgId));
    }
  }, [orgId, dispatch]);

  useEffect(() => {
    if (payment.paymentMethods.length > 0) {
      setSelectedPaymentMethod(payment.paymentMethods[0]);
    } else if (selectedPaymentMethod && window) {
      // paymentMethods.length == 0 but selectedPaymentMethod is not null
      // user is removing payment method and probably opened Stripe Checkout previously
      setSelectedPaymentMethod(null);
      window.location.reload();
    }
  }, [payment.paymentMethods, setSelectedPaymentMethod, selectedPaymentMethod]);

  const clientSecret = payment.clientToken;

  const stripeForm = clientSecret ? (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{
        clientSecret,
        onComplete: handleComplete,
      }}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  ) : (
    <button
      className="primary md"
      type="button"
      onClick={handleAddPaymentMethod}
    >
      <FontAwesomeIcon className="icon" icon={faPlus} />
      Add Payment Method
    </button>
  );

  return (
    <div className="payment-methods">
      <h3>Payment</h3>
      {payment.error && <p>{payment.error}</p>}
      {payment.loading && <p>Loading...</p>}
      {payment.loading !== null &&
        !payment.loading &&
        !payment.error &&
        (!selectedPaymentMethod ? (
          stripeForm
        ) : (
          <div className="main-container">
            <FontAwesomeIcon
              className="icon"
              icon={brands[selectedPaymentMethod.brand] ?? faCreditCard}
              size="4x"
            />
            <p>
              •••• •••• •••• {selectedPaymentMethod.last4}
              <br />
              Expires {selectedPaymentMethod.exp_month}/
              {selectedPaymentMethod.exp_year}
            </p>
            <button
              className="del-btn"
              type="button"
              onClick={(_event: React.MouseEvent<HTMLInputElement>) =>
                handleDeletePaymentMethod(selectedPaymentMethod.last4)
              }
            >
              &times;
            </button>
          </div>
        ))}
    </div>
  );
};

export default PaymentMethods;
