import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { getCustomerPortalLink } from 'api/subscription.api';
import { useToasts } from 'react-toast-notifications';
import Button from '../Shared/Button';
import './UpsellLiteSubscription.scss';

const UpsellLiteSubscription: React.FC = () => {
  const { addToast } = useToasts();
  const [loadingLink, setLoadingLink] = useState(false);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { subscription } = useSelector(
    (state: RootState) => state.subscription
  );
  const orgId = userDetails?.recruiter_detail?.organisation_id;

  const getLink = async (): Promise<void> => {
    try {
      setLoadingLink(true);
      const response = await getCustomerPortalLink(orgId);
      if (response?.data?.data?.url && response.data.data.stripe_id) {
        window.location.assign(
          `${response.data.data.url}/subscriptions/${response.data.data.stripe_id}/update`
        );
      }
    } catch (e) {
      setLoadingLink(false);
      addToast({ type: 'error', msg: e.response?.data?.message || e.message });
    }
  };

  const benefits = [
    {
      title: 'More Credits',
      description:
        'Get 100,000 credits per month, allowing you to manage more assessments and invite more candidates.',
    },
    {
      title: 'Premium Test Types',
      description:
        'Create assessments with Coding, SQL, Video, and other tests.',
    },
    {
      title: 'Access to Premium Features',
      description:
        'On our Professional plan you will have access to our advanced Anti-Cheating options, SMS reminders, and other premium features.',
    },
    {
      title: 'Customized Branding',
      description:
        'Include your company logo, colors, and messaging in emails and in the assessments.',
    },
    {
      title: 'User Permission Management',
      description:
        'Control who can access what with user permissions and groups.',
    },
    {
      title: 'And More',
      description:
        '<a href="https://www.alooba.com/pricing/alooba-assess#feature-list" target="_blank">A full list of all the features of our plans can be found here.</a>',
    },
  ];

  if (!subscription || subscription.plan?.name !== 'Lite') {
    return null;
  }

  return (
    <div className="upsell-lite-subscription main-container mt4 center">
      <h1 className="mb4">You&apos;re missing out!</h1>
      <h2 className="mb4">
        Unlock the full potential of Alooba with our Professional plan.
      </h2>
      <div className="benefits mb4">
        {benefits.map((benefit) => (
          <div className="mb2">
            <h4>{benefit.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: benefit.description }} />
          </div>
        ))}
      </div>
      <Button
        variant="primary md center"
        text="Upgrade to Professional"
        onClick={getLink}
        loading={loadingLink}
        loadingTxt="Redirecting..."
      />
    </div>
  );
};

export default UpsellLiteSubscription;
