import React, { useEffect, useState } from 'react';
import './index.scss';
import { useToasts } from 'react-toast-notifications';
import { Input } from 'components/Shared';
import { editInterview } from 'api/interview.api';
import { useDispatch, useSelector } from 'react-redux';
import { updateAssessmentInterview } from 'store/reducers/interview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { RootState } from 'store/rootReducer';
import useHover from 'hooks/useHover';
import Fade from 'components/Shared/Fade';
import PencilIcon from '../../images/icons/pencil-blue.svg';

interface Props {
  loading: boolean;
  assessment: any;
  interview: any;
}

const INTERVIEW_NAME_MAX_LENGTH = 25;

const InterviewHeader: React.FC<Props> = ({
  loading,
  assessment,
  interview,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hoverRef, isHovering] = useHover();
  const [savingName, setSavingName] = useState<boolean>(false);
  const [interviewName, setInterviewName] = useState<string | null>(
    interview?.name
  );
  const [lastSavedInterviewName, setLastSavedInterviewName] = useState<
    string | null
  >(interview?.name);
  const otherInterviewNames = useSelector((state: RootState) => {
    const assessmentInterviews = state.interview?.interviews[assessment?.id];
    if (!assessmentInterviews) {
      return [];
    }
    return assessmentInterviews.interviews
      .filter((i) => i.id !== interview?.id)
      .map((i) => i.name);
  });

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  useEffect(() => {
    setInterviewName(interview?.name);
    setLastSavedInterviewName(interview?.name);
  }, [interview]);

  const handleInterviewNameChange = (e): void => {
    const name = e.target.value;
    if (name.length <= INTERVIEW_NAME_MAX_LENGTH) {
      setInterviewName(name);
    }
  };

  const updateInterviewName = (): void => {
    setEditMode(false);
    const newName = interviewName.trim();
    setInterviewName(newName);
    if (newName === lastSavedInterviewName) {
      return;
    }

    if (otherInterviewNames.includes(newName)) {
      setInterviewName(lastSavedInterviewName);
      addToast({
        msg: `An interview with the name "${newName}" already exists on this assessment.`,
        type: 'error',
      });
      return;
    }

    if (newName.length === 0) {
      setInterviewName(lastSavedInterviewName);
      addToast({
        msg: 'The interview name cannot be empty.',
        type: 'error',
      });
      return;
    }

    setSavingName(true);
    editInterview(assessment.id, interview.id, {
      name: newName,
    })
      .then(() => {
        dispatch(
          updateAssessmentInterview({
            assessmentId: assessment.id,
            interviewId: interview.id,
            values: {
              name: newName,
            },
          })
        );
        setLastSavedInterviewName(newName);
        addToast({
          msg: 'Interview name updated successfully.',
          type: 'success',
        });
      })
      .catch((error) => {
        setInterviewName(lastSavedInterviewName);
        if (error.response?.data?.errors?.detail) {
          addToast({
            msg: error.response?.data?.errors?.detail,
            type: 'error',
          });
          return;
        }
        addToast({
          msg: 'Failed to update interview name.',
          type: 'error',
        });
      })
      .finally(() => setSavingName(false));
  };

  return (
    <>
      {!loading && assessment && interview && (
        <div className="breadcrumbs">
          <div className="inactive">{assessment.name}</div>
          <div className="divider">/</div>
          <div
            className="interview-name-container"
            onClick={() => setEditMode(true)}
            ref={hoverRef}
            role="button"
            tabIndex={0}
          >
            {editMode ? (
              <div className="interview-name-input">
                <Input
                  autoFocus
                  type="text"
                  value={interviewName}
                  onChange={handleInterviewNameChange}
                  onBlur={updateInterviewName}
                  onKeyDown={(e) => {
                    // ENTER key
                    if (e.keyCode === 13) {
                      updateInterviewName();
                      // ESC key
                    } else if (e.keyCode === 27) {
                      setEditMode(false);
                      setInterviewName(lastSavedInterviewName);
                    }
                  }}
                  withoutTopMargin
                />
                <div className="interview-name-length">
                  {`${interviewName.length}/${INTERVIEW_NAME_MAX_LENGTH}`}
                </div>
              </div>
            ) : (
              <div className="editable">{interviewName}</div>
            )}
            {!editMode &&
              (savingName ? (
                <FontAwesomeIcon
                  className="fa-spin loading-icon"
                  width={10}
                  icon={faSpinner}
                />
              ) : (
                <Fade fadeIn={isHovering}>
                  <img
                    className="interview-name-edit-icon"
                    src={PencilIcon}
                    alt="edit"
                  />
                </Fade>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default InterviewHeader;
