import OrgDetails from 'components/OrganizationDetails';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { deactivatePageHeader } from '../store/reducers/layout';

const OrganizationDetails: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <div className="container">
      <Helmet>
        <title>Organization Settings | alooba.com</title>
      </Helmet>
      <OrgDetails />
    </div>
  );
};

export default OrganizationDetails;
