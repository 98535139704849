import { Grid } from '@material-ui/core';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../../CandidateResultsList/Filters/index.scss';
import './index.scss';
import MultiSelectFilter from '../../Shared/Filters/MultiSelectFilter';
import TextFilter from '../../Shared/Filters/TextFilter';

export type filtersProps = {
  filters: Array<any>;
  onFilterChange: any;
  visible?: boolean;
  loading: boolean;
  filtering: boolean;
};
const Filters = ({
  filters,
  onFilterChange,
  visible = true,
  loading,
  filtering,
}: filtersProps): JSX.Element => {
  return (
    <>
      {loading ? (
        <div className="filters-skeleton">
          <Skeleton width={220} height={40} />
          &ensp;
          <Skeleton width={120} height={40} />
        </div>
      ) : (
        <div style={{ marginBottom: '15px' }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={2}
            key={1}
          >
            <TextFilter
              key="search"
              onChange={onFilterChange}
              {...filters[0]}
              visible={visible}
            />
            <MultiSelectFilter
              key="groups"
              onChange={onFilterChange}
              {...filters[1]}
              visible={visible}
              selectClassName="groups-filter"
            />
            <MultiSelectFilter
              key="archive"
              onChange={onFilterChange}
              {...filters[2]}
              visible={visible}
              selectClassName="archive-filter"
            />
            <span
              className={`filters-throbber-wrapper ${
                filtering ? 'visible' : ''
              }`}
            >
              <span className="pr5 filters-throbber">Applying filters...</span>
              <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            </span>
          </Grid>
        </div>
      )}
    </>
  );
};
export default Filters;
