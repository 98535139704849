import axios, { AxiosError } from 'axios';
import StackTrace, { StackFrame } from 'stacktrace-js';
import api from './api';

const isNetworkError = (error): boolean => {
  return (
    error.message === 'Network Error' ||
    error.message?.includes('Request aborted')
  );
};

const canIgnoreError = (message): boolean => {
  return message === 'Unhandled Promise Rejection: Failed to fetch';
};

export async function handleError(error: Error): Promise<void> {
  let priority = 'P2';
  const { userAgent } = window.navigator;
  const errorDetails = {
    stack: [],
    handlerStack: [],
    url: window.location.href,
    userAgent,
    xhrRequest: null,
    xhrResponse: null,
  };
  if (error === null) {
    error = new Error('Unknown error');
  }

  const isAxiosError = axios.isAxiosError(error);
  const networkError = isNetworkError(error);

  if (isAxiosError) {
    const axiosError = error as AxiosError;

    // remember, response can be undefined (e.g backend is down, timeout)
    const { response } = axiosError;
    // lower the priority if it is a network error or has a 4xx status code
    if (
      networkError ||
      (response && response.status >= 400 && response.status < 500)
    ) {
      priority = 'P5';
    }

    const {
      config: { url, method },
    } = axiosError;
    errorDetails.xhrRequest = {
      url,
      method,
    };

    if (!networkError && response) {
      const {
        config: { params },
        data,
      } = response;
      errorDetails.xhrResponse = data;
      errorDetails.xhrRequest = { ...errorDetails.xhrRequest, params };
    }
  }

  errorDetails.handlerStack = await StackTrace.fromError(Error());

  StackTrace.fromError(error)
    .then((stackFrame: StackFrame[]) => {
      errorDetails.stack = stackFrame;
    })
    .catch(() => {
      errorDetails.stack = [error.stack];
    })
    .finally(() => {
      if (canIgnoreError(error.message)) {
        return;
      }
      api
        .post(`/frontend-error?client_id=${process.env.REACT_APP_CLIENT_ID}`, {
          origin: `Frontend Error: ${error.message} at ${errorDetails.url}`,
          error: errorDetails,
          priority,
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {}); // since it always returns 500 we want to swallow this error or it will keep submitting to the error collection endpoint
    });
}
