type Sticky = 'left' | 'right';
type Scroll = 'start' | 'end';

export interface TableField {
  label: string;
  type?: string;
  cloak?: boolean;
  sticky?: Sticky;
  scroll?: Scroll;
}

export const selectFields = [
  'Reference',
  'Name',
  'Email',
  'Invited On',
  'Expires On',
  'Started On',
  'Completed On',
  'Status',
];

export const tableFields: TableField[] = [
  {
    label: 'Reference',
    sticky: 'left',
  },
  {
    label: 'Name',
    cloak: true,
    sticky: 'left',
    scroll: 'end',
  },
  {
    label: 'Email',
    cloak: true,
  },
  {
    label: 'Invited On',
    type: 'datetime',
  },
  {
    label: 'Expires On',
    type: 'datetime',
  },
  {
    label: 'Started On',
    type: 'datetime',
  },
  {
    label: 'Completed On',
    type: 'datetime',
  },
  {
    label: 'Status',
    sticky: 'right',
    scroll: 'start',
  },
];

export const statusTooltip = {
  'Invite Scheduled':
    "hasn't received the invite yet as it has been scheduled to be sent later.",
  Invited: 'has been invited, however they haven’t seen the invite yet.',
  'Seen Invite':
    'has seen the invite, however they didn’t click the link in the invite.',
  'Opened Assessment':
    'clicked the link in the invite, however they haven’t started the assessment',
  'In Progress': 'is currently doing the assessment.',
  Incomplete:
    'has done at least one test of the assessment, however they haven’t completed all assessment tests.',
  Expired:
    'didn’t start the assessment before the expiry date. You will need to extend the expiry for them to be able to take the assessment.',
  'Incomplete Expired':
    'had done at least one test of the assessment, however they didn’t complete all assessment tests before the expiry date. You will need to extend the expiry for them to be able to complete the assessment.',
  'Incomplete Rejected':
    'was rejected before completing all parts of the assessment.',
  'Requires Evaluation':
    'has completed the assessment, however some parts still require manual evaluation to finalize it.',
  Completed: 'has completed the assessment and it has been finalized.',
  'Invite Not Delivered':
    "wasn't able to receive the invite as it failed to be delivered. We will retry sending the invite a few more times.",
  'Invite Undeliverable':
    "wasn't able to receive the invite as it failed to be delivered multiple times.",
  Disqualified:
    "didn't meet the qualification criteria based on the pre assessment information provided and chose not to continue with the assessment.",
};
