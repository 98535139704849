import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import TemplateList from 'components/OrganizationTemplates';
import { deactivatePageHeader } from '../store/reducers/layout';

const OrganizationTemplates: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <div className="container">
      <Helmet>
        <title>Organization Templates | alooba.com</title>
      </Helmet>
      <TemplateList />
    </div>
  );
};

// Alooba Assess Assessment Introduction, Alooba Growth Exercise Introduction
export default OrganizationTemplates;
