import React from 'react';
import PieChart from '../Shared/PieChart';

interface Frequency {
  label: string;
  count: number;
}

interface GenderStats {
  total: number;
  summary: Frequency[];
}
const GenderPieChart: React.FC<{
  genderStats: GenderStats;
  height: number;
}> = ({ genderStats, height }): JSX.Element => {
  const genderDistribution = genderStats.summary.map(({ label, count }) => {
    return { label, value: count };
  });
  return <PieChart height={height} data={genderDistribution} />;
};

export default GenderPieChart;
