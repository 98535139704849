import React from 'react';
import { Helmet } from 'react-helmet';
import NotFoundImage from '../../images/404.svg';
import MinimalLayout from '../Shared/Layout/MinimalLayout';
import './notFound.scss';

const NotFound: React.FC = () => (
  <MinimalLayout>
    <div className="error-page-container">
      <Helmet>
        <title>Houston, we have a problem | alooba.com</title>
      </Helmet>
      <img
        src={NotFoundImage}
        alt="404 - Not Found"
        style={{
          width: '40%',
          minWidth: '200px',
          maxWidth: '500px',
          marginBottom: '20px',
        }}
      />
      <h2>This page isn&apos;t available</h2>
      <br />
      <p>The link may be broken, or the page may have been removed.</p>
      <p>Check to see if the link you&apos;re trying to open is correct.</p>
    </div>
  </MinimalLayout>
);

export default NotFound;
