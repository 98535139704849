import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DynamicTabs from 'components/Shared/DynamicTabs';
import { selectTest } from 'store/reducers/candidate';
import { useDispatch } from 'react-redux';
import '../../styles/heading.scss';

interface CandidateTabsProps {
  tabsData: any;
  breakpoint?: number;
  containerWidth?: number;
  trialBannerShow?: boolean;
}

interface UrlParams {
  reference?: string;
  tabName?: string;
}

export default function CandidateTabs(props: CandidateTabsProps): any {
  const { tabsData, breakpoint, containerWidth, trialBannerShow } = props;
  const history = useHistory();
  const [value, setValue] = useState<any>();
  const [content, setContent] = useState<any>(`this is tab content ${value}`);
  const { reference, tabName }: UrlParams = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (value) {
      if (!tabsData[value]) {
        history.push(`/candidate-details/${reference}/overview`);
      } else {
        setContent(tabsData[value]?.component);
      }
    }
  }, [reference, tabsData, value, history]);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ): any => {
    if (!tabsData[newValue].is_test) {
      dispatch(selectTest({}));
    }
    history.push(tabsData[newValue].contentLink);
  };
  useEffect(() => {
    for (let k = 0; k < tabsData.length; k += 1) {
      if (tabsData[k].contentLink.includes(tabName)) {
        setValue(k);
      }
    }
  }, [tabName, tabsData]);

  useEffect(() => {
    if (value !== undefined) {
      setContent(tabsData[value]?.component);
    }
  }, [tabsData, value]);

  const dynamicTabsProps = {
    tabsData,
    handleChange,
    value,
    content,
    breakpoint,
    containerWidth,
    trialBannerShow,
  };
  return <DynamicTabs {...dynamicTabsProps} />;
}
