import TotalScore from 'components/Shared/Test/TestHeader/TotalScore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getWorkingFiles } from 'api/candidate.api';
import { setTestProperty } from 'store/reducers/candidate';
import { useToasts } from 'react-toast-notifications';
import UploadedFiles from 'components/Shared/Test/UploadedFiles';
import RequiresEvaluationIcon from 'images/icons/requires-evaluation-blue.svg';

interface Props {
  testId: number;
}
interface Subject {
  subject_name: string;
  score: number;
  max_score: number;
  scored_percent: number;
}
interface Test {
  status: string;
  end: string;
  test_type: string;
  workingFiles: any[];
  subject_scores: Subject[];
}

const SubjectScoresSummary: React.FC<Props> = ({ testId }: Props) => {
  const dispatch = useDispatch();
  const { results, candidate } = useSelector(
    (state: RootState) => state.candidate
  );

  const { addToast } = useToasts();

  const [test, setTest] = useState<Test>(null);

  useEffect(() => {
    const currentTest = results.tests[testId];
    setTest(currentTest);
  }, [testId, results]);

  if (test && test?.test_type === 'Data Analysis') {
    // Fetch Working files
    if (testId && candidate?.reference && !test?.workingFiles) {
      getWorkingFiles(candidate?.reference, testId)
        .then((response) => {
          dispatch(
            setTestProperty({
              testId,
              propertyName: 'workingFiles',
              value: response.data.data,
            })
          );
        })
        .catch((e) => {
          addToast({
            type: 'error',
            msg: `Could not fetch working files for the test.`,
          });
          throw e;
        });
    }
  }

  return (
    <>
      <div className="subject-scores-summary">
        {test?.subject_scores.length > 0
          ? test?.subject_scores.map((subject: any) => {
              return (
                <>
                  <TotalScore
                    label={subject.subject_name}
                    scoredMarks={subject.score}
                    totalMarks={subject.max_score}
                    scoredPercent={subject.scored_percent}
                  />
                </>
              );
            })
          : ''}
      </div>

      {test && test?.status === 'Requires Evaluation' ? (
        <div className="center">
          <h2>
            <img
              className="personal-info-icon"
              src={RequiresEvaluationIcon}
              alt="Requires Evaluation Icon"
            />
            Requires Evaluation
          </h2>
          <p>
            The candidate&apos;s responses have not been evaluated. Please talk
            to your account administrator.
          </p>
        </div>
      ) : (
        ''
      )}

      <div>
        {test && test?.workingFiles?.length > 0 ? (
          <UploadedFiles
            candidate={candidate}
            files={test?.workingFiles}
            testEnd={test?.end}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default SubjectScoresSummary;
