import React, { useEffect, useState } from 'react';
import './ScoreInput.scss';

const ScoreInput: React.FC<{
  value: string;
  maxScore: number;
  onScoreChange: (number) => void;
  disabled: boolean;
}> = ({ value, maxScore, onScoreChange, disabled }) => {
  const [score, setScore] = useState(value);
  useEffect(() => {
    setScore(value);
  }, [value]);

  const scoreInputHandler = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const intValue = parseInt(evt.target.value, 10);
    onScoreChange(intValue);
  };
  return (
    <div className="score-input-field">
      <div>
        <input
          data-testid="score-input"
          disabled={disabled}
          type="number"
          step="1"
          min="0"
          max={maxScore}
          value={score}
          placeholder="#"
          onChange={scoreInputHandler}
        />
      </div>
      <div>/</div>
      <div className="max-score">{maxScore}</div>
    </div>
  );
};
export default ScoreInput;
