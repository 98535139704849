import React from 'react';
import { Modal } from '../Modal';

interface Props {
  loadingTxt?: string;
  loading?: boolean;
  isShown?: boolean;
  actionText?: string;
  handleButtonAction?: any;
  setModalVisibility?: any;
  title?: string;
  body?: any;
  cancelButtonText?: string;
  children?: any;
}
const customProps = {
  actionText: 'Confirm',
  showCancel: true,
  hideCloseButton: true,
  containerClass: 'confirm-modal',
};
const ConfirmModal = (props: Props): JSX.Element => {
  const { title, children } = props;
  return (
    <Modal {...props} {...customProps}>
      <div>
        <h2>{title}</h2>
        <br />
        {children}
        <hr />
      </div>
    </Modal>
  );
};

export default ConfirmModal;
