import { Tooltip } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import './index.scss';
import info from '../../../images/icons/question-mark.svg';

const FunnelChart: React.FC<{
  data: Record<string, any>[];
  height: number;
  strokeWidth: number;
  base: string;
}> = ({ data, height = 100, strokeWidth = 2, base = 'max' }): JSX.Element => {
  const main_hood = useRef(null);
  const svg = useRef(null);

  const [chartWidth, setChartWidth] = useState(0);

  const handleResize = (): void => {
    if (!data || data.length === 0 || !main_hood.current) {
      setChartWidth(0);
    }
    setChartWidth(Math.floor(main_hood.current.offsetWidth));
  };
  useWindowSize(handleResize);

  const getMaxPoint = (): number => {
    let max = 0;
    data.forEach((point) => {
      max = point.value > max ? point.value : max;
    });
    return max;
  };

  const getBase = (): number => {
    switch (base) {
      case 'first':
        return data[0].value;
      case 'max':
      default:
        return getMaxPoint();
    }
  };

  const setPoints = (width): Record<string, any> => {
    let idx = data.length;
    const bar_width = Math.round(width / idx);
    const bars = ['0,0', `0,${height}`];
    const last_point = width;
    bars.push(`${last_point},${height}`);
    const lines_x = [];
    const lines_y = [];
    const percent_base = getBase();
    let count = 1;
    let previous;
    while (idx > 0) {
      let current;
      let line_point;
      idx -= 1;
      let y_point;
      if (count === 1) {
        current = height * (1 - (data[idx].value * 1) / percent_base);
        previous = current;
        y_point = current === height ? current - 1 : current;
        bars.push(`${last_point},${y_point}`);
        lines_y.push(current);
        lines_x.push(last_point);
      } else if (idx === 0) {
        line_point = last_point - bar_width * (count - 1);
        lines_y.push(previous);
        lines_x.push(bar_width);
        y_point = previous === height ? previous - 1 : previous;
        bars.push(`${line_point},${y_point}`);
      } else {
        line_point = last_point - bar_width * (count - 1);
        lines_y.push(previous);
        lines_x.push(line_point);
        y_point = previous === height ? previous - 1 : previous;
        bars.push(`${line_point},${y_point}`);
        previous = height * (1 - (data[idx].value * 1) / percent_base);
      }
      count += 1;
    }

    return { bars: bars.join(' '), lines_x, lines_y };
  };

  if (!data || data.length === 0) {
    return <div className="funnel_main_hood" ref={main_hood} />;
  }

  const line_style = { stroke: 'white', strokeWidth };
  const grad_start = { stopColor: '#153F68', stopOpacity: 0.8 };
  const grad_stop = { stopColor: '#64B1FF', stopOpacity: 0.8 };
  const fill = { fill: 'url(#grad1)' };

  const dv_width = { width: chartWidth / data.length };
  const coordinates = setPoints(chartWidth);

  return (
    <div className="funnel_main_hood" ref={main_hood}>
      <div className="funnel-hood">
        <svg height={height} width={chartWidth} ref={svg}>
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={grad_start} />
              <stop offset="100%" style={grad_stop} />
            </linearGradient>
          </defs>
          <polygon points={coordinates.bars} style={fill} />
          {coordinates.lines_x.map((line_x, i) => (
            <line
              x1={line_x}
              y1={coordinates.lines_y[i] - 1}
              x2={line_x}
              y2={height}
              style={line_style}
            />
          ))}
          Sorry, your browser does not support inline SVG.
        </svg>
      </div>
      <div className="dv_labels">
        {data.map((point) => (
          <div style={dv_width} className="dv_wrapper">
            <div className="dv_point dv_all bold">{point.value}</div>
            <div className="dv_label dv_all">
              {point.tooltip ? (
                <div className="funnel-tooltip-container">
                  {point.label}
                  <Tooltip arrow title={point.tooltip} placement="top">
                    <img
                      width={18}
                      src={info}
                      alt="Info"
                      className="funnel-tooltip-icon"
                    />
                  </Tooltip>
                </div>
              ) : (
                point.label
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default FunnelChart;
