import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import PlanUpgradeModal from '../Shared/PlanUpgradeModal';
import { Button } from '../Shared';

interface RemoteIntegrationProps {
  name: string;
  loading?: boolean;
  logo?: string;
}

export const DisabledIntegrationPartner: React.FC<RemoteIntegrationProps> = ({
  name,
  loading,
  logo,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="integration-partner">
      <Button
        loading={loading}
        loadingPlaceholder={loading}
        variant="sub-primary md"
        text={name}
        onClick={() => setShowModal(true)}
      />
      {loading && <Skeleton height={32} width={160} />}
      {!loading && (logo ? <img alt={name} src={logo} /> : name)}
      <PlanUpgradeModal
        setModalVisibility={setShowModal}
        isShown={showModal}
        title="Upgrade your account to enable ATS Integration"
      >
        <p>
          Enabling an ATS integration is not supported on your current
          subscription plan. Upgrade your subscription to integrate with our
          supported ATSs as well as gaining access to many other amazing
          features Alooba has to offer.
        </p>
      </PlanUpgradeModal>
    </div>
  );
};

export default DisabledIntegrationPartner;
