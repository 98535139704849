import React from 'react';
import Select, { MenuPlacement } from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './Dropdown.scss';

interface IProps {
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  menuPlacement?: MenuPlacement;
  loading?: boolean;
  isAddButton?: boolean;
  dropdownOptions?: Array<Record<string, string>>;
  handleChange?: (e: { label: string; value: any }) => void;
  noOptionsMessage?: string;
  errorTxt?: string;
  isSearchable?: boolean;
  customStyles?: any;
  varient?: string;
  disabled?: boolean;
}

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#f5f5f5 !important' : null,
      color: '#0E0E2C',
    };
  },
};

const options = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];

const Dropdown = ({
  dropdownOptions = options,
  id,
  name,
  value,
  label,
  loading,
  handleChange,
  isAddButton,
  placeholder,
  menuPlacement,
  isSearchable = true,
  noOptionsMessage,
  errorTxt,
  customStyles,
  varient,
  disabled,
}: IProps): JSX.Element => {
  if (label && !id) {
    id = uuid();
  }
  const selected = (selectedValue, array): any => {
    return array.filter((one) => one.value === selectedValue);
  };
  const addButtonText = (
    <span className="placeholder_value">
      {isAddButton && <FontAwesomeIcon className="icon" icon={faPlus} />}
      {placeholder}
    </span>
  );
  return (
    <div
      className={`alooba-input ${
        isAddButton ? 'add_button' : 'dropdown_select'
      }`}
    >
      {label && <label htmlFor={id}>{label}</label>}
      {loading ? (
        <Skeleton height={40} />
      ) : (
        <>
          <Select
            styles={customStyles || colourStyles}
            isSearchable={isSearchable}
            options={dropdownOptions}
            inputId={id}
            name={name}
            menuPlacement={menuPlacement || 'bottom'}
            value={selected(value, dropdownOptions)}
            onChange={handleChange}
            placeholder={isAddButton ? addButtonText : placeholder}
            noOptionsMessage={() => noOptionsMessage}
            className={`dropdown ${errorTxt ? 'errorInput' : ''}${
              varient ? ` ${varient}` : ''
            }`}
            classNamePrefix="dropdown_"
            isDisabled={disabled}
          />
          {errorTxt && <p className="errorMsg">{errorTxt}</p>}
        </>
      )}
    </div>
  );
};

export default Dropdown;
