import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useChatbot } from 'hooks';
import DefaultLayout from '../Layout/Layout';
import MinimalLayout from '../Layout/MinimalLayout';
import NoLayout from '../Layout/NoLayout';
import { getCurrentTokens } from '../../../store/actions/auth.actions';

export enum Layout {
  Default,
  Minimal,
  None,
}

interface IProps extends RouteProps {
  component: React.ComponentType;
  layout?: Layout;
  showChatbot?: boolean;
  path: string;
  hasBars?: boolean;
}

export const PrivateRoute: React.FC<IProps> = ({
  component,
  layout,
  showChatbot,
  hasBars = true,
  ...options
}: IProps) => {
  useChatbot(showChatbot !== false);
  const { accessToken } = getCurrentTokens();

  if (accessToken) {
    if (!layout || layout === Layout.Default) {
      return (
        <DefaultLayout>
          <Route {...options} component={component} />
        </DefaultLayout>
      );
    } else if (layout === Layout.Minimal) {
      return (
        <MinimalLayout hasBars={hasBars}>
          <Route {...options} component={component} />
        </MinimalLayout>
      );
    } else if (layout === Layout.None) {
      return (
        <NoLayout>
          <Route {...options} component={component} />
        </NoLayout>
      );
    }
  }

  return (
    <Redirect
      to={`/login?redirect_url=${encodeURIComponent(
        window.location.href.substring(window.location.origin.length)
      )}`}
    />
  );
};
