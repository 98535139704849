import React from 'react';
import InviteCandidateIcon from 'images/icons/invite-candidate-blue.svg';
import PublicLinkIcon from 'images/icons/public-link-blue.svg';
import ConfigurationIcon from 'images/icons/configuration-blue.svg';
import StatIcon from 'components/Shared/StatIcon';
import { capitalizeFirstLetter } from 'helpers/text';
import authorize, { isRestricted } from '../../Authorization/Check';

interface AssessmentCardPublishedProps {
  assessment?: any;
  handleInviteModal?: any;
}
const AssessmentCardPublished = ({
  assessment,
  handleInviteModal,
}: AssessmentCardPublishedProps): JSX.Element => {
  const restricted = isRestricted(assessment);
  const legacyUrl = process.env.REACT_APP_ALOOBA_LEGACY_URL;
  const editSampleRoute = 'view-assessment-customized';
  const tooltip =
    assessment.purpose === 'ld' ? 'Add Employee' : 'Invite Candidate';
  const productName = assessment.purpose === 'ld' ? 'exercise' : 'assessment';

  return (
    <>
      {authorize('assessment-candidate', 'add', assessment.role.user_id) &&
      !assessment.is_archived &&
      !restricted ? (
        <StatIcon
          tooltip={tooltip}
          icon={InviteCandidateIcon}
          handleImageClick={(e) => handleInviteModal(e, assessment)}
          isLink
          id="open-invite-modal"
          alt="invite-candidate"
        />
      ) : (
        ''
      )}
      {assessment.public_url_active &&
        !assessment.is_archived &&
        authorize('assessment-candidate', 'add', assessment.role.user_id) &&
        !restricted && (
          <StatIcon
            tooltip={`Copy public ${productName} link`}
            copiedTxt="Link copied to your clipboard!"
            icon={PublicLinkIcon}
            alt="configuration"
            linkTo={assessment.public_url}
            isLink
            copyLink
          />
        )}
      {authorize('assessment', 'edit', assessment.role.user_id) &&
      !restricted ? (
        <StatIcon
          tooltip={`${capitalizeFirstLetter(productName)} Settings`}
          icon={ConfigurationIcon}
          alt="configuration"
          isLink
          linkTo={`${legacyUrl}/${editSampleRoute}/${assessment.id}`}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default AssessmentCardPublished;
