import React, { useState, useEffect, useRef } from 'react';

const PieSlice: React.FC<{
  startPoint: Record<string, any>;
  endPoint: Record<string, any>;
  r: number;
  color: string;
  isActive: boolean;
  sliceId: string;
  description: string;
  onMouseOver(id: string): void;
  onTouchStart(id: string): void;
}> = ({
  startPoint,
  endPoint,
  r,
  color,
  isActive,
  sliceId,
  description,
  onMouseOver,
  onTouchStart,
}): JSX.Element => {
  const [balloon, setBalloon] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [showBalloon, setShowBalloon] = useState(false);
  const [trianglePoints, setTrianglePoints] = useState(null);
  const sliceSVG = useRef(null);
  const getArcDefinition = (
    start: Record<string, number>,
    end: Record<string, number>
  ): string => {
    const largeArcFlag = end.a - start.a <= Math.PI ? '0' : '1';
    const movePen = ['M', 0, 0]; // 0,0 is the center of the piechart, the initial position of the "pen"
    const drawLine = ['L', start.x, start.y]; // draw a line to the start point
    const drawArc = ['A', r, r, 0, largeArcFlag, 1, end.x, end.y]; // draw an arc to the end point
    const close = ['Z']; // return to initial position to close the slice
    // merge the path definition following the same order into a space-separated string
    return movePen.concat(drawLine).concat(drawArc).concat(close).join(' ');
  };

  const [sliceStyling, setSliceStyling] = useState({ stroke: 'none' });

  const handleShowValue = (): void => {
    onMouseOver(sliceId);
    onTouchStart(sliceId);
  };

  const hover_rect_style = {
    fill: '#ECF1F4',
    filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 1px)',
  };

  useEffect(() => {
    const rad = (startPoint.a + endPoint.a) / 2;
    const x = (Math.cos(rad) * r) / 2;
    const y = (Math.sin(rad) * r) / 2;
    const c = { x, y };
    const a = {
      x: x - 3,
      y: y - 5,
    };
    const b = {
      x: x + 3,
      y: y - 5,
    };

    setTrianglePoints({ a, b, c });

    // since the label will be split into lines
    // find the line the greatest length
    // and adjust the width accordingly
    const longestLabelLine = description
      .split('\n')
      .reduce((longest, current) => {
        return longest.length > current.length ? longest : current;
      }, '').length;
    const width = Math.max(
      75, // fallback value
      longestLabelLine * 7
    );

    setBalloon({
      x: x - width / 2,
      y: y - 50,
      width,
      height: 45,
    });
  }, [startPoint, endPoint, r, description]);

  useEffect(() => {
    const sliceDefaultStyle = {
      stroke: 'none',
    };
    const sliceActiveStyle = {
      stroke: '#000000',
      strokeOpacity: 0.7,
      strokeWidth: 2,
    };
    setShowBalloon(isActive);
    setSliceStyling(isActive ? sliceActiveStyle : sliceDefaultStyle);
  }, [isActive]);

  return (
    <g id={sliceId} ref={sliceSVG}>
      <path
        data-testid="slice"
        onMouseEnter={handleShowValue}
        onTouchStart={handleShowValue}
        d={getArcDefinition(startPoint, endPoint)}
        fill={color}
        style={sliceStyling}
      />
      {showBalloon === true && (
        <>
          <rect
            data-testid="floating-balloon"
            x={balloon.x}
            y={balloon.y}
            rx={3}
            ry={3}
            width={balloon.width}
            height={balloon.height}
            style={hover_rect_style}
            stroke="none"
            strokeOpacity={0}
          />
          <polygon
            points={`
                    ${trianglePoints.a.x}, ${trianglePoints.a.y}
                    ${trianglePoints.b.x}, ${trianglePoints.b.y}
                    ${trianglePoints.c.x}, ${trianglePoints.c.y}
                `}
            stroke="none"
            style={hover_rect_style}
          />
          <text x={trianglePoints.c.x} y={balloon.y + 3}>
            {description.split('\n').map((line, i) => {
              return (
                <tspan
                  data-testid="slice-description"
                  key={`${line}-${balloon.height * 0.5 + i * 12}`}
                  x={trianglePoints.c.x}
                  dy="1.2em"
                  textAnchor="middle"
                  fontSize={12}
                  stroke="none"
                >
                  {line}
                </tspan>
              );
            })}
          </text>
        </>
      )}
    </g>
  );
};
export default PieSlice;
