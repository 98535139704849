import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';
import './Markdown.scss';

interface MarkdownProps {
  content: string;
  className?: string;
  transformImageUri?: (uri: string) => string;
  components?: ReactMarkdownOptions['components'];
  subjects?: string[];
}

const Markdown: React.FC<MarkdownProps> = ({
  content,
  components,
  className,
  subjects,
  ...props
}) => {
  // language of code section is determined based on the subjects of the question
  const codeLang = useMemo(() => {
    let lang = 'python';
    if (!subjects || !Array.isArray(subjects)) {
      return lang;
    }
    if (subjects.some((s) => s === 'Microsoft Excel')) {
      lang = 'excel-formula';
    }
    if (subjects.some((s) => s === 'Python')) {
      lang = 'python';
    }
    if (subjects.some((s) => s === 'R')) {
      lang = 'r';
    }
    return lang;
  }, [subjects]);

  const options: ReactMarkdownOptions = {
    ...props,
    children: content,
    remarkPlugins: [
      [remarkMath, { singleDollarTextMath: false }],
      [remarkGfm, { singleTilde: false }],
    ],
    rehypePlugins: [rehypeKatex],
    className: `markdown ${className || ''}`,
    components: {
      ...components,
      code({ inline, className, children }) {
        if (inline) {
          return <code className={className}>{children}</code>;
        }
        return (
          <SyntaxHighlighter
            language={codeLang}
            style={vs}
            wrapLongLines
            PreTag="div"
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        );
      },
    },
  };

  return <ReactMarkdown {...options} />;
};

export default Markdown;
