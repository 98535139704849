import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../styles/spacing.styles.scss';
import './index.scss';
import CompletedBlank from 'components/CandidateResultsList/CompletedBlank';
import { selectCandidateRow } from 'helpers/store';
import { DynamicTablePropsContext } from '../Shared/DynamicTable/context/DynamicTablePropsContext';
import TextWithTooltip from '../Shared/Tooltip/TextWithTooltip';
import { getInterviewCandidates } from '../../api/interview.api';
import { relativeDate, fullDateTime } from '../../helpers/datetime';
import { getTestTabUrl } from '../../helpers';
import { LoaderContext } from '../../context/loader';
import { RefreshContext } from '../Participants/context/refresh';
import useDynamicTable from '../../hooks/useDynamicTable';
import CompletedTable from '../CandidateResultsList/CompletedTable';
import TableBlank from '../Participants/TableBlank';
import { TableField } from '../CandidateResultsList/completedTableFields';
import { RootState } from '../../store/rootReducer';
import './InterviewCandidates.scss';

import {
  updateInterviewCandidatesQueryParams,
  setInterviewCandidatesPaginator,
  setInterviewCandidatesCurrentPage,
} from '../../store/reducers/interview';

const getDataCallback = {
  callback: (data: any): any => {
    return data;
  },
};

const fields: TableField[] = [
  {
    label: 'Reference',
    position: 1,
  },
  {
    label: 'Name',
    position: 2,
  },
  {
    label: 'Interview',
    position: 3,
  },
  {
    label: 'Interview On',
    type: 'datetime',
    position: 4,
  },
  {
    label: 'Hiring Status',
    position: 5,
  },
  {
    label: 'Total Score',
    position: 100,
    type: 'percentage',
    sticky: 'right',
    scroll: 'start',
  },
];

interface Props {
  assessment: any;
}

const InterviewCandidates: React.FC<Props> = ({ assessment }) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [tableFields] = useState<TableField[]>(fields);
  const [noDataMessage] = useState<string>(
    'No interviews have been scheduled for this assessment yet.'
  );
  const {
    interviewCandidatesPaginator: paginator,
    interviewCandidatesQueryParams: queryParams,
    interviewCandidatesCurrentPage: currentPage,
  }: any = useSelector((state: RootState) => state.interview);
  const {
    isLast,
    nextPage,
    changePerPage,
    sortingAction,
    tablePaginatorProps,
  } = useDynamicTable(getDataCallback, paginator, currentPage);

  const [completedTableProps, setCompletedTableProps] = useState<any>({
    loading: isTableLoading,
    fields: tableFields,
    data: [],
    paginator,
    filters: [],
    configurableRows: false,
    noDataMessage,
  });
  const [blank, setBlank] = useState<boolean>(true);

  const defaultQueryParams = useMemo(() => {
    return {
      fields: tableFields.map((field) => field.label),
      perPage: 10,
      page: currentPage,
    };
  }, [tableFields, currentPage]);

  const getData = async (data): Promise<any> => {
    setIsError(false);
    setTableLoading(true);
    let state = defaultQueryParams;
    if (!data.reset) {
      state = {
        ...queryParams,
        ...data,
      };
    }
    dispatch(updateInterviewCandidatesQueryParams(state));
    const res = await getInterviewCandidates(assessment.id, state);
    if (!res) {
      setIsError(true);
    } else {
      dispatch(setInterviewCandidatesPaginator(res.data.data));
      setBlank(firstLoad ? res.data.data.total === 0 : false);
      dispatch(setInterviewCandidatesCurrentPage(res.data.data.current_page));
      setFirstLoad(false);
    }
    setTableLoading(false);
    setLoaded(true);
  };
  getDataCallback.callback = useCallback(getData, [
    assessment.id,
    defaultQueryParams,
    firstLoad,
    queryParams,
    dispatch,
  ]);

  useEffect(() => {
    if (firstLoad) {
      getDataCallback.callback(defaultQueryParams);
    }
  }, [defaultQueryParams, firstLoad]);

  const processCell = useCallback(
    (row: any, field: any): any => {
      const value = row[field.label];

      if (!value) {
        if (field.type === 'percentage') {
          return <div className="bold">N/A</div>;
        }
        return 'N/A';
      }

      if (field.type === 'percentage') {
        return <div className="bold last-col">{`${value}%`}</div>;
      } else if (field.type === 'datetime') {
        return (
          <div className="text-muted">
            <TextWithTooltip
              text={relativeDate(value, userDetails.user_timezone)}
              tooltip={fullDateTime(
                value,
                userDetails.user_timezone,
                'll [at] LT [(GMT]Z[)]'
              )}
              placement="top"
            />
          </div>
        );
      }
      if (field.label === 'Reference') {
        return <div className="text-muted underline-on-hover">{value}</div>;
      } else if (field.label === 'Name') {
        return <div className="underline-on-hover primary-color">{value}</div>;
      }
      return <div>{value}</div>;
    },
    [userDetails.user_timezone]
  );

  const getPath = useCallback((row): string => {
    return `/candidate-details/${row.Reference}/${getTestTabUrl(
      row.Interview
    )}`;
  }, []);

  const viewCandidateResults = (row): void => {
    selectCandidateRow(row);
  };

  const retry = (): void => {
    getDataCallback.callback({});
  };

  useEffect(() => {
    if (!loaded || blank) {
      return;
    }
    const sortingProps = {
      sortingAction,
      orderBy: queryParams?.orderBy ?? 'Interview On',
      orderDirection: queryParams?.orderDirection ?? 'DESC',
    };
    setCompletedTableProps({
      loading: false,
      data: paginator.data,
      fields: tableFields,
      paginator,
      sortingProps,
      filters: [],
      processCell,
      getPath,
      configurableRows: true,
      onClickRow: viewCandidateResults,
      tablePaginatorProps,
      noDataMessage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loaded,
    currentPage,
    paginator,
    queryParams,
    blank,
    userDetails.user_timezone,
    userDetails,
    nextPage,
    changePerPage,
    isLast,
    isTableLoading,
    processCell,
    tableFields,
  ]);

  return (
    <div className="interview-candidates">
      <RefreshContext.Provider value={[firstLoad, setFirstLoad]}>
        <h2>Candidate Interviews</h2>
        <LoaderContext.Provider value={[isTableLoading, setTableLoading]}>
          {isError && (
            <TableBlank message="Unable to fetch candidates." onRetry={retry} />
          )}

          {!isError &&
            (isTableLoading ? (
              <CompletedBlank {...{ loading: true }} />
            ) : (
              <DynamicTablePropsContext.Provider
                value={[completedTableProps, setCompletedTableProps]}
              >
                <CompletedTable {...{ loading: false }} />
              </DynamicTablePropsContext.Provider>
            ))}
        </LoaderContext.Provider>
      </RefreshContext.Provider>
    </div>
  );
};

export default InterviewCandidates;
