import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useChatbot } from 'hooks';
import { deactivatePageHeader } from '../store/reducers/layout';
import Login from '../components/Auth/Login';

const LoginPage: React.FC = () => {
  useChatbot();
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <>
      <Helmet>
        <title>Log In To Your Account | alooba.com</title>
        <meta
          name="description"
          content="Log in to alooba.com now to access your test results and the dashboard."
        />
      </Helmet>
      <Login />
    </>
  );
};

export default LoginPage;
