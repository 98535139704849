import React from 'react';
import './divider.scss';

interface Props {
  text?: string;
}

const Divider = ({ text }: Props): JSX.Element => (
  <div id="divider">
    <span className="divide" />
    <span>{text}</span>
    <span className="divide" />
  </div>
);

export default Divider;
