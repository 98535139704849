import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import SearchCandidates from 'components/SearchCandidates';
import { deactivatePageHeader } from '../store/reducers/layout';

const SearchCandidatesPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());

  return (
    <>
      <Helmet>
        <title>Search Candidates | alooba.com</title>
      </Helmet>
      <SearchCandidates />
    </>
  );
};

export default SearchCandidatesPage;
