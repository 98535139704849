import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { changeCloakingSuccess } from 'store/reducers/organization';
import { setOrganizationDetails } from '../../api/profile.api';
import SettingsSwitcher from '../Shared/Switcher/SettingsSwitcher';

interface Props {
  organization?: any;
  loading: boolean;
}

const CandidateCloakingSettings: React.FC<Props> = (props: Props) => {
  const { loading, organization } = props;
  const dispatch = useDispatch();

  const handleChange = async (checked): Promise<any> => {
    return setOrganizationDetails(organization.id, {
      cloak_candidates: checked,
    }).then(() => {
      dispatch(changeCloakingSuccess({ checked }));
    });
  };

  const label = 'Cloaking candidate details can help reduce bias & unfairness.';
  const switcherProps = useMemo(
    () => ({
      label,
      loading: loading || !organization?.id,
      initialValue: organization.cloak_candidates,
    }),
    [organization, loading]
  );
  return (
    <div>
      <SettingsSwitcher
        title="Candidate Cloaking (All Users)"
        handleChange={handleChange}
        successMessage="Candidate cloaking settings updated for your organization."
        errorMessage="An error occured while enabling candidate cloaking. Please try again later!"
        initialValue={organization.cloak_candidates ?? false}
        innerProps={switcherProps}
        divClassName="cloak-switcher"
      />
    </div>
  );
};

export default CandidateCloakingSettings;
