import React, { useEffect, useState } from 'react';
import { Modal } from 'components/Shared/Modal';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-r';
import 'ace-builds/src-noconflict/mode-python';

import './index.scss';
import { getCodingTestCaseResults } from 'api/candidate.api';
import CodingResponseTestCase from 'components/Shared/Test/Response/CodingResponseTestCase';
import CodeExecutionModalFooter from './Shared/CodeExecutionModalFooter';

interface TestCase {
  id: number;
  name: string;
  input: string;
  output: string;
  response_output: string;
  pass: boolean;
  response_error?: string;
  loading: boolean;
}
interface ExecuteCodeModalProps {
  isShown: boolean;
  input: string;
  setModalVisibility: (isShown: boolean) => void;
  questionDetails: any;
}

const ExecuteCodeModal = (props: ExecuteCodeModalProps): JSX.Element => {
  const { isShown, input, setModalVisibility, questionDetails } = props;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(input);
  const [language, setLanguage] = useState('python');
  const [message, setMessage] = useState('');
  const [testCases, setTestCases] = useState<TestCase[]>([]);
  const [executionTestCases, setExecutionTestCases] = useState<TestCase[]>([]);

  useEffect(() => {
    setCode(input);
    setMessage('');
    setLoading(false);
    const testCases = questionDetails.response?.test_cases?.map(
      (testCase: any) => {
        return {
          id: testCase.id,
          name: testCase.name,
          input: testCase.input,
          output: testCase.output,
          response_output: null,
          response_error: null,
          pass: null,
          loading: false,
        };
      }
    );
    setTestCases(testCases);
    setExecutionTestCases(testCases);
    if (questionDetails.response?.executor) {
      setLanguage(questionDetails.response?.executor.subject_name);
    }
  }, [input, questionDetails]);

  setModalVisibility(isShown);

  const customProps = {
    loading,
    cancelVariant: 'secondary md',
    setModalVisibility,
    containerClass: 'participants-modal retake-assessment-modal',
    isCoding: true,
  };

  const execute = async (): Promise<void> => {
    setLoading(true);
    setMessage('');
    setExecutionTestCases(testCases);
    let temporaryTestCases = [...testCases];
    for (let i = 0; i < executionTestCases.length; i += 1) {
      const testCase = testCases[i];
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await getCodingTestCaseResults({
          code,
          language,
          test_question_id: questionDetails?.test_question_id,
          test_case_id: testCase?.id,
        });
        temporaryTestCases = temporaryTestCases.map((test_case) => {
          if (test_case.id === testCase.id) {
            return {
              ...test_case,
              response_output: response.data.stdout,
              response_error: response.data.stderr,
              pass: response.data.pass,
              loading: false,
            };
          }
          return test_case;
        });
        setExecutionTestCases(temporaryTestCases);
      } catch (error) {
        setLoading(false);
        setMessage('Sorry, something went wrong. Please try again.');
        break;
      }
    }
    setLoading(false);
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        <h2>Edit candidate&apos;s code</h2>
        <div className="question-response-section coding-response-section">
          <div className="candidate-response-wrapper">
            <AceEditor
              width="100%"
              height="100%"
              mode={language.toLowerCase()}
              theme="textmate"
              name="coding-response"
              fontSize={14}
              showPrintMargin={false}
              showGutter
              highlightActiveLine={false}
              value={code}
              onChange={(value) => setCode(value)}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
        </div>
        <br />
        {message && (
          <div className="sql-output-table wrong mb4">
            <div className="sql-error-message">{message}</div>
          </div>
        )}
        <div className="coding-output-wrapper">
          {executionTestCases?.map((testCase: TestCase, index: number) => {
            return (
              <CodingResponseTestCase
                key={testCase.id}
                testCase={testCase}
                position={questionDetails.position}
                index={index}
              />
            );
          })}
        </div>
        <CodeExecutionModalFooter
          execute={execute}
          input={input}
          loading={loading}
          setCode={setCode}
          setModalVisibility={setModalVisibility}
        />
      </>
    </Modal>
  );
};
export default ExecuteCodeModal;
