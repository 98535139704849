import React, { useMemo } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'components/Shared/Markdown/Markdown';
import QuestionDetails from '../Shared/Test/QuestionDetails/QuestionDetails';
import useCollapsableResponse from '../../hooks/useCollapsableResponses';
import '../Shared/Test/index.scss';
import './InterviewQuestion.scss';

interface InterviewQuestionDetailsProps {
  interviewId: number;
  onQuestionUpdate?: (newValues: any) => void;
  question?: any;
  isEditable?: boolean;
}

const InterviewQuestionDetails: React.FC<InterviewQuestionDetailsProps> = ({
  interviewId,
  onQuestionUpdate,
  question,
  isEditable = false,
}) => {
  const [collapsedSections, toggleSectionCollapse] = useCollapsableResponse({});

  const reactMarkdownRenderers = useMemo(
    () => ({
      img: (renderProps) => (
        <div className="markdown-image">
          <img
            src={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}${renderProps.src}`}
            alt="marking guide information"
          />
        </div>
      ),
    }),
    []
  );

  const showMarkingGuide =
    question &&
    (question.positive_indicators ||
      question.negative_indicators ||
      question.model_response);

  return (
    <div className="interview-question-details">
      {question && (
        <>
          <QuestionDetails
            question={question}
            onQuestionUpdate={onQuestionUpdate}
            testId={interviewId}
            testType="Interview"
            loading={false}
            isPreview
            isEditable={isEditable}
          />
          {showMarkingGuide ? <h4>Marking guide</h4> : ''}
          {question.positive_indicators ? (
            <div className="grey-section collapsable">
              <h4
                className="collapsable-section-title"
                onClick={() => toggleSectionCollapse('positive_indicators')}
              >
                Indicators of a good response
                <FontAwesomeIcon
                  className="icon"
                  icon={
                    collapsedSections.positive_indicators
                      ? faAngleDown
                      : faAngleUp
                  }
                />
              </h4>
              {!collapsedSections.positive_indicators && (
                <Markdown
                  className="long-str-wrap"
                  components={reactMarkdownRenderers}
                  content={question.positive_indicators}
                />
              )}
              <hr />
            </div>
          ) : (
            ''
          )}
          {question.negative_indicators ? (
            <div className="grey-section collapsable">
              <h4
                className="collapsable-section-title"
                onClick={() => toggleSectionCollapse('negative_indicators')}
              >
                Indicators of a bad response
                <FontAwesomeIcon
                  className="icon"
                  icon={
                    collapsedSections.negative_indicators
                      ? faAngleDown
                      : faAngleUp
                  }
                />
              </h4>
              {!collapsedSections.negative_indicators && (
                <Markdown
                  className="long-str-wrap"
                  components={reactMarkdownRenderers}
                  content={question.negative_indicators}
                />
              )}
              <hr />
            </div>
          ) : (
            ''
          )}
          {question.model_response ? (
            <div className="grey-section collapsable">
              <h4
                className="collapsable-section-title"
                onClick={() => toggleSectionCollapse('model_response')}
              >
                Model response
                <FontAwesomeIcon
                  className="icon"
                  icon={
                    collapsedSections.model_response ? faAngleDown : faAngleUp
                  }
                />
              </h4>
              {!collapsedSections.model_response && (
                <Markdown
                  className="long-str-wrap"
                  components={reactMarkdownRenderers}
                  content={question.model_response}
                />
              )}
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};
export default InterviewQuestionDetails;
