import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthResult } from '../../api-auth/auth-api';

interface AuthState {
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
  loading: boolean;
  refreshingTokens: boolean;
  error: string | null;
}

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  expiresIn: '100',
  loading: false,
  refreshingTokens: true,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
      state.refreshingTokens = true;
      state.error = null;
    },
    loginSuccess(state) {
      state.loading = false;
      state.refreshingTokens = false;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    refreshTokensStart(state) {
      state.refreshingTokens = true;
    },
    refreshTokensSuccess(state) {
      state.refreshingTokens = false;
    },
    setAccessTokenToState(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    setRefreshTokenToState(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    setExpiresInToState(state, action: PayloadAction<string>) {
      state.expiresIn = action.payload;
    },
    setTokensToState(state, action: PayloadAction<AuthResult>) {
      const { access_token, refresh_token, expires_in } = action.payload;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
      state.expiresIn = expires_in;
    },
    unsetTokens(state, action: PayloadAction<string>) {
      state.accessToken = '';
      state.refreshToken = '';
      state.expiresIn = '100';
      state.error = action.payload;
    },
    getSamlLoginStart(state) {
      state.loading = true;
    },
    getSamlLoginEnd(state) {
      state.loading = false;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  setAccessTokenToState,
  setRefreshTokenToState,
  setExpiresInToState,
  refreshTokensStart,
  refreshTokensSuccess,
  setTokensToState,
  unsetTokens,
  getSamlLoginStart,
  getSamlLoginEnd,
} = authSlice.actions;
export default authSlice.reducer;
