import { handleError } from 'handleError';
import { getInterviews } from 'api/interview.api';
import store, { AppThunk } from '../store';
import { setAssessmentInterviews } from '../reducers/interview';

export const fetchInterviews = (assessmentId: number): AppThunk => {
  return async (dispatch) => {
    const { interviews } = store.getState().interview;
    if (interviews[assessmentId]) {
      return;
    }
    dispatch(
      setAssessmentInterviews({
        assessment_id: assessmentId,
        getting: true,
      })
    );

    getInterviews(assessmentId)
      .then((res) => {
        dispatch(
          setAssessmentInterviews({
            assessment_id: assessmentId,
            getting: false,
            interviews: res.data.data,
          })
        );
      })
      .catch((err) => {
        if (interviews[assessmentId] && interviews[assessmentId].getting) {
          dispatch(
            setAssessmentInterviews({
              assessment_id: assessmentId,
              getting: false,
            })
          );
          handleError(Object.assign(err, { stack: new Error().stack }));
        }
      });
  };
};
