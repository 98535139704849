export const capitalizeFirstLetter = (s: string): string => {
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
};

export const calculateTextWidth = (text): number => {
  if (typeof jest === 'undefined') {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = getComputedStyle(document.body).font;
    return context.measureText(text).width;
  }
  return 0;
};
