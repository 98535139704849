import React from 'react';

import '../index.scss';
import { Button } from 'components/Shared';

interface CodeExecutionModalFooterProps {
  loading: boolean;
  execute: () => void;
  setCode: (code: string) => void;
  setModalVisibility: (visibility: boolean) => void;
  input: string;
}

const CodeExecutionModalFooter = (
  props: CodeExecutionModalFooterProps
): JSX.Element => {
  const { loading, execute, setCode, setModalVisibility, input } = props;

  return (
    <div className="execution-modal-buttons">
      <Button
        type="submit"
        text="Run"
        variant="primary md"
        onClick={() => execute()}
        loading={loading}
      />
      <div className="mx2">
        <Button
          type="button"
          text="Reset"
          variant="sub-primary md"
          onClick={() => setCode(input)}
        />
      </div>
      <Button
        type="button"
        text="Cancel"
        variant="sub-primary md"
        onClick={() => setModalVisibility(false)}
      />
    </div>
  );
};
export default CodeExecutionModalFooter;
