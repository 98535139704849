import React from 'react';
import { useHistory } from 'react-router-dom';
import { stopEvent } from 'helpers/events';
import CardContainer from 'components/Shared/MainContainer/CardContainer';
import CandidatesIcon from '../../images/icons/user.svg';
import CompletedCandidatesIcon from '../../images/icons/completed.svg';
import ConfigIcon from '../../images/icons/configuration-blue.svg';
import './index.scss';

interface Props {
  interview: any;
}

const InterviewCard: React.FC<Props> = ({ interview }) => {
  const history = useHistory();
  const handleConfigClick = async (
    e: React.SyntheticEvent<EventTarget>
  ): Promise<any> => {
    stopEvent(e);
    history.push(`/interview/${interview.id}/edit`);
  };
  return (
    <CardContainer>
      <div className="main-card-content">
        <h5 className="card-title">{interview.name}</h5>
        <div className="actions">
          <a
            className="action"
            href={`/interview/${interview.id}/edit`}
            onClick={handleConfigClick}
          >
            <img
              className="action icon"
              src={ConfigIcon}
              alt="edit interview icon"
            />
          </a>
        </div>
      </div>
      <div className="card-footer">
        <div className="candidates-stat">
          <img
            className="icon"
            src={CandidatesIcon}
            alt="number of candidates icon"
          />
          <div className="value">{interview.num_candidates}</div>
        </div>
        <div className="completed-candidates-stat">
          <img
            className="icon candidate-count"
            src={CompletedCandidatesIcon}
            alt="number of completed candidates icson"
          />
          <div className="value">{interview.num_completed_candidates}</div>
        </div>
      </div>
    </CardContainer>
  );
};

export default InterviewCard;
