import { handleError } from 'handleError';
import axios from 'axios';
import store, { AppThunk } from '../store';
import {
  getOrganization,
  getOrganizationGroups,
  OrganizationDetails,
  setOrganizationDetails,
} from '../../api/profile.api';
import {
  createSamlTenant,
  fetchSaml,
  saveSamlDomain,
  SamlDomain,
  SamlIdpConfiguration,
  updateSamlIdpConfig,
  fetchSamlDomains,
  updateSamlDomain,
  deleteSamlDomain,
} from '../../api/organization.api';
import {
  clearSSOError,
  getGroupsFailure,
  getGroupsStart,
  getGroupsSuccess,
  getOrgFailure,
  getOrgSSOFailure,
  getOrgSSOStart,
  getOrgSSOSuccess,
  getOrgStart,
  getOrgSuccess,
  setOrgColorUpdating,
  ssoDomainsStart,
  addDomainFailure,
  addDomainEnd,
  updateSamlFailure,
  ssoDomainsEnd,
  ssoDomainsFailure,
  editDomainStart,
  editDomainEnd,
  editDomainFailure,
  deleteDomainEnd,
  deleteDomainFailure,
  deleteDomainStart,
} from '../reducers/organization';
import { getUserGroupsSuccess } from '../reducers/users';

export const getOrgDetails = (orgId: number): AppThunk => async (dispatch) => {
  const organsation = store.getState().organization;
  if (organsation?.loading) {
    return;
  }
  dispatch(getOrgStart());
  getOrganization(orgId)
    .then((data) => {
      if (data.data.id) {
        dispatch(getOrgSuccess(data.data));
      } else {
        dispatch(getOrgFailure('Invalid organization data'));
      }
    })
    .catch((err) => {
      dispatch(getOrgFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};

export const updateOrgColor = (
  orgDetails: OrganizationDetails,
  newColorHex: string
): AppThunk => async (dispatch) => {
  dispatch(setOrgColorUpdating(true));
  return dispatch(
    updateOrgDetails({ ...orgDetails, brand_color: newColorHex })
  );
};
export const updateOrgDetails = (
  orgDetails: OrganizationDetails
): AppThunk => async (dispatch) => {
  dispatch(getOrgStart());
  return setOrganizationDetails(orgDetails.id, orgDetails)
    .then(() => {
      dispatch(getOrgDetails(orgDetails.id));
    })
    .catch((err) => {
      if (!err) {
        return;
      }
      let { message } = err;
      const errors = err?.response?.data?.errors;
      if (errors && errors.length > 0) {
        message = errors[0]?.detail;
      }
      dispatch(getOrgFailure(message));
      err.stack = new Error().stack;
      handleError(err);
    });
};

export const getOrgGroups = (): AppThunk => async (dispatch) => {
  dispatch(getGroupsStart());
  getOrganizationGroups()
    .then((data) => {
      dispatch(getGroupsSuccess(data.data));
      dispatch(getUserGroupsSuccess(data.data));
    })
    .catch((err) => {
      dispatch(getGroupsFailure(err.message));
      handleError(Object.assign(err, { stack: new Error().stack }));
    });
};

/*
 * Fetch the saml configuration for an organization
 */
export const getSSO = (orgId: number): AppThunk => async (dispatch) => {
  dispatch(getOrgSSOStart());
  return fetchSaml(orgId)
    .then(({ data }) => {
      dispatch(getOrgSSOSuccess(data));
    })
    .catch((e) => {
      dispatch(getOrgSSOFailure(null));
      handleError(e);
    });
};

/*
 * Enable SSO for an organization
 */
export const enableSSO = (orgId: number): AppThunk => async (dispatch) => {
  dispatch(getOrgSSOStart());
  return createSamlTenant(orgId)
    .then(({ data }) => {
      dispatch(getOrgSSOSuccess(data));
    })
    .catch((e) => {
      const { detail } = e.data.errors[0];
      dispatch(getOrgSSOFailure(detail));
    });
};

/**
 * Update the SAML IDP configuration for an organization
 */
export const updateSAMLConfig = (
  orgId: number,
  samlConfig: SamlIdpConfiguration
): AppThunk => async (dispatch) => {
  dispatch(getOrgSSOStart());
  return updateSamlIdpConfig(orgId, samlConfig)
    .then(({ data }) => {
      dispatch(getOrgSSOSuccess(data));
    })
    .catch((e) => {
      // handle validation errors
      if (axios.isAxiosError(e) && e.response.status === 422) {
        dispatch(
          updateSamlFailure({
            errors: e.response.data.errors,
            message:
              'Configuration not saved. Please check the values and try again',
          })
        );
      } else if (axios.isAxiosError(e)) {
        dispatch(
          updateSamlFailure({
            errors: {},
            message: e.response.data.errors.detail,
          })
        );
      }
      handleError(e);
    });
};

export const addSamlDomain = (
  orgId: number,
  domain: SamlDomain
): AppThunk => async (dispatch) => {
  dispatch(ssoDomainsStart());
  return saveSamlDomain(orgId, domain)
    .then(({ data }) => {
      dispatch(addDomainEnd(data));
    })
    .catch((e) => {
      // handle validation errors
      if (axios.isAxiosError(e) && e.response.status === 422) {
        dispatch(addDomainFailure(e.response.data.errors));
      } else if (axios.isAxiosError(e)) {
        dispatch(addDomainFailure({ domains: ['Unable to add domain'] }));
      }
      handleError(e);
    });
};

export const getSamlDomains = (orgId: number): AppThunk => async (dispatch) => {
  dispatch(ssoDomainsStart());
  return fetchSamlDomains(orgId)
    .then(({ data }) => {
      dispatch(ssoDomainsEnd(data));
    })
    .catch((e) => {
      dispatch(ssoDomainsFailure());
      handleError(e);
    });
};

export const editSamlDomain = (
  orgId: number,
  domain: SamlDomain
): AppThunk => async (dispatch) => {
  dispatch(editDomainStart());
  return updateSamlDomain(orgId, domain)
    .then(({ data }) => {
      dispatch(editDomainEnd(data));
    })
    .catch((e) => {
      dispatch(
        editDomainFailure({
          domains: [`Unable to update the domain ${domain.domain}`],
        })
      );
      handleError(e);
    });
};
export const removeSamlDomain = (
  orgId: number,
  domain: SamlDomain
): AppThunk => async (dispatch) => {
  dispatch(deleteDomainStart());
  return deleteSamlDomain(orgId, domain.domain)
    .then(() => {
      dispatch(deleteDomainEnd(domain));
    })
    .catch((e) => {
      dispatch(
        deleteDomainFailure({
          domains: [`Unable to delete the domain ${domain.domain}`],
        })
      );
      handleError(e);
    });
};

export const acknowledgeSSOError = (): AppThunk => async (dispatch) => {
  dispatch(clearSSOError());
};
