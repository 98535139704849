import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { deactivatePageHeader } from '../store/reducers/layout';
import Insights from '../components/Insights';

const InsightsPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());

  return (
    <>
      <Helmet>
        <title>Alooba Growth Insights | alooba.com</title>
        <meta
          name="description"
          content="Get insights on how your employees are doing."
        />
      </Helmet>
      <Insights />
    </>
  );
};

export default InsightsPage;
