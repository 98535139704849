import React from 'react';
import { Modal } from 'components/Shared/Modal';
import './index.scss';

interface ParticipantModalProps {
  children?: JSX.Element;
}
const ParticipantModal = (props: ParticipantModalProps): JSX.Element => {
  const { children } = props;
  const customProps = {
    hideCloseButton: true,
  };
  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        {children}
        <hr />
      </>
    </Modal>
  );
};
export default ParticipantModal;
