import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #ecf1f4',
    },
    color: '#ecf1f4',
  },
  tooltip: {
    backgroundColor: '#ecf1f4',
    color: '#0e0e2c',
    letterSpacing: '-0.02em',
    borderRadius: '4px',
    padding: '10px 16px',
    fontSize: '14px',
    fontFamily: 'Nunito, sans-serif',
  },
}));

const AloobaTooltip = (props): JSX.Element => (
  <Tooltip arrow classes={useStyles()} {...props} />
);

export default AloobaTooltip;
