import React from 'react';
import './progress.scss';

interface ProgressBarProps {
  progress: number;
  title?: string;
  showLegend?: boolean;
  className?: string;
}
const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  title,
  showLegend,
  className,
}) => {
  return (
    <div className={`progress-wrapper ${className}`}>
      <div className="progress-upper">
        <p className="progress-title">{title}</p>
        {showLegend ? (
          <p className="progress-legend">{`${Math.round(progress)}%`}</p>
        ) : (
          ''
        )}
      </div>
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
