import { handleError } from 'handleError';
import store, { AppThunk } from '../store';
import {
  getStripePaymentToken as getStripePaymentTokenApi,
  getPaymentMethods as getPaymentMethodsApi,
  deletePaymentMethod as deletePaymentMethodApi,
} from '../../api/payment.api';
import {
  getCheckoutTokenStart,
  getCheckoutTokenSuccess,
  getCheckoutTokenFailure,
  getPaymentMethodStart,
  getPaymentMethodSuccess,
  getPaymentMethodFailure,
} from '../reducers/payment';

export const getStripeCheckoutToken = (orgId: number): AppThunk => async (
  dispatch
) => {
  const { payment } = store.getState();
  if (payment?.loading) {
    return;
  }
  dispatch(getCheckoutTokenStart());
  getStripePaymentTokenApi(orgId)
    .then((data) => {
      dispatch(getCheckoutTokenSuccess(data.data));
    })
    .catch((err) => {
      dispatch(getCheckoutTokenFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};

export const getPaymentMethods = (orgId: number): AppThunk => async (
  dispatch
) => {
  const { payment } = store.getState();
  if (payment?.loading) {
    return;
  }
  dispatch(getPaymentMethodStart());
  getPaymentMethodsApi(orgId)
    .then((data) => {
      dispatch(getPaymentMethodSuccess(data.data));
    })
    .catch((err) => {
      dispatch(getPaymentMethodFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};

export const deletePaymentMethod = (
  orgId: number,
  lastDigits: string
): AppThunk => async (dispatch) => {
  const { payment } = store.getState();
  if (payment?.loading) {
    return;
  }
  dispatch(getPaymentMethodStart());
  deletePaymentMethodApi(orgId, lastDigits)
    .then((data) => {
      dispatch(getPaymentMethodSuccess(data.data));
    })
    .catch((err) => {
      dispatch(getPaymentMethodFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};
