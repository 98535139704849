import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/Shared/Modal';
import './createTemplate.scss';
import { Input } from 'components/Shared';
import CustomSelect from 'components/Shared/CustomSelect';
import { Chip, MenuItem } from '@material-ui/core';
import { parseLocationData } from 'helpers/location';
import { capitalizeFirstLetter } from 'helpers/text';
import TextArea from '../Shared/Input/textArea';

declare let google: any;
interface CreateTemplateModalProps {
  template: any;
  isShown: boolean;
  setModalVisibility: any;
  loading: boolean;
  disabled: boolean;
  loadingTxt: string;
  groups: any;
  forAssessmentGenerator: boolean;
  purpose?: string;
  createAction(
    e,
    template,
    customizedFields: {
      assessment_name: string;
      role_title: string;
      role_summary?: string;
      group_id: string;
    }
  ): void;
}
const CreateTemplateModal = (props: CreateTemplateModalProps): JSX.Element => {
  const { template, groups, isShown, purpose, forAssessmentGenerator } = props;
  const [assessmentName, setAssessmentName] = useState<string>(template?.name);
  const [roleTitle, setRoleTitle] = useState<string>(template?.role_title);
  const [roleSummary, setRoleSummary] = useState<string>(null);
  const [selectedGroup, setSelectedGroup] = useState<any>(
    template?.group_id?.toString()
  );
  const [selectedLocation, setSelectedLocation] = useState<any>(
    template?.location
  );
  const [locationInput, setLocationInput] = useState<string>('');
  const [locationError, setLocationError] = useState<string>('');
  const [roleSummaryError, setRoleSummaryError] = useState<string>(null);
  const [assessmentNameError, setAssessmentNameError] = useState<string>(null);
  const [roleTitleError, setRoleTitleError] = useState<string>(null);

  const groupList = useMemo(() => {
    const keys = groups === null ? [] : Object.keys(groups);
    const allGroups = { id: '', name: 'All' };
    let groupList = [];
    keys.forEach((key) => {
      groupList.push({ id: key, name: groups[key] });
    });
    groupList.sort((a, b) => (a.name > b.name ? 1 : -1));
    groupList = [allGroups, ...groupList];
    return groupList;
  }, [groups]);

  const modalProps = {
    actionText: forAssessmentGenerator ? 'Generate' : 'Create',
    showCancel: true,
    cancelVariant: 'sub-primary',
    cancelButtonText: 'Cancel',
    handleButtonAction: (e) => {
      let hasError = false;
      setRoleSummaryError(null);
      setAssessmentNameError(null);
      setRoleTitleError(null);
      if (locationInput !== selectedLocation?.description) {
        setLocationError('The location field cannot be empty');
        hasError = true;
      }

      if (forAssessmentGenerator) {
        if (roleTitle === null || roleTitle === '') {
          setRoleTitleError('Please enter the role title');
          hasError = true;
        }
        if (roleSummary === null || roleSummary === '') {
          setRoleSummaryError(
            'Please add the job description in the role summary field'
          );

          hasError = true;
        }
        if (assessmentName === null || assessmentName === '') {
          setAssessmentNameError('Please add a name for this assessment');
          hasError = true;
        }
      }
      if (hasError) {
        return;
      }
      const customizedFields = {
        assessment_name: assessmentName,
        group_id: selectedGroup.id,
        role_title: roleTitle,
        location: selectedLocation,
        role_summary: roleSummary,
      };
      props.createAction(e, template, customizedFields);
    },
    handleClose: () => {
      setSelectedLocation(null);
    },
    containerClass: `create-assessment-modal-wrapper ${
      forAssessmentGenerator && 'assessment-generator'
    }`,
  };
  useEffect(() => {
    setLocationError('');
    if (template?.is_internal) {
      setAssessmentName('');
    } else {
      setAssessmentName(template?.name);
    }
    setRoleTitle(template?.role_title);
    setSelectedLocation({
      ...template?.location,
      place_id: template?.location?.google_place_id,
    });
    if (template?.is_organization_template) {
      setLocationInput(template?.location?.description);
    } else {
      setLocationInput('');
    }
  }, [
    template?.is_internal,
    template?.is_organization_template,
    template?.location,
    template?.name,
    template?.role_title,
  ]);

  useEffect(() => {
    if (template?.group_id) {
      setSelectedGroup(
        groupList.filter((g) => g.id === template?.group_id?.toString())[0]
      );
    } else {
      setSelectedGroup(groupList.filter((g) => g.id === '')[0]);
    }
  }, [groupList, template]);

  const handleAssessmentNameChange = (
    event: React.SyntheticEvent<EventTarget>
  ): void => {
    const target = event.target as HTMLInputElement;
    setAssessmentName(target.value);
  };

  const handleRoleTitleChange = (
    event: React.SyntheticEvent<EventTarget>
  ): void => {
    const target = event.target as HTMLInputElement;
    setRoleTitle(target.value);
  };

  const handleGroupChange = (
    event: React.ChangeEvent<{ value: string }>
  ): void => {
    if (event.target.value) {
      setSelectedGroup(groupList.filter((g) => g.id === event.target.value)[0]);
    } else {
      setSelectedGroup(groupList.filter((g) => g.id === '')[0]);
    }
  };

  useEffect(() => {
    if (isShown) {
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('location-input') as HTMLInputElement,
        {
          types: ['(cities)'],
        }
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const parsedPlace = parseLocationData(place);
        setSelectedLocation(parsedPlace);
        setLocationInput(parsedPlace.description);
      });
    }
  }, [isShown]);

  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = (e): void => {
      setScrollTop(e.target.documentElement.scrollTop);
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const handleLocationInputChange = (e): void => {
    setLocationInput(e.target.value);
  };

  const getGroupInputValue = (): any => {
    if (!selectedGroup) {
      return groupList.filter((g) => g.id === '')[0];
    }
    return groupList.filter((g) => g.id === selectedGroup?.id)[0];
  };
  const assessmentTitle = purpose === 'ld' ? 'exercise' : 'assessment';
  return (
    <Modal {...{ ...props, ...modalProps }}>
      <div className="create-assessment-modal">
        <h2>
          {forAssessmentGenerator
            ? 'Generate an assessment'
            : `Create an ${assessmentTitle} from the template "${template?.name}"`}
          {forAssessmentGenerator && (
            <Chip
              label="Beta"
              color="secondary"
              size="small"
              variant="outlined"
            />
          )}
        </h2>
        {forAssessmentGenerator && (
          <p>
            Automatically generate an assessment with the relevant skills by
            entering the job description. To ensure accuracy, include the
            responsibilities or enter the full job description.
          </p>
        )}
        <Input
          value={assessmentName}
          label={`${capitalizeFirstLetter(assessmentTitle)} Name`}
          type="text"
          id="assessment-name-input"
          errorTxt={assessmentNameError}
          onChange={handleAssessmentNameChange}
        />
        <Input
          id="location-input"
          type="text"
          label="Location"
          onChange={handleLocationInputChange}
          value={locationInput}
          errorTxt={locationError}
        />
        <Input
          value={roleTitle}
          label="Role Title"
          type="text"
          id="role-title-input"
          onChange={handleRoleTitleChange}
          errorTxt={roleTitleError}
        />
        {forAssessmentGenerator && (
          <TextArea
            label="Role Description"
            data-testid="role-title-input"
            placeholder="Write the role's job description"
            onChange={(e) => setRoleSummary(e.target.value)}
            errorTxt={roleSummaryError}
          />
        )}
        {groupList.length > 0 && (
          <div className="group-container">
            <label htmlFor="group">Managed By Group</label>
            <br />
            <CustomSelect
              defaultValue=""
              label="Group"
              type="number"
              value={getGroupInputValue() || 'All'}
              renderValue={(selected: { id: number | null; name: string }) =>
                selected.name
              }
              onChange={handleGroupChange}
              autoWidth
              style={{ width: '100%', maxWidth: '100%' }}
            >
              {groupList.map((group) => {
                return (
                  <MenuItem
                    key={group.id}
                    style={{ width: '100%', maxWidth: '100%' }}
                    value={group.id}
                  >
                    {group.name}
                  </MenuItem>
                );
              })}
            </CustomSelect>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default React.memo(CreateTemplateModal);
