import Assessments from 'components/Dashboard';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { deactivatePageHeader } from '../store/reducers/layout';

const JuniorAssessments: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <div className="container">
      <Helmet>
        <title>Alooba Junior | alooba.com</title>
      </Helmet>
      <Assessments assessmentPurpose="junior" />
    </div>
  );
};

export default JuniorAssessments;
