import React from 'react';

interface Props {
  isChecked?: boolean;
  disabled?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  id?: string;
  name?: string;
}

const Checkbox = ({
  label,
  isChecked,
  disabled,
  handleChange,
  name,
  id,
}: Props): any => {
  return (
    <div id="checkbox_component" className="alooba-checkbox">
      <label>
        {label}
        <input
          type="checkbox"
          name={name}
          id={id}
          checked={isChecked}
          onChange={handleChange}
        />
        <span className={disabled ? 'disabled' : 'checkmark '} />
      </label>
    </div>
  );
};
export default Checkbox;
