import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface CloseIconProps {
  label?: string;
  className?: string;
}

const Close = ({ label, className = '' }: CloseIconProps): JSX.Element => {
  return (
    <div className={`${className}`}>
      <FontAwesomeIcon className="icon fa-times" icon={faTimes} />
      {label}
    </div>
  );
};
export const CloseIcon = React.memo(Close);
