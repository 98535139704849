import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAssessmentCandidatesQueryParams } from 'api/contract';
import { selectFields } from '../../components/Participants/assessmentTableFields';
import { TableField } from '../../components/CandidateResultsList/completedTableFields';

interface AssessmentCandidateState {
  assessmentId: number;
  allParticipants: any[];
  participantsPaginator: any;
  participantsCurrentPage: number;
  participantsSearch: string;
  participantsQueryParams: GetAssessmentCandidatesQueryParams;
  topResults: any[];
  resultsPaginator: any;
  resultsCurrentPage: number;
  resultsSearch: string;
  resultsQueryParams: GetAssessmentCandidatesQueryParams | null;
  resultsTableFields: TableField[];
  resultsSubjects: any[];
  resultsInformationFields: any[];
  searchCandidatesPaginator: any;
  searchCandidatesCurrentPage: number;
  searchCandidatesQueryParams: GetAssessmentCandidatesQueryParams | null;
  selectedProducts: string[];
}

const initialState: AssessmentCandidateState = {
  assessmentId: null,
  allParticipants: [],
  participantsPaginator: {
    data: [],
  },
  participantsCurrentPage: 1,
  participantsSearch: '',
  participantsQueryParams: {
    assessmentId: 0,
    fields: selectFields,
    perPage: 10,
    page: 1,
    searchTerm: '',
    orderBy: 'Invited On',
    orderDirection: 'desc',
    actionMenu: [
      'Delete Assessment',
      'Extend Expiry',
      'Increase Test Duration',
    ],
  },
  topResults: [],
  resultsPaginator: {
    data: [],
  },
  resultsCurrentPage: 1,
  resultsSearch: '',
  resultsQueryParams: null,
  resultsTableFields: [],
  resultsSubjects: [],
  resultsInformationFields: [],
  searchCandidatesPaginator: {
    data: [],
  },
  searchCandidatesCurrentPage: 1,
  searchCandidatesQueryParams: null,
  selectedProducts: [],
};

const AssessmentCandidateReducer = createSlice({
  name: 'assessmentCandidate',
  initialState,
  reducers: {
    updateParticipantsQueryParams: (state, action: PayloadAction<any>) => {
      state.participantsQueryParams = {
        ...state.participantsQueryParams,
        ...action.payload,
      };
    },
    setParticipantsPaginator: (state, action: PayloadAction<any>) => {
      state.participantsPaginator = action.payload;
    },
    setParticipantsCurrentPage: (state, action: PayloadAction<number>) => {
      state.participantsCurrentPage = action.payload;
    },
    setParticipantsSearch: (state, action: PayloadAction<string>) => {
      state.participantsSearch = action.payload;
    },
    updateResultsQueryParams: (state, action: PayloadAction<any>) => {
      state.resultsQueryParams = {
        ...state.resultsQueryParams,
        ...action.payload,
      };
    },
    setResultsQueryParams: (state, action: PayloadAction<any>) => {
      state.resultsQueryParams = action.payload;
    },
    setResultsPaginator: (state, action: PayloadAction<any>) => {
      state.resultsPaginator = action.payload;
    },
    setResultsCurrentPage: (state, action: PayloadAction<number>) => {
      state.resultsCurrentPage = action.payload;
    },
    setResultsSearch: (state, action: PayloadAction<string>) => {
      state.resultsSearch = action.payload;
    },
    setResultsTableFields: (state, action: PayloadAction<any[]>) => {
      state.resultsTableFields = action.payload;
    },
    setResultsSubjects: (state, action: PayloadAction<any[]>) => {
      state.resultsSubjects = action.payload;
    },
    setResultsInformationFields: (state, action: PayloadAction<any[]>) => {
      state.resultsInformationFields = action.payload;
    },
    updateSearchCandidatesQueryParams: (state, action: PayloadAction<any>) => {
      state.searchCandidatesQueryParams = {
        ...state.searchCandidatesQueryParams,
        ...action.payload,
      };
    },
    setSearchCandidatesPaginator: (state, action: PayloadAction<any>) => {
      if (
        action.payload.requestTime &&
        state.searchCandidatesPaginator.requestTime
      ) {
        if (
          action.payload.requestTime >
          state.searchCandidatesPaginator.requestTime
        ) {
          state.searchCandidatesPaginator = action.payload;
        }
      } else if (!state.searchCandidatesPaginator.requestTime) {
        state.searchCandidatesPaginator = action.payload;
      }
    },
    setSearchCandidatesCurrentPage: (state, action: PayloadAction<number>) => {
      state.searchCandidatesCurrentPage = action.payload;
    },
    setAssessmentId: (state, action: PayloadAction<number>) => {
      state.assessmentId = action.payload;
    },
    updateSelectedProducts: (
      state,
      action: PayloadAction<{ selectedProducts: string[] }>
    ) => {
      const { selectedProducts } = action.payload;
      if (selectedProducts && selectedProducts.length) {
        state.selectedProducts = selectedProducts;
      }
    },
    resetAssessmentResultsCache: () => initialState,
  },
});

export const {
  updateParticipantsQueryParams,
  setParticipantsPaginator,
  setParticipantsCurrentPage,
  setParticipantsSearch,
  updateResultsQueryParams,
  setResultsQueryParams,
  setResultsPaginator,
  setResultsCurrentPage,
  setResultsSearch,
  setResultsTableFields,
  setResultsSubjects,
  setResultsInformationFields,
  updateSearchCandidatesQueryParams,
  setSearchCandidatesPaginator,
  setSearchCandidatesCurrentPage,
  resetAssessmentResultsCache,
  setAssessmentId,
  updateSelectedProducts,
} = AssessmentCandidateReducer.actions;
export default AssessmentCandidateReducer.reducer;
