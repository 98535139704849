import Markdown from 'components/Shared/Markdown/Markdown';
import CardContainer from 'components/Shared/MainContainer/CardContainer';
import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { Button } from 'components/Shared';
import { getFreeResponseFeed, setQuestionScore } from 'api/assessment.api';
import { useToasts } from 'react-toast-notifications';
import { LoadingIcon } from 'components/Shared/Icon/Loading';
import { AxiosResponse } from 'axios';
import { renderFile } from 'helpers/files';
import { handleError } from 'handleError';
import DifficultyIcon from 'images/icons/difficulty-hard.svg';
import CheckIcon from 'images/icons/check-color.svg';
import QuestionButton, { QuestionDetails } from './QuestionButton';
import ScoreInput from './ScoreInput';
import { assessmentContext } from '../Assessment';

interface CandidateResponse {
  id: number;
  candidateTestId: number;
  candidateResponse: string;
  candidateResponseFile?: string;
  questionId: number;
  reference: string;
}
const FreeResponseFeed: React.FC = () => {
  const defaultResponse: CandidateResponse = {
    id: 0,
    candidateTestId: 0,
    candidateResponse: '',
    candidateResponseFile: null,
    questionId: 0,
    reference: '',
  };

  const { assessment, loading } = useContext(assessmentContext);

  const [scoreSubmitting, setScoreSubmitting] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionDetails>(
    null
  );
  const [candidateResponseQueue, setCandidateResponseQueue] = useState([]);
  const [currentCandidateResponse, setCurrentCandidateResponse] = useState(
    defaultResponse
  );
  const [currentScore, setCurrentScore] = useState('');
  const [questionQueue, setQuestionQueue] = useState([]);
  const [feedLoading, setFeedLoading] = useState(true);
  const { addToast } = useToasts();

  const transformMarkdownImageUri = (src: string): string => {
    return `${process.env.REACT_APP_ALOOBA_LEGACY_URL}${src}`;
  };

  useEffect((): void => {
    getFreeResponseFeed(assessment.id).then((response: AxiosResponse) => {
      const questionsMap = [];
      let questionSelectionQueue = [];
      Object.entries(response.data.data).forEach(
        ([subject, questionResponses]) => {
          const questionsList = [];
          Object.entries(questionResponses).forEach(([, responses]) => {
            const candidateResponses = responses.map((r) => {
              return {
                id: r.id,
                candidateTestId: r.candidate_test_id,
                candidateResponse: r.candidate_response,
                candidateResponseFile: r.candidate_response_file,
                questionId: r.question_id,
                reference: r.reference,
              } as CandidateResponse;
            });
            const r = responses[0];
            const q = {
              questionId: r.question_id,
              difficulty: r.difficulty,
              position: r.position,
              question: r.question,
              subjects: r.subjects,
              background: r.background,
              modelResponse: r.model_response,
              positiveIndicators: r.positive_indicators,
              negativeIndicators: r.negative_indicators,
              maxScore: r.max_score,
              candidateResponses,
            };
            questionsList.push(q);
          });

          questionsMap.push({
            subject,
            questions: questionsList,
          });
          questionSelectionQueue = questionSelectionQueue.concat(questionsList);
        }
      );
      setQuestions(questionsMap);
      setQuestionQueue(questionSelectionQueue);
      setFeedLoading(false);
    });
  }, [assessment]);

  useEffect((): void => {
    if (!selectedQuestion) {
      // preselect the first question
      setSelectedQuestion(questionQueue[0]);
    }
  }, [questionQueue, selectedQuestion]);
  useEffect((): void => {
    if (selectedQuestion) {
      setCandidateResponseQueue(selectedQuestion.candidateResponses);
    }
  }, [selectedQuestion]);

  useEffect((): void => {
    if (candidateResponseQueue.length > 0) {
      setCurrentCandidateResponse(candidateResponseQueue[0]);
    }
  }, [candidateResponseQueue, candidateResponseQueue.length]);

  const questionSelectedHandler = (q: QuestionDetails): void => {
    setSelectedQuestion(q);

    setCurrentScore('');
  };
  const selectNextQuestion = (): void => {
    questionQueue.shift();
    setSelectedQuestion(questionQueue[0]);
    setCurrentScore('');
  };
  const selectNextResponse = (): void => {
    if (parseInt(currentScore, 10) < 0) {
      addToast({
        type: 'warning',
        msg: `The score must be a number between 0 and ${selectedQuestion.maxScore}`,
      });
      return;
    }
    if (parseInt(currentScore, 10) > selectedQuestion.maxScore) {
      addToast({
        type: 'warning',
        msg: `The score (${currentScore}) must not exceed the maximum score of ${selectedQuestion.maxScore} for this question`,
      });
      return;
    }

    setScoreSubmitting(true);

    const payload = {
      candidate_test_id: currentCandidateResponse.candidateTestId,
      test_question_id: currentCandidateResponse.id,
      score: parseInt(currentScore, 10),
      recruiter_test_id: assessment.id,
    };
    setQuestionScore(payload)
      .then(() => {
        addToast({
          type: 'success',
          msg: `Score saved`,
        });
        setCurrentScore('');
        candidateResponseQueue.shift();
        if (candidateResponseQueue.length > 0) {
          setCurrentCandidateResponse(candidateResponseQueue[0]);
        } else {
          selectNextQuestion();
        }
      })
      .catch((e) => {
        addToast({
          type: 'error',
          msg: `Error setting the score for this response. Please reload the page.`,
        });
        handleError(e);
      })
      .finally(() => {
        setScoreSubmitting(false);
      });
  };

  return (
    <div className="test">
      <h3 className="section-title">Free Responses</h3>
      <div className="section-subtitle">
        We’ve anonymized the free response submissions for evaluation to reduce
        bias in the marking process and make it a fairer experience. You can
        award marks for each submission here.
      </div>
      <CardContainer>
        {questionQueue.length < 1 ? (
          <div className="empty-screen">
            {loading || feedLoading ? (
              <LoadingIcon />
            ) : (
              <div>
                <img src={CheckIcon} alt="checkmark icon" />
                <div className="description">
                  There are currently no unmarked Free Response questions
                  requiring evaluation.
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="feed-container">
            <div className="questions-navigation">
              {questions.map((q) => {
                return (
                  <div key={q.subject}>
                    <div className="subject-name">
                      <h5>{q.subject}</h5>
                      <div className="hr">&nbsp;</div>
                    </div>
                    <div className="questions-button-grid">
                      {q.questions.map((question) => {
                        return (
                          <QuestionButton
                            onQuestionSelected={questionSelectedHandler}
                            key={question.position}
                            questionDetails={question}
                            pendingResponses={
                              question.candidateResponses.length
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="question-details">
              <h4>Question - {selectedQuestion?.position}</h4>
              <div className="question-details-meta">
                <div className="difficulty">
                  <img
                    src={DifficultyIcon}
                    alt="difficulty meter icon"
                    className="image-align mr2"
                  />
                  {selectedQuestion?.difficulty}
                </div>
                <div className="subjects">
                  {selectedQuestion?.subjects.map((subjectName) => (
                    <span className="pill" key={`${subjectName}`}>
                      {subjectName}
                    </span>
                  ))}
                </div>
              </div>
              <div className="question-background">
                <Markdown
                  transformImageUri={transformMarkdownImageUri}
                  content={selectedQuestion?.background}
                />
                <Markdown
                  transformImageUri={transformMarkdownImageUri}
                  content={selectedQuestion?.question}
                />
              </div>

              {selectedQuestion?.positiveIndicators ? (
                <div className="question-good-response">
                  <h4>Indicators of a Good Response</h4>
                  <div className="response-box">
                    <Markdown
                      transformImageUri={transformMarkdownImageUri}
                      content={selectedQuestion?.positiveIndicators}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {selectedQuestion?.negativeIndicators ? (
                <div className="question-bad-response">
                  <h4>Indicators of a Bad Response</h4>
                  <div className="response-box">
                    <Markdown
                      transformImageUri={transformMarkdownImageUri}
                      content={selectedQuestion?.negativeIndicators}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {selectedQuestion?.modelResponse ? (
                <div className="question-model-response">
                  <h4>Model Response</h4>
                  <div className="response-box">
                    <Markdown
                      transformImageUri={transformMarkdownImageUri}
                      content={selectedQuestion?.modelResponse}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="question-candidate-response">
                <h4>
                  Candidate&apos;s Response&nbsp;
                  <span className="reference">
                    ({currentCandidateResponse.reference})
                  </span>
                </h4>
                <div className="response-box">
                  <div>
                    <p>{currentCandidateResponse?.candidateResponse}</p>
                    {currentCandidateResponse?.candidateResponseFile ? (
                      <p>
                        {renderFile(
                          currentCandidateResponse.candidateResponseFile
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="feed-controls">
                  <div className="score-input">
                    <div className="label">Awarded Marks</div>
                    <ScoreInput
                      value={currentScore}
                      maxScore={selectedQuestion?.maxScore}
                      onScoreChange={setCurrentScore}
                      disabled={scoreSubmitting}
                    />
                  </div>
                  <div className="buttons">
                    <Button
                      data-testid="score-save-button"
                      text="Save"
                      variant="primary md"
                      disabled={
                        !currentCandidateResponse ||
                        selectedQuestion?.candidateResponses.length < 1 ||
                        currentScore === '' ||
                        Number.isNaN(currentScore)
                      }
                      onClick={selectNextResponse}
                      loading={scoreSubmitting}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardContainer>
    </div>
  );
};
export default FreeResponseFeed;
