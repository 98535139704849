import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { downloadResultReport } from 'api/assessment.api';

const AssessmentReportDownloadPage: React.FC = () => {
  const { fileName } = useParams<{
    fileName: string;
  }>();
  const { addToast } = useToasts();
  const [description, setDescription] = useState<string>(
    'Your download will start shortly...'
  );

  useEffect(() => {
    downloadResultReport(fileName)
      .then((response) => {
        const url = response.data?.data?.url;
        if (url) window.location.assign(url);
      })
      .catch((errors) => {
        const msg = errors.response.data.errors.map((error) => error.detail);
        setDescription(msg.join(' '));
        addToast({
          type: 'error',
          msg: msg.join(' '),
        });
      });
  }, [fileName, addToast]);

  return (
    <>
      <Helmet>
        <title>Download Assessment Report | alooba.com</title>
      </Helmet>
      <div className="full-width-container center">
        <h2>Download Assessment Report</h2>
        <br />
        {description}
      </div>
    </>
  );
};

export default AssessmentReportDownloadPage;
