export const parseLocationData = (data): any => {
  if (!data || Object.keys(data).length === 0) {
    return {};
  }
  const component: any = {};
  const address = data.address_components;
  address?.forEach((add, i) => {
    address[i].types.forEach((t, j) => {
      switch (address[i].types[j]) {
        case 'country':
          component.country = address[i].long_name;
          break;
        case 'administrative_area_level_1':
          component.state = address[i].long_name;
          break;
        case 'administrative_area_level_2':
          if (!component.state || component.state === '') {
            component.state = address[i].long_name;
          }
          break;
        case 'colloquial_area':
          if (!component.locality || component.locality === '') {
            component.locality = address[i].long_name;
          }
          break;
        case 'locality':
          component.city = address[i].long_name;
          break;
        case 'sublocality':
          component.suburb = address[i].long_name;
          break;
        case 'administrative_area_level_3':
          if (!component.suburb || component.suburb === '') {
            component.suburb = address[i].long_name;
          }
          break;
        default:
          break;
      }
    });
  });
  component.description = data.formatted_address;
  component.google_place_id = data.place_id;
  let location_timezone: string | number =
    parseInt(data.utc_offset_minutes, 10) / 60;
  if (location_timezone >= 0) {
    const half_tz = location_timezone % 1;
    if (half_tz !== 0) {
      const int_timezone = location_timezone - half_tz;
      location_timezone = `${int_timezone}:${half_tz * 60}`;
    }
    location_timezone = `+${location_timezone}`;
  } else {
    const positive_timezone = location_timezone * -1;
    const half_tz = positive_timezone % 1;
    if (half_tz !== 0) {
      const int_timezone = positive_timezone - half_tz;
      location_timezone = `-${int_timezone}:${half_tz * 60}`;
    }
  }
  component.timezone = location_timezone;
  component.lat = data.geometry?.location.lat();
  component.lng = data.geometry?.location.lng();

  return component;
};
