import React from 'react';
import './MainContainer.scss';

interface Props {
  children?: any;
  className?: any;
}
const CardContainer: React.FC<Props> = (props: Props) => {
  const { children, className } = props;
  return <div className={`main-container ${className ?? ''}`}>{children}</div>;
};
export default CardContainer;
