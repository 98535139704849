import './timeline.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {
  EventComponentFactory,
  Timeline,
  TimelineEvent,
  TimelineLane,
  ZoomLevels,
} from 'react-svg-timeline';
import moment from 'moment';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import CorrectIcon from 'images/icons/check-color.svg';
import WrongIcon from 'images/icons/cross-color.svg';
import ViewQuestionIcon from 'images/icons/next.svg';
import OfflineIcon from 'images/icons/offline.svg';
import ClipboardIcon from 'images/icons/clipboard.svg';
import EditIcon from 'images/icons/edit.svg';
import RecordingStartIcon from 'images/icons/camera.svg';
import UploadIcon from 'images/icons/upload.svg';
import ExecuteIcon from 'images/icons/score.svg';
import { TestEvent } from '../../../store/reducers/candidate';
import CustomSwitch from '../CustomSwitch';
import { formatDuration, secondsToHms } from '../../../helpers/datetime';

interface Props {
  testEvents?: TestEvent[];
  test?: Test;
  isMCQ: boolean;
}
type TestTimelineEvent = TimelineEvent<string, string>;
interface PlottableEvent extends TestTimelineEvent {
  originalEvent: TestEvent;
  isMCQ: boolean;
  isAnswerCorrect: boolean;
}
export const createTimelineEvent = (event: TestEvent): PlottableEvent => {
  const date = event.event_time
    ? new Date(event.event_time)
    : new Date(event.created_at);
  return {
    eventId: `${event.event}-${event.id}`,
    tooltip: `${date}:  ${event.event}`,
    laneId: `${event.question_number}`,
    startTimeMillis: date.getTime(),
    originalEvent: event,
    isAnswerCorrect: false,
    isMCQ: false,
  };
};

const filterEvents = (
  eventsList: TestEvent[],
  eventTypes: string[]
): TestEvent[] => {
  return sortByEventTime(
    eventsList.filter((e: TestEvent) => {
      return eventTypes.includes(e.event);
    })
  );
};
const sortByEventTime = (eventsList: TestEvent[]): TestEvent[] => {
  return eventsList.sort((e: TestEvent, nextE: TestEvent) => {
    return (
      new Date(e.event_time).getTime() - new Date(nextE.event_time).getTime()
    );
  });
};

const findNextEvent = (
  eventsList: TestEvent[], // assumes this is already sorted
  eventType: string,
  questionNumber: number,
  startAfterIndex: number
): TestEvent => {
  let event: TestEvent;
  for (let i = startAfterIndex + 1; i < eventsList.length; i += 1) {
    const currentEvent = eventsList[i];
    if (
      currentEvent.question_number === questionNumber &&
      currentEvent.event === eventType
    ) {
      event = currentEvent;
      break;
    }

    // if the current event has a different question number
    // we've reached the end of sequence of events we need to look at
    if (currentEvent.question_number > questionNumber) {
      break;
    }
  }
  return event;
};

export interface Test {
  id: string;
  questions: Question[];
}

export interface Question {
  position: number;
  answers?: Answer[];
}
export interface Answer {
  id: number;
  answer: string;
  position: number;
  correct: boolean;
}

const AloobaTimeline = ({ testEvents, isMCQ, test }: Props): JSX.Element => {
  const [height, setHeight] = useState(250);
  const [showBrowserEvents, setShowBrowserEvents] = useState(true);
  const [showAnswerEvents, setShowAnswerEvents] = useState(true);
  const [showNetworkEvents, setShowNetworkEvents] = useState(true);
  const [timelineEvents, setTimelineEvents] = useState<
    TimelineEvent<string, string>[]
  >([]);
  const [expanded, setExpanded] = useState(false);

  const numberOfQuestions = test ? test.questions.length : 0;

  const dateFormatter = (ms: number): string =>
    moment(new Date(ms)).format('h:mm:ss A Z');

  const answerDictionary = useMemo(() => {
    const dictionary = {};
    if (test && isMCQ) {
      test.questions
        .map((q) => q.answers)
        .flat()
        .forEach((answer: Answer) => {
          if (answer) {
            dictionary[answer.id] = answer;
          }
        });
    }
    return dictionary;
  }, [test, isMCQ]);

  const lanes: TimelineLane<string>[] = useMemo(() => {
    const laneIds = [];
    if (testEvents) {
      // generating lanes from test_event.question_number
      // will have missing lanes if candidate skips questions.
      // since test is always submitted from the last question
      // get the last question number and generate lanes
      const lastQuestionNumber = testEvents.reduce(
        (lastQuestionNumber, event) =>
          event.question_number > lastQuestionNumber
            ? event.question_number
            : lastQuestionNumber,
        0
      );
      for (let lane = 1; lane <= lastQuestionNumber; lane += 1) {
        laneIds.push(`${lane}`);
      }
      // sort lanes by question number
      laneIds.sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10));
    }
    return [
      {
        laneId: 'spacer',
        label: '',
      },
    ].concat(
      laneIds.map((questionNumber: string) => {
        return {
          laneId: questionNumber,
          label: `Question ${questionNumber}`,
        };
      })
    );
  }, [testEvents]);

  useEffect(() => {
    if (!expanded) {
      setHeight(170);
    } else {
      setHeight(250 + lanes.length * 25);
    }
  }, [expanded, lanes]);

  const browserEvents = useMemo(() => {
    const viewEvents = [];
    if (testEvents) {
      filterEvents(testEvents, [
        'window-blur',
        'window-focus',
        'window-closed',
      ]).forEach((e: TestEvent, index: number, allEvents: TestEvent[]) => {
        const timelineEvent = createTimelineEvent(e);

        timelineEvent.color = '#f68c3e';

        let focusEvent;
        switch (e.event) {
          case 'window-blur':
            timelineEvent.tooltip = `Tab Switched`;
            // find the next focus event for this question
            focusEvent = findNextEvent(
              allEvents,
              'window-focus',
              e.question_number,
              index
            );

            timelineEvent.color = '#666';

            if (focusEvent && focusEvent.event_time) {
              timelineEvent.endTimeMillis = new Date(
                focusEvent.event_time
              ).getTime();
              timelineEvent.tooltip += ` for ${secondsToHms(
                (timelineEvent.endTimeMillis - timelineEvent.startTimeMillis) /
                  1000
              )}`;
            } else {
              timelineEvent.tooltip += ` unknown duration`;
            }
            break;
          case 'window-closed':
            timelineEvent.tooltip = 'Tab closed';
            break;
          case 'window-focus': // no need to display window-focus event anymore since
            // timelineEvent.tooltip = 'Focus';
            return;
          default:
            timelineEvent.tooltip = `${e.event}`;
        }

        timelineEvent.tooltip = `Question ${e.question_number}\n${timelineEvent.tooltip}`;
        viewEvents.push(timelineEvent);
      });
    }
    return viewEvents;
  }, [testEvents]);

  const questionViewEvents = useMemo(() => {
    const viewEvents = [];
    if (testEvents) {
      filterEvents(testEvents, ['view-question']).forEach((e: TestEvent) => {
        const timelineEvent = createTimelineEvent(e);
        const eventTime = new Date(e.original_event_time).getTime();
        timelineEvent.tooltip = `Opened at ${dateFormatter(eventTime)}`;
        timelineEvent.color = '#1976d2';
        timelineEvent.tooltip = `Question ${e.question_number}\n${timelineEvent.tooltip}`;
        viewEvents.push(timelineEvent);
      });
    }
    return viewEvents;
  }, [testEvents]);

  const questionAnswerEvents = useMemo(() => {
    const viewEvents = [];
    const freeResponseReduced = (str: string): string => {
      const reduced = str
        .substring(0, 1000)
        .replace(/(?![^\n]{1,80}$)([^\n]{1,80})\s/g, '$1\n') // break at word after 80 chars
        .split('\n')
        .slice(0, 10)
        .join('\n');
      return reduced !== str
        ? `${reduced}...(${str.length} characters)`
        : reduced;
    };
    if (testEvents) {
      filterEvents(testEvents, [
        'enter-answer',
        'video-started',
        'video-ended',
        'sql-executor',
        'coding-executor',
      ])
        // paste answer events must be at the end so they are rendered on top of other events
        .concat(sortByEventTime(filterEvents(testEvents, ['paste-answer'])))
        .forEach((e: TestEvent, index, events) => {
          const timelineEvent = createTimelineEvent(e);
          const eventTime = new Date(e.original_event_time).getTime();
          timelineEvent.color = '#03cea4';
          timelineEvent.isMCQ = isMCQ;
          let videoEndedEvent: TestEvent = null;
          switch (e.event) {
            case 'paste-answer':
              timelineEvent.tooltip = `Pasted Response`;
              timelineEvent.color = '#F68C3E';
              break;
            case 'enter-answer':
              if (isMCQ) {
                timelineEvent.tooltip = `Selected Answer`;
                const answer: Answer = answerDictionary[e.params.answer];
                if (answer) {
                  if (answer.correct) {
                    timelineEvent.color = '#03cea4';
                    timelineEvent.isAnswerCorrect = true;
                  } else {
                    timelineEvent.color = '#d62828';
                  }
                }
              } else {
                if (!e.params.answer) {
                  return;
                }
                const reduced = e.params.answer
                  .substring(0, 1000)
                  .replace(/(?![^\n]{1,80}$)([^\n]{1,80})\s/g, '$1\n') // break at word after 80 chars
                  .split('\n')
                  .slice(0, 10)
                  .join('\n');
                const str =
                  reduced !== e.params.answer
                    ? `${reduced}...(${e.params.answer.length} characters)`
                    : reduced;
                timelineEvent.tooltip = `Entered:\n"${str}"`;
              }
              break;
            case 'video-started':
              timelineEvent.tooltip = `Recorded Response`;
              timelineEvent.color = '#FF0000';
              videoEndedEvent = findNextEvent(
                events,
                'video-ended',
                e.question_number,
                index
              );
              if (videoEndedEvent) {
                timelineEvent.endTimeMillis = new Date(
                  videoEndedEvent.event_time
                ).getTime();
              }
              break;
            case 'sql-executor':
              if (!e.params.request.query) {
                return;
              }
              timelineEvent.tooltip = `Execute SQL (${
                e.params.message
              }):\n"${freeResponseReduced(e.params.request.query)}"`;
              break;
            case 'coding-executor':
              if (!e.params.request.code) {
                return;
              }
              timelineEvent.tooltip = `Execute Code (${
                e.params.message
              }):\n"${freeResponseReduced(e.params.request.code)}"`;
              break;
            default:
          }
          if (
            !isMCQ &&
            ['enter-answer', 'sql-executor', 'coding-executor'].includes(
              e.event
            )
          ) {
            timelineEvent.tooltip = `${dateFormatter(eventTime)}\n${
              timelineEvent.tooltip
            }`;
          } else {
            timelineEvent.tooltip += `\n${dateFormatter(eventTime)}`;
          }

          timelineEvent.tooltip = `Question ${e.question_number}\n${timelineEvent.tooltip}`;
          viewEvents.push(timelineEvent);
        });
    }
    return viewEvents.concat(questionViewEvents);
  }, [testEvents, questionViewEvents, isMCQ, answerDictionary]);

  const networkEvents = useMemo(() => {
    const events = [];
    if (testEvents) {
      filterEvents(testEvents, [
        'disconnected',
        'reconnected',
        'upload-started',
        'upload-finished',
        'upload-failed',
      ]).forEach((e: TestEvent, index: number, allEvents: TestEvent[]) => {
        const timelineEvent = createTimelineEvent(e);
        timelineEvent.color = '#d62828';
        let uploadCompletedEvent = null;
        let uploadFailedEvent = null;
        switch (e.event) {
          case 'disconnected':
            timelineEvent.tooltip = `Network Disconnected at ${dateFormatter(
              timelineEvent.startTimeMillis
            )}`;
            break;
          case 'reconnected':
            timelineEvent.tooltip = `Network Reconnected at ${dateFormatter(
              timelineEvent.startTimeMillis
            )}`;
            break;
          case 'upload-started':
            uploadCompletedEvent = findNextEvent(
              allEvents,
              'upload-finished',
              e.question_number,
              index
            );
            uploadFailedEvent = findNextEvent(
              allEvents,
              'upload-failed',
              e.question_number,
              index
            );
            timelineEvent.tooltip = `Uploaded at ${dateFormatter(
              timelineEvent.startTimeMillis
            )}`;
            timelineEvent.color = '#f68c3e';
            if (uploadCompletedEvent) {
              timelineEvent.endTimeMillis = new Date(
                uploadCompletedEvent.event_time
              ).getTime();
              timelineEvent.color = '#03cea4';
              timelineEvent.tooltip = `Upload Duration: ${secondsToHms(
                (timelineEvent.endTimeMillis - timelineEvent.startTimeMillis) /
                  1000
              )}`;
            }
            if (uploadFailedEvent) {
              timelineEvent.endTimeMillis = new Date(
                uploadFailedEvent.event_time
              ).getTime();
              timelineEvent.tooltip = `Upload Failed after ${secondsToHms(
                (timelineEvent.endTimeMillis - timelineEvent.startTimeMillis) /
                  1000
              )}`;
            }
            break;
          default:
        }
        events.push(timelineEvent);
      });
    }
    return events;
  }, [testEvents]);

  const timeGapEvents = useMemo(() => {
    const events = [];
    if (testEvents) {
      filterEvents(testEvents, ['time-gap']).forEach((e: TestEvent) => {
        for (let i = 1; i <= numberOfQuestions; i += 1) {
          const timelineEvent = createTimelineEvent({
            ...e,
            question_number: i,
          });
          timelineEvent.color = '#d62828';
          timelineEvent.tooltip = `Time gap of ${formatDuration(
            JSON.parse(e.params).time_gap
          )}`;
          events.push(timelineEvent);
        }
      });
    }
    return events;
  }, [testEvents, numberOfQuestions]);

  useEffect(() => {
    let displayableEvents = [];
    if (showBrowserEvents) {
      displayableEvents = displayableEvents.concat(browserEvents);
    }
    if (showAnswerEvents) {
      displayableEvents = displayableEvents.concat(questionAnswerEvents);
    }

    if (showNetworkEvents) {
      displayableEvents = displayableEvents.concat(networkEvents);
    }

    if (timeGapEvents) {
      displayableEvents = displayableEvents.concat(timeGapEvents);
    }

    setTimelineEvents(displayableEvents);
  }, [
    showAnswerEvents,
    showNetworkEvents,
    showBrowserEvents,
    browserEvents,
    questionAnswerEvents,
    networkEvents,
    timeGapEvents,
  ]);

  return (
    <>
      <div
        className="side-by-side"
        style={{ height: `${expanded ? `${height}px` : 'auto'}` }}
      >
        <div className="timeline-event-switch-controls">
          <div className="controls">
            <div>Click to zoom in</div>
            <div>Alt + Click to zoom out</div>
          </div>
          <label className="timeline-event-switch">
            <CustomSwitch
              data-testid="timeline-expand-switch"
              checked={expanded}
              onChange={() => setExpanded(!expanded)}
            />{' '}
            Expand Timeline
          </label>
          {expanded && (
            <div>
              <div>
                <label className="timeline-event-switch">
                  <CustomSwitch
                    checked={
                      showAnswerEvents && questionAnswerEvents.length > 0
                    }
                    onChange={() => setShowAnswerEvents(!showAnswerEvents)}
                  />{' '}
                  {isMCQ ? 'Answer Selection' : 'Response Input'} Events (
                  {questionAnswerEvents.length})
                </label>
              </div>
              <div>
                <label className="timeline-event-switch">
                  <CustomSwitch
                    checked={showNetworkEvents && networkEvents.length > 0}
                    onChange={() => setShowNetworkEvents(!showNetworkEvents)}
                  />{' '}
                  Network Events ({networkEvents.length})
                </label>
              </div>
              <div>
                <label className="timeline-event-switch">
                  <CustomSwitch
                    checked={showBrowserEvents && browserEvents.length > 0}
                    onChange={() => setShowBrowserEvents(!showBrowserEvents)}
                  />{' '}
                  Browser Events ({browserEvents.length})
                </label>
              </div>
            </div>
          )}
        </div>
        <AutoSizer className={`timeline ${expanded && 'expanded'}`}>
          {({ width }: Size) => {
            let timelineWidth = width >= 700 ? width - 380 : width;
            if (!expanded) {
              timelineWidth = width >= 700 ? width - 270 : width;
            }
            return (
              <Timeline
                width={timelineWidth}
                laneDisplayMode={expanded ? 'expanded' : 'collapsed'}
                height={height}
                events={timelineEvents}
                zoomLevels={[
                  ZoomLevels.FIFTEEN_MINS,
                  ZoomLevels.FIVE_MINS,
                  ZoomLevels.ONE_MIN,
                ]}
                lanes={lanes}
                eventComponent={eventComponentFactory}
                dateFormat={dateFormatter}
              />
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
};

export const eventComponentFactory: EventComponentFactory<
  string,
  string,
  PlottableEvent
> = (
  e: PlottableEvent,
  role: string,
  timeScale: (arg0: any) => any,
  y: number
) => {
  const startX = timeScale(e.startTimeMillis);
  const endX = timeScale(e.endTimeMillis);
  let width = endX - startX;

  let component = null;
  if (e.originalEvent) {
    switch (e.originalEvent.event) {
      case 'video-started':
        component = (
          <>
            {bar(startX, y, width, e.color, 1, 10)}
            {icon(startX + 15, y + 2.5, RecordingStartIcon, 0.9, 0.7)}
          </>
        );
        break;

      case 'video-ended':
        component = <></>;
        break;
      case 'enter-answer':
        if (e.isMCQ) {
          let icon = WrongIcon;
          if (e.isAnswerCorrect) {
            icon = CorrectIcon;
          }
          component = roundIcon(startX, y, icon);
        } else {
          component = (
            <>
              {point(startX, y, '#FFFFFF')}
              {icon(startX + 3.5, y + 3.5, EditIcon, 0.7, 0.7)}
            </>
          );
        }
        break;
      case 'paste-answer':
        component = (
          <>
            {point(startX, y, '#f68c3e')}
            {icon(startX + 3.3, y + 3.5, ClipboardIcon, 0.6, 0.7)}
          </>
        );
        break;
      case 'view-question':
        component = (
          <>
            {point(startX, y, e.color)}
            {icon(startX + 2, y + 2, ViewQuestionIcon, 0.9, 0.8)}
          </>
        );
        break;
      case 'disconnected':
        component = (
          <>
            {bar(startX, y, width, e.color)}
            {icon(startX + 11, y, OfflineIcon, 0.4)}
          </>
        );
        break;
      case 'upload-started':
        if (e.endTimeMillis) {
          // set a minimum width
          if (e.endTimeMillis - e.startTimeMillis < 10000) {
            width += 30;
          }

          component = (
            <>
              {bar(startX, y, width, e.color, 1, 10)}
              {icon(startX + 1 + width / 2, y, UploadIcon, 0.4, 0.9)}
            </>
          );
        } else {
          component = (
            <>
              {point(startX, y, e.color)}
              {icon(startX + 1, y, UploadIcon, 0.4, 0.9)}
            </>
          );
        }
        break;
      case 'upload-finished':
        component = <></>;
        break;
      case 'window-blur':
        component = bar(startX, y, width, e.color, 0.5);
        break;
      case 'sql-executor':
        component = roundIcon(startX, y, ExecuteIcon);
        break;
      case 'coding-executor':
        component = roundIcon(startX, y, ExecuteIcon);
        break;
      default:
    }
  }
  if (component) {
    return component;
  }
  if (!e.endTimeMillis) {
    return point(startX, y, e.color);
  }
  return bar(startX, y, width, e.color);
};

const bar = (
  x: number,
  y: number,
  width: number,
  colorHex = '#1976d2',
  opacity = 1,
  borderRadius = 10
): React.ReactNode => {
  return (
    <g>
      <rect
        x={x}
        y={y - 10}
        width={width}
        height="20"
        fill="#fff"
        rx={width > borderRadius ? borderRadius : 0}
        ry={width > borderRadius ? borderRadius : 0}
      />
      <rect
        className="timeline-point"
        rx={width > borderRadius ? borderRadius : 0}
        ry={width > borderRadius ? borderRadius : 0}
        opacity={opacity}
        x={x}
        y={y - 10}
        width={width}
        height="20"
        fill={colorHex}
      />
    </g>
  );
};
const point = (x: number, y: number, colorHex = '#1976d2'): React.ReactNode => {
  return (
    <g>
      <circle cx={x} cy={y} r="10" fill="#fff" />
      <circle className="timeline-point" cx={x} cy={y} r="10" fill={colorHex} />
    </g>
  );
};

const icon = (
  x: number,
  y: number,
  icon: string,
  opacity = 1,
  scale = 1
): React.ReactNode => {
  return (
    <g>
      <image
        x={x - 10}
        y={y - 10}
        width={20 * scale}
        height={20 * scale}
        opacity={opacity}
        href={icon}
      />
    </g>
  );
};

const roundIcon = (
  x: number,
  y: number,
  icon: string,
  background = true
): React.ReactNode => {
  return (
    <g>
      {background && (
        <circle className="timeline-icon-bg" cx={x} cy={y} r="10" fill="#fff" />
      )}
      <image
        className="timeline-icon"
        x={x - 10}
        y={y - 10}
        width="20"
        height="20"
        href={icon}
      />
    </g>
  );
};

export default AloobaTimeline;
