import { createSlice } from '@reduxjs/toolkit';

interface MenuState {
  collapsed: boolean;
  hasPageHeader: boolean;
}

const initialState: MenuState = {
  collapsed: false,
  hasPageHeader: false,
};

const menuReducer = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.collapsed = !state.collapsed;
    },
    collapseMenu: (state) => {
      state.collapsed = true;
    },
    uncollapseMenu: (state) => {
      state.collapsed = false;
    },
    activatePageHeader(state) {
      state.hasPageHeader = true;
    },
    deactivatePageHeader(state) {
      state.hasPageHeader = false;
    },
  },
});

export const {
  toggleMenu,
  collapseMenu,
  uncollapseMenu,
  activatePageHeader,
  deactivatePageHeader,
} = menuReducer.actions;
export default menuReducer.reducer;
