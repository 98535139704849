import React from 'react';
import './Input.scss';
import Skeleton from 'react-loading-skeleton';
import { NumberContext } from './context/NumberContext';
import { stopEvent } from '../../../helpers/events';
import { doesEventHasNumber } from '../../../helpers/numbers';

interface Props {
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  id?: string;
  handleClick?: any;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  ref?: any;
  errorTxt?: string;
  loading?: boolean;
  autoComplete?: string;
  min?: number;
  max?: number;
}

const NumberInput = ({
  onChange,
  disabled,
  value,
  placeholder,
  label,
  handleClick,
  defaultValue,
  readOnly,
  name,
  onBlur,
  onFocus,
  ref,
  id,
  errorTxt,
  loading,
  autoComplete,
  min,
  max,
}: Props): JSX.Element => {
  const [, setInputNumber] = React.useContext(NumberContext);
  const handleChange = (e): void => {
    if (!doesEventHasNumber(e)) {
      stopEvent(e);
      return;
    }
    setInputNumber({
      id,
      value: e.target.value,
    });
    if (onChange) {
      onChange(e);
    }
  };
  const classError = (): string => {
    if (errorTxt) {
      return 'errorInput';
    }
    return '';
  };
  return (
    <div className="alooba-input">
      {loading ? (
        <Skeleton height={12} width={100} />
      ) : (
        <label htmlFor={label}>{label}</label>
      )}
      {loading ? (
        <Skeleton height={40} />
      ) : (
        <input
          value={value}
          placeholder={placeholder}
          type="number"
          autoComplete={autoComplete}
          disabled={disabled}
          onChange={handleChange}
          onClick={handleClick}
          defaultValue={defaultValue}
          readOnly={readOnly}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          id={id}
          className={`number ${classError()}`}
          min={min}
          max={max}
          onKeyPress={handleChange}
          ref={ref}
        />
      )}
      <p className="errorMsg">{errorTxt}</p>
    </div>
  );
};

export default NumberInput;
