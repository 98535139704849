/* eslint-disable prettier/prettier */
import jwt_decode from 'jwt-decode';
import { startLoading } from 'store/reducers/app';
import { revokeToken } from 'api-auth/auth-api';
import { handleError } from 'handleError';
import { Dispatch } from 'redux';
import store from '../store/store';
import {
  getCurrentTokens,
  refreshCurrentTokens,
  logoutUser,
} from '../store/actions/auth.actions';
import { getUserDetails } from '../store/actions/profile.actions';
import * as actions from '../store/reducers/auth';

export function authFlow(): void {
  const ttlBuffer = 60;
  const { accessToken } = getCurrentTokens();
  if (accessToken) {
    const decoded: { ufid: number; exp: any } = jwt_decode(accessToken);
    if (!decoded.ufid || Date.now() / 1000 + ttlBuffer > decoded.exp) {
      store.dispatch(refreshCurrentTokens());
    } else {
      store.dispatch(getUserDetails(decoded.ufid));
    }
  }
}

const shouldAppLogoutUser = (e): void => {
  // when the access token changes from another tab to an empty value or is removed, we log the user out
  if (e.key === '@alooba/access_token' && e.oldValue && !e.newValue) {
    logoutUser();
    window.dispatchEvent(new Event('storage'));
    window.location.href = `/logout`;
  }
  if (e.key === '@alooba/refresh_token' && e.oldValue && !e.newValue) {
    logoutUser();
    window.location.href = `/logout`;
  }
};

export function storageEventListeners(): void {
  window.addEventListener('storage', (e) => {
    shouldAppLogoutUser(e);

    // when the access token changes from another tab update the access token in the current state
    if (e.key === '@alooba/access_token' && e.oldValue && e.newValue) {
      store.dispatch(actions.setAccessTokenToState(e.newValue));
    }

    // when the refresh token changes from another tab update the access token in the current state
    if (e.key === '@alooba/refresh_token' && e.oldValue && e.newValue) {
      store.dispatch(actions.setRefreshTokenToState(e.newValue));
    }

    // when the expires_in from the token changes from another tab update the access token in the current state
    if (e.key === '@alooba/expires_in') {
      store.dispatch(actions.setExpiresInToState(e.newValue));
    }

    // Global tabs lock is not optimal:
    // Handle case when user opens new tab, it starts refreshing the token, then he closes the new tab before the response
    // Refreshing stays true, old tokens are used and invalidated by the Auth, new tokens are now lost
    // After 20 seconds, if it is still refreshing, something went wront, let the user log in again
    if (e.key === '@alooba/refreshing_tokens') {
      if (e.newValue === 'true') {
        store.dispatch(actions.refreshTokensStart());
        setTimeout(() => {
          const { refreshingTokens } = store.getState().auth;
          if (refreshingTokens) {
            window.location.href = `/login?redirect_url=${window.location.pathname}`;
          }
        }, 20 * 1000);
      } else {
        store.dispatch(actions.refreshTokensSuccess());
      }
    }
  });
}

export function logoutAndRedirect(dispatch: Dispatch, redirectURL): void {
  dispatch(startLoading());
  revokeToken().then(() => {
    logoutUser();
    window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/do-logout?redirect_url=${redirectURL}`;
  });
}

export const handleApiError = (
  e,
  dispatch,
  callback,
  showToast,
  errorMessage: string,
  authenticationErrorMessage: string
): void => {
  if (e.response && e.response.status === 401) {
    showToast(authenticationErrorMessage, false);
    const currentRoute = window.location.pathname;
    setTimeout(() => {
      logoutAndRedirect(dispatch, currentRoute);
    }, 1000);
  } else {
    showToast(errorMessage, false);
    handleError(e);
    if (callback && typeof callback === 'function') {
      callback();
    }
  }
};
