import CircleProgressBar from 'components/Shared/CircleProgressBar';
import React from 'react';
import './OverviewTab.scss';

interface SubjectScore {
  subject: string;
  scored_percent: number;
}

interface SubjectScoresProps {
  subject_scores: SubjectScore[];
}
const SubjectScores: React.FC<SubjectScoresProps> = ({ subject_scores }) => {
  return (
    <div className="subject-scores">
      {subject_scores.map((score: SubjectScore) => (
        <div className="subject-score" key={score.subject}>
          <div className="score">
            <CircleProgressBar
              strokeWidth={8}
              size={100}
              percent={score.scored_percent}
            >
              <div className="scored-percent">
                {`${Math.round(score.scored_percent)}%`}
              </div>
            </CircleProgressBar>
          </div>
          <h3 className="subject-name">{score.subject}</h3>
        </div>
      ))}
    </div>
  );
};

export default SubjectScores;
