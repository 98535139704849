import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface LoadingIconProps {
  label?: string;
  className?: string;
}

const Loading = ({ label, className = '' }: LoadingIconProps): JSX.Element => {
  return (
    <div className={`${className}`}>
      <FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
      {label}
    </div>
  );
};
export const LoadingIcon = React.memo(Loading);
