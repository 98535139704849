import api from './index';

export interface SamlConfigurationResult {
  data: SamlConfiguration;
}
export interface SamlConfiguration {
  uuid: string;
  organization_id: number;
  idp_entity_id: string;
  idp_login_url: string;
  idp_logout_url: string;
  idp_x509_cert: string;
  metadata_url: string;
  consumer_service_url: string;
  login_url: string;
  logout_url: string;
  metadata: Record<string, any>;
}

export interface SamlIdpConfiguration {
  idp_metadata_url?: string;
  idp_entity_id: string;
  idp_login_url: string;
  idp_logout_url: string;
  idp_x509_cert: string;
}

export interface OrganizationTemplateResult {
  data: any;
}

/*
 * Enables the SSO feature for the organization
 *
 * @param orgId The organization ID.
 */
export async function createSamlTenant(
  orgId: number
): Promise<SamlConfigurationResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml`;
  const response = await api.post(url, {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    organization_id: orgId,
  });
  return response.data;
}

/*
 * Fetches the SAML configuration for the given organization.
 *
 * @param orgId The organization ID.
 */
export async function fetchSaml(
  orgId: number
): Promise<SamlConfigurationResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml/${orgId}`;
  const response = await api.get(url, {
    params: {
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    },
  });
  return response.data;
}

/*
 * Updates the SAML IDP configuration for the given organization.
 */
export async function updateSamlIdpConfig(
  orgId: number,
  config: SamlIdpConfiguration
): Promise<SamlConfigurationResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml/${orgId}`;
  const response = await api.patch(url, config, {
    params: {
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    },
  });
  return response.data;
}

/*
 * Retrieve the IDP configuration from a metadata URL.
 */
export async function parseMetadata(
  metadataUrl: string
): Promise<SamlConfigurationResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml/metadata`;
  const response = await api.post(url, {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    idp_metadata_url: metadataUrl,
  });
  return response.data;
}

export interface SamlDomainResult {
  data: SamlDomain[];
}
export interface SamlDomain {
  domain: string;
  force_sso: boolean;
}
export async function saveSamlDomain(
  orgId: number,
  domain: SamlDomain
): Promise<{ data: SamlDomain }> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml/${orgId}/domains`;
  const response = await api.post(url, {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    ...domain,
  });
  return response.data;
}

export async function updateSamlDomain(
  orgId: number,
  domain: SamlDomain
): Promise<{ data: SamlDomain }> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml/${orgId}/domains`;
  const response = await api.patch(url, {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    ...domain,
  });
  return response.data;
}

export async function deleteSamlDomain(
  orgId: number,
  domainName: string
): Promise<{ data: SamlDomain }> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml/${orgId}/domains`;
  const response = await api.delete(url, {
    data: {
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
      domain: domainName,
    },
  });
  return response.data;
}

export async function fetchSamlDomains(
  orgId: number
): Promise<SamlDomainResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/saml/${orgId}/domains`;
  const response = await api.get(url, {
    params: {
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    },
  });
  return response.data;
}

export async function getOrganizationTemplate(
  orgId: number,
  name: string,
  purpose: string
): Promise<OrganizationTemplateResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/templates?client_id=${process.env.REACT_APP_CLIENT_ID}&name=${name}&purpose=${purpose}`;
  const response = await api.get(url);
  return response.data;
}

export async function updateOrganizationTemplate(
  orgId: number,
  purpose: string,
  name: string,
  template: string
): Promise<OrganizationTemplateResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/templates?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.patch(url, {
    purpose,
    name,
    template,
  });
  return response.data;
}

export async function setOrganizationSetting(
  orgId: number,
  setting: string,
  value: string
): Promise<OrganizationTemplateResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/settings/${setting}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.post(url, {
    value,
  });
  return response.data;
}
