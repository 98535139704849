import React, { useEffect, useState } from 'react';
import { Modal } from 'components/Shared/Modal';
import AceEditor from 'react-ace';

import './index.scss';
import { getSqlResults } from 'api/candidate.api';
import SQLOutputTable from 'components/Shared/Test/Response/SQLOutputTable';
import CodeExecutionModalFooter from './Shared/CodeExecutionModalFooter';

interface ExecuteSQLModalProps {
  isShown: boolean;
  input: string;
  setModalVisibility: (isShown: boolean) => void;
  questionDetails: any;
}
const ExecuteSQLModal = (props: ExecuteSQLModalProps): JSX.Element => {
  const { isShown, input, setModalVisibility, questionDetails } = props;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(input);
  const [results, setResults] = useState<any>({});
  const [message, setMessage] = useState('');
  const [showOutput, setShowOutput] = useState(false);

  setModalVisibility(isShown);

  useEffect(() => {
    setCode(input);
    setResults({});
    setMessage('');
    setLoading(false);
    setShowOutput(false);
  }, [input, questionDetails]);

  const customProps = {
    loading,
    cancelVariant: 'secondary md',
    setModalVisibility,
    containerClass: 'participants-modal retake-assessment-modal',
    isCoding: true,
  };

  const execute = (): void => {
    setLoading(true);
    setShowOutput(false);
    setMessage('');
    setResults({});
    getSqlResults({
      query: code,
      test_question_id: questionDetails?.test_question_id,
    })
      .then((response) => {
        if (response.data.message) {
          setMessage(response.data.message);
        } else {
          setResults(response.data.result);
        }
        setShowOutput(true);
        setLoading(false);
      })
      .catch(() => {
        setMessage('Sorry, something went wrong. Please try again.');
        setLoading(false);
      });
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        <h2>Edit candidate&apos;s SQL query</h2>
        <div className="question-response-section coding-response-section">
          <div className="candidate-response-wrapper">
            <AceEditor
              width="100%"
              height="100%"
              mode="sql"
              theme="textmate"
              name="coding-response"
              fontSize={14}
              showPrintMargin={false}
              showGutter
              highlightActiveLine={false}
              value={code}
              onChange={(value) => setCode(value)}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
        </div>
        <br />
        {message && (
          <div className="sql-output-table wrong">
            <div className="sql-error-message">{message}</div>
          </div>
        )}
        {showOutput && results.fields && (
          <div>
            <SQLOutputTable
              fields={results?.fields || []}
              data={results?.data || []}
            />
          </div>
        )}
        <CodeExecutionModalFooter
          execute={execute}
          input={input}
          loading={loading}
          setCode={setCode}
          setModalVisibility={setModalVisibility}
        />
      </>
    </Modal>
  );
};
export default ExecuteSQLModal;
