import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from 'components/Shared/Icon/Chevron';
import ImageWithTooltip from 'components/Shared/Tooltip';
import './Header.scss';
import { AvatarIcon } from './AvatarIcon';
import searchIcon from '../../images/icons/search-color.svg';
import helpIcon from '../../images/icons/help.svg';

interface Props {
  toggleMobileMenu?: any;
  hideAvatarIcon?: boolean;
  hideMenuModal?: boolean;
  flat?: boolean;
  homeUrl?: string;
  accomodateBanner?: boolean;
}
const Header = ({
  toggleMobileMenu,
  hideAvatarIcon,
  hideMenuModal,
  flat,
  homeUrl,
  accomodateBanner,
}: Props): JSX.Element => {
  return (
    <header
      className={`header ${flat ? 'flat' : ''} ${
        accomodateBanner ? 'has-banner' : ''
      }`}
    >
      <menu>
        <a
          href={
            homeUrl ||
            `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/alooba-assess`
          }
          className="logo-wrapper"
        >
          <div className="logo" />
        </a>
        <div className="icons">
          {hideAvatarIcon ? (
            <></>
          ) : (
            <Link
              to="/search-candidates"
              className="other-assessment-title mr2"
            >
              <img src={searchIcon} alt="Search Icon" height="24" />
            </Link>
          )}
          <div style={{ padding: '8px' }}>
            <ImageWithTooltip
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SUPPORT_PAGE_URL,
                  '_blank',
                  'noopener'
                )
              }
              icon={helpIcon}
              tooltip="Support"
              alt="Support"
              noHover
            />
          </div>
          {hideAvatarIcon ? <></> : <AvatarIcon />}
          {hideMenuModal ? (
            <></>
          ) : (
            <div
              onClick={toggleMobileMenu}
              onKeyDown={toggleMobileMenu}
              role="navigation"
              aria-hidden
              className="mobile-container"
            >
              <ChevronRightIcon />
            </div>
          )}
        </div>
      </menu>
    </header>
  );
};

export default Header;
