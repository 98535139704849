import {
  getGroupsFailure,
  getGroupsStart,
  getGroupsSuccess,
} from 'store/reducers/organization';
import { handleError } from 'handleError';
import { AppThunk } from '../store';
import { userApi, getUserGroups } from '../../api/profile.api';
import {
  getUserSuccess,
  getUserFailure,
  getUserStart,
} from '../reducers/profile';

export const getUserDetails = (userId: number): AppThunk => async (
  dispatch
) => {
  try {
    if (!userId) {
      throw Error('Invalid user id');
    }
    dispatch(getUserStart());
    const data = await userApi(userId);
    dispatch(getUserSuccess(data));
  } catch (err) {
    dispatch(getUserFailure(err.message));
  }
};

export const getOrgGroups = (userId: number): AppThunk => async (dispatch) => {
  dispatch(getGroupsStart());
  getUserGroups(userId)
    .then((data) => {
      dispatch(getGroupsSuccess({ data: data.data.data.groups }));
    })
    .catch((err) => {
      dispatch(getGroupsFailure(err.message));
      handleError(Object.assign(err, { stack: new Error().stack }));
    });
};
