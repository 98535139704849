import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activatePageHeader } from '../store/reducers/layout';
import CandidateReport from '../components/CandidateReport';

interface UrlParams {
  reference?: string;
}

const CandidateReportPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(activatePageHeader());
  const { reference }: UrlParams = useParams();
  return <CandidateReport reference={reference} />;
};

export default CandidateReportPage;
