import React, { useState, useEffect } from 'react';
import { Highlight } from '.';
import { HistogramBarProps } from '../Histogram/bar';

interface QuestionInsightBarProps extends HistogramBarProps {
  bucketResolver: (number) => any;
  onMouseOver?(id: string, index: number): void;
  highlight?: Highlight;
}
const QuestionInsightBar: React.FC<QuestionInsightBarProps> = ({
  height,
  y,
  width,
  index,
  balloonLabel,
  bucketResolver,
  onMouseOver,
  highlight,
}): JSX.Element => {
  const balloonHeight = 80;

  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [correctRate, setCorrectRate] = useState(0);

  const [topBar, setTopBar] = useState({
    y: 0,
    height: 0,
    className: '',
  });

  const [bottomBar, setBottomBar] = useState({
    y: 0,
    height: 0,
    className: '',
  });

  useEffect(() => {
    const bucket = bucketResolver(index);
    const incorrectLength = bucket.incorrectCount;
    const correctLength = bucket.correctCount;
    const total = bucket.value;
    setCorrectRate(correctLength / total);
    setCorrectCount(correctLength);
    setIncorrectCount(incorrectLength);
    let hightlightCorrect = false;
    let hightlightInCorrect = false;
    if (
      highlight !== undefined &&
      highlight.time_taken >= bucket.min &&
      highlight.time_taken < bucket.max
    ) {
      if (highlight.correct) {
        hightlightCorrect = true;
      } else {
        hightlightInCorrect = true;
      }
    }

    setTopBar({
      y,
      height: (incorrectLength / total) * height,
      className: `incorrect ${hightlightInCorrect ? 'highlight' : ''}`,
    });
    setBottomBar({
      y,
      height,
      className: `correct ${hightlightCorrect ? 'highlight' : ''}`,
    });
  }, [index, bucketResolver, y, height, highlight]);

  const getTrianglePoints = (
    barWidth: number
  ): Record<string, Record<string, number>> => {
    const a = {
      x: barWidth * 0.5 - 4,
      y:
        Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10) + balloonHeight,
    };
    const b = {
      x: barWidth * 0.5 + 4,
      y: a.y,
    };
    const c = { x: barWidth * 0.5, y: a.y + 4 };
    return { a, b, c };
  };

  const trianglePoints = getTrianglePoints(width);

  const [showBalloon, setShowBalloon] = useState(false);

  const handleMouseOver = (): void => {
    if (onMouseOver) {
      onMouseOver(`insight-balloon-${index}`, index);
    }
  };

  return (
    <g id={`insight-bar-${index}`} onMouseOver={handleMouseOver}>
      <rect
        id={`bottomRecBar${index}`}
        y={bottomBar.y}
        width={width}
        height={bottomBar.height}
        className={bottomBar.className}
        onMouseOver={() => setShowBalloon(true)}
        onMouseLeave={() => setShowBalloon(false)}
      />

      <rect
        id={`topRecBar${index}`}
        y={topBar.y}
        width={width}
        height={topBar.height}
        className={topBar.className}
        onMouseOver={() => setShowBalloon(true)}
        onMouseLeave={() => setShowBalloon(false)}
      />
      {/* hover balloon */}
      {showBalloon === true && (
        <g id={`insight-balloon-${index}`}>
          <rect
            id={`recBarBalloon${index}`}
            x={width * -0.1}
            width={width * 1.2}
            y={Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10)}
            rx={width * 0.02}
            height={balloonHeight}
            className="hover-balloon"
            style={{ fill: '#ECF1F4' }}
          />
          <polygon
            points={`
                    ${trianglePoints.a.x}, ${trianglePoints.a.y}
                    ${trianglePoints.b.x}, ${trianglePoints.b.y}
                    ${trianglePoints.c.x}, ${trianglePoints.c.y}
                `}
            className="hover-balloon"
          />
          <text
            x={width * 0.5}
            y={Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10) + 20}
            dominantBaseline="middle"
            textAnchor="middle"
            style={{ fill: '#000000' }}
          >
            <tspan fontSize={12}>{balloonLabel}</tspan>
          </text>

          <circle
            cx={width * 0.05}
            cy={Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10) + 34}
            r={5}
            fill="#03CEA4"
          />
          <text
            x={width * 0.15}
            y={Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10) + 35}
            dominantBaseline="middle"
            style={{ fill: '#000000' }}
          >
            <tspan fontSize={12}>Correct:{correctCount}</tspan>
          </text>

          <circle
            cx={width * 0.05}
            cy={Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10) + 49}
            r={5}
            fill="#D62828"
          />
          <text
            x={width * 0.15}
            y={Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10) + 50}
            dominantBaseline="middle"
            style={{ fill: '#000000' }}
          >
            <tspan fontSize={12}>Incorrect: {incorrectCount}</tspan>
          </text>
          <text
            x={width * 0.5}
            y={Math.max(Math.min(topBar.y, bottomBar.y, 110) - 50, 10) + 65}
            dominantBaseline="middle"
            textAnchor="middle"
            style={{ fill: '#000000' }}
          >
            <tspan fontSize={12}>
              Correct Rate:{Math.round(correctRate * 100)}%
            </tspan>
          </text>
        </g>
      )}
    </g>
  );
};

export default QuestionInsightBar;
