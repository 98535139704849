import React, { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'components/Shared/Modal';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import TextSkeleton from 'components/Shared/TextSkeleton';
import QuestionIcon from '../../images/icons/question.svg';
import ClockIcon from '../../images/icons/clock.svg';
import DurationIcon from '../../images/icons/duration.svg';
import EasyIcon from '../../images/icons/difficulty-easy.svg';
import MediumIcon from '../../images/icons/difficulty-medium.svg';
import HardIcon from '../../images/icons/difficulty-hard.svg';
import './index.scss';

interface TemplateDetailsModalProps {
  template: any;
  isShown: boolean;
  setModalVisibility: any;
  createAction(e, template): any;
}
const TemplateDetailsModal = (
  props: TemplateDetailsModalProps
): JSX.Element => {
  const [expandedSkills, setExpandedSkills] = useState<any>([]);
  const { template } = props;
  const organizationName = useSelector(
    (state: RootState) => state?.organization?.organizationDetails?.name
  );
  let parts = null;
  if (template) {
    parts = [...template.parts];
    parts.sort((a, b) => {
      return a.index - b.index;
    });
  }
  const modalProps = {
    actionText: 'Create',
    showCancel: true,
    cancelVariant: 'sub-primary',
    cancelButtonText: 'Close',
    handleButtonAction: (e) => {
      props.createAction(e, template);
    },
    containerClass: 'template-details',
  };
  const numberWords = {
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
  };
  const formatDuration = (duration): ReactElement => {
    const hours = Math.trunc(duration / 60 / 60);
    const minutes = (duration - hours * 60 * 60) / 60;
    return (
      <>
        {hours > 0 && (
          <>
            <span className="value">{hours}&nbsp;</span>
            <span className="label">{hours > 1 ? 'hours' : 'hour'}</span>
          </>
        )}
        {minutes > 0 && (
          <>
            <span className="value">{minutes}&nbsp;</span>
            <span className="label">{minutes > 1 ? 'minutes' : 'minute'}</span>
          </>
        )}
      </>
    );
  };

  const getPartSubjects = (part): string => {
    const subjects = part.subjects.map((s) => s.name);
    if (subjects.length === 1) {
      return subjects[0];
    } else if (subjects.length === 2) {
      return subjects.join(' or ');
    } else {
      const lastSubject = subjects.pop();
      return `${subjects.join(', ')}, or ${lastSubject}`;
    }
  };

  return (
    <Modal {...{ ...props, ...modalProps }}>
      {template && (
        <>
          <div className="details-card-header">
            <h2>{template.name}</h2>
            <div className="template-sub-header">
              <TextSkeleton loading={organizationName === ''} width="80px">
                {organizationName ? (
                  <i>
                    {template.is_organization_template
                      ? `${organizationName} Template`
                      : 'Alooba Template'}
                  </i>
                ) : undefined}
              </TextSkeleton>
            </div>
          </div>
          <br />
          {template.parts.length === 1 ? (
            <p>
              This assessment consists of a single{' '}
              <strong>
                {template.parts[0].type === 'MCQ'
                  ? 'Concepts & Knowledge'
                  : template.parts[0].type}
              </strong>{' '}
              test.
            </p>
          ) : (
            <p>
              This assessment consists of {numberWords[template.parts.length]}{' '}
              tests. Candidates will be required to take them{' '}
              {template.parts.length === 2 ? 'both' : 'all'} to get a full
              picture of their skills across the relevant areas.
            </p>
          )}
          {template.job_titles?.length > 0 && (
            <>
              {template.job_titles.length > 1 ? (
                <div className="relevant-roles">
                  <h3>Relevant Roles</h3>
                  <p>
                    Typical roles that this assessment is appropriate in
                    assessing the skills of include:
                  </p>
                  <div className="job-titles">
                    {template.job_titles.map((title) => {
                      return (
                        <div
                          key={`job-title-${
                            template.id
                          }-${title.toLowerCase()}`}
                          className="job-title"
                        >
                          {title}
                        </div>
                      );
                    })}
                  </div>
                  <p>
                    However, titles can be a little misleading. Similar roles
                    may have a different name within your organization.
                  </p>
                </div>
              ) : (
                <>
                  <h3>Relevant Role</h3>
                  <p>
                    This assessment is specifically designed to assess the
                    skills of candidates for the role of{' '}
                    <strong>{template.job_titles[0]}</strong>. However, titles
                    can be a little misleading. What we refer to as a{' '}
                    {template.job_titles[0]} may have a different name within
                    your organization.
                  </p>
                </>
              )}
            </>
          )}
          {parts.map((part) => {
            return (
              <div className="part">
                {parts.length > 1 && (
                  <div className="part-index">{part.index}</div>
                )}
                <h3 className="type">
                  {part.type === 'MCQ' ? 'Concepts & Knowledge' : part.type}
                </h3>
                <div className="stats">
                  <div className="stat">
                    <img
                      className="icon"
                      src={QuestionIcon}
                      alt="Number of questions icon"
                    />
                    <span className="value">{part.num_questions}&nbsp;</span>
                    <span className="label">
                      question{part.num_questions !== 1 && 's'}
                    </span>
                  </div>
                  {part.prep_time_duration > 0 && (
                    <div className="stat">
                      <img
                        className="icon"
                        src={ClockIcon}
                        alt="Preparation time icon"
                      />
                      {formatDuration(part.prep_time_duration)}
                      <span className="label">&nbsp;preparation time</span>
                    </div>
                  )}
                  <div className="stat">
                    <img
                      className="icon"
                      src={DurationIcon}
                      alt="Scheduled duration icon"
                    />
                    {formatDuration(part.duration)}
                    <span className="label">&nbsp;test duration</span>
                  </div>
                  {part.difficulty && (
                    <div className="stat">
                      {part.difficulty === 'Easy' && (
                        <>
                          <img
                            className="icon"
                            src={EasyIcon}
                            alt="Difficulty icon"
                          />
                          <span className="value">Standard</span>
                          <span className="label">&nbsp;difficulty</span>
                        </>
                      )}
                      {part.difficulty === 'Medium' && (
                        <>
                          <img
                            className="icon"
                            src={MediumIcon}
                            alt="Difficulty icon"
                          />
                          <span className="value">Advanced</span>
                          <span className="label">&nbsp;difficulty</span>
                        </>
                      )}
                      {part.difficulty === 'Hard' && (
                        <>
                          <img
                            className="icon"
                            src={HardIcon}
                            alt="Difficulty icon"
                          />
                          <span className="value">Expert</span>
                          <span className="label">&nbsp;difficulty</span>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="description">
                  {part.type === 'MCQ' && (
                    <p>
                      This is a multiple choice quiz lasting{' '}
                      {formatDuration(part.duration)} with {part.num_questions}{' '}
                      questions.{' '}
                      {part.subjects.length > 1
                        ? `The questions are split over ${part.subjects.length} skill areas.`
                        : `The questions all center around ${part.subjects[0].name}.`}
                    </p>
                  )}
                  {part.type === 'Data Analysis' && (
                    <>
                      <p>
                        The candidate will be presented with some basic
                        information about a business and a small dataset. They
                        will have some time to download the datasets and import
                        them into their own analytical tool of choice, such as
                        MS Excel, R, Python, PowerBI or any other tool they are
                        familiar with.
                      </p>
                      <p>
                        The candidate will analyse the data to answer some
                        simple questions to solve some business problems. The
                        candidate may need to perform some cleansing of the
                        data, and then some filtering, grouping, aggregation
                        etc. The questions they answer are all multiple choice
                        questions.
                      </p>
                    </>
                  )}
                  {part.type === 'SQL' && (
                    <p>
                      The candidates will be asked to write a series of SQL
                      statements to query a common database schema. The
                      candidate&apos;s queries will be executed and the output
                      will be automatically validated against the expected
                      result.
                    </p>
                  )}
                  {(part.type === 'Python Coding' ||
                    part.type === 'Analytics Coding') && (
                    <p>
                      The candidates will be asked to write functions in{' '}
                      {getPartSubjects(part)} to solve various challenges. The
                      candidate&apos;s code will be executed and automatically
                      validated across our set of test cases.
                    </p>
                  )}
                  {part.type === 'Free Response' && (
                    <>
                      <p>
                        The Free Response questions delve a little deeper into
                        capturing the candidate&apos;s comprehension of a
                        subject and gain an understanding of some of the their
                        opinions on relevant areas of data subjects. These will
                        require the candidate to think about their response to
                        the questions and provide written answers to them.
                      </p>
                      <p>
                        Most free response questions don&apos;t have a single
                        correct answer therefore you will not get an automated
                        score for each candidate for this section, but you will
                        be able to review their responses and compare them with
                        our model responses and some key things to look out for
                        in the responses and provide your own score for each
                        response.
                      </p>
                    </>
                  )}
                  {part.type === 'Personality Profiling' && (
                    <p>
                      Gain insight into your candidates using the Big 5
                      personality assessment. Personality tests can be useful
                      for understand what motivates your candidates, their work
                      style, and how they might fit into your organisation.
                    </p>
                  )}
                </div>
                {(part.type === 'MCQ' || part.type === 'Free Response') && (
                  <div className="skills">
                    {part.subjects.map((skill) => {
                      return (
                        <div className="skill">
                          <h4
                            onClick={() => {
                              const newExpandedSkills = { ...expandedSkills };
                              if (newExpandedSkills[part.id] === undefined) {
                                newExpandedSkills[part.id] = [];
                              }
                              if (
                                newExpandedSkills[part.id][skill.id] ===
                                  undefined ||
                                !newExpandedSkills[part.id][skill.id]
                              ) {
                                newExpandedSkills[part.id][skill.id] = true;
                              } else {
                                newExpandedSkills[part.id][skill.id] = false;
                              }
                              setExpandedSkills(newExpandedSkills);
                            }}
                          >
                            {expandedSkills[part.id] &&
                            expandedSkills[part.id][skill.id] ? (
                              <FontAwesomeIcon
                                className="icon fa-chevron-down"
                                icon={faChevronDown}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="icon fa-chevron-right"
                                icon={faChevronRight}
                              />
                            )}
                            <span className="name">{skill.name}</span>{' '}
                            {skill.proportion && skill.proportion !== 100 && (
                              <span className="proportion">
                                ({skill.proportion}%)
                              </span>
                            )}
                          </h4>
                          {expandedSkills[part.id] &&
                            expandedSkills[part.id][skill.id] && (
                              <div className="description">
                                {skill.description}
                                <div className="exclusions">
                                  {skill.exclusions}
                                </div>
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
          <hr />
        </>
      )}
    </Modal>
  );
};
export default TemplateDetailsModal;
