import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment-timezone';
import validator from 'validator';
import { inviteCandidateToInterview } from 'api/interview.api';
import { fetchTimezones } from 'store/actions/utils.actions';
import { fetchInterviews } from 'store/actions/interview.actions';
import { increaseInvitedUsersCount } from 'store/reducers/interview';
import { fetchUsers } from 'store/actions/users.actions';
import { stopEvent } from 'helpers/events';
import { fullDateTime } from 'helpers/datetime';
import { Button } from 'components/Shared';
import { Modal } from 'components/Shared/Modal';
import MultiSelect from 'components/Shared/MultiSelect';
import DateTimePicker from 'components/Shared/DateTimePicker';
import MemoizedTimezoneDropDown from 'components/Shared/Dropdown/MemoizedTimezoneDropDown';
import Input from 'components/Shared/Input';
import TextInput from 'components/Shared/Input/textArea';
import Dropdown from 'components/Shared/Dropdown';
import CandidateIcon from 'images/icons/user.svg';
import QuestionIcon from '../../images/icons/question.svg';
import InfoIcon from '../../images/icons/info.svg';
import ClockIcon from '../../images/icons/clock.svg';
import CalendarIcon from '../../images/icons/calendar.svg';
import { assessmentContext } from '../Assessment';
import './InterviewInviteModal.scss';

interface InterviewInviteModalProps {
  setModalVisibility: any;
  candidate: any;
}
const InterviewInviteModal = (
  props: InterviewInviteModalProps
): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setModalVisibility, candidate } = props;
  const { assessment } = useContext(assessmentContext);
  const { addToast } = useToasts();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { timezones } = useSelector((state: RootState) => state.utils);
  const { users } = useSelector((state: RootState) => state.users);
  const { interviews } = useSelector((state: RootState) => state.interview);
  const [loading, setLoading] = useState<boolean>(true);
  const [assessmentInterviews, setAssessmentInterviews] = useState<any>([]);
  const [interviewDropdownOptions, setInterviewDropdownOptions] = useState<any>(
    []
  );
  const [values, setValues] = useState<any>({
    interview: null,
    meetingUrl: '',
    interviewers: [],
    tz_code: userDetails.user_timezone,
    date: '',
    date_verbose: '',
    note: '',
  });
  const [interviewError, setInterviewError] = useState<string>(null);
  const [meetingUrlError, setMeetingUrlError] = useState<string>(null);
  const [interviewersError, setInterviewersError] = useState<string>(null);
  const [timezoneError, setTimezoneError] = useState<string>(null);
  const [scheduleError, setScheduleError] = useState<string>(null);
  const [inviting, setInviting] = useState<boolean>(false);

  const ERROR_NO_INTERVIEW_SELECTED =
    'You must select an interview to invite the candidate to.';
  const ERROR_MISSING_MEETING_URL =
    'You must provide a meeting URL for the candidate and interviewers to connect to the video call.';
  const ERROR_INVALID_MEETING_URL =
    'The meeting URL must be a valid URL for the candidate and interviewers to connect to the video call.';
  const ERROR_NO_INTERVIEWERS_SELECTED =
    'You must select at least one interviewer.';
  const ERROR_NO_TIMEZONE_SELECTED =
    'You must select a timezone for the interview schedule.';
  const ERROR_MISSING_SCHEDULE_DATE =
    'You must provide a date and time for the interview schedule.';
  const ERROR_INVALID_SCHEDULE_DATE =
    'The selected interview start time is invalid.';
  const ERROR_INVALID_FORM =
    'You must address the issues before you can invite the candidate.';

  // On first load fetch timezones
  useEffect(() => {
    dispatch(fetchTimezones());
  }, [dispatch]);

  // On load, fetch user details
  useEffect(() => {
    const orgId =
      userDetails &&
      userDetails.recruiter_detail &&
      userDetails.recruiter_detail.organisation_id;
    if (orgId) {
      dispatch(fetchUsers(orgId));
    }
  }, [dispatch, userDetails]);

  // On load, fetch assessment interviews
  useEffect(() => {
    dispatch(fetchInterviews(assessment.id));
  }, [dispatch, assessment]);

  // Once everything is loaded, update the assessment to include the interviews.
  useEffect(() => {
    if (
      loading &&
      timezones &&
      userDetails &&
      assessment &&
      interviews[assessment.id]?.getting === false
    ) {
      setLoading(false);
      setAssessmentInterviews(interviews[assessment.id].interviews);
    }
  }, [loading, timezones, userDetails, assessment, interviews]);

  const handleCreateInterviewClick = (): void => {
    const createInterviewURL = `/interview/create?assessment=${assessment.id}`;
    history.push(createInterviewURL);
  };

  useEffect(() => {
    const options = [];
    if (assessmentInterviews) {
      assessmentInterviews.forEach((interview) => {
        options.push({
          label: interview.name,
          value: interview.id,
        });
      });
      setInterviewDropdownOptions(options);
    }
  }, [assessmentInterviews]);
  const interviewersDropdownOptions = !users
    ? []
    : users
        .filter((user) => {
          return user.Name !== 'Alooba Support';
        })
        .map((user) => {
          return {
            label: `${user.Name} <${user.Email}>`,
            value: user.id,
            user,
          };
        });
  const validateInterview = (selected?): boolean => {
    if ((selected === undefined && values.interview) || selected?.value) {
      setInterviewError(null);
      return true;
    } else {
      setInterviewError(ERROR_NO_INTERVIEW_SELECTED);
      return false;
    }
  };
  const handleSelectInterview = (selected): void => {
    if (validateInterview(selected)) {
      for (let i = 0; i < assessmentInterviews.length; i += 1) {
        if (assessmentInterviews[i].id === selected.value) {
          setValues({
            ...values,
            interview: assessmentInterviews[i],
          });
          break;
        }
      }
    } else {
      setValues({
        ...values,
        interview: null,
      });
    }
  };

  // Meeting URL
  const validateMeetingUrl = (url?): boolean => {
    if (url === undefined) {
      url = values.meetingUrl;
    }
    if (!url) {
      setMeetingUrlError(ERROR_MISSING_MEETING_URL);
      return false;
    } else if (
      !validator.isURL(url, {
        protocols: ['http', 'https'],
        require_protocol: true,
      })
    ) {
      setMeetingUrlError(ERROR_INVALID_MEETING_URL);
      return false;
    } else {
      setMeetingUrlError(null);
      return true;
    }
  };
  const handleMeetingUrlChange = (
    event: React.SyntheticEvent<EventTarget>
  ): void => {
    const target = event.target as HTMLInputElement;
    let { value } = target;
    // If they didn't include the protocol, add 'https' protocol
    if (event.type === 'blur' && value && !/^\w+:\/\//.test(value)) {
      value = `https://${value}`;
    }
    setValues({
      ...values,
      meetingUrl: value,
    });
    if (event.type === 'blur') {
      validateMeetingUrl(value);
    } else if (event.type === 'change') {
      if (meetingUrlError) {
        validateMeetingUrl(value);
      }
    }
  };

  // Adding and removing interviewers
  const validateInterviewers = (interviewers?): boolean => {
    if (interviewers === undefined) {
      interviewers = values.interviewers;
    }
    if (interviewers.length === 0) {
      setInterviewersError(ERROR_NO_INTERVIEWERS_SELECTED);
      return false;
    } else {
      setInterviewersError(null);
      return true;
    }
  };
  const handleAddInterviewer = (option): void => {
    const newInterviewers = [...values.interviewers, option.user];
    setValues({
      ...values,
      interviewers: newInterviewers,
    });
    validateInterviewers(newInterviewers);
  };
  const handleRemoveInterviewer = (event, option): void => {
    const newInterviewers = values.interviewers.filter((interviewer) => {
      return interviewer.id !== option.value;
    });
    setValues({
      ...values,
      interviewers: newInterviewers,
    });
    validateInterviewers(newInterviewers);
  };

  // Interview Schedule
  const validateTimezone = (option?): boolean => {
    if ((option === undefined && values.tz_code) || option?.code) {
      setTimezoneError(null);
      return true;
    } else {
      setTimezoneError(ERROR_NO_TIMEZONE_SELECTED);
      return false;
    }
  };
  const handleTimezoneChange = (option): void => {
    if (validateTimezone(option)) {
      const changeValues = {
        tz_code: option.code,
        tz_offset: option.offset,
        date: '',
      };
      setValues({
        ...values,
        ...changeValues,
      });
    }
  };
  const validateSchedule = (value?): boolean => {
    value = value === undefined ? values.date : value;
    if (value === '') {
      setScheduleError(ERROR_MISSING_SCHEDULE_DATE);
      return false;
    } else if (fullDateTime(value, values.tz_code) === 'N/A') {
      setScheduleError(ERROR_INVALID_SCHEDULE_DATE);
      return false;
    } else {
      setScheduleError(null);
      return true;
    }
  };
  const handleDateTimeChange = (value: string): void => {
    if (validateSchedule(value)) {
      setValues({
        ...values,
        ...{
          date: value,
          date_verbose: fullDateTime(value, values.tz_code),
        },
      });
    }
  };
  const datetimeInput = (
    <Input
      type="text"
      label="Interview Schedule"
      autoComplete="off"
      placeholder="Select interview start time"
      icon={CalendarIcon}
      errorTxt={scheduleError}
    />
  );
  const DateTimePickerProps = {
    id: 'interview-schedule',
    customInput: datetimeInput,
    utc: values.new_expiry_date,
    handleChange: handleDateTimeChange,
    props: {
      minDate: moment().add(1, 'days').toDate(),
    },
  };

  const handleButtonAction = async (
    event: React.SyntheticEvent<EventTarget>
  ): Promise<any> => {
    stopEvent(event);
    const validInterview = validateInterview();
    const validMeetingUrl = validateMeetingUrl();
    const validInterviewers = validateInterviewers();
    const validTimezone = validateTimezone();
    const validSchedule = validateSchedule();
    const valid =
      validInterview &&
      validMeetingUrl &&
      validInterviewers &&
      validTimezone &&
      validSchedule;
    if (!valid) {
      addToast({
        type: 'warning',
        msg: ERROR_INVALID_FORM,
      });
    } else {
      setInviting(true);
      inviteCandidateToInterview(
        candidate.Reference,
        values.interview.id,
        values.meetingUrl,
        values.interviewers.map((interviewer) => interviewer.id),
        values.tz_code,
        values.date,
        values.note
      )
        .then(() => {
          setInviting(false);
          addToast({
            type: 'success',
            msg: `${candidate.Name} has been invited to ${values.interview.name}. An email invitation has been sent to their email address.`,
          });
          setModalVisibility(false);
          dispatch(
            increaseInvitedUsersCount({
              assessmentId: assessment.id,
              interviewId: values.interview.id,
            })
          );
        })
        .catch((error) => {
          setInviting(false);
          if (
            error.response?.status >= 400 &&
            error.response?.status < 500 &&
            error.response?.data?.errors[0]?.detail
          ) {
            error.response?.data?.errors.forEach((e) => {
              addToast({
                type: 'error',
                msg: e.detail,
              });
            });
          } else {
            addToast({
              type: 'error',
              msg:
                'There was an unexpected error while trying to invite the candidate to the interview. If the problem persists please contact your account manager.',
            });
            throw error;
          }
        });
    }
  };

  const modalProps = {
    loading: inviting,
    loadingTxt: 'Sending Invite',
    disabled: loading || assessmentInterviews?.length === 0,
    actionText: 'Invite Candidate',
    showCancel: true,
    cancelVariant: 'sub-primary',
    hideCloseButton: true,
    handleButtonAction,
    containerClass: 'participants-modal',
  };

  const hours = values.interview
    ? Math.trunc(values.interview.duration / 60 / 60)
    : null;
  const minutes = values.interview
    ? values.interview.duration / 60 - hours * 60
    : null;

  return (
    <Modal {...{ ...props, ...modalProps }}>
      <>
        <h2>Invite Candidate to an Interview</h2>
        <div className="candidate-interview-selection">
          <h4 className="candidate-details">
            <img className="icon" src={CandidateIcon} alt="Candidate icon" />
            <div className="candidate">{candidate.Name}</div>
          </h4>
          {loading && <Skeleton height="80px" width="100%" />}
          {!loading && assessmentInterviews?.length > 0 && (
            <div className="interview-selection">
              <Dropdown
                id="selected-interview"
                isSearchable={false}
                placeholder="Select Interview"
                dropdownOptions={interviewDropdownOptions}
                handleChange={handleSelectInterview}
                value={values.interview?.id ?? null}
                errorTxt={interviewError}
              />
              <Button
                addButton
                onClick={handleCreateInterviewClick}
                variant="primary md"
                text="Create New Interview"
              />
            </div>
          )}
          {!loading && assessmentInterviews?.length === 0 && (
            <>
              <div className="message">
                <img className="icon" src={InfoIcon} alt="info icon" />
                <p>
                  No interviews have been set up for this assessment. Please
                  create an interview to invite the candidate to.
                </p>
              </div>
              <Button
                addButton
                onClick={handleCreateInterviewClick}
                variant="primary md"
                text="Create Interview"
              />
            </>
          )}
          {values.interview && (
            <div className="interview-details">
              <div className="stat">
                <img
                  className="icon"
                  src={QuestionIcon}
                  alt="Number of questions icon"
                />
                <span className="value">{values.interview?.num_questions}</span>
                <span className="label">Questions</span>
              </div>
              <div className="stat">
                <img
                  className="icon"
                  src={ClockIcon}
                  alt="Scheduled duration icon"
                />
                {hours ? (
                  <>
                    <span className="value">{hours}</span>
                    <span className="label">
                      {hours === 1 ? 'hour' : 'hours'}
                    </span>
                  </>
                ) : (
                  ''
                )}
                {minutes ? (
                  <>
                    <span className="value">{minutes}</span>
                    <span className="label">
                      {minutes === 1 ? 'minute' : 'minutes'}
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
        </div>
        {assessmentInterviews?.length > 0 && (
          <div className="interview-invite-fields">
            <div className="meeting-url">
              <h3>Interview Meeting URL *</h3>
              <p className="sub-text">
                Please enter the link for the candidate to attend the video
                call. Create the call on your preferred video calling software
                such as Google Meet, Zoom, Webex, or Microsoft Teams.
              </p>
              <Input
                label="Meeting URL"
                id="meeting-url"
                type="text"
                autoComplete="off"
                prefix="URL"
                placeholder="https://meet.google.com/xxx-xxxx-xxx"
                value={values.meetingUrl}
                onBlur={handleMeetingUrlChange}
                onChange={handleMeetingUrlChange}
                errorTxt={meetingUrlError}
              />
            </div>
            <div className="interviewers">
              <h3>Assigned Interviewer(s) *</h3>
              <p className="sub-text">
                Interviewers: {values.interviewers.length}
              </p>
              <MultiSelect
                inline
                dropdownPlaceholder="Add Interviewer"
                value={values.interviewers.map((interviewer) => {
                  return {
                    label: `${interviewer.Name}`,
                    value: interviewer.id,
                    hoverText: interviewer.Email,
                  };
                })}
                options={interviewersDropdownOptions}
                handleOptionSelected={handleAddInterviewer}
                handleValueRemoved={handleRemoveInterviewer}
                errorTxt={interviewersError}
              />
            </div>
            <div className="schedule">
              <h3>Schedule Interview *</h3>
              <div className="interview-schedule-fields">
                <MemoizedTimezoneDropDown
                  id="timezone"
                  label="Timezone"
                  placeholder="Search Timezones..."
                  value={values.tz_code}
                  handleChange={handleTimezoneChange}
                  menuPlacement="top"
                  defaultTimezones={timezones}
                  errorTxt={timezoneError}
                />
                <DateTimePicker {...DateTimePickerProps} />
              </div>
            </div>
            <div className="notes">
              <h3>Additional Notes</h3>
              <p className="sub-text">
                Additional details the candidate should know to prepare for or
                attend the interview.
              </p>
              <TextInput
                id="notes"
                label="Notes"
                placeholder="Enter additional information to share with the candidate here."
                onChange={(event) => {
                  const target = event.target as HTMLTextAreaElement;
                  values.note = target.value;
                }}
              />
            </div>
          </div>
        )}
        <hr />
      </>
    </Modal>
  );
};
export default InterviewInviteModal;
