type Options = {
  static: Array<string>;
  dynamic?: Record<string, (data: any) => boolean>;
};

export type RulesOptions = {
  [key: string]: Options;
};

const rules: RulesOptions = {
  visitors: {
    static: ['assessments:list', 'candidates:list'],
  },
  recruiter: {
    static: ['assessments:list', 'assessments:create'],
    dynamic: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      'assessments:edit': ({ recruiterId, testRecruiterId }) => {
        if (!recruiterId || !testRecruiterId) return false;
        return recruiterId === testRecruiterId;
      },
    },
  },
  admin: {
    static: ['dashboard:list', 'assessments:list'],
  },
};

export default rules;
