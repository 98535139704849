import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useChatbot } from 'hooks';
import { deactivatePageHeader } from '../store/reducers/layout';
import PasswordReset from '../components/Auth/PasswordReset';

const PasswordResetPage: React.FC = () => {
  useChatbot();
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <>
      <Helmet>
        <title>Reset Your Password | alooba.com</title>
        <meta name="description" content="Here you can reset your password." />
      </Helmet>
      <PasswordReset />
    </>
  );
};
export default PasswordResetPage;
