import React from 'react';
import './Footer.scss';

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-menu-box">
          <a
            href={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/faq`}
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>
          |
          <a
            href="https://support.alooba.com/"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
          |
          <a
            href={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/privacy`}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          |
          <a
            href={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/terms-and-conditions`}
            rel="noreferrer"
            target="_blank"
          >
            Terms and Conditions
          </a>
        </div>

        <div className="social">
          <a
            href="https://facebook.com/AloobaTests/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          |
          <a
            href="https://linkedin.com/company/alooba"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </div>
        <div className="copyright">© 2019-{year}. All Rights Reserved</div>
      </div>
    </footer>
  );
};

export default Footer;
