import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useToasts } from 'react-toast-notifications';
import { updateOrgDetails } from '../../store/actions/organization.actions';
import { Button } from '../Shared';
import { Modal } from '../Shared/Modal';
import { handleError } from '../../handleError';

interface Props {
  organization?: any;
  loading: boolean;
}

const JoinCommunityTalentHubSettings: React.FC<Props> = (props: Props) => {
  const { loading, organization } = props;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalButtonText, setModalButtonText] = useState<string>();
  const [modalContent, setModalContent] = useState<JSX.Element>();

  useEffect(() => {
    if (organization.join_community_talent_hub) {
      setModalContent(null);
      setModalButtonText(null);
    } else {
      setModalContent(
        <div>
          <h4 className="mb4">Join Community Talent Hub</h4>
          <p>
            Upon joining the Community Talent Hub, it&apos;s important to
            understand that this decision is irreversible. Once your
            organization becomes a part of the Talent Hub, there is no option to
            opt out at a later stage.
            <br />
            Are you sure you want to proceed?
          </p>
        </div>
      );
      setModalButtonText('Join');
    }
  }, [organization]);

  const handleAccept = async (): Promise<any> => {
    setShowModal(false);
    new Promise((resolve, reject) => {
      try {
        resolve(
          dispatch(
            updateOrgDetails({
              ...organization,
              join_community_talent_hub: true,
            })
          )
        );
      } catch (e) {
        reject(e);
      }
    })
      .then(() => {
        addToast({
          type: 'success',
          msg: 'Community Talent Hub settings updated for your organization.',
        });
      })
      .catch((e) => {
        addToast({
          type: 'error',
          msg: 'Failed to join Community Talent Hub. Please try again later.',
        });
        handleError(e);
      });
  };

  return (
    <div>
      <div className="title-row">
        <h3>Community Talent Hub</h3>
      </div>
      <div className="alooba-input alooba-switch">
        <label>
          Community Talent Hub is a platform designed to enhance your job
          posts&apos; visibility. By opting to join the Community Talent Hub,
          your newly posted job openings on the job board will receive wider
          exposure as candidates within the hub will be notified. As part of
          this collaboration, your own candidates will also be part of the
          talent hub, providing them with more chances to discover exciting job
          openings. While we encourage you to embrace the power of collective
          success, we understand that your preferences matter. Once you decide
          to join the talent hub, your connection will be established
          <b> permanently</b>.
        </label>
      </div>
      <div>
        <Button
          loading={loading}
          loadingPlaceholder={loading}
          variant="primary md"
          text={
            organization.join_community_talent_hub ? 'Joined' : 'Join the Hub'
          }
          disabled={organization.join_community_talent_hub}
          onClick={() => setShowModal(true)}
        />
        {loading && <Skeleton height={32} width={160} />}
      </div>
      <Modal
        isShown={showModal}
        actionText={modalButtonText}
        handleButtonAction={handleAccept}
        setModalVisibility={setShowModal}
        showCancel
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default JoinCommunityTalentHubSettings;
