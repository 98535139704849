import React, { useState, useEffect } from 'react';
import CheckIcon from '../../../../images/icons/check-color.svg';
import CrossIcon from '../../../../images/icons/cross-color.svg';
import LightTooltip from '../../Tooltip/LightTooltip';
import './index.scss';

export interface Props {
  testCase: any;
  index: number;
  position: string;
}

const CodingResponseTestCase: React.FC<Props> = ({
  testCase,
  index,
  position,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(true);
  }, [position]);

  const toggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  // Determine test case color and icon
  let iconSrc: string;
  let questionAnswerClass: string;
  if (testCase.pass === null) {
    iconSrc = null;
    questionAnswerClass = 'not-evaluated';
  } else {
    iconSrc = testCase.score === 1 || testCase.pass ? CheckIcon : CrossIcon;
    questionAnswerClass =
      testCase.score === 1 || testCase.pass ? 'correct' : 'wrong';
  }
  const name = testCase.name ?? `Test case ${index + 1}`;

  return (
    <>
      <LightTooltip title="Click to show / hide output" placement="top" arrow>
        <div
          className={`test-case ${questionAnswerClass}`}
          onClick={toggleCollapsed}
          role="button"
          tabIndex={0}
        >
          <div className="test-case-name">{name}</div>
          <div className={`right-icon ${questionAnswerClass}`}>
            {iconSrc && (
              <img src={iconSrc} alt={`Test case ${questionAnswerClass}`} />
            )}
          </div>
        </div>
      </LightTooltip>
      <div className={`test-case-output ${collapsed ? 'hidden' : ''}`}>
        <p className="inner-title">Input</p>
        <div className="test-case-output-wrapper">
          <code className="input">{testCase.input}</code>
        </div>
        <p className="inner-title">Expected output</p>
        <div className="test-case-output-wrapper">
          <code className="expected-output">{testCase.output}</code>
        </div>
        <p className="inner-title">Candidate output</p>
        <div className="test-case-output-wrapper">
          <code className="candidate-output">
            {testCase.response_output}
            {testCase.response_error ? (
              <>
                <br />
                <span className="error">{testCase.response_error}</span>
              </>
            ) : (
              ''
            )}
          </code>
        </div>
      </div>
    </>
  );
};
export default CodingResponseTestCase;
