import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { authFlow, storageEventListeners } from 'helpers/auth-flow';
import { GlobalStyle } from './styles/global';
import Routes from './routes';
import store from './store/store';
import { handleError } from './handleError';

export const ErrorFallback = (): any => {
  return (
    <div role="alert">
      <p>Something went wrong</p>
    </div>
  );
};

window.onerror = (msg, src, lineno, colno, error): void => {
  const ignoreErrors = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
  ];
  if (ignoreErrors.some((v) => msg?.includes(v))) {
    return;
  }
  if (!error) {
    const errorMessage = `On error handler with a null error was called. \n
      message: ${msg}\n source: ${src}\n lineno: ${lineno}\n colno: ${colno}`;
    error = new Error(errorMessage);
  }
  handleError(error);
};

window.onunhandledrejection = (e: PromiseRejectionEvent) => {
  if (!e?.reason) {
    // tslint:disable-next-line:no-unsafe-any
    handleError(new Error('onunhandledrejection called with a null error'));
  } else {
    handleError(e.reason);
  }
};

authFlow();
storageEventListeners();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
          <Routes />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
