import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Grid from '@material-ui/core/Grid';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { LoaderContext } from '../../../context/loader';
import { RootState } from '../../../store/rootReducer';
import DynamicTableSearch from './DynamicTableSearch';
import DynamicTablePaginator from './DynamicTablePaginator';
import DynamicTableHeader from './DynamicTableHeader';
import { DynamicTablePropsContext } from './context/DynamicTablePropsContext';
import './index.scss';
import StickyTableCellLeft from './StickyTableCellLeft';
import StickyTableCellRight from './StickyTableCellRight';
import ActionMenuCell from './ActionMenuCell';
import DynamicTableCell from './DynamicTableCell';
import Filters from '../../CandidateResultsList/Filters/index';
import { debounce } from '../../../helpers/events';

const DynamicTable = (): JSX.Element => {
  const [DynamicTableProps] = useContext(DynamicTablePropsContext);
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const hasSticky = useRef(null);
  hasSticky.current = false;
  const {
    fields,
    toggleColumns,
    data,
    loading,
    tablePaginatorProps = null,
    tableSearchProps = null,
    sortingProps = null,
    actionMenu,
    getPath,
    processCell,
    filters = [],
    configurableColumns,
    onFilterChange = null,
    onExport = null,
    openInNewTab,
    onClickRow,
    shouldCloak = false,
    noDataMessage = 'There are no participants matching the current filter',
    showResetFiltersButton = false,
    noMinHeight = false,
  } = DynamicTableProps;

  const [isLoading] = useContext(LoaderContext);
  const { collapsed } = useSelector((state: RootState) => state.layout);
  const containerRef = useRef(null);

  const checkIfHasScroll = (): void => {
    if (containerRef.current) {
      setHasScroll(
        containerRef.current.scrollWidth > containerRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    checkIfHasScroll();
  });

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = 0;
    }
  }, [containerRef, isLoading]);

  if (loading) {
    return <Skeleton width="100%" height="100%" />;
  }
  const tableHead = (
    <DynamicTableHeader
      {...{
        fields,
        actionMenu,
        sortingProps,
        StickyTableCellLeft,
        StickyTableCellRight,
        hasScroll,
        configurableColumns,
        shouldCloak,
        toggleColumns,
      }}
    />
  );

  const fieldScroll = (field): string => {
    if (hasScroll) {
      return field.scroll || '';
    }
    return '';
  };

  const emptyTable = (
    <TableRow>
      <TableCell colSpan={12} align="center">
        {noDataMessage}
      </TableCell>
    </TableRow>
  );

  return (
    <div
      className={`dynamic-table-container ${
        noMinHeight ? 'dynanic-table-container-no-min-height' : ''
      }`}
    >
      <div>
        {filters.length > 0 ? (
          <Filters
            filters={filters}
            onFilterChange={debounce(onFilterChange, 1000)}
            onExport={onExport}
            showResetFiltersButton={showResetFiltersButton}
          />
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={2}
          >
            {tableSearchProps && (
              <Grid item>
                <DynamicTableSearch {...{ loading, tableSearchProps }} />
              </Grid>
            )}
          </Grid>
        )}
      </div>
      <TableContainer
        component={Paper}
        className={`dynamic-paper-container ${
          collapsed ? 'collapsed' : 'full'
        } ${isLoading === true ? 'container-loading' : ''}`}
        ref={containerRef}
      >
        <div className={`table-loading ${isLoading === true ? '' : 'hide'}`}>
          <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
        </div>
        <Table className="dynamic-table" stickyHeader aria-label="sticky table">
          {tableHead}
          <TableBody>
            {data.length === 0
              ? emptyTable
              : data.map((row, rowIndex: number) => {
                  let inactive = '';
                  if (!onClickRow && row.Status === 'Suspended') {
                    inactive = 'inactive';
                  }
                  const rowClass = `${
                    onClickRow ? 'cursor-pointer' : 'organization-users-table'
                  } dynamic-table-row ${inactive} ${
                    rowIndex > data.length - 4 ? 'top-stat' : 'down-stat'
                  } ${data.length < 4 ? 'single-stat' : ''}`;
                  return (
                    <TableRow
                      key={
                        row.id
                          ? row.id
                          : `_${Math.random().toString(36).substr(2, 9)}`
                      }
                      hover={false}
                      className={rowClass}
                    >
                      {fields.map((field, cellIndex: number): any => (
                        <DynamicTableCell
                          row={row}
                          field={field}
                          rowIndex={rowIndex}
                          cellIndex={cellIndex}
                          hasSticky={hasSticky}
                          getPath={getPath}
                          fieldScroll={fieldScroll}
                          processCell={processCell}
                          openInNewTab={openInNewTab}
                          onClickRow={onClickRow}
                        />
                      ))}
                      <ActionMenuCell
                        row={row}
                        actionMenu={actionMenu}
                        hasSticky={hasSticky}
                      />
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {tablePaginatorProps && (
        <DynamicTablePaginator {...{ loading, tablePaginatorProps }} />
      )}
    </div>
  );
};

export default DynamicTable;
