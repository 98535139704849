import api from './index';

export interface StripeCheckoutResult {
  token: string;
}

export interface PaymentMethodResult {
  data: any;
}

export interface BillingDetailsResult {
  name: string;
  email: string;
  country: string;
  abn: string;
  postal_code: string;
}

export async function getStripePaymentToken(orgId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/stripe-checkout?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.post(url, {});
  return response.data;
}

export async function getPaymentMethods(orgId: number): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/payment-method` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = api.get(url);
  return response;
}

export async function deletePaymentMethod(
  orgId: number,
  lastDigits: string
): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/payment-method` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = api.delete(url, {
    data: { lastDigits },
  });
  return response;
}

export async function getBillingDetails(orgId: number): Promise<any> {
  const url =
    `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/billing` +
    `?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = api.get(url);
  return response;
}
