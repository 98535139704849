import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import TotalScore from './TotalScore';
import CalendarIcon from '../../../../images/icons/calendar.svg';
import './TestHeader.scss';

interface Props {
  scoredPercent: number;
  testDetails?: any;
  loading?: boolean;
}
export default function InterviewHeader({
  scoredPercent,
  testDetails,
  loading,
}: Props): any {
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const scheduledStart = testDetails
    ? moment.unix(testDetails.interview.scheduled_start_timestamp)
    : null;
  const alreadyHappened = testDetails ? moment.utc() > scheduledStart : false;
  return (
    <div className="test-header">
      {loading ? (
        <Skeleton width={250} height={64} style={{ borderRadius: '32px' }} />
      ) : (
        <TotalScore
          scoredPercent={scoredPercent}
          totalMarks={testDetails?.total_score?.max_score}
          scoredMarks={testDetails?.total_score?.score}
        />
      )}
      <div className="spacer" />
      <div className="total-test-time">
        {loading ? (
          <Skeleton width={150} height={20} />
        ) : (
          <>
            {scheduledStart && (
              <>
                <img src={CalendarIcon} alt="Calendar icon" />
                <span className="test-duration-text">
                  {alreadyHappened ? 'Interviewed On:' : 'Scheduled At:'}
                </span>
                <div>
                  {scheduledStart
                    .tz(userDetails.user_timezone ?? 'UTC')
                    .format('MMM DD, YYYY \\a\\t h:mma')}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
