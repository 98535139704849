import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

interface Props {
  fadeIn: boolean;
}

const Fade: React.FC<Props> = ({ fadeIn: flag, children }) => {
  const [fadeIn, setFadeIn] = useState(flag);

  const fadeProps = useSpring({
    opacity: fadeIn ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 100 },
  });

  React.useEffect(() => {
    setFadeIn(flag);
  }, [flag]);

  return <animated.div style={{ ...fadeProps }}>{children}</animated.div>;
};

export default Fade;
