import * as React from 'react';
import { useEffect, useState } from 'react';
import Pill from 'components/Shared/Pill';
import Dropdown from 'components/Shared/Dropdown';
import './multiselect.scss';

interface Props {
  /**
   * The options to display in the dropdown
   *
   * Items present in this property will be hidden if they also exist in the value property.
   */
  options: SelectInputOption[];
  /**
   * The values to be preselected
   */
  value: SelectedValue[];
  handleOptionSelected: (event: {
    label: string;
    value: any;
    clickUpgrade?: boolean;
  }) => void;
  handleValueRemoved: (
    event: React.ChangeEvent<HTMLLIElement>,
    option: SelectInputOption
  ) => void;
  dropdownPlaceholder?: string;
  noOptionsMessage?: string;
  errorTxt?: string;
  label?: string;
  /**
   * Display the dropdown inline with the values
   */
  inline?: boolean;
  /**
   * Set the fixed width of the add button
   */
  minInlineWidth?: string;
  savingPlaceholder?: string;
}
export interface Removable {
  isBeingRemoved?: boolean;
}
export interface SelectInputOption extends Removable {
  value: string | number;
  label: string;
}

export interface SelectedValue extends SelectInputOption {
  hoverText?: string;
}

const MultiSelect: React.FunctionComponent<Props> = ({
  options,
  value,
  handleOptionSelected,
  handleValueRemoved,
  dropdownPlaceholder,
  noOptionsMessage,
  errorTxt,
  label,
  inline = false,
  minInlineWidth = '100%',
  savingPlaceholder = null,
}: Props) => {
  if (!dropdownPlaceholder) {
    dropdownPlaceholder = 'Select Option';
  }
  if (!noOptionsMessage) {
    noOptionsMessage = 'No options available';
  }
  const [displayedOptions, setDisplayedOptions] = useState([]);
  useEffect(() => {
    setDisplayedOptions(
      options.filter((o) => {
        return value.find((s) => s?.value === o.value) === undefined;
      })
    );
  }, [options, value]);

  return (
    <div className="alooba-input multiselect">
      {!label ? '' : <label htmlFor={label}>{label}</label>}
      <div className={`card ${errorTxt ? 'errorInput' : ''}`}>
        {value.map((option: SelectedValue) => {
          return option ? (
            <Pill
              handleRemovePill={(e) => {
                e.showSpinner = true;
                handleValueRemoved(e, option);
              }}
              key={option.value}
              text={option.label}
              hoverText={option.hoverText ? option.hoverText : option.label}
              showDeleteButton={!option.isBeingRemoved}
              showSpinner={option.isBeingRemoved}
              variant="ml2"
            />
          ) : null;
        })}
        {savingPlaceholder !== null && (
          <Pill
            key={`placeholder-${savingPlaceholder}`}
            text={savingPlaceholder}
            hoverText={savingPlaceholder}
            variant="ml2 disabled"
            showSpinner
          />
        )}
        <div
          className={inline ? 'inline ml2' : ''}
          style={{ minWidth: minInlineWidth }}
        >
          <Dropdown
            isAddButton
            value={null}
            placeholder={dropdownPlaceholder}
            noOptionsMessage={noOptionsMessage}
            dropdownOptions={displayedOptions}
            handleChange={handleOptionSelected}
          />
        </div>
      </div>
      {errorTxt && <p className="errorMsg">{errorTxt}</p>}
    </div>
  );
};
export default MultiSelect;
