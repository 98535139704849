import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './Input.scss';

export interface RadioInputProps {
  value?: string;
  label?: string;
  defaultValue?: string;
  helperText?: string;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  id?: string;
  onChange?: any;
  loading?: boolean;
}

const RadioInput = ({
  value,
  name,
  label,
  id,
  disabled,
  readOnly,
  defaultValue,
  checked,
  onChange,
  loading,
}: RadioInputProps): JSX.Element => (
  <>
    <label className="radio-button">
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        defaultValue={defaultValue}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
      />
      <span className="label-visible">
        {loading ? (
          <span className="radio-placeholder">
            <Skeleton circle height={24} width={24} />
          </span>
        ) : (
          <span className={`fake-radiobutton ${disabled ? 'disabled' : ''}`} />
        )}
        {!!label && label}
      </span>
    </label>
  </>
);

export default RadioInput;
