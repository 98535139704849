import CardContainer from 'components/Shared/MainContainer/CardContainer';
import './index.scss';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import AloobaPhoneInput from 'components/Shared/Input/AloobaPhoneInput';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Input } from 'components/Shared';
import { getUserLocationData } from 'api/ip-info';
import { handleError } from 'handleError';
import { useFormik } from 'formik';
import {
  updateUserAndOrganizationDetails,
  getUserExtraSignupData,
} from 'api/profile.api';
import { useToasts } from 'react-toast-notifications';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { refreshCurrentTokens } from 'store/actions/auth.actions';
import axios from 'axios';

const AccountSetup: React.FC = () => {
  const [countryCode, setCountryCode] = useState('us');
  const history = useHistory();
  const location = useLocation();
  const queryParams = location.search;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    getUserLocationData()
      .then((res) => {
        setCountryCode(res.data?.country?.toLowerCase());
      })
      .catch((err) => {
        handleError(err);
      });
    const cancelTokenSource = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      cancelTokenSource.cancel('Request was cancelled');
    });
    getUserExtraSignupData(cancelTokenSource.token)
      .then((res) => {
        if (res.data?.first_name)
          formik.setFieldValue('first_name', res.data?.first_name);
        if (res.data?.last_name)
          formik.setFieldValue('last_name', res.data?.last_name);
        if (res.data?.job_title)
          formik.setFieldValue('job_title', res.data?.job_title);
        if (res.data?.organization_name)
          formik.setFieldValue(
            'organization_name',
            res.data?.organization_name
          );
      })
      .catch((err) => {
        handleError(err);
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone_number: '',
      organization_name: '',
      job_title: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required('First Name is required')
        .min(2, 'First Name must be at least 2 characters'),
      last_name: Yup.string()
        .required('Last Name is required')
        .min(2, 'Last Name must be at least 2 characters'),
      phone_number: Yup.string()
        .required('Phone Number is required')
        .min(10, 'Phone Number must be at least 10 digits')
        .max(15, 'Phone Number can not be more than 15 digits'),
      organization_name: Yup.string()
        .required('Organization Name is required')
        .min(2, 'Organization Name must be at least 2 characters'),
      job_title: Yup.string()
        .required('Job Title is required')
        .min(2, 'Job Title must be at least 2 characters'),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        setLoading(true);
        updateUserAndOrganizationDetails({
          ...values,
          user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
          .then(async () => {
            await dispatch(refreshCurrentTokens());
            history.push(`/billing${queryParams}`);
          })
          .catch((error) => {
            const errors = error.response.data?.errors;
            if (errors) {
              const message = Object.values(errors)[0][0];
              addToast({
                type: 'error',
                msg: message,
              });
            } else {
              addToast({
                type: 'error',
                msg: 'Something went wrong. Please try again later.',
              });
              handleError(error);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <div className="signup-flow">
      <Helmet>
        <title>Complete Account Setup</title>
      </Helmet>
      <CardContainer>
        <h1>Complete Your Account Setup</h1>
        <p className="welcome">
          Welcome to Alooba! We are excited to help you streamline your hiring
          process. Just a couple more steps and you&#39;ll be all set to explore
          the features and benefits we offer.
        </p>
        <form onSubmit={formik.handleSubmit} className="form">
          <div className="first-form-row">
            <div className="form-item">
              <Input
                required
                type="text"
                label="Your First Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorTxt={
                  formik.touched.first_name && formik.errors.first_name
                    ? formik.errors.first_name
                    : ''
                }
                name="first_name"
                value={formik.values.first_name}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <Input
                required
                type="text"
                label="Your Last Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorTxt={
                  formik.touched.last_name && formik.errors.last_name
                    ? formik.errors.last_name
                    : ''
                }
                name="last_name"
                value={formik.values.last_name}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <AloobaPhoneInput
                required
                onChange={(e) => formik.setFieldValue('phone_number', e)}
                label="Your Phone Number"
                errorTxt={
                  formik.touched.phone_number && formik.errors.phone_number
                    ? formik.errors.phone_number
                    : ''
                }
                disabled={false}
                placeholder=""
                value={formik.values.phone_number}
                defaultCountryCode={countryCode}
              />
            </div>
          </div>
          <div className="second-form-row">
            <div className="form-item">
              <Input
                required
                type="text"
                label="Organization Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorTxt={
                  formik.touched.organization_name &&
                  formik.errors.organization_name
                    ? formik.errors.organization_name
                    : ''
                }
                name="organization_name"
                value={formik.values.organization_name}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <Input
                required
                type="text"
                label="Your Job Title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorTxt={
                  formik.touched.job_title && formik.errors.job_title
                    ? formik.errors.job_title
                    : ''
                }
                name="job_title"
                value={formik.values.job_title}
                disabled={false}
              />
            </div>
          </div>
          <div className="submit">
            <Button
              text="Save and Continue"
              variant="primary lg"
              type="submit"
              disabled={loading}
              loading={formik.isSubmitting}
              loadingTxt="Saving details"
            />
          </div>
        </form>
      </CardContainer>
    </div>
  );
};

export default AccountSetup;
