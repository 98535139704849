import React from 'react';
import './MainContainer.scss';

interface Props {
  children?: any;
  handleSubmit?: any;
}
const FormContainer: React.FC = (props: Props) => {
  const { children, handleSubmit } = props;
  return (
    <form className="form main-container" onSubmit={handleSubmit}>
      {children}
    </form>
  );
};
export default FormContainer;
