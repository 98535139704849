import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
  loading: boolean;
  children?: string | JSX.Element;
  width?: string | number;
}
const TextSkeleton = (props: Props): JSX.Element => {
  const { loading, children, width } = props;
  if (children === undefined && loading) {
    return <Skeleton width={width} />;
  }
  return <>{children}</>;
};

export default TextSkeleton;
