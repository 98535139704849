import React from 'react';
import Skeleton from 'react-loading-skeleton';
import DynamicTable from 'components/Shared/DynamicTable';
import './index.scss';

interface Props {
  loading?: boolean;
}
const CompletedTable = ({ loading = true }: Props): JSX.Element => {
  return (
    <div className="participants-container">
      {loading ? <Skeleton /> : <DynamicTable />}
    </div>
  );
};
export default CompletedTable;
