import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import apiLegacy from '../../api-legacy';
import { loginStart } from '../../store/reducers/auth';

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

function saveTokens(data: {
  access_token: string;
  refresh_token: string;
  expires_in: string;
}): void {
  const { access_token, refresh_token, expires_in } = data;
  if (access_token) {
    localStorage.setItem('@alooba/access_token', access_token);
  }
  if (refresh_token) {
    localStorage.setItem('@alooba/refresh_token', refresh_token);
  }
  if (expires_in) {
    localStorage.setItem('@alooba/expires_in', expires_in);
  }
}

const TemporarySignUp: React.FC = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const alias = query.get('alias');
  const trialCode = query.get('trial_code');
  const redirect = query.get('redirect');

  async function getJwt(token: string): Promise<any> {
    const legacy = await apiLegacy.post('/exchange-alias', {
      alias: token,
    });
    return legacy.data;
  }

  useMemo(() => {
    if (alias) {
      getJwt(alias).then((data) => {
        if (data?.data?.mfa_id) {
          history.push({
            pathname: `/login-mfa/${data.data.mfa_id}`,
            search: new URLSearchParams({
              redirectUrl: redirect || '',
              message: data.message,
              email: data.data.email,
            }).toString(),
          });
        } else {
          dispatch(loginStart());
          saveTokens(data);
          let redirectUrl = `${process.env.REACT_APP_URL}/alooba-assess`;
          if (redirect) {
            if (!redirect.startsWith('/')) {
              redirectUrl = `${process.env.REACT_APP_URL}/${redirect}`;
            } else {
              redirectUrl = `${process.env.REACT_APP_URL}${redirect}`;
            }
            redirectUrl += `?trial_code=${trialCode}`;
          }
          window.location.href = redirectUrl;
        }
      });
    }
  }, [alias, dispatch, history, redirect, trialCode]);

  return <LoadingOverlay active spinner={<BounceLoader color="gray" />} />;
};

export default TemporarySignUp;
