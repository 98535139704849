import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './groups.scss';
import { RootState } from 'store/rootReducer';
import { useToasts } from 'react-toast-notifications';
import { Button, NoPageContent } from 'components/Shared';
import { useErrorHandler } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import {
  getOrganizationGroups,
  createOrganizationGroups,
  deleteOrganizationGroups,
  addGroupUser,
  removeGroupUser,
  updateOrganizationGroup,
  checkGroupAssessment,
  getOrganizationUsers,
} from 'api/profile.api';
import { refreshCurrentTokens } from '../../store/actions/auth.actions';
import store from '../../store/store';
import GroupForm from './groupForm';
import GroupPlaceholder from './groupPlaceholder';
import { hasOrgFeature } from '../../Authorization/Check';

const intro = `Manage groups to restrict access to assessments and candidate information. 
When creating an assessment, assign it to a group so that only the members of 
that group can access the assessment and candidate information. The account owner 
will always have access to all assessments.`;
interface UsersProps {
  id?: string;
  label?: string;
}
const Groups: React.FC = () => {
  const [groupNames, setGroupName] = useState<any>({});
  const [currentGroup, setCurrentGroup] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [creatingGroup, setCreatingGroup] = useState<boolean>(false);
  const [deletingGroup, setDeletingGroup] = useState<boolean>(false);
  const [errorTexts, setErrorText] = useState<any>({});
  const [valueOnFocus, setValueOnFocus] = useState<string>('');
  const [organizationUsers, setOrganizationUsers] = useState<UsersProps[]>([]);
  const [orgGroups, setOrganizationGroups] = useState<any>([]);

  const history = useHistory();
  const { addToast } = useToasts();
  const handleError = useErrorHandler();
  const { userDetails } = useSelector((state: RootState) => state.profile);

  const handleGroupName = ({ target }): any => {
    const errorsObject = { ...errorTexts };
    const isMatch = Object.entries(groupNames).filter((entry, idx) => {
      return entry[1] === target.value && target.id !== `name${idx}`;
    });
    if (isMatch.length) {
      errorsObject[target.id] = 'You must provide a unique group name.';
      setErrorText(errorsObject);
    } else if (target.value.length === 0) {
      errorsObject[target.id] = 'You must provide a group name';
      setErrorText(errorsObject);
    } else if (target.value.length < 4) {
      errorsObject[target.id] =
        'Your group name must contain at least 4 characters';
      setErrorText(errorsObject);
    } else if (target.value.length > 40) {
      errorsObject[target.id] =
        'Your group name must contain a maximum of 40 characters';
      setErrorText(errorsObject);
    } else {
      errorsObject[target.id] = '';
      setErrorText(errorsObject);
    }
    const newGroupNames = { ...groupNames };
    newGroupNames[target.id] = target.value;
    setGroupName(newGroupNames);
  };

  const handleOnFocus = ({ target }): any => {
    setValueOnFocus(target.value);
  };

  const handleUpdateGroupName = async ({ target }, groupId): Promise<void> => {
    if (valueOnFocus !== target.value) {
      const response = await updateOrganizationGroup(groupId, target.value);
      if (response.status === 200) {
        const data = response.data ? response.data.data : [];
        setOrganizationGroups(data);
        addToast({
          type: 'success',
          msg: 'Group name successfully updated.',
        });
      } else {
        addToast({
          type: 'error',
          msg: 'An error occured while updating group name. Please try again!',
        });
      }
    }
  };

  const handleAddUser = async (group, user): Promise<void> => {
    if (user.permissions === 'Owner') {
      addToast({
        type: 'warning',
        msg: 'Owner users have full access and cannot be added to groups.',
      });
      return;
    }
    try {
      const response = await addGroupUser(group.id, user.id);
      if (response.status === 200) {
        const data = response.data ? response.data.data : [];
        setOrganizationGroups(data);
        addToast({
          type: 'success',
          msg: `${user.label} has been added to ${group.name}`,
        });
        const groupUsers = data.filter((g) => g.id === group.id)[0]?.users;
        const currentUserAdded =
          groupUsers?.filter((u) => u.email === userDetails.email).length > 0;
        if (currentUserAdded) {
          store.dispatch(refreshCurrentTokens());
        }
      }
    } catch (e) {
      addToast({
        type: 'error',
        msg:
          'An error occured while adding the user to the group. Please try again or refresh the page.',
      });
    }
  };

  const handleRemoveUser = async (group, user): Promise<void> => {
    try {
      const deletedUser = group.users.find((u) => u.id === user.value);
      const response = await removeGroupUser(group.id, user.value);
      if (response.status === 200) {
        const data = response.data ? response.data.data : [];
        setOrganizationGroups(data);
        addToast({
          type: 'success',
          msg: `${`${deletedUser.first_name} ${deletedUser.last_name}`} has been removed from ${
            group.name
          }`,
        });
        const userBelongedToGroup =
          group.users.filter((u) => u.email === userDetails.email).length > 0;
        const groupUsers = data.filter((g) => g.id === group.id)[0]?.users;
        const currentUserRemoved =
          groupUsers?.filter((u) => u.email === userDetails.email).length ===
            0 && userBelongedToGroup;
        if (currentUserRemoved) {
          store.dispatch(refreshCurrentTokens());
        }
      }
    } catch (e) {
      addToast({
        type: 'error',
        msg:
          'An error occured while removing user from group. Please try again!',
      });
    }
  };

  const handleCreateGroup = async (): Promise<void> => {
    setCreatingGroup(true);
    const response = await createOrganizationGroups();
    if (response.status === 200) {
      fetchOrgGroups();
      addToast({
        type: 'success',
        msg: 'You have successfully created a new group!',
      });
    } else {
      addToast({
        type: 'error',
        msg: 'An error occured while creating a new group. Please try again!',
      });
    }
    setCreatingGroup(false);
  };

  const handleCurrentGroupSelect = (e, idx): any => {
    setCurrentGroup(idx);
  };

  const handleDeleteGroup = async (group): Promise<void> => {
    setDeletingGroup(true);
    const hasAssessment = await checkGroupAssessment(group.id);
    if (hasAssessment) {
      addToast({
        type: 'warning',
        msg: `Unable to delete ${group.name}. It has active assessments.`,
      });
      setDeletingGroup(false);
    } else {
      const response = await deleteOrganizationGroups(group.id);
      if (response.status === 200) {
        addToast({
          type: 'success',
          msg: `${group.name} has been deleted`,
        });
        setDeletingGroup(false);
        fetchOrgGroups();
      } else {
        addToast({
          type: 'error',
          msg: `An error occured while deleting ${group.name}. Please try again!`,
        });
        setDeletingGroup(false);
      }
    }
  };

  const fetchOrgGroups = async (): Promise<void> => {
    const response = await getOrganizationGroups();
    if (response.status === 200) {
      const data = response.data ? response.data.data : [];
      setOrganizationGroups(data);
      if (data.length > 0) {
        setValueOnFocus(data[0].name);
      }
      const defaultGroupNames = {};
      for (let i = 0; i < data.length; i += 1) {
        defaultGroupNames[`name${[i]}`] = data[i].name;
      }
      setGroupName(defaultGroupNames);
    } else {
      addToast({
        type: 'error',
        msg:
          'An error occured while fetching organization groups. Please try again!',
      });
    }
  };

  const clickUpgrade = (): void => {
    history.push('/billing');
  };

  const orgId =
    userDetails &&
    userDetails.recruiter_detail &&
    userDetails.recruiter_detail.organisation_id;
  const getOrgUsers = async (): Promise<void> => {
    if (orgId) {
      const response = await getOrganizationUsers(orgId, { perPage: -1 });
      if (response?.data) {
        const orgUsers = response.data.data.data
          .map((user) => {
            return {
              id: user.id,
              label: `${user.Name} <${user.Email}>`,
              code: user.Name,
              email: user.Email,
              permissions: user.Permissions,
            };
          })
          .filter((user) => {
            return user.code !== 'Alooba Support';
          });
        setOrganizationUsers(orgUsers);
      } else {
        addToast({
          type: 'error',
          msg:
            'An error occured while fetching organization users. Please try again!',
        });
      }
    }
  };
  const fetchOrgGroupsOnPageLoad = async (): Promise<void> => {
    setLoading(true);
    await fetchOrgGroups();
    setLoading(false);
  };

  useEffect(() => {
    fetchOrgGroupsOnPageLoad().catch((err) => handleError(err));
    getOrgUsers().catch((err) => handleError(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  let mainComponent = <GroupPlaceholder />;

  if (hasOrgFeature('groups')) {
    if (loading) {
      mainComponent = <GroupPlaceholder />;
    } else {
      mainComponent =
        orgGroups && orgGroups.length > 0 ? (
          orgGroups.map((group, idx) => (
            <GroupForm
              key={group.id}
              id={`name${idx}`}
              autoFocus={idx === 0}
              errorText={errorTexts[`name${idx}`]}
              value={groupNames[`name${idx}`]}
              handleDeleteGroup={() => handleDeleteGroup(group)}
              deletingGroup={currentGroup === idx ? deletingGroup : null}
              handleGroupSelect={(e) => handleCurrentGroupSelect(e, idx)}
              handleGroupName={handleGroupName}
              handleUpdateGroupName={(e) => {
                handleUpdateGroupName(e, group.id);
              }}
              handleOnFocus={handleOnFocus}
              members={group.users}
              orgMembersList={organizationUsers}
              handleAddUser={(user) => handleAddUser(group, user)}
              handleRemoveUser={(_e, user) => handleRemoveUser(group, user)}
            />
          ))
        ) : (
          <NoPageContent
            btnTxt="Create Group"
            title="No Groups Created Yet!"
            desc="You don't have any groups yet. Click on the 'Create Group' button to start adding your organization users to groups."
            handleClick={handleCreateGroup}
          />
        );
    }
  } else {
    mainComponent = (
      <NoPageContent
        btnTxt="Upgrade Subscription"
        title="Upgrade Your Subscription To Manage Groups!"
        desc={`Group management is not included in your current subscription plan.

        Upgrade your subscription to access group management and many other amazing features Alooba has to offer.`}
        icon={false}
        handleClick={clickUpgrade}
      />
    );
  }

  return (
    <div id="groups">
      <div className="intro">
        <div className="desc">
          <h2 className="mb4">
            Groups
            <Button
              addButton
              loading={creatingGroup}
              disabled={creatingGroup || !hasOrgFeature('groups')}
              onClick={handleCreateGroup}
              variant="primary md right"
              text="Create Group"
            />
          </h2>
          <p>{intro}</p>
        </div>
      </div>
      <hr />
      {mainComponent}
    </div>
  );
};

export default Groups;
