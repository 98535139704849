import { handleError } from 'handleError';
import { QuestionType } from 'helpers/constants';
import api from './index';

export async function getInterviews(assessmentId): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/interviews?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function createInterview(assessmentId): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/interviews?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url);
}

export async function editInterview(
  assessmentId,
  interviewId,
  values
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/interviews/${interviewId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.patch(url, values);
}

export async function getInterview(interviewId): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/interviews/${interviewId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function getInterviewCandidates(
  assessmentId,
  queryParams
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/assessments/${assessmentId}/interview-candidates`;
  return api
    .get(url, {
      params: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        assessmentId,
        ...queryParams,
      },
    })
    .catch((error) => {
      handleError(error);
      if (error.response && error.response.status === 403) {
        window.location.replace('/forbidden');
      }
    });
}

export async function inviteCandidateToInterview(
  candidateReference: string,
  interview_id: number,
  meeting_url: string,
  interviewers: number[],
  timezone: string,
  date: string,
  note?: string
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${candidateReference}/interviews?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    interview_id,
    meeting_url,
    interviewers,
    timezone,
    date,
    note,
  });
}

export async function addInterviewQuestion(
  question: string,
  subject_id: number,
  time_estimate: number,
  max_score: number,
  type: QuestionType
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/interviews/questions?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.post(url, {
    question,
    subject_id,
    time_estimate,
    max_score,
    type,
  });
}
