import React from 'react';
import HistogramChart from 'components/Shared/Histogram/index';

interface Frequency {
  label: string;
  count: number;
}
interface Summary {
  total: number;
  summary: Frequency[];
}

const DynamicHistogram: React.FC<{
  stats: Summary;
  histogramHeight: number;
  xAxisLabel: string;
  yAxisLabel: string;
  bucketIntervals?: number;
}> = ({
  stats,
  histogramHeight,
  xAxisLabel,
  yAxisLabel,
  bucketIntervals,
}): JSX.Element => {
  const frequencyValues = stats.summary
    .map((o) => parseInt(o.label, 10))
    .filter((age) => !Number.isNaN(age));
  const maxValue = Math.max(...frequencyValues);
  const minValue = Math.min(...frequencyValues);

  const meanDistance = Math.round(
    frequencyValues.reduce((sum, current) => {
      sum += current;
      return sum;
    }, 0) / frequencyValues.length
  );

  /**
   * use the mean difference as bucket intervals
   * until the max value is reached
   */
  const groupUntilMaxed = (s: Summary): Array<any> => {
    const buckets = [];
    let intervals = Math.min(Math.round(meanDistance / 4), 10);
    const iterations = (maxValue - minValue) / intervals;
    const normalizedIntervals = Math.round((maxValue - minValue) / 10);
    intervals = iterations > 10 ? normalizedIntervals : intervals;

    let min = minValue;
    let max = minValue;

    while (max < maxValue) {
      min = max;
      max = min + intervals;
      if (max > maxValue) {
        max = maxValue;
      }
      buckets.push({
        label: `${min}-${max}`,
        value: 0,
        min,
        max,
      });
    }

    s.summary.forEach(({ label, count }) => {
      const value = parseInt(label, 10);
      if (!Number.isNaN(value)) {
        const bracket = buckets.find((e) => value <= e.max && value >= e.min);
        if (bracket) {
          bracket.value += count;
        }
      }
    });
    return buckets;
  };

  const groupByNumberOfBuckets = (
    s: Summary,
    bucketCount: number
  ): Array<any> => {
    const buckets = [];
    let max = maxValue;
    if (maxValue > bucketCount && s.summary.length > 1) {
      max = bucketCount;
    }
    const range = Math.round(max / bucketCount);
    for (let i = 0; i < bucketCount; i += 1) {
      const bucketMin = range * i;
      const bucketMax = range * (i + 1);
      let label = `${bucketMin}-${bucketMax}`;
      if (!Number.isInteger(bucketMin) || !Number.isInteger(bucketMax)) {
        label = `${bucketMin.toFixed(1)}-${bucketMax.toFixed(1)}`;
      }
      buckets.push({
        label,
        value: 0,
        min: bucketMin,
        max: bucketMax,
      });
    }

    s.summary.forEach(({ label, count }) => {
      const bound = Number.parseFloat(label);
      if (!Number.isNaN(bound)) {
        const bracket = buckets.find((e) => bound <= e.max && bound >= e.min);
        bracket.value += count;
      }
    });
    return buckets;
  };

  // small number of choices selected by the participants
  if (frequencyValues.length < 10 && maxValue <= frequencyValues.length) {
    bucketIntervals = maxValue / frequencyValues.length;
  }

  let buckets = [];
  if (bucketIntervals) {
    const bucketCount = maxValue / bucketIntervals;
    buckets = groupByNumberOfBuckets(stats, bucketCount);
  } else {
    buckets = groupUntilMaxed(stats);
  }

  return (
    <HistogramChart
      height={histogramHeight}
      data={buckets}
      lineAmount={2}
      yaxis={yAxisLabel}
      xaxis={xAxisLabel}
    />
  );
};

export default DynamicHistogram;
