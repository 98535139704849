import Axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import base64 from 'base-64';

const headers = new Headers();

if (process.env.REACT_SERVER_AUTH) {
  headers.append(
    'Authorization',
    `Basic ${base64.encode(process.env.REACT_SERVER_AUTH)}`
  );
}

export const apiLegacy = Axios.create({
  baseURL: process.env.REACT_APP_ALOOBA_LEGACY_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

axiosBetterStacktrace(apiLegacy);

export default apiLegacy;
