import React, { useEffect, useState } from 'react';
import './rejectCandidateModal.scss';
import { FormHelperText, MenuItem } from '@material-ui/core';
import { Modal } from '../Modal';
import CustomSelect from '../CustomSelect';
import Input from '../Input';
import Switcher from '../Switcher';
import TextArea from '../Input/textArea';

interface RejectCandidateModalProps {
  candidateName: string;
  isShown: boolean;
  onCancel?: () => void;
  onConfirm?: (RejectReasonPayload) => void;
}
export interface RejectReasonPayload {
  reason: string;
  notifyCandidate: boolean;
  note: string | null;
}

const otherReason = 'Other';
const rejectionReasons: string[] = [
  'Scored too low overall',
  'Scored too low for a core skill',
  'Insufficient relevant experience',
  'Poor communication',
  'Overqualified for the role',
  "Doesn't have an appropriate visa",
  'Misalignment of job expectations',
  'Misalignment of salary expectations',
  'Misalignment of expected working hours',
  'Not willing to relocate',
  'No show to interview',
  'Failed background checks',
  'Lack of interest or enthusiasm for the role',
  'Overbearing or conceited',
  'Not aligned to values',
  otherReason,
];
const RejectCandidateModal: React.FC<RejectCandidateModalProps> = ({
  candidateName,
  isShown,
  onCancel,
  onConfirm,
}): JSX.Element => {
  const [rejectReason, setRejectReason] = useState<string>('');
  const [inputReason, setInputReason] = useState();
  const [errorText, setErrorText] = useState<string>();
  const [note, setNote] = useState<string>();
  const [sendEmailToCandidate, setSendEmailToCandidate] = useState(true);
  const [showReasonInputField, setShowReasonInputField] = useState(false);
  const [actionButtonText, setActionButtonText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onReasonSelected = (e): void => {
    const selectedReason = rejectionReasons.find(
      (r: string) => r === e.target.value
    );
    setRejectReason(selectedReason);
  };

  useEffect(() => {
    setShowReasonInputField(rejectReason === otherReason);
  }, [rejectReason]);

  // removes read-only when hidden and shown again
  useEffect(() => {
    if (isShown) {
      setIsLoading(false);
    }
  }, [isShown]);

  useEffect(() => {
    if (sendEmailToCandidate) {
      setActionButtonText('Reject & Notify Candidate');
    } else {
      setActionButtonText('Reject without notification');
    }
  }, [sendEmailToCandidate]);

  const onClickConfirm = (): void => {
    setErrorText(null);
    let reason = rejectReason;
    if (reason === otherReason) {
      reason = inputReason;
    }
    if (!reason || reason.length < 1) {
      setErrorText('Please provide a reason');
      return;
    }

    if (onConfirm) {
      const payload: RejectReasonPayload = {
        reason,
        note,
        notifyCandidate: sendEmailToCandidate,
      };
      onConfirm(payload);
      setIsLoading(true);
    }
  };
  const onClickCancel = (): void => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div>
      <Modal
        isShown={isShown}
        actionText={actionButtonText}
        cancelButtonText="Cancel"
        containerClass="reject-modal-wrapper"
        showCancel
        handleButtonAction={onClickConfirm}
        handleClose={onClickCancel}
      >
        <div className="reject-candidate-modal">
          <h2>Reject Candidate</h2>
          <p data-testid="reject-description" className="description">
            Please provide a reason why you are rejecting {candidateName}. Note,
            this will not be shared with the candidate.
          </p>
          <div className="reasons">
            <div className="alooba-input">
              <label id="reason-select-label">Select a reason *</label>
              <CustomSelect
                inputProps={{ 'data-testid': 'reason-select' }}
                SelectDisplayProps={
                  { 'data-testid': 'reason-select-button' } as any
                }
                disabled={isLoading}
                fullWidth
                value={rejectReason}
                onChange={onReasonSelected}
              >
                {rejectionReasons.map((reason) => {
                  return (
                    <MenuItem key={reason} value={reason}>
                      {reason}
                    </MenuItem>
                  );
                })}
              </CustomSelect>
              {showReasonInputField ? (
                ''
              ) : (
                <FormHelperText error>{errorText}</FormHelperText>
              )}
            </div>
            <div data-testid="specify-reason-field">
              {showReasonInputField ? (
                <Input
                  label="Specify the reason *"
                  errorTxt={errorText}
                  onChange={(e) => setInputReason(e.target.value)}
                  disabled={isLoading}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="candidate-notification-fields">
            <h3>Notify Candidate</h3>
            <div>
              <Switcher
                inputProps={{ 'data-testid': 'notify-switch' }}
                label="Send email notification to the candidate?"
                checked={sendEmailToCandidate}
                onChange={(e) => setSendEmailToCandidate(e.target.checked)}
                disabled={isLoading}
              />
            </div>

            {sendEmailToCandidate ? (
              <p id="notification-description" className="description">
                An email will be sent to {candidateName} to inform them that
                their application is no longer being considered.
              </p>
            ) : (
              ''
            )}
            <div className="note-input">
              {sendEmailToCandidate ? (
                <TextArea
                  id="candidate-reject-note"
                  placeholder=""
                  label="Optional message for the candidate"
                  disabled={isLoading}
                  onChange={(e) => setNote(e.target.value)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <hr />
        </div>
      </Modal>
    </div>
  );
};
export default RejectCandidateModal;
