import React from 'react';
import { formatBytes } from 'helpers';
import CloudDownload from '../../../../images/icons/cloud-download-blue.svg';

export interface Props {
  file: any;
  candidateId: number;
}

const UploadedFileCard: React.FC<Props> = ({ file, candidateId }) => {
  const fileTypeParts = file.file_type.split('/');
  const fileExt = fileTypeParts[fileTypeParts.length - 1];
  return (
    <a
      href={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/data-analysis-working-files/${candidateId}/${file.file_path}`}
      download={file.file_name}
    >
      <div className="file-card">
        <div className="top-section">
          <div className="left-section">
            <div className="file-ext">{fileExt}</div>
            <div className="file-size">{formatBytes(file.file_size)}</div>
          </div>
          <div className="right-section">
            <img src={CloudDownload} alt="Cloud download icon" />
          </div>
        </div>
        <div className="bottom-section">{file.file_name}</div>
      </div>
    </a>
  );
};
export default UploadedFileCard;
